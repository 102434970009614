import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "oliCloud® - Acceso a profesionales"
    }
  },
  {
    path: '/inicio',
    name: 'Almazara',
    meta: {
      title: "Almazara - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Inicio" */ '../views/Inicio.vue')
  },
  {
    path: '/productores',
    name: 'Productores',
    meta: {
      title: "Productores - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Productores" */ '../views/Productores.vue')
  },
  {
    path: '/producto',
    name: 'Producto',
    meta: {
      title: "Entrada de producto - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Producto" */ '../views/Producto.vue')
  },
  {
    path: '/liquidaciones',
    name: 'Liquidaciones',
    meta: {
      title: "Liquidaciones y anticipos - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Liquidaciones" */ '../views/Liquidaciones.vue')
  },
  {
    path: '/solicitudes',
    name: 'Solicitudes',
    meta: {
      title: "Solicitudes - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Solicitudes" */ '../views/Solicitudes.vue')
  },
  {
    path: '/rendimientos',
    name: 'Análisis rendimientos',
    meta: {
      title: "Análisis rendimientos - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Rendimientos" */ '../views/Rendimientos.vue')
  },
  {
    path: '/productos',
    name: 'Productos',
    meta: {
      title: "Productos - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Productos" */ '../views/Productos.vue')
  },
  {
    path: '/precios',
    name: 'Precios',
    meta: {
      title: "Fijación de precios - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Precios" */ '../views/Precios.vue')
  },
  {
    path: '/importacion',
    name: 'Importacion',
    meta: {
      title: "Importación de datos - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Importacion" */ '../views/Importacion.vue')
  },
  {
    path: '/documentacion',
    name: 'Documentacion',
    meta: {
      title: "Documentación - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Documentacion" */ '../views/Documentacion.vue')
  },
  {
    path: '/noticias',
    name: 'Noticias',
    meta: {
      title: "Noticias y avisos - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Noticias" */ '../views/Noticias.vue')
  },
  {
    path: '/informes',
    name: 'Informes',
    meta: {
      title: "Informes - oliCloud®"
    },
    component: () => import(/* webpackChunkName: "Informes" */ '../views/Informes.vue')
  },
  {
    path: '/recoverPassword',
    name: 'RecoverPass',
    meta: {
      title: "Recuperar contraseña - OliCloud®"
    },
    component: () => import(/* webpackChunkName: "RecoverPass" */ '../views/RecoverPass.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if(to.hash.indexOf("#tk")>-1){
    router.push({name: 'Login', params: { tk: to.hash.substring(3)}});
  }else{
    if(store.state.auth == "" && to.name != "Login"){
      location.href = "https://olicloud.es/acceso";
    }else{
      next();
    }
  }  
})

export default router
