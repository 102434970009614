import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#233d4d',
        primary_dark: '#001725',
        primary_light: '#4e6779',
        secondary: '#91dc5a',
        secondary_dark: '#5ba824',
        secondary_light: '#b8e996',
        white: '#fff',
        red: '#ff1f1f',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});
