import genericReload from "../components/genericReload";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from "vuex-multi-tab-state";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: "",
    user: "",
    productor: [],
    productos: [],
    precios: [],
    fincas: [],
    provincias: [
      {
        id: "04",
        nm: "Almería",
      },
      {
        id: "11",
        nm: "Cádiz",
      },
      {
        id: "14",
        nm: "Córdoba",
      },
      {
        id: "18",
        nm: "Granada",
      },
      {
        id: "21",
        nm: "Huelva",
      },
      {
        id: "23",
        nm: "Jaén",
      },
      {
        id: "29",
        nm: "Málaga",
      },
      {
        id: "41",
        nm: "Sevilla",
      },
      {
        id: "22",
        nm: "Huesca",
      },
      {
        id: "44",
        nm: "Teruel",
      },
      {
        id: "50",
        nm: "Zaragoza",
      },
      {
        id: "33",
        nm: "Asturias",
      },
      {
        id: "07",
        nm: "Balears, Illes",
      },
      {
        id: "35",
        nm: "Palmas, Las",
      },
      {
        id: "38",
        nm: "Santa Cruz de Tenerife",
      },
      {
        id: "39",
        nm: "Cantabria",
      },
      {
        id: "05",
        nm: "Ávila",
      },
      {
        id: "09",
        nm: "Burgos",
      },
      {
        id: "24",
        nm: "León",
      },
      {
        id: "34",
        nm: "Palencia",
      },
      {
        id: "37",
        nm: "Salamanca",
      },
      {
        id: "40",
        nm: "Segovia",
      },
      {
        id: "42",
        nm: "Soria",
      },
      {
        id: "47",
        nm: "Valladolid",
      },
      {
        id: "49",
        nm: "Zamora",
      },
      {
        id: "02",
        nm: "Albacete",
      },
      {
        id: "13",
        nm: "Ciudad Real",
      },
      {
        id: "16",
        nm: "Cuenca",
      },
      {
        id: "19",
        nm: "Guadalajara",
      },
      {
        id: "45",
        nm: "Toledo",
      },
      {
        id: "08",
        nm: "Barcelona",
      },
      {
        id: "17",
        nm: "Girona",
      },
      {
        id: "25",
        nm: "Lleida",
      },
      {
        id: "43",
        nm: "Tarragona",
      },
      {
        id: "03",
        nm: "Alicante/Alacant",
      },
      {
        id: "12",
        nm: "Castellón/Castelló",
      },
      {
        id: "46",
        nm: "Valencia/València",
      },
      {
        id: "06",
        nm: "Badajoz",
      },
      {
        id: "10",
        nm: "Cáceres",
      },
      {
        id: "15",
        nm: "Coruña, A",
      },
      {
        id: "27",
        nm: "Lugo",
      },
      {
        id: "32",
        nm: "Ourense",
      },
      {
        id: "36",
        nm: "Pontevedra",
      },
      {
        id: "28",
        nm: "Madrid",
      },
      {
        id: "30",
        nm: "Murcia",
      },
      {
        id: "31",
        nm: "Navarra",
      },
      {
        id: "01",
        nm: "Araba/Álava",
      },
      {
        id: "48",
        nm: "Bizkaia",
      },
      {
        id: "20",
        nm: "Gipuzkoa",
      },
      {
        id: "26",
        nm: "Rioja, La",
      },
      {
        id: "51",
        nm: "Ceuta",
      },
      {
        id: "52",
        nm: "Melilla",
      },
    ],
    municipios: [
      {
        id: "01002",
        nm: "Amurrio",
      },
      {
        id: "01049",
        nm: "Añana",
      },
      {
        id: "01003",
        nm: "Aramaio",
      },
      {
        id: "01006",
        nm: "Armiñón",
      },
      {
        id: "01037",
        nm: "Arraia-Maeztu",
      },
      {
        id: "01008",
        nm: "Arratzua-Ubarrundia",
      },
      {
        id: "01004",
        nm: "Artziniega",
      },
      {
        id: "01009",
        nm: "Asparrena",
      },
      {
        id: "01010",
        nm: "Ayala/Aiara",
      },
      {
        id: "01011",
        nm: "Baños de Ebro/Mañueta",
      },
      {
        id: "01013",
        nm: "Barrundia",
      },
      {
        id: "01014",
        nm: "Berantevilla",
      },
      {
        id: "01016",
        nm: "Bernedo",
      },
      {
        id: "01017",
        nm: "Campezo/Kanpezu",
      },
      {
        id: "01021",
        nm: "Elburgo/Burgelu",
      },
      {
        id: "01022",
        nm: "Elciego",
      },
      {
        id: "01023",
        nm: "Elvillar/Bilar",
      },
      {
        id: "01046",
        nm: "Erriberagoitia/Ribera Alta",
      },
      {
        id: "01056",
        nm: "Harana/Valle de Arana",
      },
      {
        id: "01901",
        nm: "Iruña Oka/Iruña de Oca",
      },
      {
        id: "01027",
        nm: "Iruraiz-Gauna",
      },
      {
        id: "01019",
        nm: "Kripan",
      },
      {
        id: "01020",
        nm: "Kuartango",
      },
      {
        id: "01028",
        nm: "Labastida/Bastida",
      },
      {
        id: "01030",
        nm: "Lagrán",
      },
      {
        id: "01031",
        nm: "Laguardia",
      },
      {
        id: "01032",
        nm: "Lanciego/Lantziego",
      },
      {
        id: "01902",
        nm: "Lantarón",
      },
      {
        id: "01033",
        nm: "Lapuebla de Labarca",
      },
      {
        id: "01036",
        nm: "Laudio/Llodio",
      },
      {
        id: "01058",
        nm: "Legutio",
      },
      {
        id: "01034",
        nm: "Leza",
      },
      {
        id: "01039",
        nm: "Moreda de Álava/Moreda Araba",
      },
      {
        id: "01041",
        nm: "Navaridas",
      },
      {
        id: "01042",
        nm: "Okondo",
      },
      {
        id: "01043",
        nm: "Oyón-Oion",
      },
      {
        id: "01044",
        nm: "Peñacerrada-Urizaharra",
      },
      {
        id: "01047",
        nm: "Ribera Baja/Erribera Beitia",
      },
      {
        id: "01051",
        nm: "Salvatierra/Agurain",
      },
      {
        id: "01052",
        nm: "Samaniego",
      },
      {
        id: "01053",
        nm: "San Millán/Donemiliaga",
      },
      {
        id: "01054",
        nm: "Urkabustaiz",
      },
      {
        id: "01055",
        nm: "Valdegovía/Gaubea",
      },
      {
        id: "01057",
        nm: "Villabuena de Álava/Eskuernaga",
      },
      {
        id: "01059",
        nm: "Vitoria-Gasteiz",
      },
      {
        id: "01060",
        nm: "Yécora/Iekora",
      },
      {
        id: "01061",
        nm: "Zalduondo",
      },
      {
        id: "01062",
        nm: "Zambrana",
      },
      {
        id: "01018",
        nm: "Zigoitia",
      },
      {
        id: "01063",
        nm: "Zuia",
      },
      {
        id: "02001",
        nm: "Abengibre",
      },
      {
        id: "02002",
        nm: "Alatoz",
      },
      {
        id: "02003",
        nm: "Albacete",
      },
      {
        id: "02004",
        nm: "Albatana",
      },
      {
        id: "02005",
        nm: "Alborea",
      },
      {
        id: "02006",
        nm: "Alcadozo",
      },
      {
        id: "02007",
        nm: "Alcalá del Júcar",
      },
      {
        id: "02008",
        nm: "Alcaraz",
      },
      {
        id: "02009",
        nm: "Almansa",
      },
      {
        id: "02010",
        nm: "Alpera",
      },
      {
        id: "02011",
        nm: "Ayna",
      },
      {
        id: "02012",
        nm: "Balazote",
      },
      {
        id: "02014",
        nm: "Ballestero, El",
      },
      {
        id: "02013",
        nm: "Balsa de Ves",
      },
      {
        id: "02015",
        nm: "Barrax",
      },
      {
        id: "02016",
        nm: "Bienservida",
      },
      {
        id: "02017",
        nm: "Bogarra",
      },
      {
        id: "02018",
        nm: "Bonete",
      },
      {
        id: "02019",
        nm: "Bonillo, El",
      },
      {
        id: "02020",
        nm: "Carcelén",
      },
      {
        id: "02021",
        nm: "Casas de Juan Núñez",
      },
      {
        id: "02022",
        nm: "Casas de Lázaro",
      },
      {
        id: "02023",
        nm: "Casas de Ves",
      },
      {
        id: "02024",
        nm: "Casas-Ibáñez",
      },
      {
        id: "02025",
        nm: "Caudete",
      },
      {
        id: "02026",
        nm: "Cenizate",
      },
      {
        id: "02029",
        nm: "Chinchilla de Monte-Aragón",
      },
      {
        id: "02027",
        nm: "Corral-Rubio",
      },
      {
        id: "02028",
        nm: "Cotillas",
      },
      {
        id: "02030",
        nm: "Elche de la Sierra",
      },
      {
        id: "02031",
        nm: "Férez",
      },
      {
        id: "02032",
        nm: "Fuensanta",
      },
      {
        id: "02033",
        nm: "Fuente-Álamo",
      },
      {
        id: "02034",
        nm: "Fuentealbilla",
      },
      {
        id: "02035",
        nm: "Gineta, La",
      },
      {
        id: "02036",
        nm: "Golosalvo",
      },
      {
        id: "02037",
        nm: "Hellín",
      },
      {
        id: "02038",
        nm: "Herrera, La",
      },
      {
        id: "02039",
        nm: "Higueruela",
      },
      {
        id: "02040",
        nm: "Hoya-Gonzalo",
      },
      {
        id: "02041",
        nm: "Jorquera",
      },
      {
        id: "02042",
        nm: "Letur",
      },
      {
        id: "02043",
        nm: "Lezuza",
      },
      {
        id: "02044",
        nm: "Liétor",
      },
      {
        id: "02045",
        nm: "Madrigueras",
      },
      {
        id: "02046",
        nm: "Mahora",
      },
      {
        id: "02047",
        nm: "Masegoso",
      },
      {
        id: "02048",
        nm: "Minaya",
      },
      {
        id: "02049",
        nm: "Molinicos",
      },
      {
        id: "02050",
        nm: "Montalvos",
      },
      {
        id: "02051",
        nm: "Montealegre del Castillo",
      },
      {
        id: "02052",
        nm: "Motilleja",
      },
      {
        id: "02053",
        nm: "Munera",
      },
      {
        id: "02054",
        nm: "Navas de Jorquera",
      },
      {
        id: "02055",
        nm: "Nerpio",
      },
      {
        id: "02056",
        nm: "Ontur",
      },
      {
        id: "02057",
        nm: "Ossa de Montiel",
      },
      {
        id: "02058",
        nm: "Paterna del Madera",
      },
      {
        id: "02060",
        nm: "Peñas de San Pedro",
      },
      {
        id: "02059",
        nm: "Peñascosa",
      },
      {
        id: "02061",
        nm: "Pétrola",
      },
      {
        id: "02062",
        nm: "Povedilla",
      },
      {
        id: "02901",
        nm: "Pozo Cañada",
      },
      {
        id: "02063",
        nm: "Pozohondo",
      },
      {
        id: "02064",
        nm: "Pozo-Lorente",
      },
      {
        id: "02065",
        nm: "Pozuelo",
      },
      {
        id: "02066",
        nm: "Recueja, La",
      },
      {
        id: "02067",
        nm: "Riópar",
      },
      {
        id: "02068",
        nm: "Robledo",
      },
      {
        id: "02069",
        nm: "Roda, La",
      },
      {
        id: "02070",
        nm: "Salobre",
      },
      {
        id: "02071",
        nm: "San Pedro",
      },
      {
        id: "02072",
        nm: "Socovos",
      },
      {
        id: "02073",
        nm: "Tarazona de la Mancha",
      },
      {
        id: "02074",
        nm: "Tobarra",
      },
      {
        id: "02075",
        nm: "Valdeganga",
      },
      {
        id: "02076",
        nm: "Vianos",
      },
      {
        id: "02077",
        nm: "Villa de Ves",
      },
      {
        id: "02078",
        nm: "Villalgordo del Júcar",
      },
      {
        id: "02079",
        nm: "Villamalea",
      },
      {
        id: "02080",
        nm: "Villapalacios",
      },
      {
        id: "02081",
        nm: "Villarrobledo",
      },
      {
        id: "02082",
        nm: "Villatoya",
      },
      {
        id: "02083",
        nm: "Villavaliente",
      },
      {
        id: "02084",
        nm: "Villaverde de Guadalimar",
      },
      {
        id: "02085",
        nm: "Viveros",
      },
      {
        id: "02086",
        nm: "Yeste",
      },
      {
        id: "03002",
        nm: "Agost",
      },
      {
        id: "03003",
        nm: "Agres",
      },
      {
        id: "03004",
        nm: "Aigües",
      },
      {
        id: "03005",
        nm: "Albatera",
      },
      {
        id: "03006",
        nm: "Alcalalí",
      },
      {
        id: "03007",
        nm: "Alcocer de Planes",
      },
      {
        id: "03008",
        nm: "Alcoleja",
      },
      {
        id: "03009",
        nm: "Alcoy/Alcoi",
      },
      {
        id: "03010",
        nm: "Alfafara",
      },
      {
        id: "03011",
        nm: "Alfàs del Pi, l'",
      },
      {
        id: "03012",
        nm: "Algorfa",
      },
      {
        id: "03013",
        nm: "Algueña",
      },
      {
        id: "03014",
        nm: "Alicante/Alacant",
      },
      {
        id: "03015",
        nm: "Almoradí",
      },
      {
        id: "03016",
        nm: "Almudaina",
      },
      {
        id: "03017",
        nm: "Alqueria d'Asnar, l'",
      },
      {
        id: "03018",
        nm: "Altea",
      },
      {
        id: "03019",
        nm: "Aspe",
      },
      {
        id: "03001",
        nm: "Atzúbia, l'",
      },
      {
        id: "03020",
        nm: "Balones",
      },
      {
        id: "03021",
        nm: "Banyeres de Mariola",
      },
      {
        id: "03022",
        nm: "Benasau",
      },
      {
        id: "03023",
        nm: "Beneixama",
      },
      {
        id: "03024",
        nm: "Benejúzar",
      },
      {
        id: "03025",
        nm: "Benferri",
      },
      {
        id: "03026",
        nm: "Beniarbeig",
      },
      {
        id: "03027",
        nm: "Beniardá",
      },
      {
        id: "03028",
        nm: "Beniarrés",
      },
      {
        id: "03030",
        nm: "Benidoleig",
      },
      {
        id: "03031",
        nm: "Benidorm",
      },
      {
        id: "03032",
        nm: "Benifallim",
      },
      {
        id: "03033",
        nm: "Benifato",
      },
      {
        id: "03029",
        nm: "Benigembla",
      },
      {
        id: "03034",
        nm: "Benijófar",
      },
      {
        id: "03035",
        nm: "Benilloba",
      },
      {
        id: "03036",
        nm: "Benillup",
      },
      {
        id: "03037",
        nm: "Benimantell",
      },
      {
        id: "03038",
        nm: "Benimarfull",
      },
      {
        id: "03039",
        nm: "Benimassot",
      },
      {
        id: "03040",
        nm: "Benimeli",
      },
      {
        id: "03041",
        nm: "Benissa",
      },
      {
        id: "03042",
        nm: "Benitachell/Poble Nou de Benitatxell, el",
      },
      {
        id: "03043",
        nm: "Biar",
      },
      {
        id: "03044",
        nm: "Bigastro",
      },
      {
        id: "03045",
        nm: "Bolulla",
      },
      {
        id: "03046",
        nm: "Busot",
      },
      {
        id: "03049",
        nm: "Callosa de Segura",
      },
      {
        id: "03048",
        nm: "Callosa d'en Sarrià",
      },
      {
        id: "03047",
        nm: "Calp",
      },
      {
        id: "03050",
        nm: "Campello, el",
      },
      {
        id: "03051",
        nm: "Campo de Mirra/Camp de Mirra, el",
      },
      {
        id: "03052",
        nm: "Cañada",
      },
      {
        id: "03053",
        nm: "Castalla",
      },
      {
        id: "03054",
        nm: "Castell de Castells",
      },
      {
        id: "03075",
        nm: "Castell de Guadalest, el",
      },
      {
        id: "03055",
        nm: "Catral",
      },
      {
        id: "03056",
        nm: "Cocentaina",
      },
      {
        id: "03057",
        nm: "Confrides",
      },
      {
        id: "03058",
        nm: "Cox",
      },
      {
        id: "03059",
        nm: "Crevillent",
      },
      {
        id: "03061",
        nm: "Daya Nueva",
      },
      {
        id: "03062",
        nm: "Daya Vieja",
      },
      {
        id: "03063",
        nm: "Dénia",
      },
      {
        id: "03064",
        nm: "Dolores",
      },
      {
        id: "03065",
        nm: "Elche/Elx",
      },
      {
        id: "03066",
        nm: "Elda",
      },
      {
        id: "03067",
        nm: "Facheca",
      },
      {
        id: "03068",
        nm: "Famorca",
      },
      {
        id: "03069",
        nm: "Finestrat",
      },
      {
        id: "03077",
        nm: "Fondó de les Neus, el/Hondón de las Nieves",
      },
      {
        id: "03070",
        nm: "Formentera del Segura",
      },
      {
        id: "03072",
        nm: "Gaianes",
      },
      {
        id: "03071",
        nm: "Gata de Gorgos",
      },
      {
        id: "03073",
        nm: "Gorga",
      },
      {
        id: "03074",
        nm: "Granja de Rocamora",
      },
      {
        id: "03076",
        nm: "Guardamar del Segura",
      },
      {
        id: "03078",
        nm: "Hondón de los Frailes",
      },
      {
        id: "03079",
        nm: "Ibi",
      },
      {
        id: "03080",
        nm: "Jacarilla",
      },
      {
        id: "03082",
        nm: "Jávea/Xàbia",
      },
      {
        id: "03083",
        nm: "Jijona/Xixona",
      },
      {
        id: "03085",
        nm: "Llíber",
      },
      {
        id: "03084",
        nm: "Lorcha/Orxa, l'",
      },
      {
        id: "03086",
        nm: "Millena",
      },
      {
        id: "03088",
        nm: "Monforte del Cid",
      },
      {
        id: "03089",
        nm: "Monóvar/Monòver",
      },
      {
        id: "03903",
        nm: "Montesinos, Los",
      },
      {
        id: "03091",
        nm: "Murla",
      },
      {
        id: "03092",
        nm: "Muro de Alcoy",
      },
      {
        id: "03090",
        nm: "Mutxamel",
      },
      {
        id: "03093",
        nm: "Novelda",
      },
      {
        id: "03094",
        nm: "Nucia, la",
      },
      {
        id: "03095",
        nm: "Ondara",
      },
      {
        id: "03096",
        nm: "Onil",
      },
      {
        id: "03097",
        nm: "Orba",
      },
      {
        id: "03099",
        nm: "Orihuela",
      },
      {
        id: "03098",
        nm: "Orxeta",
      },
      {
        id: "03100",
        nm: "Parcent",
      },
      {
        id: "03101",
        nm: "Pedreguer",
      },
      {
        id: "03102",
        nm: "Pego",
      },
      {
        id: "03103",
        nm: "Penàguila",
      },
      {
        id: "03104",
        nm: "Petrer",
      },
      {
        id: "03902",
        nm: "Pilar de la Horadada",
      },
      {
        id: "03105",
        nm: "Pinós, el/Pinoso",
      },
      {
        id: "03106",
        nm: "Planes",
      },
      {
        id: "03901",
        nm: "Poblets, els",
      },
      {
        id: "03107",
        nm: "Polop",
      },
      {
        id: "03060",
        nm: "Quatretondeta",
      },
      {
        id: "03109",
        nm: "Rafal",
      },
      {
        id: "03110",
        nm: "Ràfol d'Almúnia, el",
      },
      {
        id: "03111",
        nm: "Redován",
      },
      {
        id: "03112",
        nm: "Relleu",
      },
      {
        id: "03113",
        nm: "Rojales",
      },
      {
        id: "03114",
        nm: "Romana, la",
      },
      {
        id: "03115",
        nm: "Sagra",
      },
      {
        id: "03116",
        nm: "Salinas",
      },
      {
        id: "03118",
        nm: "San Fulgencio",
      },
      {
        id: "03904",
        nm: "San Isidro",
      },
      {
        id: "03120",
        nm: "San Miguel de Salinas",
      },
      {
        id: "03122",
        nm: "San Vicente del Raspeig/Sant Vicent del Raspeig",
      },
      {
        id: "03117",
        nm: "Sanet y Negrals",
      },
      {
        id: "03119",
        nm: "Sant Joan d'Alacant",
      },
      {
        id: "03121",
        nm: "Santa Pola",
      },
      {
        id: "03123",
        nm: "Sax",
      },
      {
        id: "03124",
        nm: "Sella",
      },
      {
        id: "03125",
        nm: "Senija",
      },
      {
        id: "03127",
        nm: "Tàrbena",
      },
      {
        id: "03128",
        nm: "Teulada",
      },
      {
        id: "03129",
        nm: "Tibi",
      },
      {
        id: "03130",
        nm: "Tollos",
      },
      {
        id: "03131",
        nm: "Tormos",
      },
      {
        id: "03132",
        nm: "Torremanzanas/Torre de les Maçanes, la",
      },
      {
        id: "03133",
        nm: "Torrevieja",
      },
      {
        id: "03134",
        nm: "Vall d'Alcalà, la",
      },
      {
        id: "03136",
        nm: "Vall de Gallinera",
      },
      {
        id: "03137",
        nm: "Vall de Laguar, la",
      },
      {
        id: "03135",
        nm: "Vall d'Ebo, la",
      },
      {
        id: "03138",
        nm: "Verger, el",
      },
      {
        id: "03139",
        nm: "Villajoyosa/Vila Joiosa, la",
      },
      {
        id: "03140",
        nm: "Villena",
      },
      {
        id: "03081",
        nm: "Xaló",
      },
      {
        id: "04001",
        nm: "Abla",
      },
      {
        id: "04002",
        nm: "Abrucena",
      },
      {
        id: "04003",
        nm: "Adra",
      },
      {
        id: "04004",
        nm: "Albánchez",
      },
      {
        id: "04005",
        nm: "Alboloduy",
      },
      {
        id: "04006",
        nm: "Albox",
      },
      {
        id: "04007",
        nm: "Alcolea",
      },
      {
        id: "04008",
        nm: "Alcóntar",
      },
      {
        id: "04009",
        nm: "Alcudia de Monteagud",
      },
      {
        id: "04010",
        nm: "Alhabia",
      },
      {
        id: "04011",
        nm: "Alhama de Almería",
      },
      {
        id: "04012",
        nm: "Alicún",
      },
      {
        id: "04013",
        nm: "Almería",
      },
      {
        id: "04014",
        nm: "Almócita",
      },
      {
        id: "04015",
        nm: "Alsodux",
      },
      {
        id: "04016",
        nm: "Antas",
      },
      {
        id: "04017",
        nm: "Arboleas",
      },
      {
        id: "04018",
        nm: "Armuña de Almanzora",
      },
      {
        id: "04019",
        nm: "Bacares",
      },
      {
        id: "04904",
        nm: "Balanegra",
      },
      {
        id: "04020",
        nm: "Bayárcal",
      },
      {
        id: "04021",
        nm: "Bayarque",
      },
      {
        id: "04022",
        nm: "Bédar",
      },
      {
        id: "04023",
        nm: "Beires",
      },
      {
        id: "04024",
        nm: "Benahadux",
      },
      {
        id: "04026",
        nm: "Benitagla",
      },
      {
        id: "04027",
        nm: "Benizalón",
      },
      {
        id: "04028",
        nm: "Bentarique",
      },
      {
        id: "04029",
        nm: "Berja",
      },
      {
        id: "04030",
        nm: "Canjáyar",
      },
      {
        id: "04031",
        nm: "Cantoria",
      },
      {
        id: "04032",
        nm: "Carboneras",
      },
      {
        id: "04033",
        nm: "Castro de Filabres",
      },
      {
        id: "04036",
        nm: "Chercos",
      },
      {
        id: "04037",
        nm: "Chirivel",
      },
      {
        id: "04034",
        nm: "Cóbdar",
      },
      {
        id: "04035",
        nm: "Cuevas del Almanzora",
      },
      {
        id: "04038",
        nm: "Dalías",
      },
      {
        id: "04902",
        nm: "Ejido, El",
      },
      {
        id: "04041",
        nm: "Enix",
      },
      {
        id: "04043",
        nm: "Felix",
      },
      {
        id: "04044",
        nm: "Fines",
      },
      {
        id: "04045",
        nm: "Fiñana",
      },
      {
        id: "04046",
        nm: "Fondón",
      },
      {
        id: "04047",
        nm: "Gádor",
      },
      {
        id: "04048",
        nm: "Gallardos, Los",
      },
      {
        id: "04049",
        nm: "Garrucha",
      },
      {
        id: "04050",
        nm: "Gérgal",
      },
      {
        id: "04051",
        nm: "Huécija",
      },
      {
        id: "04052",
        nm: "Huércal de Almería",
      },
      {
        id: "04053",
        nm: "Huércal-Overa",
      },
      {
        id: "04054",
        nm: "Illar",
      },
      {
        id: "04055",
        nm: "Instinción",
      },
      {
        id: "04056",
        nm: "Laroya",
      },
      {
        id: "04057",
        nm: "Láujar de Andarax",
      },
      {
        id: "04058",
        nm: "Líjar",
      },
      {
        id: "04059",
        nm: "Lubrín",
      },
      {
        id: "04060",
        nm: "Lucainena de las Torres",
      },
      {
        id: "04061",
        nm: "Lúcar",
      },
      {
        id: "04062",
        nm: "Macael",
      },
      {
        id: "04063",
        nm: "María",
      },
      {
        id: "04064",
        nm: "Mojácar",
      },
      {
        id: "04903",
        nm: "Mojonera, La",
      },
      {
        id: "04065",
        nm: "Nacimiento",
      },
      {
        id: "04066",
        nm: "Níjar",
      },
      {
        id: "04067",
        nm: "Ohanes",
      },
      {
        id: "04068",
        nm: "Olula de Castro",
      },
      {
        id: "04069",
        nm: "Olula del Río",
      },
      {
        id: "04070",
        nm: "Oria",
      },
      {
        id: "04071",
        nm: "Padules",
      },
      {
        id: "04072",
        nm: "Partaloa",
      },
      {
        id: "04073",
        nm: "Paterna del Río",
      },
      {
        id: "04074",
        nm: "Pechina",
      },
      {
        id: "04075",
        nm: "Pulpí",
      },
      {
        id: "04076",
        nm: "Purchena",
      },
      {
        id: "04077",
        nm: "Rágol",
      },
      {
        id: "04078",
        nm: "Rioja",
      },
      {
        id: "04079",
        nm: "Roquetas de Mar",
      },
      {
        id: "04080",
        nm: "Santa Cruz de Marchena",
      },
      {
        id: "04081",
        nm: "Santa Fe de Mondújar",
      },
      {
        id: "04082",
        nm: "Senés",
      },
      {
        id: "04083",
        nm: "Serón",
      },
      {
        id: "04084",
        nm: "Sierro",
      },
      {
        id: "04085",
        nm: "Somontín",
      },
      {
        id: "04086",
        nm: "Sorbas",
      },
      {
        id: "04087",
        nm: "Suflí",
      },
      {
        id: "04088",
        nm: "Tabernas",
      },
      {
        id: "04089",
        nm: "Taberno",
      },
      {
        id: "04090",
        nm: "Tahal",
      },
      {
        id: "04091",
        nm: "Terque",
      },
      {
        id: "04092",
        nm: "Tíjola",
      },
      {
        id: "04901",
        nm: "Tres Villas, Las",
      },
      {
        id: "04093",
        nm: "Turre",
      },
      {
        id: "04094",
        nm: "Turrillas",
      },
      {
        id: "04095",
        nm: "Uleila del Campo",
      },
      {
        id: "04096",
        nm: "Urrácal",
      },
      {
        id: "04097",
        nm: "Velefique",
      },
      {
        id: "04098",
        nm: "Vélez-Blanco",
      },
      {
        id: "04099",
        nm: "Vélez-Rubio",
      },
      {
        id: "04100",
        nm: "Vera",
      },
      {
        id: "04101",
        nm: "Viator",
      },
      {
        id: "04102",
        nm: "Vícar",
      },
      {
        id: "04103",
        nm: "Zurgena",
      },
      {
        id: "05001",
        nm: "Adanero",
      },
      {
        id: "05002",
        nm: "Adrada, La",
      },
      {
        id: "05005",
        nm: "Albornos",
      },
      {
        id: "05007",
        nm: "Aldeanueva de Santa Cruz",
      },
      {
        id: "05008",
        nm: "Aldeaseca",
      },
      {
        id: "05010",
        nm: "Aldehuela, La",
      },
      {
        id: "05012",
        nm: "Amavida",
      },
      {
        id: "05013",
        nm: "Arenal, El",
      },
      {
        id: "05014",
        nm: "Arenas de San Pedro",
      },
      {
        id: "05015",
        nm: "Arevalillo",
      },
      {
        id: "05016",
        nm: "Arévalo",
      },
      {
        id: "05017",
        nm: "Aveinte",
      },
      {
        id: "05018",
        nm: "Avellaneda",
      },
      {
        id: "05019",
        nm: "Ávila",
      },
      {
        id: "05021",
        nm: "Barco de Ávila, El",
      },
      {
        id: "05022",
        nm: "Barraco, El",
      },
      {
        id: "05023",
        nm: "Barromán",
      },
      {
        id: "05024",
        nm: "Becedas",
      },
      {
        id: "05025",
        nm: "Becedillas",
      },
      {
        id: "05026",
        nm: "Bercial de Zapardiel",
      },
      {
        id: "05027",
        nm: "Berlanas, Las",
      },
      {
        id: "05029",
        nm: "Bernuy-Zapardiel",
      },
      {
        id: "05030",
        nm: "Berrocalejo de Aragona",
      },
      {
        id: "05033",
        nm: "Blascomillán",
      },
      {
        id: "05034",
        nm: "Blasconuño de Matacabras",
      },
      {
        id: "05035",
        nm: "Blascosancho",
      },
      {
        id: "05036",
        nm: "Bohodón, El",
      },
      {
        id: "05037",
        nm: "Bohoyo",
      },
      {
        id: "05038",
        nm: "Bonilla de la Sierra",
      },
      {
        id: "05039",
        nm: "Brabos",
      },
      {
        id: "05040",
        nm: "Bularros",
      },
      {
        id: "05041",
        nm: "Burgohondo",
      },
      {
        id: "05042",
        nm: "Cabezas de Alambre",
      },
      {
        id: "05043",
        nm: "Cabezas del Pozo",
      },
      {
        id: "05044",
        nm: "Cabezas del Villar",
      },
      {
        id: "05045",
        nm: "Cabizuela",
      },
      {
        id: "05046",
        nm: "Canales",
      },
      {
        id: "05047",
        nm: "Candeleda",
      },
      {
        id: "05048",
        nm: "Cantiveros",
      },
      {
        id: "05049",
        nm: "Cardeñosa",
      },
      {
        id: "05051",
        nm: "Carrera, La",
      },
      {
        id: "05052",
        nm: "Casas del Puerto",
      },
      {
        id: "05053",
        nm: "Casasola",
      },
      {
        id: "05054",
        nm: "Casavieja",
      },
      {
        id: "05055",
        nm: "Casillas",
      },
      {
        id: "05056",
        nm: "Castellanos de Zapardiel",
      },
      {
        id: "05057",
        nm: "Cebreros",
      },
      {
        id: "05058",
        nm: "Cepeda la Mora",
      },
      {
        id: "05067",
        nm: "Chamartín",
      },
      {
        id: "05059",
        nm: "Cillán",
      },
      {
        id: "05060",
        nm: "Cisla",
      },
      {
        id: "05061",
        nm: "Colilla, La",
      },
      {
        id: "05062",
        nm: "Collado de Contreras",
      },
      {
        id: "05063",
        nm: "Collado del Mirón",
      },
      {
        id: "05064",
        nm: "Constanzana",
      },
      {
        id: "05065",
        nm: "Crespos",
      },
      {
        id: "05066",
        nm: "Cuevas del Valle",
      },
      {
        id: "05903",
        nm: "Diego del Carpio",
      },
      {
        id: "05069",
        nm: "Donjimeno",
      },
      {
        id: "05070",
        nm: "Donvidas",
      },
      {
        id: "05072",
        nm: "Espinosa de los Caballeros",
      },
      {
        id: "05073",
        nm: "Flores de Ávila",
      },
      {
        id: "05074",
        nm: "Fontiveros",
      },
      {
        id: "05075",
        nm: "Fresnedilla",
      },
      {
        id: "05076",
        nm: "Fresno, El",
      },
      {
        id: "05077",
        nm: "Fuente el Saúz",
      },
      {
        id: "05078",
        nm: "Fuentes de Año",
      },
      {
        id: "05079",
        nm: "Gallegos de Altamiros",
      },
      {
        id: "05080",
        nm: "Gallegos de Sobrinos",
      },
      {
        id: "05081",
        nm: "Garganta del Villar",
      },
      {
        id: "05082",
        nm: "Gavilanes",
      },
      {
        id: "05083",
        nm: "Gemuño",
      },
      {
        id: "05085",
        nm: "Gil García",
      },
      {
        id: "05084",
        nm: "Gilbuena",
      },
      {
        id: "05086",
        nm: "Gimialcón",
      },
      {
        id: "05087",
        nm: "Gotarrendura",
      },
      {
        id: "05088",
        nm: "Grandes y San Martín",
      },
      {
        id: "05089",
        nm: "Guisando",
      },
      {
        id: "05090",
        nm: "Gutierre-Muñoz",
      },
      {
        id: "05092",
        nm: "Hernansancho",
      },
      {
        id: "05093",
        nm: "Herradón de Pinares",
      },
      {
        id: "05094",
        nm: "Herreros de Suso",
      },
      {
        id: "05095",
        nm: "Higuera de las Dueñas",
      },
      {
        id: "05096",
        nm: "Hija de Dios, La",
      },
      {
        id: "05097",
        nm: "Horcajada, La",
      },
      {
        id: "05099",
        nm: "Horcajo de las Torres",
      },
      {
        id: "05100",
        nm: "Hornillo, El",
      },
      {
        id: "05102",
        nm: "Hoyo de Pinares, El",
      },
      {
        id: "05101",
        nm: "Hoyocasero",
      },
      {
        id: "05103",
        nm: "Hoyorredondo",
      },
      {
        id: "05106",
        nm: "Hoyos de Miguel Muñoz",
      },
      {
        id: "05104",
        nm: "Hoyos del Collado",
      },
      {
        id: "05105",
        nm: "Hoyos del Espino",
      },
      {
        id: "05107",
        nm: "Hurtumpascual",
      },
      {
        id: "05108",
        nm: "Junciana",
      },
      {
        id: "05109",
        nm: "Langa",
      },
      {
        id: "05110",
        nm: "Lanzahíta",
      },
      {
        id: "05113",
        nm: "Llanos de Tormes, Los",
      },
      {
        id: "05112",
        nm: "Losar del Barco, El",
      },
      {
        id: "05114",
        nm: "Madrigal de las Altas Torres",
      },
      {
        id: "05115",
        nm: "Maello",
      },
      {
        id: "05116",
        nm: "Malpartida de Corneja",
      },
      {
        id: "05117",
        nm: "Mamblas",
      },
      {
        id: "05118",
        nm: "Mancera de Arriba",
      },
      {
        id: "05119",
        nm: "Manjabálago y Ortigosa de Rioalmar",
      },
      {
        id: "05120",
        nm: "Marlín",
      },
      {
        id: "05121",
        nm: "Martiherrero",
      },
      {
        id: "05122",
        nm: "Martínez",
      },
      {
        id: "05123",
        nm: "Mediana de Voltoya",
      },
      {
        id: "05124",
        nm: "Medinilla",
      },
      {
        id: "05125",
        nm: "Mengamuñoz",
      },
      {
        id: "05126",
        nm: "Mesegar de Corneja",
      },
      {
        id: "05127",
        nm: "Mijares",
      },
      {
        id: "05128",
        nm: "Mingorría",
      },
      {
        id: "05129",
        nm: "Mirón, El",
      },
      {
        id: "05130",
        nm: "Mironcillo",
      },
      {
        id: "05131",
        nm: "Mirueña de los Infanzones",
      },
      {
        id: "05132",
        nm: "Mombeltrán",
      },
      {
        id: "05133",
        nm: "Monsalupe",
      },
      {
        id: "05134",
        nm: "Moraleja de Matacabras",
      },
      {
        id: "05135",
        nm: "Muñana",
      },
      {
        id: "05136",
        nm: "Muñico",
      },
      {
        id: "05138",
        nm: "Muñogalindo",
      },
      {
        id: "05139",
        nm: "Muñogrande",
      },
      {
        id: "05140",
        nm: "Muñomer del Peco",
      },
      {
        id: "05141",
        nm: "Muñopepe",
      },
      {
        id: "05142",
        nm: "Muñosancho",
      },
      {
        id: "05143",
        nm: "Muñotello",
      },
      {
        id: "05144",
        nm: "Narrillos del Álamo",
      },
      {
        id: "05145",
        nm: "Narrillos del Rebollar",
      },
      {
        id: "05149",
        nm: "Narros de Saldueña",
      },
      {
        id: "05147",
        nm: "Narros del Castillo",
      },
      {
        id: "05148",
        nm: "Narros del Puerto",
      },
      {
        id: "05152",
        nm: "Nava de Arévalo",
      },
      {
        id: "05153",
        nm: "Nava del Barco",
      },
      {
        id: "05151",
        nm: "Navacepedilla de Corneja",
      },
      {
        id: "05154",
        nm: "Navadijos",
      },
      {
        id: "05155",
        nm: "Navaescurial",
      },
      {
        id: "05156",
        nm: "Navahondilla",
      },
      {
        id: "05157",
        nm: "Navalacruz",
      },
      {
        id: "05158",
        nm: "Navalmoral",
      },
      {
        id: "05159",
        nm: "Navalonguilla",
      },
      {
        id: "05160",
        nm: "Navalosa",
      },
      {
        id: "05161",
        nm: "Navalperal de Pinares",
      },
      {
        id: "05162",
        nm: "Navalperal de Tormes",
      },
      {
        id: "05163",
        nm: "Navaluenga",
      },
      {
        id: "05164",
        nm: "Navaquesera",
      },
      {
        id: "05165",
        nm: "Navarredonda de Gredos",
      },
      {
        id: "05166",
        nm: "Navarredondilla",
      },
      {
        id: "05167",
        nm: "Navarrevisca",
      },
      {
        id: "05168",
        nm: "Navas del Marqués, Las",
      },
      {
        id: "05169",
        nm: "Navatalgordo",
      },
      {
        id: "05170",
        nm: "Navatejares",
      },
      {
        id: "05171",
        nm: "Neila de San Miguel",
      },
      {
        id: "05172",
        nm: "Niharra",
      },
      {
        id: "05173",
        nm: "Ojos-Albos",
      },
      {
        id: "05174",
        nm: "Orbita",
      },
      {
        id: "05175",
        nm: "Oso, El",
      },
      {
        id: "05176",
        nm: "Padiernos",
      },
      {
        id: "05177",
        nm: "Pajares de Adaja",
      },
      {
        id: "05178",
        nm: "Palacios de Goda",
      },
      {
        id: "05179",
        nm: "Papatrigo",
      },
      {
        id: "05180",
        nm: "Parral, El",
      },
      {
        id: "05181",
        nm: "Pascualcobo",
      },
      {
        id: "05182",
        nm: "Pedro Bernardo",
      },
      {
        id: "05183",
        nm: "Pedro-Rodríguez",
      },
      {
        id: "05184",
        nm: "Peguerinos",
      },
      {
        id: "05185",
        nm: "Peñalba de Ávila",
      },
      {
        id: "05186",
        nm: "Piedrahíta",
      },
      {
        id: "05187",
        nm: "Piedralaves",
      },
      {
        id: "05188",
        nm: "Poveda",
      },
      {
        id: "05189",
        nm: "Poyales del Hoyo",
      },
      {
        id: "05190",
        nm: "Pozanco",
      },
      {
        id: "05191",
        nm: "Pradosegar",
      },
      {
        id: "05192",
        nm: "Puerto Castilla",
      },
      {
        id: "05193",
        nm: "Rasueros",
      },
      {
        id: "05194",
        nm: "Riocabado",
      },
      {
        id: "05195",
        nm: "Riofrío",
      },
      {
        id: "05196",
        nm: "Rivilla de Barajas",
      },
      {
        id: "05197",
        nm: "Salobral",
      },
      {
        id: "05198",
        nm: "Salvadiós",
      },
      {
        id: "05199",
        nm: "San Bartolomé de Béjar",
      },
      {
        id: "05200",
        nm: "San Bartolomé de Corneja",
      },
      {
        id: "05201",
        nm: "San Bartolomé de Pinares",
      },
      {
        id: "05206",
        nm: "San Esteban de los Patos",
      },
      {
        id: "05208",
        nm: "San Esteban de Zapardiel",
      },
      {
        id: "05207",
        nm: "San Esteban del Valle",
      },
      {
        id: "05209",
        nm: "San García de Ingelmos",
      },
      {
        id: "05901",
        nm: "San Juan de Gredos",
      },
      {
        id: "05210",
        nm: "San Juan de la Encinilla",
      },
      {
        id: "05211",
        nm: "San Juan de la Nava",
      },
      {
        id: "05212",
        nm: "San Juan del Molinillo",
      },
      {
        id: "05213",
        nm: "San Juan del Olmo",
      },
      {
        id: "05214",
        nm: "San Lorenzo de Tormes",
      },
      {
        id: "05215",
        nm: "San Martín de la Vega del Alberche",
      },
      {
        id: "05216",
        nm: "San Martín del Pimpollar",
      },
      {
        id: "05217",
        nm: "San Miguel de Corneja",
      },
      {
        id: "05218",
        nm: "San Miguel de Serrezuela",
      },
      {
        id: "05219",
        nm: "San Pascual",
      },
      {
        id: "05220",
        nm: "San Pedro del Arroyo",
      },
      {
        id: "05231",
        nm: "San Vicente de Arévalo",
      },
      {
        id: "05204",
        nm: "Sanchidrián",
      },
      {
        id: "05205",
        nm: "Sanchorreja",
      },
      {
        id: "05222",
        nm: "Santa Cruz de Pinares",
      },
      {
        id: "05221",
        nm: "Santa Cruz del Valle",
      },
      {
        id: "05226",
        nm: "Santa María de los Caballeros",
      },
      {
        id: "05224",
        nm: "Santa María del Arroyo",
      },
      {
        id: "05225",
        nm: "Santa María del Berrocal",
      },
      {
        id: "05902",
        nm: "Santa María del Cubillo",
      },
      {
        id: "05227",
        nm: "Santa María del Tiétar",
      },
      {
        id: "05228",
        nm: "Santiago del Collado",
      },
      {
        id: "05904",
        nm: "Santiago del Tormes",
      },
      {
        id: "05229",
        nm: "Santo Domingo de las Posadas",
      },
      {
        id: "05230",
        nm: "Santo Tomé de Zabarcos",
      },
      {
        id: "05232",
        nm: "Serrada, La",
      },
      {
        id: "05233",
        nm: "Serranillos",
      },
      {
        id: "05234",
        nm: "Sigeres",
      },
      {
        id: "05235",
        nm: "Sinlabajos",
      },
      {
        id: "05236",
        nm: "Solana de Ávila",
      },
      {
        id: "05237",
        nm: "Solana de Rioalmar",
      },
      {
        id: "05238",
        nm: "Solosancho",
      },
      {
        id: "05239",
        nm: "Sotalbo",
      },
      {
        id: "05240",
        nm: "Sotillo de la Adrada",
      },
      {
        id: "05241",
        nm: "Tiemblo, El",
      },
      {
        id: "05242",
        nm: "Tiñosillos",
      },
      {
        id: "05243",
        nm: "Tolbaños",
      },
      {
        id: "05244",
        nm: "Tormellas",
      },
      {
        id: "05245",
        nm: "Tornadizos de Ávila",
      },
      {
        id: "05247",
        nm: "Torre, La",
      },
      {
        id: "05246",
        nm: "Tórtoles",
      },
      {
        id: "05249",
        nm: "Umbrías",
      },
      {
        id: "05251",
        nm: "Vadillo de la Sierra",
      },
      {
        id: "05252",
        nm: "Valdecasa",
      },
      {
        id: "05253",
        nm: "Vega de Santa María",
      },
      {
        id: "05254",
        nm: "Velayos",
      },
      {
        id: "05256",
        nm: "Villaflor",
      },
      {
        id: "05257",
        nm: "Villafranca de la Sierra",
      },
      {
        id: "05905",
        nm: "Villanueva de Ávila",
      },
      {
        id: "05258",
        nm: "Villanueva de Gómez",
      },
      {
        id: "05259",
        nm: "Villanueva del Aceral",
      },
      {
        id: "05260",
        nm: "Villanueva del Campillo",
      },
      {
        id: "05261",
        nm: "Villar de Corneja",
      },
      {
        id: "05262",
        nm: "Villarejo del Valle",
      },
      {
        id: "05263",
        nm: "Villatoro",
      },
      {
        id: "05264",
        nm: "Viñegra de Moraña",
      },
      {
        id: "05265",
        nm: "Vita",
      },
      {
        id: "05266",
        nm: "Zapardiel de la Cañada",
      },
      {
        id: "05267",
        nm: "Zapardiel de la Ribera",
      },
      {
        id: "06001",
        nm: "Acedera",
      },
      {
        id: "06002",
        nm: "Aceuchal",
      },
      {
        id: "06003",
        nm: "Ahillones",
      },
      {
        id: "06004",
        nm: "Alange",
      },
      {
        id: "06005",
        nm: "Albuera, La",
      },
      {
        id: "06006",
        nm: "Alburquerque",
      },
      {
        id: "06007",
        nm: "Alconchel",
      },
      {
        id: "06008",
        nm: "Alconera",
      },
      {
        id: "06009",
        nm: "Aljucén",
      },
      {
        id: "06010",
        nm: "Almendral",
      },
      {
        id: "06011",
        nm: "Almendralejo",
      },
      {
        id: "06012",
        nm: "Arroyo de San Serván",
      },
      {
        id: "06013",
        nm: "Atalaya",
      },
      {
        id: "06014",
        nm: "Azuaga",
      },
      {
        id: "06015",
        nm: "Badajoz",
      },
      {
        id: "06016",
        nm: "Barcarrota",
      },
      {
        id: "06017",
        nm: "Baterno",
      },
      {
        id: "06018",
        nm: "Benquerencia de la Serena",
      },
      {
        id: "06019",
        nm: "Berlanga",
      },
      {
        id: "06020",
        nm: "Bienvenida",
      },
      {
        id: "06021",
        nm: "Bodonal de la Sierra",
      },
      {
        id: "06022",
        nm: "Burguillos del Cerro",
      },
      {
        id: "06023",
        nm: "Cabeza del Buey",
      },
      {
        id: "06024",
        nm: "Cabeza la Vaca",
      },
      {
        id: "06025",
        nm: "Calamonte",
      },
      {
        id: "06026",
        nm: "Calera de León",
      },
      {
        id: "06027",
        nm: "Calzadilla de los Barros",
      },
      {
        id: "06028",
        nm: "Campanario",
      },
      {
        id: "06029",
        nm: "Campillo de Llerena",
      },
      {
        id: "06030",
        nm: "Capilla",
      },
      {
        id: "06031",
        nm: "Carmonita",
      },
      {
        id: "06032",
        nm: "Carrascalejo, El",
      },
      {
        id: "06033",
        nm: "Casas de Don Pedro",
      },
      {
        id: "06034",
        nm: "Casas de Reina",
      },
      {
        id: "06035",
        nm: "Castilblanco",
      },
      {
        id: "06036",
        nm: "Castuera",
      },
      {
        id: "06042",
        nm: "Cheles",
      },
      {
        id: "06037",
        nm: "Codosera, La",
      },
      {
        id: "06038",
        nm: "Cordobilla de Lácara",
      },
      {
        id: "06039",
        nm: "Coronada, La",
      },
      {
        id: "06040",
        nm: "Corte de Peleas",
      },
      {
        id: "06041",
        nm: "Cristina",
      },
      {
        id: "06043",
        nm: "Don Álvaro",
      },
      {
        id: "06044",
        nm: "Don Benito",
      },
      {
        id: "06045",
        nm: "Entrín Bajo",
      },
      {
        id: "06046",
        nm: "Esparragalejo",
      },
      {
        id: "06047",
        nm: "Esparragosa de la Serena",
      },
      {
        id: "06048",
        nm: "Esparragosa de Lares",
      },
      {
        id: "06049",
        nm: "Feria",
      },
      {
        id: "06050",
        nm: "Fregenal de la Sierra",
      },
      {
        id: "06051",
        nm: "Fuenlabrada de los Montes",
      },
      {
        id: "06052",
        nm: "Fuente de Cantos",
      },
      {
        id: "06053",
        nm: "Fuente del Arco",
      },
      {
        id: "06054",
        nm: "Fuente del Maestre",
      },
      {
        id: "06055",
        nm: "Fuentes de León",
      },
      {
        id: "06056",
        nm: "Garbayuela",
      },
      {
        id: "06057",
        nm: "Garlitos",
      },
      {
        id: "06058",
        nm: "Garrovilla, La",
      },
      {
        id: "06059",
        nm: "Granja de Torrehermosa",
      },
      {
        id: "06903",
        nm: "Guadiana del Caudillo",
      },
      {
        id: "06060",
        nm: "Guareña",
      },
      {
        id: "06061",
        nm: "Haba, La",
      },
      {
        id: "06062",
        nm: "Helechosa de los Montes",
      },
      {
        id: "06063",
        nm: "Herrera del Duque",
      },
      {
        id: "06064",
        nm: "Higuera de la Serena",
      },
      {
        id: "06065",
        nm: "Higuera de Llerena",
      },
      {
        id: "06066",
        nm: "Higuera de Vargas",
      },
      {
        id: "06067",
        nm: "Higuera la Real",
      },
      {
        id: "06068",
        nm: "Hinojosa del Valle",
      },
      {
        id: "06069",
        nm: "Hornachos",
      },
      {
        id: "06070",
        nm: "Jerez de los Caballeros",
      },
      {
        id: "06071",
        nm: "Lapa, La",
      },
      {
        id: "06073",
        nm: "Llera",
      },
      {
        id: "06074",
        nm: "Llerena",
      },
      {
        id: "06072",
        nm: "Lobón",
      },
      {
        id: "06075",
        nm: "Magacela",
      },
      {
        id: "06076",
        nm: "Maguilla",
      },
      {
        id: "06077",
        nm: "Malcocinado",
      },
      {
        id: "06078",
        nm: "Malpartida de la Serena",
      },
      {
        id: "06079",
        nm: "Manchita",
      },
      {
        id: "06080",
        nm: "Medellín",
      },
      {
        id: "06081",
        nm: "Medina de las Torres",
      },
      {
        id: "06082",
        nm: "Mengabril",
      },
      {
        id: "06083",
        nm: "Mérida",
      },
      {
        id: "06084",
        nm: "Mirandilla",
      },
      {
        id: "06085",
        nm: "Monesterio",
      },
      {
        id: "06086",
        nm: "Montemolín",
      },
      {
        id: "06087",
        nm: "Monterrubio de la Serena",
      },
      {
        id: "06088",
        nm: "Montijo",
      },
      {
        id: "06089",
        nm: "Morera, La",
      },
      {
        id: "06090",
        nm: "Nava de Santiago, La",
      },
      {
        id: "06091",
        nm: "Navalvillar de Pela",
      },
      {
        id: "06092",
        nm: "Nogales",
      },
      {
        id: "06093",
        nm: "Oliva de la Frontera",
      },
      {
        id: "06094",
        nm: "Oliva de Mérida",
      },
      {
        id: "06095",
        nm: "Olivenza",
      },
      {
        id: "06096",
        nm: "Orellana de la Sierra",
      },
      {
        id: "06097",
        nm: "Orellana la Vieja",
      },
      {
        id: "06098",
        nm: "Palomas",
      },
      {
        id: "06099",
        nm: "Parra, La",
      },
      {
        id: "06100",
        nm: "Peñalsordo",
      },
      {
        id: "06101",
        nm: "Peraleda del Zaucejo",
      },
      {
        id: "06102",
        nm: "Puebla de Alcocer",
      },
      {
        id: "06103",
        nm: "Puebla de la Calzada",
      },
      {
        id: "06104",
        nm: "Puebla de la Reina",
      },
      {
        id: "06107",
        nm: "Puebla de Obando",
      },
      {
        id: "06108",
        nm: "Puebla de Sancho Pérez",
      },
      {
        id: "06105",
        nm: "Puebla del Maestre",
      },
      {
        id: "06106",
        nm: "Puebla del Prior",
      },
      {
        id: "06902",
        nm: "Pueblonuevo del Guadiana",
      },
      {
        id: "06109",
        nm: "Quintana de la Serena",
      },
      {
        id: "06110",
        nm: "Reina",
      },
      {
        id: "06111",
        nm: "Rena",
      },
      {
        id: "06112",
        nm: "Retamal de Llerena",
      },
      {
        id: "06113",
        nm: "Ribera del Fresno",
      },
      {
        id: "06114",
        nm: "Risco",
      },
      {
        id: "06115",
        nm: "Roca de la Sierra, La",
      },
      {
        id: "06116",
        nm: "Salvaleón",
      },
      {
        id: "06117",
        nm: "Salvatierra de los Barros",
      },
      {
        id: "06119",
        nm: "San Pedro de Mérida",
      },
      {
        id: "06123",
        nm: "San Vicente de Alcántara",
      },
      {
        id: "06118",
        nm: "Sancti-Spíritus",
      },
      {
        id: "06120",
        nm: "Santa Amalia",
      },
      {
        id: "06121",
        nm: "Santa Marta",
      },
      {
        id: "06122",
        nm: "Santos de Maimona, Los",
      },
      {
        id: "06124",
        nm: "Segura de León",
      },
      {
        id: "06125",
        nm: "Siruela",
      },
      {
        id: "06126",
        nm: "Solana de los Barros",
      },
      {
        id: "06127",
        nm: "Talarrubias",
      },
      {
        id: "06128",
        nm: "Talavera la Real",
      },
      {
        id: "06129",
        nm: "Táliga",
      },
      {
        id: "06130",
        nm: "Tamurejo",
      },
      {
        id: "06131",
        nm: "Torre de Miguel Sesmero",
      },
      {
        id: "06132",
        nm: "Torremayor",
      },
      {
        id: "06133",
        nm: "Torremejía",
      },
      {
        id: "06134",
        nm: "Trasierra",
      },
      {
        id: "06135",
        nm: "Trujillanos",
      },
      {
        id: "06136",
        nm: "Usagre",
      },
      {
        id: "06137",
        nm: "Valdecaballeros",
      },
      {
        id: "06901",
        nm: "Valdelacalzada",
      },
      {
        id: "06138",
        nm: "Valdetorres",
      },
      {
        id: "06139",
        nm: "Valencia de las Torres",
      },
      {
        id: "06140",
        nm: "Valencia del Mombuey",
      },
      {
        id: "06141",
        nm: "Valencia del Ventoso",
      },
      {
        id: "06146",
        nm: "Valle de la Serena",
      },
      {
        id: "06147",
        nm: "Valle de Matamoros",
      },
      {
        id: "06148",
        nm: "Valle de Santa Ana",
      },
      {
        id: "06142",
        nm: "Valverde de Burguillos",
      },
      {
        id: "06143",
        nm: "Valverde de Leganés",
      },
      {
        id: "06144",
        nm: "Valverde de Llerena",
      },
      {
        id: "06145",
        nm: "Valverde de Mérida",
      },
      {
        id: "06149",
        nm: "Villafranca de los Barros",
      },
      {
        id: "06150",
        nm: "Villagarcía de la Torre",
      },
      {
        id: "06151",
        nm: "Villagonzalo",
      },
      {
        id: "06152",
        nm: "Villalba de los Barros",
      },
      {
        id: "06153",
        nm: "Villanueva de la Serena",
      },
      {
        id: "06154",
        nm: "Villanueva del Fresno",
      },
      {
        id: "06156",
        nm: "Villar de Rena",
      },
      {
        id: "06155",
        nm: "Villar del Rey",
      },
      {
        id: "06157",
        nm: "Villarta de los Montes",
      },
      {
        id: "06158",
        nm: "Zafra",
      },
      {
        id: "06159",
        nm: "Zahínos",
      },
      {
        id: "06160",
        nm: "Zalamea de la Serena",
      },
      {
        id: "06162",
        nm: "Zarza, La",
      },
      {
        id: "06161",
        nm: "Zarza-Capilla",
      },
      {
        id: "07002",
        nm: "Alaior",
      },
      {
        id: "07001",
        nm: "Alaró",
      },
      {
        id: "07003",
        nm: "Alcúdia",
      },
      {
        id: "07004",
        nm: "Algaida",
      },
      {
        id: "07005",
        nm: "Andratx",
      },
      {
        id: "07901",
        nm: "Ariany",
      },
      {
        id: "07006",
        nm: "Artà",
      },
      {
        id: "07007",
        nm: "Banyalbufar",
      },
      {
        id: "07008",
        nm: "Binissalem",
      },
      {
        id: "07009",
        nm: "Búger",
      },
      {
        id: "07010",
        nm: "Bunyola",
      },
      {
        id: "07011",
        nm: "Calvià",
      },
      {
        id: "07012",
        nm: "Campanet",
      },
      {
        id: "07013",
        nm: "Campos",
      },
      {
        id: "07014",
        nm: "Capdepera",
      },
      {
        id: "07064",
        nm: "Castell, Es",
      },
      {
        id: "07015",
        nm: "Ciutadella de Menorca",
      },
      {
        id: "07016",
        nm: "Consell",
      },
      {
        id: "07017",
        nm: "Costitx",
      },
      {
        id: "07018",
        nm: "Deià",
      },
      {
        id: "07026",
        nm: "Eivissa",
      },
      {
        id: "07019",
        nm: "Escorca",
      },
      {
        id: "07020",
        nm: "Esporles",
      },
      {
        id: "07021",
        nm: "Estellencs",
      },
      {
        id: "07022",
        nm: "Felanitx",
      },
      {
        id: "07023",
        nm: "Ferreries",
      },
      {
        id: "07024",
        nm: "Formentera",
      },
      {
        id: "07025",
        nm: "Fornalutx",
      },
      {
        id: "07027",
        nm: "Inca",
      },
      {
        id: "07028",
        nm: "Lloret de Vistalegre",
      },
      {
        id: "07029",
        nm: "Lloseta",
      },
      {
        id: "07030",
        nm: "Llubí",
      },
      {
        id: "07031",
        nm: "Llucmajor",
      },
      {
        id: "07033",
        nm: "Manacor",
      },
      {
        id: "07034",
        nm: "Mancor de la Vall",
      },
      {
        id: "07032",
        nm: "Maó-Mahón",
      },
      {
        id: "07035",
        nm: "Maria de la Salut",
      },
      {
        id: "07036",
        nm: "Marratxí",
      },
      {
        id: "07037",
        nm: "Mercadal, Es",
      },
      {
        id: "07902",
        nm: "Migjorn Gran, Es",
      },
      {
        id: "07038",
        nm: "Montuïri",
      },
      {
        id: "07039",
        nm: "Muro",
      },
      {
        id: "07040",
        nm: "Palma de Mallorca",
      },
      {
        id: "07041",
        nm: "Petra",
      },
      {
        id: "07044",
        nm: "Pobla, Sa",
      },
      {
        id: "07042",
        nm: "Pollença",
      },
      {
        id: "07043",
        nm: "Porreres",
      },
      {
        id: "07045",
        nm: "Puigpunyent",
      },
      {
        id: "07059",
        nm: "Salines, Ses",
      },
      {
        id: "07046",
        nm: "Sant Antoni de Portmany",
      },
      {
        id: "07049",
        nm: "Sant Joan",
      },
      {
        id: "07050",
        nm: "Sant Joan de Labritja",
      },
      {
        id: "07048",
        nm: "Sant Josep de sa Talaia",
      },
      {
        id: "07051",
        nm: "Sant Llorenç des Cardassar",
      },
      {
        id: "07052",
        nm: "Sant Lluís",
      },
      {
        id: "07053",
        nm: "Santa Eugènia",
      },
      {
        id: "07054",
        nm: "Santa Eulària des Riu",
      },
      {
        id: "07055",
        nm: "Santa Margalida",
      },
      {
        id: "07056",
        nm: "Santa María del Camí",
      },
      {
        id: "07057",
        nm: "Santanyí",
      },
      {
        id: "07058",
        nm: "Selva",
      },
      {
        id: "07047",
        nm: "Sencelles",
      },
      {
        id: "07060",
        nm: "Sineu",
      },
      {
        id: "07061",
        nm: "Sóller",
      },
      {
        id: "07062",
        nm: "Son Servera",
      },
      {
        id: "07063",
        nm: "Valldemossa",
      },
      {
        id: "07065",
        nm: "Vilafranca de Bonany",
      },
      {
        id: "08001",
        nm: "Abrera",
      },
      {
        id: "08002",
        nm: "Aguilar de Segarra",
      },
      {
        id: "08014",
        nm: "Aiguafreda",
      },
      {
        id: "08003",
        nm: "Alella",
      },
      {
        id: "08004",
        nm: "Alpens",
      },
      {
        id: "08005",
        nm: "Ametlla del Vallès, L'",
      },
      {
        id: "08006",
        nm: "Arenys de Mar",
      },
      {
        id: "08007",
        nm: "Arenys de Munt",
      },
      {
        id: "08008",
        nm: "Argençola",
      },
      {
        id: "08009",
        nm: "Argentona",
      },
      {
        id: "08010",
        nm: "Artés",
      },
      {
        id: "08011",
        nm: "Avià",
      },
      {
        id: "08012",
        nm: "Avinyó",
      },
      {
        id: "08013",
        nm: "Avinyonet del Penedès",
      },
      {
        id: "08015",
        nm: "Badalona",
      },
      {
        id: "08904",
        nm: "Badia del Vallès",
      },
      {
        id: "08016",
        nm: "Bagà",
      },
      {
        id: "08017",
        nm: "Balenyà",
      },
      {
        id: "08018",
        nm: "Balsareny",
      },
      {
        id: "08252",
        nm: "Barberà del Vallès",
      },
      {
        id: "08019",
        nm: "Barcelona",
      },
      {
        id: "08020",
        nm: "Begues",
      },
      {
        id: "08021",
        nm: "Bellprat",
      },
      {
        id: "08022",
        nm: "Berga",
      },
      {
        id: "08023",
        nm: "Bigues i Riells",
      },
      {
        id: "08024",
        nm: "Borredà",
      },
      {
        id: "08025",
        nm: "Bruc, El",
      },
      {
        id: "08026",
        nm: "Brull, El",
      },
      {
        id: "08027",
        nm: "Cabanyes, Les",
      },
      {
        id: "08028",
        nm: "Cabrera d'Anoia",
      },
      {
        id: "08029",
        nm: "Cabrera de Mar",
      },
      {
        id: "08030",
        nm: "Cabrils",
      },
      {
        id: "08031",
        nm: "Calaf",
      },
      {
        id: "08034",
        nm: "Calders",
      },
      {
        id: "08033",
        nm: "Caldes de Montbui",
      },
      {
        id: "08032",
        nm: "Caldes d'Estrac",
      },
      {
        id: "08035",
        nm: "Calella",
      },
      {
        id: "08037",
        nm: "Calldetenes",
      },
      {
        id: "08038",
        nm: "Callús",
      },
      {
        id: "08036",
        nm: "Calonge de Segarra",
      },
      {
        id: "08039",
        nm: "Campins",
      },
      {
        id: "08040",
        nm: "Canet de Mar",
      },
      {
        id: "08041",
        nm: "Canovelles",
      },
      {
        id: "08042",
        nm: "Cànoves i Samalús",
      },
      {
        id: "08043",
        nm: "Canyelles",
      },
      {
        id: "08044",
        nm: "Capellades",
      },
      {
        id: "08045",
        nm: "Capolat",
      },
      {
        id: "08046",
        nm: "Cardedeu",
      },
      {
        id: "08047",
        nm: "Cardona",
      },
      {
        id: "08048",
        nm: "Carme",
      },
      {
        id: "08049",
        nm: "Casserres",
      },
      {
        id: "08057",
        nm: "Castell de l'Areny",
      },
      {
        id: "08052",
        nm: "Castellar de n'Hug",
      },
      {
        id: "08050",
        nm: "Castellar del Riu",
      },
      {
        id: "08051",
        nm: "Castellar del Vallès",
      },
      {
        id: "08053",
        nm: "Castellbell i el Vilar",
      },
      {
        id: "08054",
        nm: "Castellbisbal",
      },
      {
        id: "08055",
        nm: "Castellcir",
      },
      {
        id: "08056",
        nm: "Castelldefels",
      },
      {
        id: "08058",
        nm: "Castellet i la Gornal",
      },
      {
        id: "08060",
        nm: "Castellfollit de Riubregós",
      },
      {
        id: "08059",
        nm: "Castellfollit del Boix",
      },
      {
        id: "08061",
        nm: "Castellgalí",
      },
      {
        id: "08062",
        nm: "Castellnou de Bages",
      },
      {
        id: "08063",
        nm: "Castellolí",
      },
      {
        id: "08064",
        nm: "Castellterçol",
      },
      {
        id: "08065",
        nm: "Castellví de la Marca",
      },
      {
        id: "08066",
        nm: "Castellví de Rosanes",
      },
      {
        id: "08067",
        nm: "Centelles",
      },
      {
        id: "08268",
        nm: "Cercs",
      },
      {
        id: "08266",
        nm: "Cerdanyola del Vallès",
      },
      {
        id: "08068",
        nm: "Cervelló",
      },
      {
        id: "08069",
        nm: "Collbató",
      },
      {
        id: "08070",
        nm: "Collsuspina",
      },
      {
        id: "08071",
        nm: "Copons",
      },
      {
        id: "08072",
        nm: "Corbera de Llobregat",
      },
      {
        id: "08073",
        nm: "Cornellà de Llobregat",
      },
      {
        id: "08074",
        nm: "Cubelles",
      },
      {
        id: "08075",
        nm: "Dosrius",
      },
      {
        id: "08076",
        nm: "Esparreguera",
      },
      {
        id: "08077",
        nm: "Esplugues de Llobregat",
      },
      {
        id: "08078",
        nm: "Espunyola, L'",
      },
      {
        id: "08254",
        nm: "Esquirol, L'",
      },
      {
        id: "08079",
        nm: "Estany, L'",
      },
      {
        id: "08134",
        nm: "Figaró-Montmany",
      },
      {
        id: "08080",
        nm: "Fígols",
      },
      {
        id: "08082",
        nm: "Fogars de la Selva",
      },
      {
        id: "08081",
        nm: "Fogars de Montclús",
      },
      {
        id: "08083",
        nm: "Folgueroles",
      },
      {
        id: "08084",
        nm: "Fonollosa",
      },
      {
        id: "08085",
        nm: "Font-rubí",
      },
      {
        id: "08086",
        nm: "Franqueses del Vallès, Les",
      },
      {
        id: "08090",
        nm: "Gaià",
      },
      {
        id: "08087",
        nm: "Gallifa",
      },
      {
        id: "08088",
        nm: "Garriga, La",
      },
      {
        id: "08089",
        nm: "Gavà",
      },
      {
        id: "08091",
        nm: "Gelida",
      },
      {
        id: "08092",
        nm: "Gironella",
      },
      {
        id: "08093",
        nm: "Gisclareny",
      },
      {
        id: "08094",
        nm: "Granada, La",
      },
      {
        id: "08095",
        nm: "Granera",
      },
      {
        id: "08096",
        nm: "Granollers",
      },
      {
        id: "08097",
        nm: "Gualba",
      },
      {
        id: "08099",
        nm: "Guardiola de Berguedà",
      },
      {
        id: "08100",
        nm: "Gurb",
      },
      {
        id: "08101",
        nm: "Hospitalet de Llobregat, L'",
      },
      {
        id: "08162",
        nm: "Hostalets de Pierola, Els",
      },
      {
        id: "08102",
        nm: "Igualada",
      },
      {
        id: "08103",
        nm: "Jorba",
      },
      {
        id: "08104",
        nm: "Llacuna, La",
      },
      {
        id: "08105",
        nm: "Llagosta, La",
      },
      {
        id: "08107",
        nm: "Lliçà d'Amunt",
      },
      {
        id: "08108",
        nm: "Lliçà de Vall",
      },
      {
        id: "08106",
        nm: "Llinars del Vallès",
      },
      {
        id: "08109",
        nm: "Lluçà",
      },
      {
        id: "08110",
        nm: "Malgrat de Mar",
      },
      {
        id: "08111",
        nm: "Malla",
      },
      {
        id: "08112",
        nm: "Manlleu",
      },
      {
        id: "08113",
        nm: "Manresa",
      },
      {
        id: "08242",
        nm: "Marganell",
      },
      {
        id: "08114",
        nm: "Martorell",
      },
      {
        id: "08115",
        nm: "Martorelles",
      },
      {
        id: "08116",
        nm: "Masies de Roda, Les",
      },
      {
        id: "08117",
        nm: "Masies de Voltregà, Les",
      },
      {
        id: "08118",
        nm: "Masnou, El",
      },
      {
        id: "08119",
        nm: "Masquefa",
      },
      {
        id: "08120",
        nm: "Matadepera",
      },
      {
        id: "08121",
        nm: "Mataró",
      },
      {
        id: "08122",
        nm: "Mediona",
      },
      {
        id: "08138",
        nm: "Moià",
      },
      {
        id: "08123",
        nm: "Molins de Rei",
      },
      {
        id: "08124",
        nm: "Mollet del Vallès",
      },
      {
        id: "08128",
        nm: "Monistrol de Calders",
      },
      {
        id: "08127",
        nm: "Monistrol de Montserrat",
      },
      {
        id: "08125",
        nm: "Montcada i Reixac",
      },
      {
        id: "08130",
        nm: "Montclar",
      },
      {
        id: "08131",
        nm: "Montesquiu",
      },
      {
        id: "08126",
        nm: "Montgat",
      },
      {
        id: "08132",
        nm: "Montmajor",
      },
      {
        id: "08133",
        nm: "Montmaneu",
      },
      {
        id: "08135",
        nm: "Montmeló",
      },
      {
        id: "08136",
        nm: "Montornès del Vallès",
      },
      {
        id: "08137",
        nm: "Montseny",
      },
      {
        id: "08129",
        nm: "Muntanyola",
      },
      {
        id: "08139",
        nm: "Mura",
      },
      {
        id: "08140",
        nm: "Navarcles",
      },
      {
        id: "08141",
        nm: "Navàs",
      },
      {
        id: "08142",
        nm: "Nou de Berguedà, La",
      },
      {
        id: "08143",
        nm: "Òdena",
      },
      {
        id: "08145",
        nm: "Olèrdola",
      },
      {
        id: "08146",
        nm: "Olesa de Bonesvalls",
      },
      {
        id: "08147",
        nm: "Olesa de Montserrat",
      },
      {
        id: "08148",
        nm: "Olivella",
      },
      {
        id: "08149",
        nm: "Olost",
      },
      {
        id: "08144",
        nm: "Olvan",
      },
      {
        id: "08150",
        nm: "Orís",
      },
      {
        id: "08151",
        nm: "Oristà",
      },
      {
        id: "08152",
        nm: "Orpí",
      },
      {
        id: "08153",
        nm: "Òrrius",
      },
      {
        id: "08154",
        nm: "Pacs del Penedès",
      },
      {
        id: "08155",
        nm: "Palafolls",
      },
      {
        id: "08156",
        nm: "Palau-solità i Plegamans",
      },
      {
        id: "08157",
        nm: "Pallejà",
      },
      {
        id: "08905",
        nm: "Palma de Cervelló, La",
      },
      {
        id: "08158",
        nm: "Papiol, El",
      },
      {
        id: "08159",
        nm: "Parets del Vallès",
      },
      {
        id: "08160",
        nm: "Perafita",
      },
      {
        id: "08161",
        nm: "Piera",
      },
      {
        id: "08163",
        nm: "Pineda de Mar",
      },
      {
        id: "08164",
        nm: "Pla del Penedès, El",
      },
      {
        id: "08165",
        nm: "Pobla de Claramunt, La",
      },
      {
        id: "08166",
        nm: "Pobla de Lillet, La",
      },
      {
        id: "08167",
        nm: "Polinyà",
      },
      {
        id: "08182",
        nm: "Pont de Vilomara i Rocafort, El",
      },
      {
        id: "08168",
        nm: "Pontons",
      },
      {
        id: "08169",
        nm: "Prat de Llobregat, El",
      },
      {
        id: "08171",
        nm: "Prats de Lluçanès",
      },
      {
        id: "08170",
        nm: "Prats de Rei, Els",
      },
      {
        id: "08230",
        nm: "Premià de Dalt",
      },
      {
        id: "08172",
        nm: "Premià de Mar",
      },
      {
        id: "08174",
        nm: "Puigdàlber",
      },
      {
        id: "08175",
        nm: "Puig-reig",
      },
      {
        id: "08176",
        nm: "Pujalt",
      },
      {
        id: "08177",
        nm: "Quar, La",
      },
      {
        id: "08178",
        nm: "Rajadell",
      },
      {
        id: "08179",
        nm: "Rellinars",
      },
      {
        id: "08180",
        nm: "Ripollet",
      },
      {
        id: "08181",
        nm: "Roca del Vallès, La",
      },
      {
        id: "08183",
        nm: "Roda de Ter",
      },
      {
        id: "08184",
        nm: "Rubí",
      },
      {
        id: "08185",
        nm: "Rubió",
      },
      {
        id: "08901",
        nm: "Rupit i Pruit",
      },
      {
        id: "08187",
        nm: "Sabadell",
      },
      {
        id: "08188",
        nm: "Sagàs",
      },
      {
        id: "08190",
        nm: "Saldes",
      },
      {
        id: "08191",
        nm: "Sallent",
      },
      {
        id: "08194",
        nm: "Sant Adrià de Besòs",
      },
      {
        id: "08195",
        nm: "Sant Agustí de Lluçanès",
      },
      {
        id: "08196",
        nm: "Sant Andreu de la Barca",
      },
      {
        id: "08197",
        nm: "Sant Andreu de Llavaneres",
      },
      {
        id: "08198",
        nm: "Sant Antoni de Vilamajor",
      },
      {
        id: "08199",
        nm: "Sant Bartomeu del Grau",
      },
      {
        id: "08200",
        nm: "Sant Boi de Llobregat",
      },
      {
        id: "08201",
        nm: "Sant Boi de Lluçanès",
      },
      {
        id: "08203",
        nm: "Sant Cebrià de Vallalta",
      },
      {
        id: "08202",
        nm: "Sant Celoni",
      },
      {
        id: "08204",
        nm: "Sant Climent de Llobregat",
      },
      {
        id: "08205",
        nm: "Sant Cugat del Vallès",
      },
      {
        id: "08206",
        nm: "Sant Cugat Sesgarrigues",
      },
      {
        id: "08207",
        nm: "Sant Esteve de Palautordera",
      },
      {
        id: "08208",
        nm: "Sant Esteve Sesrovires",
      },
      {
        id: "08210",
        nm: "Sant Feliu de Codines",
      },
      {
        id: "08211",
        nm: "Sant Feliu de Llobregat",
      },
      {
        id: "08212",
        nm: "Sant Feliu Sasserra",
      },
      {
        id: "08209",
        nm: "Sant Fost de Campsentelles",
      },
      {
        id: "08213",
        nm: "Sant Fruitós de Bages",
      },
      {
        id: "08215",
        nm: "Sant Hipòlit de Voltregà",
      },
      {
        id: "08193",
        nm: "Sant Iscle de Vallalta",
      },
      {
        id: "08216",
        nm: "Sant Jaume de Frontanyà",
      },
      {
        id: "08218",
        nm: "Sant Joan de Vilatorrada",
      },
      {
        id: "08217",
        nm: "Sant Joan Despí",
      },
      {
        id: "08903",
        nm: "Sant Julià de Cerdanyola",
      },
      {
        id: "08220",
        nm: "Sant Julià de Vilatorta",
      },
      {
        id: "08221",
        nm: "Sant Just Desvern",
      },
      {
        id: "08222",
        nm: "Sant Llorenç d'Hortons",
      },
      {
        id: "08223",
        nm: "Sant Llorenç Savall",
      },
      {
        id: "08225",
        nm: "Sant Martí d'Albars",
      },
      {
        id: "08224",
        nm: "Sant Martí de Centelles",
      },
      {
        id: "08226",
        nm: "Sant Martí de Tous",
      },
      {
        id: "08227",
        nm: "Sant Martí Sarroca",
      },
      {
        id: "08228",
        nm: "Sant Martí Sesgueioles",
      },
      {
        id: "08229",
        nm: "Sant Mateu de Bages",
      },
      {
        id: "08231",
        nm: "Sant Pere de Ribes",
      },
      {
        id: "08232",
        nm: "Sant Pere de Riudebitlles",
      },
      {
        id: "08233",
        nm: "Sant Pere de Torelló",
      },
      {
        id: "08234",
        nm: "Sant Pere de Vilamajor",
      },
      {
        id: "08189",
        nm: "Sant Pere Sallavinera",
      },
      {
        id: "08235",
        nm: "Sant Pol de Mar",
      },
      {
        id: "08236",
        nm: "Sant Quintí de Mediona",
      },
      {
        id: "08237",
        nm: "Sant Quirze de Besora",
      },
      {
        id: "08238",
        nm: "Sant Quirze del Vallès",
      },
      {
        id: "08239",
        nm: "Sant Quirze Safaja",
      },
      {
        id: "08240",
        nm: "Sant Sadurní d'Anoia",
      },
      {
        id: "08241",
        nm: "Sant Sadurní d'Osormort",
      },
      {
        id: "08098",
        nm: "Sant Salvador de Guardiola",
      },
      {
        id: "08262",
        nm: "Sant Vicenç de Castellet",
      },
      {
        id: "08264",
        nm: "Sant Vicenç de Montalt",
      },
      {
        id: "08265",
        nm: "Sant Vicenç de Torelló",
      },
      {
        id: "08263",
        nm: "Sant Vicenç dels Horts",
      },
      {
        id: "08243",
        nm: "Santa Cecília de Voltregà",
      },
      {
        id: "08244",
        nm: "Santa Coloma de Cervelló",
      },
      {
        id: "08245",
        nm: "Santa Coloma de Gramenet",
      },
      {
        id: "08246",
        nm: "Santa Eugènia de Berga",
      },
      {
        id: "08247",
        nm: "Santa Eulàlia de Riuprimer",
      },
      {
        id: "08248",
        nm: "Santa Eulàlia de Ronçana",
      },
      {
        id: "08249",
        nm: "Santa Fe del Penedès",
      },
      {
        id: "08250",
        nm: "Santa Margarida de Montbui",
      },
      {
        id: "08251",
        nm: "Santa Margarida i els Monjos",
      },
      {
        id: "08253",
        nm: "Santa Maria de Besora",
      },
      {
        id: "08256",
        nm: "Santa Maria de Martorelles",
      },
      {
        id: "08255",
        nm: "Santa Maria de Merlès",
      },
      {
        id: "08257",
        nm: "Santa Maria de Miralles",
      },
      {
        id: "08259",
        nm: "Santa Maria de Palautordera",
      },
      {
        id: "08258",
        nm: "Santa Maria d'Oló",
      },
      {
        id: "08260",
        nm: "Santa Perpètua de Mogoda",
      },
      {
        id: "08261",
        nm: "Santa Susanna",
      },
      {
        id: "08192",
        nm: "Santpedor",
      },
      {
        id: "08267",
        nm: "Sentmenat",
      },
      {
        id: "08269",
        nm: "Seva",
      },
      {
        id: "08270",
        nm: "Sitges",
      },
      {
        id: "08271",
        nm: "Sobremunt",
      },
      {
        id: "08272",
        nm: "Sora",
      },
      {
        id: "08273",
        nm: "Subirats",
      },
      {
        id: "08274",
        nm: "Súria",
      },
      {
        id: "08276",
        nm: "Tagamanent",
      },
      {
        id: "08277",
        nm: "Talamanca",
      },
      {
        id: "08278",
        nm: "Taradell",
      },
      {
        id: "08275",
        nm: "Tavèrnoles",
      },
      {
        id: "08280",
        nm: "Tavertet",
      },
      {
        id: "08281",
        nm: "Teià",
      },
      {
        id: "08279",
        nm: "Terrassa",
      },
      {
        id: "08282",
        nm: "Tiana",
      },
      {
        id: "08283",
        nm: "Tona",
      },
      {
        id: "08284",
        nm: "Tordera",
      },
      {
        id: "08285",
        nm: "Torelló",
      },
      {
        id: "08286",
        nm: "Torre de Claramunt, La",
      },
      {
        id: "08287",
        nm: "Torrelavit",
      },
      {
        id: "08288",
        nm: "Torrelles de Foix",
      },
      {
        id: "08289",
        nm: "Torrelles de Llobregat",
      },
      {
        id: "08290",
        nm: "Ullastrell",
      },
      {
        id: "08291",
        nm: "Vacarisses",
      },
      {
        id: "08292",
        nm: "Vallbona d'Anoia",
      },
      {
        id: "08293",
        nm: "Vallcebre",
      },
      {
        id: "08294",
        nm: "Vallgorguina",
      },
      {
        id: "08295",
        nm: "Vallirana",
      },
      {
        id: "08296",
        nm: "Vallromanes",
      },
      {
        id: "08297",
        nm: "Veciana",
      },
      {
        id: "08298",
        nm: "Vic",
      },
      {
        id: "08299",
        nm: "Vilada",
      },
      {
        id: "08301",
        nm: "Viladecans",
      },
      {
        id: "08300",
        nm: "Viladecavalls",
      },
      {
        id: "08305",
        nm: "Vilafranca del Penedès",
      },
      {
        id: "08306",
        nm: "Vilalba Sasserra",
      },
      {
        id: "08303",
        nm: "Vilanova de Sau",
      },
      {
        id: "08302",
        nm: "Vilanova del Camí",
      },
      {
        id: "08902",
        nm: "Vilanova del Vallès",
      },
      {
        id: "08307",
        nm: "Vilanova i la Geltrú",
      },
      {
        id: "08214",
        nm: "Vilassar de Dalt",
      },
      {
        id: "08219",
        nm: "Vilassar de Mar",
      },
      {
        id: "08304",
        nm: "Vilobí del Penedès",
      },
      {
        id: "08308",
        nm: "Viver i Serrateix",
      },
      {
        id: "09001",
        nm: "Abajas",
      },
      {
        id: "09003",
        nm: "Adrada de Haza",
      },
      {
        id: "09006",
        nm: "Aguas Cándidas",
      },
      {
        id: "09007",
        nm: "Aguilar de Bureba",
      },
      {
        id: "09009",
        nm: "Albillos",
      },
      {
        id: "09010",
        nm: "Alcocero de Mola",
      },
      {
        id: "09011",
        nm: "Alfoz de Bricia",
      },
      {
        id: "09907",
        nm: "Alfoz de Quintanadueñas",
      },
      {
        id: "09012",
        nm: "Alfoz de Santa Gadea",
      },
      {
        id: "09013",
        nm: "Altable",
      },
      {
        id: "09014",
        nm: "Altos, Los",
      },
      {
        id: "09016",
        nm: "Ameyugo",
      },
      {
        id: "09017",
        nm: "Anguix",
      },
      {
        id: "09018",
        nm: "Aranda de Duero",
      },
      {
        id: "09019",
        nm: "Arandilla",
      },
      {
        id: "09020",
        nm: "Arauzo de Miel",
      },
      {
        id: "09021",
        nm: "Arauzo de Salce",
      },
      {
        id: "09022",
        nm: "Arauzo de Torre",
      },
      {
        id: "09023",
        nm: "Arcos",
      },
      {
        id: "09024",
        nm: "Arenillas de Riopisuerga",
      },
      {
        id: "09025",
        nm: "Arija",
      },
      {
        id: "09026",
        nm: "Arlanzón",
      },
      {
        id: "09027",
        nm: "Arraya de Oca",
      },
      {
        id: "09029",
        nm: "Atapuerca",
      },
      {
        id: "09030",
        nm: "Ausines, Los",
      },
      {
        id: "09032",
        nm: "Avellanosa de Muñó",
      },
      {
        id: "09033",
        nm: "Bahabón de Esgueva",
      },
      {
        id: "09034",
        nm: "Balbases, Los",
      },
      {
        id: "09035",
        nm: "Baños de Valdearados",
      },
      {
        id: "09036",
        nm: "Bañuelos de Bureba",
      },
      {
        id: "09037",
        nm: "Barbadillo de Herreros",
      },
      {
        id: "09038",
        nm: "Barbadillo del Mercado",
      },
      {
        id: "09039",
        nm: "Barbadillo del Pez",
      },
      {
        id: "09041",
        nm: "Barrio de Muñó",
      },
      {
        id: "09043",
        nm: "Barrios de Bureba, Los",
      },
      {
        id: "09044",
        nm: "Barrios de Colina",
      },
      {
        id: "09045",
        nm: "Basconcillos del Tozo",
      },
      {
        id: "09046",
        nm: "Bascuñana",
      },
      {
        id: "09047",
        nm: "Belbimbre",
      },
      {
        id: "09048",
        nm: "Belorado",
      },
      {
        id: "09050",
        nm: "Berberana",
      },
      {
        id: "09051",
        nm: "Berlangas de Roa",
      },
      {
        id: "09052",
        nm: "Berzosa de Bureba",
      },
      {
        id: "09054",
        nm: "Bozoó",
      },
      {
        id: "09055",
        nm: "Brazacorta",
      },
      {
        id: "09056",
        nm: "Briviesca",
      },
      {
        id: "09057",
        nm: "Bugedo",
      },
      {
        id: "09058",
        nm: "Buniel",
      },
      {
        id: "09059",
        nm: "Burgos",
      },
      {
        id: "09060",
        nm: "Busto de Bureba",
      },
      {
        id: "09061",
        nm: "Cabañes de Esgueva",
      },
      {
        id: "09062",
        nm: "Cabezón de la Sierra",
      },
      {
        id: "09064",
        nm: "Caleruega",
      },
      {
        id: "09065",
        nm: "Campillo de Aranda",
      },
      {
        id: "09066",
        nm: "Campolara",
      },
      {
        id: "09067",
        nm: "Canicosa de la Sierra",
      },
      {
        id: "09068",
        nm: "Cantabrana",
      },
      {
        id: "09070",
        nm: "Carazo",
      },
      {
        id: "09071",
        nm: "Carcedo de Bureba",
      },
      {
        id: "09072",
        nm: "Carcedo de Burgos",
      },
      {
        id: "09073",
        nm: "Cardeñadijo",
      },
      {
        id: "09074",
        nm: "Cardeñajimeno",
      },
      {
        id: "09075",
        nm: "Cardeñuela Riopico",
      },
      {
        id: "09076",
        nm: "Carrias",
      },
      {
        id: "09077",
        nm: "Cascajares de Bureba",
      },
      {
        id: "09078",
        nm: "Cascajares de la Sierra",
      },
      {
        id: "09079",
        nm: "Castellanos de Castro",
      },
      {
        id: "09083",
        nm: "Castil de Peones",
      },
      {
        id: "09082",
        nm: "Castildelgado",
      },
      {
        id: "09084",
        nm: "Castrillo de la Reina",
      },
      {
        id: "09085",
        nm: "Castrillo de la Vega",
      },
      {
        id: "09088",
        nm: "Castrillo de Riopisuerga",
      },
      {
        id: "09086",
        nm: "Castrillo del Val",
      },
      {
        id: "09090",
        nm: "Castrillo Mota de Judíos",
      },
      {
        id: "09091",
        nm: "Castrojeriz",
      },
      {
        id: "09063",
        nm: "Cavia",
      },
      {
        id: "09093",
        nm: "Cayuela",
      },
      {
        id: "09094",
        nm: "Cebrecos",
      },
      {
        id: "09095",
        nm: "Celada del Camino",
      },
      {
        id: "09098",
        nm: "Cerezo de Río Tirón",
      },
      {
        id: "09100",
        nm: "Cerratón de Juarros",
      },
      {
        id: "09101",
        nm: "Ciadoncha",
      },
      {
        id: "09102",
        nm: "Cillaperlata",
      },
      {
        id: "09103",
        nm: "Cilleruelo de Abajo",
      },
      {
        id: "09104",
        nm: "Cilleruelo de Arriba",
      },
      {
        id: "09105",
        nm: "Ciruelos de Cervera",
      },
      {
        id: "09108",
        nm: "Cogollos",
      },
      {
        id: "09109",
        nm: "Condado de Treviño",
      },
      {
        id: "09110",
        nm: "Contreras",
      },
      {
        id: "09112",
        nm: "Coruña del Conde",
      },
      {
        id: "09113",
        nm: "Covarrubias",
      },
      {
        id: "09114",
        nm: "Cubillo del Campo",
      },
      {
        id: "09115",
        nm: "Cubo de Bureba",
      },
      {
        id: "09117",
        nm: "Cueva de Roa, La",
      },
      {
        id: "09119",
        nm: "Cuevas de San Clemente",
      },
      {
        id: "09120",
        nm: "Encío",
      },
      {
        id: "09122",
        nm: "Espinosa de Cervera",
      },
      {
        id: "09124",
        nm: "Espinosa de los Monteros",
      },
      {
        id: "09123",
        nm: "Espinosa del Camino",
      },
      {
        id: "09125",
        nm: "Estépar",
      },
      {
        id: "09127",
        nm: "Fontioso",
      },
      {
        id: "09128",
        nm: "Frandovínez",
      },
      {
        id: "09129",
        nm: "Fresneda de la Sierra Tirón",
      },
      {
        id: "09130",
        nm: "Fresneña",
      },
      {
        id: "09131",
        nm: "Fresnillo de las Dueñas",
      },
      {
        id: "09132",
        nm: "Fresno de Río Tirón",
      },
      {
        id: "09133",
        nm: "Fresno de Rodilla",
      },
      {
        id: "09134",
        nm: "Frías",
      },
      {
        id: "09135",
        nm: "Fuentebureba",
      },
      {
        id: "09136",
        nm: "Fuentecén",
      },
      {
        id: "09137",
        nm: "Fuentelcésped",
      },
      {
        id: "09138",
        nm: "Fuentelisendo",
      },
      {
        id: "09139",
        nm: "Fuentemolinos",
      },
      {
        id: "09140",
        nm: "Fuentenebro",
      },
      {
        id: "09141",
        nm: "Fuentespina",
      },
      {
        id: "09143",
        nm: "Galbarros",
      },
      {
        id: "09144",
        nm: "Gallega, La",
      },
      {
        id: "09148",
        nm: "Grijalba",
      },
      {
        id: "09149",
        nm: "Grisaleña",
      },
      {
        id: "09151",
        nm: "Gumiel de Izán",
      },
      {
        id: "09152",
        nm: "Gumiel de Mercado",
      },
      {
        id: "09154",
        nm: "Hacinas",
      },
      {
        id: "09155",
        nm: "Haza",
      },
      {
        id: "09159",
        nm: "Hontanas",
      },
      {
        id: "09160",
        nm: "Hontangas",
      },
      {
        id: "09162",
        nm: "Hontoria de la Cantera",
      },
      {
        id: "09164",
        nm: "Hontoria de Valdearados",
      },
      {
        id: "09163",
        nm: "Hontoria del Pinar",
      },
      {
        id: "09166",
        nm: "Hormazas, Las",
      },
      {
        id: "09167",
        nm: "Hornillos del Camino",
      },
      {
        id: "09168",
        nm: "Horra, La",
      },
      {
        id: "09169",
        nm: "Hortigüela",
      },
      {
        id: "09170",
        nm: "Hoyales de Roa",
      },
      {
        id: "09172",
        nm: "Huérmeces",
      },
      {
        id: "09173",
        nm: "Huerta de Arriba",
      },
      {
        id: "09174",
        nm: "Huerta de Rey",
      },
      {
        id: "09175",
        nm: "Humada",
      },
      {
        id: "09176",
        nm: "Hurones",
      },
      {
        id: "09177",
        nm: "Ibeas de Juarros",
      },
      {
        id: "09178",
        nm: "Ibrillos",
      },
      {
        id: "09179",
        nm: "Iglesiarrubia",
      },
      {
        id: "09180",
        nm: "Iglesias",
      },
      {
        id: "09181",
        nm: "Isar",
      },
      {
        id: "09182",
        nm: "Itero del Castillo",
      },
      {
        id: "09183",
        nm: "Jaramillo de la Fuente",
      },
      {
        id: "09184",
        nm: "Jaramillo Quemado",
      },
      {
        id: "09189",
        nm: "Junta de Traslaloma",
      },
      {
        id: "09190",
        nm: "Junta de Villalba de Losa",
      },
      {
        id: "09191",
        nm: "Jurisdicción de Lara",
      },
      {
        id: "09192",
        nm: "Jurisdicción de San Zadornil",
      },
      {
        id: "09194",
        nm: "Lerma",
      },
      {
        id: "09195",
        nm: "Llano de Bureba",
      },
      {
        id: "09196",
        nm: "Madrigal del Monte",
      },
      {
        id: "09197",
        nm: "Madrigalejo del Monte",
      },
      {
        id: "09198",
        nm: "Mahamud",
      },
      {
        id: "09199",
        nm: "Mambrilla de Castrejón",
      },
      {
        id: "09200",
        nm: "Mambrillas de Lara",
      },
      {
        id: "09201",
        nm: "Mamolar",
      },
      {
        id: "09202",
        nm: "Manciles",
      },
      {
        id: "09206",
        nm: "Mazuela",
      },
      {
        id: "09208",
        nm: "Mecerreyes",
      },
      {
        id: "09209",
        nm: "Medina de Pomar",
      },
      {
        id: "09211",
        nm: "Melgar de Fernamental",
      },
      {
        id: "09213",
        nm: "Merindad de Cuesta-Urria",
      },
      {
        id: "09214",
        nm: "Merindad de Montija",
      },
      {
        id: "09906",
        nm: "Merindad de Río Ubierna",
      },
      {
        id: "09215",
        nm: "Merindad de Sotoscueva",
      },
      {
        id: "09216",
        nm: "Merindad de Valdeporres",
      },
      {
        id: "09217",
        nm: "Merindad de Valdivielso",
      },
      {
        id: "09218",
        nm: "Milagros",
      },
      {
        id: "09219",
        nm: "Miranda de Ebro",
      },
      {
        id: "09220",
        nm: "Miraveche",
      },
      {
        id: "09221",
        nm: "Modúbar de la Emparedada",
      },
      {
        id: "09223",
        nm: "Monasterio de la Sierra",
      },
      {
        id: "09224",
        nm: "Monasterio de Rodilla",
      },
      {
        id: "09225",
        nm: "Moncalvillo",
      },
      {
        id: "09226",
        nm: "Monterrubio de la Demanda",
      },
      {
        id: "09227",
        nm: "Montorio",
      },
      {
        id: "09228",
        nm: "Moradillo de Roa",
      },
      {
        id: "09229",
        nm: "Nava de Roa",
      },
      {
        id: "09230",
        nm: "Navas de Bureba",
      },
      {
        id: "09231",
        nm: "Nebreda",
      },
      {
        id: "09232",
        nm: "Neila",
      },
      {
        id: "09235",
        nm: "Olmedillo de Roa",
      },
      {
        id: "09236",
        nm: "Olmillos de Muñó",
      },
      {
        id: "09238",
        nm: "Oña",
      },
      {
        id: "09239",
        nm: "Oquillas",
      },
      {
        id: "09241",
        nm: "Orbaneja Riopico",
      },
      {
        id: "09242",
        nm: "Padilla de Abajo",
      },
      {
        id: "09243",
        nm: "Padilla de Arriba",
      },
      {
        id: "09244",
        nm: "Padrones de Bureba",
      },
      {
        id: "09246",
        nm: "Palacios de la Sierra",
      },
      {
        id: "09247",
        nm: "Palacios de Riopisuerga",
      },
      {
        id: "09248",
        nm: "Palazuelos de la Sierra",
      },
      {
        id: "09249",
        nm: "Palazuelos de Muñó",
      },
      {
        id: "09250",
        nm: "Pampliega",
      },
      {
        id: "09251",
        nm: "Pancorbo",
      },
      {
        id: "09253",
        nm: "Pardilla",
      },
      {
        id: "09255",
        nm: "Partido de la Sierra en Tobalina",
      },
      {
        id: "09256",
        nm: "Pedrosa de Duero",
      },
      {
        id: "09259",
        nm: "Pedrosa de Río Úrbel",
      },
      {
        id: "09257",
        nm: "Pedrosa del Páramo",
      },
      {
        id: "09258",
        nm: "Pedrosa del Príncipe",
      },
      {
        id: "09261",
        nm: "Peñaranda de Duero",
      },
      {
        id: "09262",
        nm: "Peral de Arlanza",
      },
      {
        id: "09265",
        nm: "Piérnigas",
      },
      {
        id: "09266",
        nm: "Pineda de la Sierra",
      },
      {
        id: "09267",
        nm: "Pineda Trasmonte",
      },
      {
        id: "09268",
        nm: "Pinilla de los Barruecos",
      },
      {
        id: "09269",
        nm: "Pinilla de los Moros",
      },
      {
        id: "09270",
        nm: "Pinilla Trasmonte",
      },
      {
        id: "09272",
        nm: "Poza de la Sal",
      },
      {
        id: "09273",
        nm: "Prádanos de Bureba",
      },
      {
        id: "09274",
        nm: "Pradoluengo",
      },
      {
        id: "09275",
        nm: "Presencio",
      },
      {
        id: "09276",
        nm: "Puebla de Arganzón, La",
      },
      {
        id: "09277",
        nm: "Puentedura",
      },
      {
        id: "09279",
        nm: "Quemada",
      },
      {
        id: "09281",
        nm: "Quintana del Pidio",
      },
      {
        id: "09280",
        nm: "Quintanabureba",
      },
      {
        id: "09283",
        nm: "Quintanaélez",
      },
      {
        id: "09287",
        nm: "Quintanaortuño",
      },
      {
        id: "09288",
        nm: "Quintanapalla",
      },
      {
        id: "09289",
        nm: "Quintanar de la Sierra",
      },
      {
        id: "09292",
        nm: "Quintanavides",
      },
      {
        id: "09294",
        nm: "Quintanilla de la Mata",
      },
      {
        id: "09901",
        nm: "Quintanilla del Agua y Tordueles",
      },
      {
        id: "09295",
        nm: "Quintanilla del Coco",
      },
      {
        id: "09298",
        nm: "Quintanilla San García",
      },
      {
        id: "09301",
        nm: "Quintanilla Vivar",
      },
      {
        id: "09297",
        nm: "Quintanillas, Las",
      },
      {
        id: "09302",
        nm: "Rabanera del Pinar",
      },
      {
        id: "09303",
        nm: "Rábanos",
      },
      {
        id: "09304",
        nm: "Rabé de las Calzadas",
      },
      {
        id: "09306",
        nm: "Rebolledo de la Torre",
      },
      {
        id: "09307",
        nm: "Redecilla del Camino",
      },
      {
        id: "09308",
        nm: "Redecilla del Campo",
      },
      {
        id: "09309",
        nm: "Regumiel de la Sierra",
      },
      {
        id: "09310",
        nm: "Reinoso",
      },
      {
        id: "09311",
        nm: "Retuerta",
      },
      {
        id: "09314",
        nm: "Revilla del Campo",
      },
      {
        id: "09316",
        nm: "Revilla Vallejera",
      },
      {
        id: "09312",
        nm: "Revilla y Ahedo, La",
      },
      {
        id: "09315",
        nm: "Revillarruz",
      },
      {
        id: "09317",
        nm: "Rezmondo",
      },
      {
        id: "09318",
        nm: "Riocavado de la Sierra",
      },
      {
        id: "09321",
        nm: "Roa",
      },
      {
        id: "09323",
        nm: "Rojas",
      },
      {
        id: "09325",
        nm: "Royuela de Río Franco",
      },
      {
        id: "09326",
        nm: "Rubena",
      },
      {
        id: "09327",
        nm: "Rublacedo de Abajo",
      },
      {
        id: "09328",
        nm: "Rucandio",
      },
      {
        id: "09329",
        nm: "Salas de Bureba",
      },
      {
        id: "09330",
        nm: "Salas de los Infantes",
      },
      {
        id: "09332",
        nm: "Saldaña de Burgos",
      },
      {
        id: "09334",
        nm: "Salinillas de Bureba",
      },
      {
        id: "09335",
        nm: "San Adrián de Juarros",
      },
      {
        id: "09337",
        nm: "San Juan del Monte",
      },
      {
        id: "09338",
        nm: "San Mamés de Burgos",
      },
      {
        id: "09339",
        nm: "San Martín de Rubiales",
      },
      {
        id: "09340",
        nm: "San Millán de Lara",
      },
      {
        id: "09360",
        nm: "San Vicente del Valle",
      },
      {
        id: "09343",
        nm: "Santa Cecilia",
      },
      {
        id: "09345",
        nm: "Santa Cruz de la Salceda",
      },
      {
        id: "09346",
        nm: "Santa Cruz del Valle Urbión",
      },
      {
        id: "09347",
        nm: "Santa Gadea del Cid",
      },
      {
        id: "09348",
        nm: "Santa Inés",
      },
      {
        id: "09350",
        nm: "Santa María del Campo",
      },
      {
        id: "09351",
        nm: "Santa María del Invierno",
      },
      {
        id: "09352",
        nm: "Santa María del Mercadillo",
      },
      {
        id: "09353",
        nm: "Santa María Rivarredonda",
      },
      {
        id: "09354",
        nm: "Santa Olalla de Bureba",
      },
      {
        id: "09355",
        nm: "Santibáñez de Esgueva",
      },
      {
        id: "09356",
        nm: "Santibáñez del Val",
      },
      {
        id: "09358",
        nm: "Santo Domingo de Silos",
      },
      {
        id: "09361",
        nm: "Sargentes de la Lora",
      },
      {
        id: "09362",
        nm: "Sarracín",
      },
      {
        id: "09363",
        nm: "Sasamón",
      },
      {
        id: "09365",
        nm: "Sequera de Haza, La",
      },
      {
        id: "09366",
        nm: "Solarana",
      },
      {
        id: "09368",
        nm: "Sordillos",
      },
      {
        id: "09369",
        nm: "Sotillo de la Ribera",
      },
      {
        id: "09372",
        nm: "Sotragero",
      },
      {
        id: "09373",
        nm: "Sotresgudo",
      },
      {
        id: "09374",
        nm: "Susinos del Páramo",
      },
      {
        id: "09375",
        nm: "Tamarón",
      },
      {
        id: "09377",
        nm: "Tardajos",
      },
      {
        id: "09378",
        nm: "Tejada",
      },
      {
        id: "09380",
        nm: "Terradillos de Esgueva",
      },
      {
        id: "09381",
        nm: "Tinieblas de la Sierra",
      },
      {
        id: "09382",
        nm: "Tobar",
      },
      {
        id: "09384",
        nm: "Tordómar",
      },
      {
        id: "09386",
        nm: "Torrecilla del Monte",
      },
      {
        id: "09387",
        nm: "Torregalindo",
      },
      {
        id: "09388",
        nm: "Torrelara",
      },
      {
        id: "09389",
        nm: "Torrepadre",
      },
      {
        id: "09390",
        nm: "Torresandino",
      },
      {
        id: "09391",
        nm: "Tórtoles de Esgueva",
      },
      {
        id: "09392",
        nm: "Tosantos",
      },
      {
        id: "09394",
        nm: "Trespaderne",
      },
      {
        id: "09395",
        nm: "Tubilla del Agua",
      },
      {
        id: "09396",
        nm: "Tubilla del Lago",
      },
      {
        id: "09398",
        nm: "Úrbel del Castillo",
      },
      {
        id: "09400",
        nm: "Vadocondes",
      },
      {
        id: "09403",
        nm: "Valdeande",
      },
      {
        id: "09405",
        nm: "Valdezate",
      },
      {
        id: "09406",
        nm: "Valdorros",
      },
      {
        id: "09408",
        nm: "Vallarta de Bureba",
      },
      {
        id: "09904",
        nm: "Valle de las Navas",
      },
      {
        id: "09908",
        nm: "Valle de Losa",
      },
      {
        id: "09409",
        nm: "Valle de Manzanedo",
      },
      {
        id: "09410",
        nm: "Valle de Mena",
      },
      {
        id: "09411",
        nm: "Valle de Oca",
      },
      {
        id: "09902",
        nm: "Valle de Santibáñez",
      },
      {
        id: "09905",
        nm: "Valle de Sedano",
      },
      {
        id: "09412",
        nm: "Valle de Tobalina",
      },
      {
        id: "09413",
        nm: "Valle de Valdebezana",
      },
      {
        id: "09414",
        nm: "Valle de Valdelaguna",
      },
      {
        id: "09415",
        nm: "Valle de Valdelucio",
      },
      {
        id: "09416",
        nm: "Valle de Zamanzas",
      },
      {
        id: "09417",
        nm: "Vallejera",
      },
      {
        id: "09418",
        nm: "Valles de Palenzuela",
      },
      {
        id: "09419",
        nm: "Valluércanes",
      },
      {
        id: "09407",
        nm: "Valmala",
      },
      {
        id: "09422",
        nm: "Vid de Bureba, La",
      },
      {
        id: "09421",
        nm: "Vid y Barrios, La",
      },
      {
        id: "09423",
        nm: "Vileña",
      },
      {
        id: "09427",
        nm: "Villadiego",
      },
      {
        id: "09428",
        nm: "Villaescusa de Roa",
      },
      {
        id: "09429",
        nm: "Villaescusa la Sombría",
      },
      {
        id: "09430",
        nm: "Villaespasa",
      },
      {
        id: "09431",
        nm: "Villafranca Montes de Oca",
      },
      {
        id: "09432",
        nm: "Villafruela",
      },
      {
        id: "09433",
        nm: "Villagalijo",
      },
      {
        id: "09434",
        nm: "Villagonzalo Pedernales",
      },
      {
        id: "09437",
        nm: "Villahoz",
      },
      {
        id: "09438",
        nm: "Villalba de Duero",
      },
      {
        id: "09439",
        nm: "Villalbilla de Burgos",
      },
      {
        id: "09440",
        nm: "Villalbilla de Gumiel",
      },
      {
        id: "09441",
        nm: "Villaldemiro",
      },
      {
        id: "09442",
        nm: "Villalmanzo",
      },
      {
        id: "09443",
        nm: "Villamayor de los Montes",
      },
      {
        id: "09444",
        nm: "Villamayor de Treviño",
      },
      {
        id: "09445",
        nm: "Villambistia",
      },
      {
        id: "09446",
        nm: "Villamedianilla",
      },
      {
        id: "09447",
        nm: "Villamiel de la Sierra",
      },
      {
        id: "09448",
        nm: "Villangómez",
      },
      {
        id: "09449",
        nm: "Villanueva de Argaño",
      },
      {
        id: "09450",
        nm: "Villanueva de Carazo",
      },
      {
        id: "09451",
        nm: "Villanueva de Gumiel",
      },
      {
        id: "09454",
        nm: "Villanueva de Teba",
      },
      {
        id: "09455",
        nm: "Villaquirán de la Puebla",
      },
      {
        id: "09456",
        nm: "Villaquirán de los Infantes",
      },
      {
        id: "09903",
        nm: "Villarcayo de Merindad de Castilla la Vieja",
      },
      {
        id: "09458",
        nm: "Villariezo",
      },
      {
        id: "09460",
        nm: "Villasandino",
      },
      {
        id: "09463",
        nm: "Villasur de Herreros",
      },
      {
        id: "09464",
        nm: "Villatuelda",
      },
      {
        id: "09466",
        nm: "Villaverde del Monte",
      },
      {
        id: "09467",
        nm: "Villaverde-Mogina",
      },
      {
        id: "09471",
        nm: "Villayerno Morquillas",
      },
      {
        id: "09472",
        nm: "Villazopeque",
      },
      {
        id: "09473",
        nm: "Villegas",
      },
      {
        id: "09476",
        nm: "Villoruebo",
      },
      {
        id: "09424",
        nm: "Viloria de Rioja",
      },
      {
        id: "09425",
        nm: "Vilviestre del Pinar",
      },
      {
        id: "09478",
        nm: "Vizcaínos",
      },
      {
        id: "09480",
        nm: "Zael",
      },
      {
        id: "09482",
        nm: "Zarzosa de Río Pisuerga",
      },
      {
        id: "09483",
        nm: "Zazuar",
      },
      {
        id: "09485",
        nm: "Zuñeda",
      },
      {
        id: "10001",
        nm: "Abadía",
      },
      {
        id: "10002",
        nm: "Abertura",
      },
      {
        id: "10003",
        nm: "Acebo",
      },
      {
        id: "10004",
        nm: "Acehúche",
      },
      {
        id: "10005",
        nm: "Aceituna",
      },
      {
        id: "10006",
        nm: "Ahigal",
      },
      {
        id: "10903",
        nm: "Alagón del Río",
      },
      {
        id: "10007",
        nm: "Albalá",
      },
      {
        id: "10008",
        nm: "Alcántara",
      },
      {
        id: "10009",
        nm: "Alcollarín",
      },
      {
        id: "10010",
        nm: "Alcuéscar",
      },
      {
        id: "10012",
        nm: "Aldea del Cano",
      },
      {
        id: "10013",
        nm: "Aldea del Obispo, La",
      },
      {
        id: "10011",
        nm: "Aldeacentenera",
      },
      {
        id: "10014",
        nm: "Aldeanueva de la Vera",
      },
      {
        id: "10015",
        nm: "Aldeanueva del Camino",
      },
      {
        id: "10016",
        nm: "Aldehuela de Jerte",
      },
      {
        id: "10017",
        nm: "Alía",
      },
      {
        id: "10018",
        nm: "Aliseda",
      },
      {
        id: "10019",
        nm: "Almaraz",
      },
      {
        id: "10020",
        nm: "Almoharín",
      },
      {
        id: "10021",
        nm: "Arroyo de la Luz",
      },
      {
        id: "10023",
        nm: "Arroyomolinos",
      },
      {
        id: "10022",
        nm: "Arroyomolinos de la Vera",
      },
      {
        id: "10024",
        nm: "Baños de Montemayor",
      },
      {
        id: "10025",
        nm: "Barrado",
      },
      {
        id: "10026",
        nm: "Belvís de Monroy",
      },
      {
        id: "10027",
        nm: "Benquerencia",
      },
      {
        id: "10028",
        nm: "Berrocalejo",
      },
      {
        id: "10029",
        nm: "Berzocana",
      },
      {
        id: "10030",
        nm: "Bohonal de Ibor",
      },
      {
        id: "10031",
        nm: "Botija",
      },
      {
        id: "10032",
        nm: "Brozas",
      },
      {
        id: "10033",
        nm: "Cabañas del Castillo",
      },
      {
        id: "10034",
        nm: "Cabezabellosa",
      },
      {
        id: "10035",
        nm: "Cabezuela del Valle",
      },
      {
        id: "10036",
        nm: "Cabrero",
      },
      {
        id: "10037",
        nm: "Cáceres",
      },
      {
        id: "10038",
        nm: "Cachorrilla",
      },
      {
        id: "10039",
        nm: "Cadalso",
      },
      {
        id: "10040",
        nm: "Calzadilla",
      },
      {
        id: "10041",
        nm: "Caminomorisco",
      },
      {
        id: "10042",
        nm: "Campillo de Deleitosa",
      },
      {
        id: "10043",
        nm: "Campo Lugar",
      },
      {
        id: "10044",
        nm: "Cañamero",
      },
      {
        id: "10045",
        nm: "Cañaveral",
      },
      {
        id: "10046",
        nm: "Carbajo",
      },
      {
        id: "10047",
        nm: "Carcaboso",
      },
      {
        id: "10048",
        nm: "Carrascalejo",
      },
      {
        id: "10049",
        nm: "Casar de Cáceres",
      },
      {
        id: "10050",
        nm: "Casar de Palomero",
      },
      {
        id: "10051",
        nm: "Casares de las Hurdes",
      },
      {
        id: "10052",
        nm: "Casas de Don Antonio",
      },
      {
        id: "10053",
        nm: "Casas de Don Gómez",
      },
      {
        id: "10056",
        nm: "Casas de Millán",
      },
      {
        id: "10057",
        nm: "Casas de Miravete",
      },
      {
        id: "10054",
        nm: "Casas del Castañar",
      },
      {
        id: "10055",
        nm: "Casas del Monte",
      },
      {
        id: "10058",
        nm: "Casatejada",
      },
      {
        id: "10059",
        nm: "Casillas de Coria",
      },
      {
        id: "10060",
        nm: "Castañar de Ibor",
      },
      {
        id: "10061",
        nm: "Ceclavín",
      },
      {
        id: "10062",
        nm: "Cedillo",
      },
      {
        id: "10063",
        nm: "Cerezo",
      },
      {
        id: "10064",
        nm: "Cilleros",
      },
      {
        id: "10065",
        nm: "Collado de la Vera",
      },
      {
        id: "10066",
        nm: "Conquista de la Sierra",
      },
      {
        id: "10067",
        nm: "Coria",
      },
      {
        id: "10068",
        nm: "Cuacos de Yuste",
      },
      {
        id: "10069",
        nm: "Cumbre, La",
      },
      {
        id: "10070",
        nm: "Deleitosa",
      },
      {
        id: "10071",
        nm: "Descargamaría",
      },
      {
        id: "10072",
        nm: "Eljas",
      },
      {
        id: "10073",
        nm: "Escurial",
      },
      {
        id: "10075",
        nm: "Fresnedoso de Ibor",
      },
      {
        id: "10076",
        nm: "Galisteo",
      },
      {
        id: "10077",
        nm: "Garciaz",
      },
      {
        id: "10079",
        nm: "Garganta la Olla",
      },
      {
        id: "10078",
        nm: "Garganta, La",
      },
      {
        id: "10080",
        nm: "Gargantilla",
      },
      {
        id: "10081",
        nm: "Gargüera",
      },
      {
        id: "10082",
        nm: "Garrovillas de Alconétar",
      },
      {
        id: "10083",
        nm: "Garvín",
      },
      {
        id: "10084",
        nm: "Gata",
      },
      {
        id: "10085",
        nm: "Gordo, El",
      },
      {
        id: "10086",
        nm: "Granja, La",
      },
      {
        id: "10087",
        nm: "Guadalupe",
      },
      {
        id: "10088",
        nm: "Guijo de Coria",
      },
      {
        id: "10089",
        nm: "Guijo de Galisteo",
      },
      {
        id: "10090",
        nm: "Guijo de Granadilla",
      },
      {
        id: "10091",
        nm: "Guijo de Santa Bárbara",
      },
      {
        id: "10092",
        nm: "Herguijuela",
      },
      {
        id: "10093",
        nm: "Hernán-Pérez",
      },
      {
        id: "10094",
        nm: "Herrera de Alcántara",
      },
      {
        id: "10095",
        nm: "Herreruela",
      },
      {
        id: "10096",
        nm: "Hervás",
      },
      {
        id: "10097",
        nm: "Higuera",
      },
      {
        id: "10098",
        nm: "Hinojal",
      },
      {
        id: "10099",
        nm: "Holguera",
      },
      {
        id: "10100",
        nm: "Hoyos",
      },
      {
        id: "10101",
        nm: "Huélaga",
      },
      {
        id: "10102",
        nm: "Ibahernando",
      },
      {
        id: "10103",
        nm: "Jaraicejo",
      },
      {
        id: "10104",
        nm: "Jaraíz de la Vera",
      },
      {
        id: "10105",
        nm: "Jarandilla de la Vera",
      },
      {
        id: "10106",
        nm: "Jarilla",
      },
      {
        id: "10107",
        nm: "Jerte",
      },
      {
        id: "10108",
        nm: "Ladrillar",
      },
      {
        id: "10109",
        nm: "Logrosán",
      },
      {
        id: "10110",
        nm: "Losar de la Vera",
      },
      {
        id: "10111",
        nm: "Madrigal de la Vera",
      },
      {
        id: "10112",
        nm: "Madrigalejo",
      },
      {
        id: "10113",
        nm: "Madroñera",
      },
      {
        id: "10114",
        nm: "Majadas",
      },
      {
        id: "10115",
        nm: "Malpartida de Cáceres",
      },
      {
        id: "10116",
        nm: "Malpartida de Plasencia",
      },
      {
        id: "10117",
        nm: "Marchagaz",
      },
      {
        id: "10118",
        nm: "Mata de Alcántara",
      },
      {
        id: "10119",
        nm: "Membrío",
      },
      {
        id: "10120",
        nm: "Mesas de Ibor",
      },
      {
        id: "10121",
        nm: "Miajadas",
      },
      {
        id: "10122",
        nm: "Millanes",
      },
      {
        id: "10123",
        nm: "Mirabel",
      },
      {
        id: "10124",
        nm: "Mohedas de Granadilla",
      },
      {
        id: "10125",
        nm: "Monroy",
      },
      {
        id: "10126",
        nm: "Montánchez",
      },
      {
        id: "10127",
        nm: "Montehermoso",
      },
      {
        id: "10128",
        nm: "Moraleja",
      },
      {
        id: "10129",
        nm: "Morcillo",
      },
      {
        id: "10130",
        nm: "Navaconcejo",
      },
      {
        id: "10131",
        nm: "Navalmoral de la Mata",
      },
      {
        id: "10132",
        nm: "Navalvillar de Ibor",
      },
      {
        id: "10133",
        nm: "Navas del Madroño",
      },
      {
        id: "10134",
        nm: "Navezuelas",
      },
      {
        id: "10135",
        nm: "Nuñomoral",
      },
      {
        id: "10136",
        nm: "Oliva de Plasencia",
      },
      {
        id: "10137",
        nm: "Palomero",
      },
      {
        id: "10138",
        nm: "Pasarón de la Vera",
      },
      {
        id: "10139",
        nm: "Pedroso de Acim",
      },
      {
        id: "10140",
        nm: "Peraleda de la Mata",
      },
      {
        id: "10141",
        nm: "Peraleda de San Román",
      },
      {
        id: "10142",
        nm: "Perales del Puerto",
      },
      {
        id: "10143",
        nm: "Pescueza",
      },
      {
        id: "10144",
        nm: "Pesga, La",
      },
      {
        id: "10145",
        nm: "Piedras Albas",
      },
      {
        id: "10146",
        nm: "Pinofranqueado",
      },
      {
        id: "10147",
        nm: "Piornal",
      },
      {
        id: "10148",
        nm: "Plasencia",
      },
      {
        id: "10149",
        nm: "Plasenzuela",
      },
      {
        id: "10150",
        nm: "Portaje",
      },
      {
        id: "10151",
        nm: "Portezuelo",
      },
      {
        id: "10152",
        nm: "Pozuelo de Zarzón",
      },
      {
        id: "10905",
        nm: "Pueblonuevo de Miramontes",
      },
      {
        id: "10153",
        nm: "Puerto de Santa Cruz",
      },
      {
        id: "10154",
        nm: "Rebollar",
      },
      {
        id: "10155",
        nm: "Riolobos",
      },
      {
        id: "10156",
        nm: "Robledillo de Gata",
      },
      {
        id: "10157",
        nm: "Robledillo de la Vera",
      },
      {
        id: "10158",
        nm: "Robledillo de Trujillo",
      },
      {
        id: "10159",
        nm: "Robledollano",
      },
      {
        id: "10160",
        nm: "Romangordo",
      },
      {
        id: "10901",
        nm: "Rosalejo",
      },
      {
        id: "10161",
        nm: "Ruanes",
      },
      {
        id: "10162",
        nm: "Salorino",
      },
      {
        id: "10163",
        nm: "Salvatierra de Santiago",
      },
      {
        id: "10164",
        nm: "San Martín de Trevejo",
      },
      {
        id: "10165",
        nm: "Santa Ana",
      },
      {
        id: "10166",
        nm: "Santa Cruz de la Sierra",
      },
      {
        id: "10167",
        nm: "Santa Cruz de Paniagua",
      },
      {
        id: "10168",
        nm: "Santa Marta de Magasca",
      },
      {
        id: "10169",
        nm: "Santiago de Alcántara",
      },
      {
        id: "10170",
        nm: "Santiago del Campo",
      },
      {
        id: "10171",
        nm: "Santibáñez el Alto",
      },
      {
        id: "10172",
        nm: "Santibáñez el Bajo",
      },
      {
        id: "10173",
        nm: "Saucedilla",
      },
      {
        id: "10174",
        nm: "Segura de Toro",
      },
      {
        id: "10175",
        nm: "Serradilla",
      },
      {
        id: "10176",
        nm: "Serrejón",
      },
      {
        id: "10177",
        nm: "Sierra de Fuentes",
      },
      {
        id: "10178",
        nm: "Talaván",
      },
      {
        id: "10179",
        nm: "Talaveruela de la Vera",
      },
      {
        id: "10180",
        nm: "Talayuela",
      },
      {
        id: "10181",
        nm: "Tejeda de Tiétar",
      },
      {
        id: "10904",
        nm: "Tiétar",
      },
      {
        id: "10182",
        nm: "Toril",
      },
      {
        id: "10183",
        nm: "Tornavacas",
      },
      {
        id: "10184",
        nm: "Torno, El",
      },
      {
        id: "10187",
        nm: "Torre de Don Miguel",
      },
      {
        id: "10188",
        nm: "Torre de Santa María",
      },
      {
        id: "10185",
        nm: "Torrecilla de los Ángeles",
      },
      {
        id: "10186",
        nm: "Torrecillas de la Tiesa",
      },
      {
        id: "10190",
        nm: "Torrejón el Rubio",
      },
      {
        id: "10189",
        nm: "Torrejoncillo",
      },
      {
        id: "10191",
        nm: "Torremenga",
      },
      {
        id: "10192",
        nm: "Torremocha",
      },
      {
        id: "10193",
        nm: "Torreorgaz",
      },
      {
        id: "10194",
        nm: "Torrequemada",
      },
      {
        id: "10195",
        nm: "Trujillo",
      },
      {
        id: "10196",
        nm: "Valdastillas",
      },
      {
        id: "10197",
        nm: "Valdecañas de Tajo",
      },
      {
        id: "10198",
        nm: "Valdefuentes",
      },
      {
        id: "10199",
        nm: "Valdehúncar",
      },
      {
        id: "10200",
        nm: "Valdelacasa de Tajo",
      },
      {
        id: "10201",
        nm: "Valdemorales",
      },
      {
        id: "10202",
        nm: "Valdeobispo",
      },
      {
        id: "10203",
        nm: "Valencia de Alcántara",
      },
      {
        id: "10204",
        nm: "Valverde de la Vera",
      },
      {
        id: "10205",
        nm: "Valverde del Fresno",
      },
      {
        id: "10902",
        nm: "Vegaviana",
      },
      {
        id: "10206",
        nm: "Viandar de la Vera",
      },
      {
        id: "10207",
        nm: "Villa del Campo",
      },
      {
        id: "10208",
        nm: "Villa del Rey",
      },
      {
        id: "10209",
        nm: "Villamesías",
      },
      {
        id: "10210",
        nm: "Villamiel",
      },
      {
        id: "10211",
        nm: "Villanueva de la Sierra",
      },
      {
        id: "10212",
        nm: "Villanueva de la Vera",
      },
      {
        id: "10214",
        nm: "Villar de Plasencia",
      },
      {
        id: "10213",
        nm: "Villar del Pedroso",
      },
      {
        id: "10215",
        nm: "Villasbuenas de Gata",
      },
      {
        id: "10216",
        nm: "Zarza de Granadilla",
      },
      {
        id: "10217",
        nm: "Zarza de Montánchez",
      },
      {
        id: "10218",
        nm: "Zarza la Mayor",
      },
      {
        id: "10219",
        nm: "Zorita",
      },
      {
        id: "11001",
        nm: "Alcalá de los Gazules",
      },
      {
        id: "11002",
        nm: "Alcalá del Valle",
      },
      {
        id: "11003",
        nm: "Algar",
      },
      {
        id: "11004",
        nm: "Algeciras",
      },
      {
        id: "11005",
        nm: "Algodonales",
      },
      {
        id: "11006",
        nm: "Arcos de la Frontera",
      },
      {
        id: "11007",
        nm: "Barbate",
      },
      {
        id: "11008",
        nm: "Barrios, Los",
      },
      {
        id: "11901",
        nm: "Benalup-Casas Viejas",
      },
      {
        id: "11009",
        nm: "Benaocaz",
      },
      {
        id: "11010",
        nm: "Bornos",
      },
      {
        id: "11011",
        nm: "Bosque, El",
      },
      {
        id: "11012",
        nm: "Cádiz",
      },
      {
        id: "11013",
        nm: "Castellar de la Frontera",
      },
      {
        id: "11015",
        nm: "Chiclana de la Frontera",
      },
      {
        id: "11016",
        nm: "Chipiona",
      },
      {
        id: "11014",
        nm: "Conil de la Frontera",
      },
      {
        id: "11017",
        nm: "Espera",
      },
      {
        id: "11018",
        nm: "Gastor, El",
      },
      {
        id: "11019",
        nm: "Grazalema",
      },
      {
        id: "11020",
        nm: "Jerez de la Frontera",
      },
      {
        id: "11021",
        nm: "Jimena de la Frontera",
      },
      {
        id: "11022",
        nm: "Línea de la Concepción, La",
      },
      {
        id: "11023",
        nm: "Medina-Sidonia",
      },
      {
        id: "11024",
        nm: "Olvera",
      },
      {
        id: "11025",
        nm: "Paterna de Rivera",
      },
      {
        id: "11026",
        nm: "Prado del Rey",
      },
      {
        id: "11027",
        nm: "Puerto de Santa María, El",
      },
      {
        id: "11028",
        nm: "Puerto Real",
      },
      {
        id: "11029",
        nm: "Puerto Serrano",
      },
      {
        id: "11030",
        nm: "Rota",
      },
      {
        id: "11031",
        nm: "San Fernando",
      },
      {
        id: "11902",
        nm: "San José del Valle",
      },
      {
        id: "11033",
        nm: "San Roque",
      },
      {
        id: "11032",
        nm: "Sanlúcar de Barrameda",
      },
      {
        id: "11034",
        nm: "Setenil de las Bodegas",
      },
      {
        id: "11035",
        nm: "Tarifa",
      },
      {
        id: "11036",
        nm: "Torre Alháquime",
      },
      {
        id: "11037",
        nm: "Trebujena",
      },
      {
        id: "11038",
        nm: "Ubrique",
      },
      {
        id: "11039",
        nm: "Vejer de la Frontera",
      },
      {
        id: "11040",
        nm: "Villaluenga del Rosario",
      },
      {
        id: "11041",
        nm: "Villamartín",
      },
      {
        id: "11042",
        nm: "Zahara",
      },
      {
        id: "12002",
        nm: "Aín",
      },
      {
        id: "12003",
        nm: "Albocàsser",
      },
      {
        id: "12004",
        nm: "Alcalà de Xivert",
      },
      {
        id: "12005",
        nm: "Alcora, l'",
      },
      {
        id: "12006",
        nm: "Alcudia de Veo",
      },
      {
        id: "12007",
        nm: "Alfondeguilla",
      },
      {
        id: "12008",
        nm: "Algimia de Almonacid",
      },
      {
        id: "12009",
        nm: "Almazora/Almassora",
      },
      {
        id: "12010",
        nm: "Almedíjar",
      },
      {
        id: "12011",
        nm: "Almenara",
      },
      {
        id: "12901",
        nm: "Alqueries, les/Alquerías del Niño Perdido",
      },
      {
        id: "12012",
        nm: "Altura",
      },
      {
        id: "12013",
        nm: "Arañuel",
      },
      {
        id: "12014",
        nm: "Ares del Maestrat",
      },
      {
        id: "12015",
        nm: "Argelita",
      },
      {
        id: "12016",
        nm: "Artana",
      },
      {
        id: "12001",
        nm: "Atzeneta del Maestrat",
      },
      {
        id: "12017",
        nm: "Ayódar",
      },
      {
        id: "12018",
        nm: "Azuébar",
      },
      {
        id: "12020",
        nm: "Barracas",
      },
      {
        id: "12022",
        nm: "Bejís",
      },
      {
        id: "12024",
        nm: "Benafer",
      },
      {
        id: "12025",
        nm: "Benafigos",
      },
      {
        id: "12026",
        nm: "Benassal",
      },
      {
        id: "12027",
        nm: "Benicarló",
      },
      {
        id: "12028",
        nm: "Benicasim/Benicàssim",
      },
      {
        id: "12029",
        nm: "Benlloch",
      },
      {
        id: "12021",
        nm: "Betxí",
      },
      {
        id: "12032",
        nm: "Borriana/Burriana",
      },
      {
        id: "12031",
        nm: "Borriol",
      },
      {
        id: "12033",
        nm: "Cabanes",
      },
      {
        id: "12034",
        nm: "Càlig",
      },
      {
        id: "12036",
        nm: "Canet lo Roig",
      },
      {
        id: "12037",
        nm: "Castell de Cabres",
      },
      {
        id: "12038",
        nm: "Castellfort",
      },
      {
        id: "12039",
        nm: "Castellnovo",
      },
      {
        id: "12040",
        nm: "Castellón de la Plana/Castelló de la Plana",
      },
      {
        id: "12041",
        nm: "Castillo de Villamalefa",
      },
      {
        id: "12042",
        nm: "Catí",
      },
      {
        id: "12043",
        nm: "Caudiel",
      },
      {
        id: "12044",
        nm: "Cervera del Maestre",
      },
      {
        id: "12053",
        nm: "Chilches/Xilxes",
      },
      {
        id: "12055",
        nm: "Chodos/Xodos",
      },
      {
        id: "12056",
        nm: "Chóvar",
      },
      {
        id: "12045",
        nm: "Cinctorres",
      },
      {
        id: "12046",
        nm: "Cirat",
      },
      {
        id: "12048",
        nm: "Cortes de Arenoso",
      },
      {
        id: "12049",
        nm: "Costur",
      },
      {
        id: "12050",
        nm: "Coves de Vinromà, les",
      },
      {
        id: "12051",
        nm: "Culla",
      },
      {
        id: "12057",
        nm: "Eslida",
      },
      {
        id: "12058",
        nm: "Espadilla",
      },
      {
        id: "12059",
        nm: "Fanzara",
      },
      {
        id: "12060",
        nm: "Figueroles",
      },
      {
        id: "12061",
        nm: "Forcall",
      },
      {
        id: "12063",
        nm: "Fuente la Reina",
      },
      {
        id: "12064",
        nm: "Fuentes de Ayódar",
      },
      {
        id: "12065",
        nm: "Gaibiel",
      },
      {
        id: "12067",
        nm: "Geldo",
      },
      {
        id: "12068",
        nm: "Herbés",
      },
      {
        id: "12069",
        nm: "Higueras",
      },
      {
        id: "12070",
        nm: "Jana, la",
      },
      {
        id: "12071",
        nm: "Jérica",
      },
      {
        id: "12074",
        nm: "Llosa, la",
      },
      {
        id: "12072",
        nm: "Lucena del Cid",
      },
      {
        id: "12073",
        nm: "Ludiente",
      },
      {
        id: "12075",
        nm: "Mata de Morella, la",
      },
      {
        id: "12076",
        nm: "Matet",
      },
      {
        id: "12077",
        nm: "Moncofa",
      },
      {
        id: "12078",
        nm: "Montán",
      },
      {
        id: "12079",
        nm: "Montanejos",
      },
      {
        id: "12080",
        nm: "Morella",
      },
      {
        id: "12081",
        nm: "Navajas",
      },
      {
        id: "12082",
        nm: "Nules",
      },
      {
        id: "12083",
        nm: "Olocau del Rey",
      },
      {
        id: "12084",
        nm: "Onda",
      },
      {
        id: "12085",
        nm: "Oropesa del Mar/Orpesa",
      },
      {
        id: "12087",
        nm: "Palanques",
      },
      {
        id: "12088",
        nm: "Pavías",
      },
      {
        id: "12089",
        nm: "Peníscola/Peñíscola",
      },
      {
        id: "12090",
        nm: "Pina de Montalgrao",
      },
      {
        id: "12093",
        nm: "Pobla de Benifassà, la",
      },
      {
        id: "12094",
        nm: "Pobla Tornesa, la",
      },
      {
        id: "12091",
        nm: "Portell de Morella",
      },
      {
        id: "12092",
        nm: "Puebla de Arenoso",
      },
      {
        id: "12095",
        nm: "Ribesalbes",
      },
      {
        id: "12096",
        nm: "Rossell",
      },
      {
        id: "12097",
        nm: "Sacañet",
      },
      {
        id: "12098",
        nm: "Salzadella, la",
      },
      {
        id: "12101",
        nm: "San Rafael del Río",
      },
      {
        id: "12902",
        nm: "Sant Joan de Moró",
      },
      {
        id: "12099",
        nm: "Sant Jordi/San Jorge",
      },
      {
        id: "12100",
        nm: "Sant Mateu",
      },
      {
        id: "12102",
        nm: "Santa Magdalena de Pulpis",
      },
      {
        id: "12104",
        nm: "Segorbe",
      },
      {
        id: "12103",
        nm: "Serratella, la",
      },
      {
        id: "12105",
        nm: "Sierra Engarcerán",
      },
      {
        id: "12106",
        nm: "Soneja",
      },
      {
        id: "12107",
        nm: "Sot de Ferrer",
      },
      {
        id: "12108",
        nm: "Sueras/Suera",
      },
      {
        id: "12109",
        nm: "Tales",
      },
      {
        id: "12110",
        nm: "Teresa",
      },
      {
        id: "12111",
        nm: "Tírig",
      },
      {
        id: "12112",
        nm: "Todolella",
      },
      {
        id: "12113",
        nm: "Toga",
      },
      {
        id: "12114",
        nm: "Torás",
      },
      {
        id: "12115",
        nm: "Toro, El",
      },
      {
        id: "12116",
        nm: "Torralba del Pinar",
      },
      {
        id: "12119",
        nm: "Torre d'En Besora, la",
      },
      {
        id: "12120",
        nm: "Torre d'en Doménec, la",
      },
      {
        id: "12117",
        nm: "Torreblanca",
      },
      {
        id: "12118",
        nm: "Torrechiva",
      },
      {
        id: "12121",
        nm: "Traiguera",
      },
      {
        id: "12122",
        nm: "Useras/Useres, les",
      },
      {
        id: "12124",
        nm: "Vall d'Alba",
      },
      {
        id: "12125",
        nm: "Vall de Almonacid",
      },
      {
        id: "12126",
        nm: "Vall d'Uixó, la",
      },
      {
        id: "12123",
        nm: "Vallat",
      },
      {
        id: "12127",
        nm: "Vallibona",
      },
      {
        id: "12128",
        nm: "Vilafamés",
      },
      {
        id: "12132",
        nm: "Vilanova d'Alcolea",
      },
      {
        id: "12134",
        nm: "Vilar de Canes",
      },
      {
        id: "12135",
        nm: "Vila-real",
      },
      {
        id: "12136",
        nm: "Vilavella, la",
      },
      {
        id: "12129",
        nm: "Villafranca del Cid/Vilafranca",
      },
      {
        id: "12130",
        nm: "Villahermosa del Río",
      },
      {
        id: "12131",
        nm: "Villamalur",
      },
      {
        id: "12133",
        nm: "Villanueva de Viver",
      },
      {
        id: "12137",
        nm: "Villores",
      },
      {
        id: "12138",
        nm: "Vinaròs",
      },
      {
        id: "12139",
        nm: "Vistabella del Maestrat",
      },
      {
        id: "12140",
        nm: "Viver",
      },
      {
        id: "12052",
        nm: "Xert",
      },
      {
        id: "12141",
        nm: "Zorita del Maestrazgo",
      },
      {
        id: "12142",
        nm: "Zucaina",
      },
      {
        id: "13001",
        nm: "Abenójar",
      },
      {
        id: "13002",
        nm: "Agudo",
      },
      {
        id: "13003",
        nm: "Alamillo",
      },
      {
        id: "13004",
        nm: "Albaladejo",
      },
      {
        id: "13005",
        nm: "Alcázar de San Juan",
      },
      {
        id: "13006",
        nm: "Alcoba",
      },
      {
        id: "13007",
        nm: "Alcolea de Calatrava",
      },
      {
        id: "13008",
        nm: "Alcubillas",
      },
      {
        id: "13009",
        nm: "Aldea del Rey",
      },
      {
        id: "13010",
        nm: "Alhambra",
      },
      {
        id: "13011",
        nm: "Almadén",
      },
      {
        id: "13012",
        nm: "Almadenejos",
      },
      {
        id: "13013",
        nm: "Almagro",
      },
      {
        id: "13014",
        nm: "Almedina",
      },
      {
        id: "13015",
        nm: "Almodóvar del Campo",
      },
      {
        id: "13016",
        nm: "Almuradiel",
      },
      {
        id: "13017",
        nm: "Anchuras",
      },
      {
        id: "13903",
        nm: "Arenales de San Gregorio",
      },
      {
        id: "13018",
        nm: "Arenas de San Juan",
      },
      {
        id: "13019",
        nm: "Argamasilla de Alba",
      },
      {
        id: "13020",
        nm: "Argamasilla de Calatrava",
      },
      {
        id: "13021",
        nm: "Arroba de los Montes",
      },
      {
        id: "13022",
        nm: "Ballesteros de Calatrava",
      },
      {
        id: "13023",
        nm: "Bolaños de Calatrava",
      },
      {
        id: "13024",
        nm: "Brazatortas",
      },
      {
        id: "13025",
        nm: "Cabezarados",
      },
      {
        id: "13026",
        nm: "Cabezarrubias del Puerto",
      },
      {
        id: "13027",
        nm: "Calzada de Calatrava",
      },
      {
        id: "13028",
        nm: "Campo de Criptana",
      },
      {
        id: "13029",
        nm: "Cañada de Calatrava",
      },
      {
        id: "13030",
        nm: "Caracuel de Calatrava",
      },
      {
        id: "13031",
        nm: "Carrión de Calatrava",
      },
      {
        id: "13032",
        nm: "Carrizosa",
      },
      {
        id: "13033",
        nm: "Castellar de Santiago",
      },
      {
        id: "13038",
        nm: "Chillón",
      },
      {
        id: "13034",
        nm: "Ciudad Real",
      },
      {
        id: "13035",
        nm: "Corral de Calatrava",
      },
      {
        id: "13036",
        nm: "Cortijos, Los",
      },
      {
        id: "13037",
        nm: "Cózar",
      },
      {
        id: "13039",
        nm: "Daimiel",
      },
      {
        id: "13040",
        nm: "Fernán Caballero",
      },
      {
        id: "13041",
        nm: "Fontanarejo",
      },
      {
        id: "13042",
        nm: "Fuencaliente",
      },
      {
        id: "13043",
        nm: "Fuenllana",
      },
      {
        id: "13044",
        nm: "Fuente el Fresno",
      },
      {
        id: "13045",
        nm: "Granátula de Calatrava",
      },
      {
        id: "13046",
        nm: "Guadalmez",
      },
      {
        id: "13047",
        nm: "Herencia",
      },
      {
        id: "13048",
        nm: "Hinojosas de Calatrava",
      },
      {
        id: "13049",
        nm: "Horcajo de los Montes",
      },
      {
        id: "13050",
        nm: "Labores, Las",
      },
      {
        id: "13904",
        nm: "Llanos del Caudillo",
      },
      {
        id: "13051",
        nm: "Luciana",
      },
      {
        id: "13052",
        nm: "Malagón",
      },
      {
        id: "13053",
        nm: "Manzanares",
      },
      {
        id: "13054",
        nm: "Membrilla",
      },
      {
        id: "13055",
        nm: "Mestanza",
      },
      {
        id: "13056",
        nm: "Miguelturra",
      },
      {
        id: "13057",
        nm: "Montiel",
      },
      {
        id: "13058",
        nm: "Moral de Calatrava",
      },
      {
        id: "13059",
        nm: "Navalpino",
      },
      {
        id: "13060",
        nm: "Navas de Estena",
      },
      {
        id: "13061",
        nm: "Pedro Muñoz",
      },
      {
        id: "13062",
        nm: "Picón",
      },
      {
        id: "13063",
        nm: "Piedrabuena",
      },
      {
        id: "13064",
        nm: "Poblete",
      },
      {
        id: "13065",
        nm: "Porzuna",
      },
      {
        id: "13066",
        nm: "Pozuelo de Calatrava",
      },
      {
        id: "13067",
        nm: "Pozuelos de Calatrava, Los",
      },
      {
        id: "13068",
        nm: "Puebla de Don Rodrigo",
      },
      {
        id: "13069",
        nm: "Puebla del Príncipe",
      },
      {
        id: "13070",
        nm: "Puerto Lápice",
      },
      {
        id: "13071",
        nm: "Puertollano",
      },
      {
        id: "13072",
        nm: "Retuerta del Bullaque",
      },
      {
        id: "13901",
        nm: "Robledo, El",
      },
      {
        id: "13902",
        nm: "Ruidera",
      },
      {
        id: "13073",
        nm: "Saceruela",
      },
      {
        id: "13074",
        nm: "San Carlos del Valle",
      },
      {
        id: "13075",
        nm: "San Lorenzo de Calatrava",
      },
      {
        id: "13076",
        nm: "Santa Cruz de los Cáñamos",
      },
      {
        id: "13077",
        nm: "Santa Cruz de Mudela",
      },
      {
        id: "13078",
        nm: "Socuéllamos",
      },
      {
        id: "13080",
        nm: "Solana del Pino",
      },
      {
        id: "13079",
        nm: "Solana, La",
      },
      {
        id: "13081",
        nm: "Terrinches",
      },
      {
        id: "13082",
        nm: "Tomelloso",
      },
      {
        id: "13083",
        nm: "Torralba de Calatrava",
      },
      {
        id: "13084",
        nm: "Torre de Juan Abad",
      },
      {
        id: "13085",
        nm: "Torrenueva",
      },
      {
        id: "13086",
        nm: "Valdemanco del Esteras",
      },
      {
        id: "13087",
        nm: "Valdepeñas",
      },
      {
        id: "13088",
        nm: "Valenzuela de Calatrava",
      },
      {
        id: "13089",
        nm: "Villahermosa",
      },
      {
        id: "13090",
        nm: "Villamanrique",
      },
      {
        id: "13091",
        nm: "Villamayor de Calatrava",
      },
      {
        id: "13092",
        nm: "Villanueva de la Fuente",
      },
      {
        id: "13093",
        nm: "Villanueva de los Infantes",
      },
      {
        id: "13094",
        nm: "Villanueva de San Carlos",
      },
      {
        id: "13095",
        nm: "Villar del Pozo",
      },
      {
        id: "13096",
        nm: "Villarrubia de los Ojos",
      },
      {
        id: "13097",
        nm: "Villarta de San Juan",
      },
      {
        id: "13098",
        nm: "Viso del Marqués",
      },
      {
        id: "14001",
        nm: "Adamuz",
      },
      {
        id: "14002",
        nm: "Aguilar de la Frontera",
      },
      {
        id: "14003",
        nm: "Alcaracejos",
      },
      {
        id: "14004",
        nm: "Almedinilla",
      },
      {
        id: "14005",
        nm: "Almodóvar del Río",
      },
      {
        id: "14006",
        nm: "Añora",
      },
      {
        id: "14007",
        nm: "Baena",
      },
      {
        id: "14008",
        nm: "Belalcázar",
      },
      {
        id: "14009",
        nm: "Belmez",
      },
      {
        id: "14010",
        nm: "Benamejí",
      },
      {
        id: "14011",
        nm: "Blázquez, Los",
      },
      {
        id: "14012",
        nm: "Bujalance",
      },
      {
        id: "14013",
        nm: "Cabra",
      },
      {
        id: "14014",
        nm: "Cañete de las Torres",
      },
      {
        id: "14015",
        nm: "Carcabuey",
      },
      {
        id: "14016",
        nm: "Cardeña",
      },
      {
        id: "14017",
        nm: "Carlota, La",
      },
      {
        id: "14018",
        nm: "Carpio, El",
      },
      {
        id: "14019",
        nm: "Castro del Río",
      },
      {
        id: "14020",
        nm: "Conquista",
      },
      {
        id: "14021",
        nm: "Córdoba",
      },
      {
        id: "14022",
        nm: "Doña Mencía",
      },
      {
        id: "14023",
        nm: "Dos Torres",
      },
      {
        id: "14024",
        nm: "Encinas Reales",
      },
      {
        id: "14025",
        nm: "Espejo",
      },
      {
        id: "14026",
        nm: "Espiel",
      },
      {
        id: "14027",
        nm: "Fernán-Núñez",
      },
      {
        id: "14028",
        nm: "Fuente la Lancha",
      },
      {
        id: "14029",
        nm: "Fuente Obejuna",
      },
      {
        id: "14030",
        nm: "Fuente Palmera",
      },
      {
        id: "14031",
        nm: "Fuente-Tójar",
      },
      {
        id: "14032",
        nm: "Granjuela, La",
      },
      {
        id: "14033",
        nm: "Guadalcázar",
      },
      {
        id: "14034",
        nm: "Guijo, El",
      },
      {
        id: "14035",
        nm: "Hinojosa del Duque",
      },
      {
        id: "14036",
        nm: "Hornachuelos",
      },
      {
        id: "14037",
        nm: "Iznájar",
      },
      {
        id: "14038",
        nm: "Lucena",
      },
      {
        id: "14039",
        nm: "Luque",
      },
      {
        id: "14040",
        nm: "Montalbán de Córdoba",
      },
      {
        id: "14041",
        nm: "Montemayor",
      },
      {
        id: "14042",
        nm: "Montilla",
      },
      {
        id: "14043",
        nm: "Montoro",
      },
      {
        id: "14044",
        nm: "Monturque",
      },
      {
        id: "14045",
        nm: "Moriles",
      },
      {
        id: "14046",
        nm: "Nueva Carteya",
      },
      {
        id: "14047",
        nm: "Obejo",
      },
      {
        id: "14048",
        nm: "Palenciana",
      },
      {
        id: "14049",
        nm: "Palma del Río",
      },
      {
        id: "14050",
        nm: "Pedro Abad",
      },
      {
        id: "14051",
        nm: "Pedroche",
      },
      {
        id: "14052",
        nm: "Peñarroya-Pueblonuevo",
      },
      {
        id: "14053",
        nm: "Posadas",
      },
      {
        id: "14054",
        nm: "Pozoblanco",
      },
      {
        id: "14055",
        nm: "Priego de Córdoba",
      },
      {
        id: "14056",
        nm: "Puente Genil",
      },
      {
        id: "14057",
        nm: "Rambla, La",
      },
      {
        id: "14058",
        nm: "Rute",
      },
      {
        id: "14059",
        nm: "San Sebastián de los Ballesteros",
      },
      {
        id: "14061",
        nm: "Santa Eufemia",
      },
      {
        id: "14060",
        nm: "Santaella",
      },
      {
        id: "14062",
        nm: "Torrecampo",
      },
      {
        id: "14063",
        nm: "Valenzuela",
      },
      {
        id: "14064",
        nm: "Valsequillo",
      },
      {
        id: "14065",
        nm: "Victoria, La",
      },
      {
        id: "14066",
        nm: "Villa del Río",
      },
      {
        id: "14067",
        nm: "Villafranca de Córdoba",
      },
      {
        id: "14068",
        nm: "Villaharta",
      },
      {
        id: "14069",
        nm: "Villanueva de Córdoba",
      },
      {
        id: "14070",
        nm: "Villanueva del Duque",
      },
      {
        id: "14071",
        nm: "Villanueva del Rey",
      },
      {
        id: "14072",
        nm: "Villaralto",
      },
      {
        id: "14073",
        nm: "Villaviciosa de Córdoba",
      },
      {
        id: "14074",
        nm: "Viso, El",
      },
      {
        id: "14075",
        nm: "Zuheros",
      },
      {
        id: "15001",
        nm: "Abegondo",
      },
      {
        id: "15002",
        nm: "Ames",
      },
      {
        id: "15003",
        nm: "Aranga",
      },
      {
        id: "15004",
        nm: "Ares",
      },
      {
        id: "15005",
        nm: "Arteixo",
      },
      {
        id: "15006",
        nm: "Arzúa",
      },
      {
        id: "15007",
        nm: "Baña, A",
      },
      {
        id: "15008",
        nm: "Bergondo",
      },
      {
        id: "15009",
        nm: "Betanzos",
      },
      {
        id: "15010",
        nm: "Boimorto",
      },
      {
        id: "15011",
        nm: "Boiro",
      },
      {
        id: "15012",
        nm: "Boqueixón",
      },
      {
        id: "15013",
        nm: "Brión",
      },
      {
        id: "15014",
        nm: "Cabana de Bergantiños",
      },
      {
        id: "15015",
        nm: "Cabanas",
      },
      {
        id: "15016",
        nm: "Camariñas",
      },
      {
        id: "15017",
        nm: "Cambre",
      },
      {
        id: "15018",
        nm: "Capela, A",
      },
      {
        id: "15019",
        nm: "Carballo",
      },
      {
        id: "15901",
        nm: "Cariño",
      },
      {
        id: "15020",
        nm: "Carnota",
      },
      {
        id: "15021",
        nm: "Carral",
      },
      {
        id: "15022",
        nm: "Cedeira",
      },
      {
        id: "15023",
        nm: "Cee",
      },
      {
        id: "15024",
        nm: "Cerceda",
      },
      {
        id: "15025",
        nm: "Cerdido",
      },
      {
        id: "15027",
        nm: "Coirós",
      },
      {
        id: "15028",
        nm: "Corcubión",
      },
      {
        id: "15029",
        nm: "Coristanco",
      },
      {
        id: "15030",
        nm: "Coruña, A",
      },
      {
        id: "15031",
        nm: "Culleredo",
      },
      {
        id: "15032",
        nm: "Curtis",
      },
      {
        id: "15033",
        nm: "Dodro",
      },
      {
        id: "15034",
        nm: "Dumbría",
      },
      {
        id: "15035",
        nm: "Fene",
      },
      {
        id: "15036",
        nm: "Ferrol",
      },
      {
        id: "15037",
        nm: "Fisterra",
      },
      {
        id: "15038",
        nm: "Frades",
      },
      {
        id: "15039",
        nm: "Irixoa",
      },
      {
        id: "15041",
        nm: "Laracha, A",
      },
      {
        id: "15040",
        nm: "Laxe",
      },
      {
        id: "15042",
        nm: "Lousame",
      },
      {
        id: "15043",
        nm: "Malpica de Bergantiños",
      },
      {
        id: "15044",
        nm: "Mañón",
      },
      {
        id: "15045",
        nm: "Mazaricos",
      },
      {
        id: "15046",
        nm: "Melide",
      },
      {
        id: "15047",
        nm: "Mesía",
      },
      {
        id: "15048",
        nm: "Miño",
      },
      {
        id: "15049",
        nm: "Moeche",
      },
      {
        id: "15050",
        nm: "Monfero",
      },
      {
        id: "15051",
        nm: "Mugardos",
      },
      {
        id: "15053",
        nm: "Muros",
      },
      {
        id: "15052",
        nm: "Muxía",
      },
      {
        id: "15054",
        nm: "Narón",
      },
      {
        id: "15055",
        nm: "Neda",
      },
      {
        id: "15056",
        nm: "Negreira",
      },
      {
        id: "15057",
        nm: "Noia",
      },
      {
        id: "15058",
        nm: "Oleiros",
      },
      {
        id: "15059",
        nm: "Ordes",
      },
      {
        id: "15060",
        nm: "Oroso",
      },
      {
        id: "15061",
        nm: "Ortigueira",
      },
      {
        id: "15062",
        nm: "Outes",
      },
      {
        id: "15902",
        nm: "Oza-Cesuras",
      },
      {
        id: "15064",
        nm: "Paderne",
      },
      {
        id: "15065",
        nm: "Padrón",
      },
      {
        id: "15066",
        nm: "Pino, O",
      },
      {
        id: "15067",
        nm: "Pobra do Caramiñal, A",
      },
      {
        id: "15068",
        nm: "Ponteceso",
      },
      {
        id: "15069",
        nm: "Pontedeume",
      },
      {
        id: "15070",
        nm: "Pontes de García Rodríguez, As",
      },
      {
        id: "15071",
        nm: "Porto do Son",
      },
      {
        id: "15072",
        nm: "Rianxo",
      },
      {
        id: "15073",
        nm: "Ribeira",
      },
      {
        id: "15074",
        nm: "Rois",
      },
      {
        id: "15075",
        nm: "Sada",
      },
      {
        id: "15076",
        nm: "San Sadurniño",
      },
      {
        id: "15077",
        nm: "Santa Comba",
      },
      {
        id: "15078",
        nm: "Santiago de Compostela",
      },
      {
        id: "15079",
        nm: "Santiso",
      },
      {
        id: "15080",
        nm: "Sobrado",
      },
      {
        id: "15081",
        nm: "Somozas, As",
      },
      {
        id: "15082",
        nm: "Teo",
      },
      {
        id: "15083",
        nm: "Toques",
      },
      {
        id: "15084",
        nm: "Tordoia",
      },
      {
        id: "15085",
        nm: "Touro",
      },
      {
        id: "15086",
        nm: "Trazo",
      },
      {
        id: "15088",
        nm: "Val do Dubra",
      },
      {
        id: "15087",
        nm: "Valdoviño",
      },
      {
        id: "15089",
        nm: "Vedra",
      },
      {
        id: "15091",
        nm: "Vilarmaior",
      },
      {
        id: "15090",
        nm: "Vilasantar",
      },
      {
        id: "15092",
        nm: "Vimianzo",
      },
      {
        id: "15093",
        nm: "Zas",
      },
      {
        id: "16001",
        nm: "Abia de la Obispalía",
      },
      {
        id: "16002",
        nm: "Acebrón, El",
      },
      {
        id: "16003",
        nm: "Alarcón",
      },
      {
        id: "16004",
        nm: "Albaladejo del Cuende",
      },
      {
        id: "16005",
        nm: "Albalate de las Nogueras",
      },
      {
        id: "16006",
        nm: "Albendea",
      },
      {
        id: "16007",
        nm: "Alberca de Záncara, La",
      },
      {
        id: "16008",
        nm: "Alcalá de la Vega",
      },
      {
        id: "16009",
        nm: "Alcantud",
      },
      {
        id: "16010",
        nm: "Alcázar del Rey",
      },
      {
        id: "16011",
        nm: "Alcohujate",
      },
      {
        id: "16012",
        nm: "Alconchel de la Estrella",
      },
      {
        id: "16013",
        nm: "Algarra",
      },
      {
        id: "16014",
        nm: "Aliaguilla",
      },
      {
        id: "16015",
        nm: "Almarcha, La",
      },
      {
        id: "16016",
        nm: "Almendros",
      },
      {
        id: "16017",
        nm: "Almodóvar del Pinar",
      },
      {
        id: "16018",
        nm: "Almonacid del Marquesado",
      },
      {
        id: "16019",
        nm: "Altarejos",
      },
      {
        id: "16020",
        nm: "Arandilla del Arroyo",
      },
      {
        id: "16905",
        nm: "Arcas",
      },
      {
        id: "16022",
        nm: "Arcos de la Sierra",
      },
      {
        id: "16024",
        nm: "Arguisuelas",
      },
      {
        id: "16025",
        nm: "Arrancacepas",
      },
      {
        id: "16026",
        nm: "Atalaya del Cañavate",
      },
      {
        id: "16027",
        nm: "Barajas de Melo",
      },
      {
        id: "16029",
        nm: "Barchín del Hoyo",
      },
      {
        id: "16030",
        nm: "Bascuñana de San Pedro",
      },
      {
        id: "16031",
        nm: "Beamud",
      },
      {
        id: "16032",
        nm: "Belinchón",
      },
      {
        id: "16033",
        nm: "Belmonte",
      },
      {
        id: "16034",
        nm: "Belmontejo",
      },
      {
        id: "16035",
        nm: "Beteta",
      },
      {
        id: "16036",
        nm: "Boniches",
      },
      {
        id: "16038",
        nm: "Buciegas",
      },
      {
        id: "16039",
        nm: "Buenache de Alarcón",
      },
      {
        id: "16040",
        nm: "Buenache de la Sierra",
      },
      {
        id: "16041",
        nm: "Buendía",
      },
      {
        id: "16042",
        nm: "Campillo de Altobuey",
      },
      {
        id: "16043",
        nm: "Campillos-Paravientos",
      },
      {
        id: "16044",
        nm: "Campillos-Sierra",
      },
      {
        id: "16901",
        nm: "Campos del Paraíso",
      },
      {
        id: "16045",
        nm: "Canalejas del Arroyo",
      },
      {
        id: "16046",
        nm: "Cañada del Hoyo",
      },
      {
        id: "16047",
        nm: "Cañada Juncosa",
      },
      {
        id: "16048",
        nm: "Cañamares",
      },
      {
        id: "16049",
        nm: "Cañavate, El",
      },
      {
        id: "16050",
        nm: "Cañaveras",
      },
      {
        id: "16051",
        nm: "Cañaveruelas",
      },
      {
        id: "16052",
        nm: "Cañete",
      },
      {
        id: "16053",
        nm: "Cañizares",
      },
      {
        id: "16055",
        nm: "Carboneras de Guadazaón",
      },
      {
        id: "16056",
        nm: "Cardenete",
      },
      {
        id: "16057",
        nm: "Carrascosa",
      },
      {
        id: "16058",
        nm: "Carrascosa de Haro",
      },
      {
        id: "16060",
        nm: "Casas de Benítez",
      },
      {
        id: "16061",
        nm: "Casas de Fernando Alonso",
      },
      {
        id: "16062",
        nm: "Casas de Garcimolina",
      },
      {
        id: "16063",
        nm: "Casas de Guijarro",
      },
      {
        id: "16064",
        nm: "Casas de Haro",
      },
      {
        id: "16065",
        nm: "Casas de los Pinos",
      },
      {
        id: "16066",
        nm: "Casasimarro",
      },
      {
        id: "16067",
        nm: "Castejón",
      },
      {
        id: "16068",
        nm: "Castillejo de Iniesta",
      },
      {
        id: "16070",
        nm: "Castillejo-Sierra",
      },
      {
        id: "16072",
        nm: "Castillo de Garcimuñoz",
      },
      {
        id: "16071",
        nm: "Castillo-Albaráñez",
      },
      {
        id: "16073",
        nm: "Cervera del Llano",
      },
      {
        id: "16023",
        nm: "Chillarón de Cuenca",
      },
      {
        id: "16081",
        nm: "Chumillas",
      },
      {
        id: "16074",
        nm: "Cierva, La",
      },
      {
        id: "16078",
        nm: "Cuenca",
      },
      {
        id: "16079",
        nm: "Cueva del Hierro",
      },
      {
        id: "16082",
        nm: "Enguídanos",
      },
      {
        id: "16083",
        nm: "Fresneda de Altarejos",
      },
      {
        id: "16084",
        nm: "Fresneda de la Sierra",
      },
      {
        id: "16085",
        nm: "Frontera, La",
      },
      {
        id: "16086",
        nm: "Fuente de Pedro Naharro",
      },
      {
        id: "16087",
        nm: "Fuentelespino de Haro",
      },
      {
        id: "16088",
        nm: "Fuentelespino de Moya",
      },
      {
        id: "16904",
        nm: "Fuentenava de Jábaga",
      },
      {
        id: "16089",
        nm: "Fuentes",
      },
      {
        id: "16091",
        nm: "Fuertescusa",
      },
      {
        id: "16092",
        nm: "Gabaldón",
      },
      {
        id: "16093",
        nm: "Garaballa",
      },
      {
        id: "16094",
        nm: "Gascueña",
      },
      {
        id: "16095",
        nm: "Graja de Campalbo",
      },
      {
        id: "16096",
        nm: "Graja de Iniesta",
      },
      {
        id: "16097",
        nm: "Henarejos",
      },
      {
        id: "16098",
        nm: "Herrumblar, El",
      },
      {
        id: "16099",
        nm: "Hinojosa, La",
      },
      {
        id: "16100",
        nm: "Hinojosos, Los",
      },
      {
        id: "16101",
        nm: "Hito, El",
      },
      {
        id: "16102",
        nm: "Honrubia",
      },
      {
        id: "16103",
        nm: "Hontanaya",
      },
      {
        id: "16104",
        nm: "Hontecillas",
      },
      {
        id: "16106",
        nm: "Horcajo de Santiago",
      },
      {
        id: "16107",
        nm: "Huélamo",
      },
      {
        id: "16108",
        nm: "Huelves",
      },
      {
        id: "16109",
        nm: "Huérguina",
      },
      {
        id: "16110",
        nm: "Huerta de la Obispalía",
      },
      {
        id: "16111",
        nm: "Huerta del Marquesado",
      },
      {
        id: "16112",
        nm: "Huete",
      },
      {
        id: "16113",
        nm: "Iniesta",
      },
      {
        id: "16115",
        nm: "Laguna del Marquesado",
      },
      {
        id: "16116",
        nm: "Lagunaseca",
      },
      {
        id: "16117",
        nm: "Landete",
      },
      {
        id: "16118",
        nm: "Ledaña",
      },
      {
        id: "16119",
        nm: "Leganiel",
      },
      {
        id: "16121",
        nm: "Majadas, Las",
      },
      {
        id: "16122",
        nm: "Mariana",
      },
      {
        id: "16123",
        nm: "Masegosa",
      },
      {
        id: "16124",
        nm: "Mesas, Las",
      },
      {
        id: "16125",
        nm: "Minglanilla",
      },
      {
        id: "16126",
        nm: "Mira",
      },
      {
        id: "16128",
        nm: "Monreal del Llano",
      },
      {
        id: "16129",
        nm: "Montalbanejo",
      },
      {
        id: "16130",
        nm: "Montalbo",
      },
      {
        id: "16131",
        nm: "Monteagudo de las Salinas",
      },
      {
        id: "16132",
        nm: "Mota de Altarejos",
      },
      {
        id: "16133",
        nm: "Mota del Cuervo",
      },
      {
        id: "16134",
        nm: "Motilla del Palancar",
      },
      {
        id: "16135",
        nm: "Moya",
      },
      {
        id: "16137",
        nm: "Narboneta",
      },
      {
        id: "16139",
        nm: "Olivares de Júcar",
      },
      {
        id: "16140",
        nm: "Olmeda de la Cuesta",
      },
      {
        id: "16141",
        nm: "Olmeda del Rey",
      },
      {
        id: "16142",
        nm: "Olmedilla de Alarcón",
      },
      {
        id: "16143",
        nm: "Olmedilla de Eliz",
      },
      {
        id: "16145",
        nm: "Osa de la Vega",
      },
      {
        id: "16146",
        nm: "Pajarón",
      },
      {
        id: "16147",
        nm: "Pajaroncillo",
      },
      {
        id: "16148",
        nm: "Palomares del Campo",
      },
      {
        id: "16149",
        nm: "Palomera",
      },
      {
        id: "16150",
        nm: "Paracuellos",
      },
      {
        id: "16151",
        nm: "Paredes",
      },
      {
        id: "16152",
        nm: "Parra de las Vegas, La",
      },
      {
        id: "16153",
        nm: "Pedernoso, El",
      },
      {
        id: "16154",
        nm: "Pedroñeras, Las",
      },
      {
        id: "16155",
        nm: "Peral, El",
      },
      {
        id: "16156",
        nm: "Peraleja, La",
      },
      {
        id: "16157",
        nm: "Pesquera, La",
      },
      {
        id: "16158",
        nm: "Picazo, El",
      },
      {
        id: "16159",
        nm: "Pinarejo",
      },
      {
        id: "16160",
        nm: "Pineda de Gigüela",
      },
      {
        id: "16161",
        nm: "Piqueras del Castillo",
      },
      {
        id: "16162",
        nm: "Portalrubio de Guadamejud",
      },
      {
        id: "16163",
        nm: "Portilla",
      },
      {
        id: "16165",
        nm: "Poyatos",
      },
      {
        id: "16166",
        nm: "Pozoamargo",
      },
      {
        id: "16908",
        nm: "Pozorrubielos de la Mancha",
      },
      {
        id: "16167",
        nm: "Pozorrubio de Santiago",
      },
      {
        id: "16169",
        nm: "Pozuelo, El",
      },
      {
        id: "16170",
        nm: "Priego",
      },
      {
        id: "16171",
        nm: "Provencio, El",
      },
      {
        id: "16172",
        nm: "Puebla de Almenara",
      },
      {
        id: "16174",
        nm: "Puebla del Salvador",
      },
      {
        id: "16175",
        nm: "Quintanar del Rey",
      },
      {
        id: "16176",
        nm: "Rada de Haro",
      },
      {
        id: "16177",
        nm: "Reíllo",
      },
      {
        id: "16181",
        nm: "Rozalén del Monte",
      },
      {
        id: "16185",
        nm: "Saceda-Trasierra",
      },
      {
        id: "16186",
        nm: "Saelices",
      },
      {
        id: "16187",
        nm: "Salinas del Manzano",
      },
      {
        id: "16188",
        nm: "Salmeroncillos",
      },
      {
        id: "16189",
        nm: "Salvacañete",
      },
      {
        id: "16190",
        nm: "San Clemente",
      },
      {
        id: "16191",
        nm: "San Lorenzo de la Parrilla",
      },
      {
        id: "16192",
        nm: "San Martín de Boniches",
      },
      {
        id: "16193",
        nm: "San Pedro Palmiches",
      },
      {
        id: "16194",
        nm: "Santa Cruz de Moya",
      },
      {
        id: "16196",
        nm: "Santa María de los Llanos",
      },
      {
        id: "16195",
        nm: "Santa María del Campo Rus",
      },
      {
        id: "16197",
        nm: "Santa María del Val",
      },
      {
        id: "16198",
        nm: "Sisante",
      },
      {
        id: "16199",
        nm: "Solera de Gabaldón",
      },
      {
        id: "16909",
        nm: "Sotorribas",
      },
      {
        id: "16202",
        nm: "Talayuelas",
      },
      {
        id: "16203",
        nm: "Tarancón",
      },
      {
        id: "16204",
        nm: "Tébar",
      },
      {
        id: "16205",
        nm: "Tejadillos",
      },
      {
        id: "16206",
        nm: "Tinajas",
      },
      {
        id: "16209",
        nm: "Torralba",
      },
      {
        id: "16211",
        nm: "Torrejoncillo del Rey",
      },
      {
        id: "16212",
        nm: "Torrubia del Campo",
      },
      {
        id: "16213",
        nm: "Torrubia del Castillo",
      },
      {
        id: "16215",
        nm: "Tragacete",
      },
      {
        id: "16216",
        nm: "Tresjuncos",
      },
      {
        id: "16217",
        nm: "Tribaldos",
      },
      {
        id: "16218",
        nm: "Uclés",
      },
      {
        id: "16219",
        nm: "Uña",
      },
      {
        id: "16906",
        nm: "Valdecolmenas, Los",
      },
      {
        id: "16224",
        nm: "Valdemeca",
      },
      {
        id: "16225",
        nm: "Valdemorillo de la Sierra",
      },
      {
        id: "16227",
        nm: "Valdemoro-Sierra",
      },
      {
        id: "16228",
        nm: "Valdeolivas",
      },
      {
        id: "16902",
        nm: "Valdetórtola",
      },
      {
        id: "16903",
        nm: "Valeras, Las",
      },
      {
        id: "16231",
        nm: "Valhermoso de la Fuente",
      },
      {
        id: "16173",
        nm: "Valle de Altomira, El",
      },
      {
        id: "16234",
        nm: "Valsalobre",
      },
      {
        id: "16236",
        nm: "Valverde de Júcar",
      },
      {
        id: "16237",
        nm: "Valverdejo",
      },
      {
        id: "16238",
        nm: "Vara de Rey",
      },
      {
        id: "16239",
        nm: "Vega del Codorno",
      },
      {
        id: "16240",
        nm: "Vellisca",
      },
      {
        id: "16242",
        nm: "Villaconejos de Trabaque",
      },
      {
        id: "16243",
        nm: "Villaescusa de Haro",
      },
      {
        id: "16244",
        nm: "Villagarcía del Llano",
      },
      {
        id: "16245",
        nm: "Villalba de la Sierra",
      },
      {
        id: "16246",
        nm: "Villalba del Rey",
      },
      {
        id: "16247",
        nm: "Villalgordo del Marquesado",
      },
      {
        id: "16248",
        nm: "Villalpardo",
      },
      {
        id: "16249",
        nm: "Villamayor de Santiago",
      },
      {
        id: "16250",
        nm: "Villanueva de Guadamejud",
      },
      {
        id: "16251",
        nm: "Villanueva de la Jara",
      },
      {
        id: "16253",
        nm: "Villar de Cañas",
      },
      {
        id: "16254",
        nm: "Villar de Domingo García",
      },
      {
        id: "16255",
        nm: "Villar de la Encina",
      },
      {
        id: "16263",
        nm: "Villar de Olalla",
      },
      {
        id: "16258",
        nm: "Villar del Humo",
      },
      {
        id: "16259",
        nm: "Villar del Infantado",
      },
      {
        id: "16910",
        nm: "Villar y Velasco",
      },
      {
        id: "16264",
        nm: "Villarejo de Fuentes",
      },
      {
        id: "16265",
        nm: "Villarejo de la Peñuela",
      },
      {
        id: "16266",
        nm: "Villarejo-Periesteban",
      },
      {
        id: "16269",
        nm: "Villares del Saz",
      },
      {
        id: "16270",
        nm: "Villarrubio",
      },
      {
        id: "16271",
        nm: "Villarta",
      },
      {
        id: "16272",
        nm: "Villas de la Ventosa",
      },
      {
        id: "16273",
        nm: "Villaverde y Pasaconsol",
      },
      {
        id: "16274",
        nm: "Víllora",
      },
      {
        id: "16275",
        nm: "Vindel",
      },
      {
        id: "16276",
        nm: "Yémeda",
      },
      {
        id: "16277",
        nm: "Zafra de Záncara",
      },
      {
        id: "16278",
        nm: "Zafrilla",
      },
      {
        id: "16279",
        nm: "Zarza de Tajo",
      },
      {
        id: "16280",
        nm: "Zarzuela",
      },
      {
        id: "17001",
        nm: "Agullana",
      },
      {
        id: "17002",
        nm: "Aiguaviva",
      },
      {
        id: "17003",
        nm: "Albanyà",
      },
      {
        id: "17004",
        nm: "Albons",
      },
      {
        id: "17006",
        nm: "Alp",
      },
      {
        id: "17007",
        nm: "Amer",
      },
      {
        id: "17008",
        nm: "Anglès",
      },
      {
        id: "17009",
        nm: "Arbúcies",
      },
      {
        id: "17010",
        nm: "Argelaguer",
      },
      {
        id: "17011",
        nm: "Armentera, L'",
      },
      {
        id: "17012",
        nm: "Avinyonet de Puigventós",
      },
      {
        id: "17015",
        nm: "Banyoles",
      },
      {
        id: "17016",
        nm: "Bàscara",
      },
      {
        id: "17013",
        nm: "Begur",
      },
      {
        id: "17018",
        nm: "Bellcaire d'Empordà",
      },
      {
        id: "17019",
        nm: "Besalú",
      },
      {
        id: "17020",
        nm: "Bescanó",
      },
      {
        id: "17021",
        nm: "Beuda",
      },
      {
        id: "17022",
        nm: "Bisbal d'Empordà, La",
      },
      {
        id: "17234",
        nm: "Biure",
      },
      {
        id: "17023",
        nm: "Blanes",
      },
      {
        id: "17029",
        nm: "Boadella i les Escaules",
      },
      {
        id: "17024",
        nm: "Bolvir",
      },
      {
        id: "17025",
        nm: "Bordils",
      },
      {
        id: "17026",
        nm: "Borrassà",
      },
      {
        id: "17027",
        nm: "Breda",
      },
      {
        id: "17028",
        nm: "Brunyola",
      },
      {
        id: "17031",
        nm: "Cabanelles",
      },
      {
        id: "17030",
        nm: "Cabanes",
      },
      {
        id: "17032",
        nm: "Cadaqués",
      },
      {
        id: "17033",
        nm: "Caldes de Malavella",
      },
      {
        id: "17034",
        nm: "Calonge",
      },
      {
        id: "17035",
        nm: "Camós",
      },
      {
        id: "17036",
        nm: "Campdevànol",
      },
      {
        id: "17037",
        nm: "Campelles",
      },
      {
        id: "17038",
        nm: "Campllong",
      },
      {
        id: "17039",
        nm: "Camprodon",
      },
      {
        id: "17040",
        nm: "Canet d'Adri",
      },
      {
        id: "17041",
        nm: "Cantallops",
      },
      {
        id: "17042",
        nm: "Capmany",
      },
      {
        id: "17044",
        nm: "Cassà de la Selva",
      },
      {
        id: "17046",
        nm: "Castellfollit de la Roca",
      },
      {
        id: "17047",
        nm: "Castelló d'Empúries",
      },
      {
        id: "17048",
        nm: "Castell-Platja d'Aro",
      },
      {
        id: "17189",
        nm: "Cellera de Ter, La",
      },
      {
        id: "17049",
        nm: "Celrà",
      },
      {
        id: "17050",
        nm: "Cervià de Ter",
      },
      {
        id: "17051",
        nm: "Cistella",
      },
      {
        id: "17054",
        nm: "Colera",
      },
      {
        id: "17055",
        nm: "Colomers",
      },
      {
        id: "17057",
        nm: "Corçà",
      },
      {
        id: "17056",
        nm: "Cornellà del Terri",
      },
      {
        id: "17058",
        nm: "Crespià",
      },
      {
        id: "17901",
        nm: "Cruïlles, Monells i Sant Sadurní de l'Heura",
      },
      {
        id: "17060",
        nm: "Darnius",
      },
      {
        id: "17061",
        nm: "Das",
      },
      {
        id: "17062",
        nm: "Escala, L'",
      },
      {
        id: "17063",
        nm: "Espinelves",
      },
      {
        id: "17064",
        nm: "Espolla",
      },
      {
        id: "17065",
        nm: "Esponellà",
      },
      {
        id: "17005",
        nm: "Far d'Empordà, El",
      },
      {
        id: "17066",
        nm: "Figueres",
      },
      {
        id: "17067",
        nm: "Flaçà",
      },
      {
        id: "17068",
        nm: "Foixà",
      },
      {
        id: "17069",
        nm: "Fontanals de Cerdanya",
      },
      {
        id: "17070",
        nm: "Fontanilles",
      },
      {
        id: "17071",
        nm: "Fontcoberta",
      },
      {
        id: "17902",
        nm: "Forallac",
      },
      {
        id: "17073",
        nm: "Fornells de la Selva",
      },
      {
        id: "17074",
        nm: "Fortià",
      },
      {
        id: "17075",
        nm: "Garrigàs",
      },
      {
        id: "17076",
        nm: "Garrigoles",
      },
      {
        id: "17077",
        nm: "Garriguella",
      },
      {
        id: "17078",
        nm: "Ger",
      },
      {
        id: "17079",
        nm: "Girona",
      },
      {
        id: "17080",
        nm: "Gombrèn",
      },
      {
        id: "17081",
        nm: "Gualta",
      },
      {
        id: "17082",
        nm: "Guils de Cerdanya",
      },
      {
        id: "17083",
        nm: "Hostalric",
      },
      {
        id: "17084",
        nm: "Isòvol",
      },
      {
        id: "17085",
        nm: "Jafre",
      },
      {
        id: "17086",
        nm: "Jonquera, La",
      },
      {
        id: "17087",
        nm: "Juià",
      },
      {
        id: "17088",
        nm: "Lladó",
      },
      {
        id: "17089",
        nm: "Llagostera",
      },
      {
        id: "17090",
        nm: "Llambilles",
      },
      {
        id: "17091",
        nm: "Llanars",
      },
      {
        id: "17092",
        nm: "Llançà",
      },
      {
        id: "17093",
        nm: "Llers",
      },
      {
        id: "17094",
        nm: "Llívia",
      },
      {
        id: "17095",
        nm: "Lloret de Mar",
      },
      {
        id: "17096",
        nm: "Llosses, Les",
      },
      {
        id: "17102",
        nm: "Maçanet de Cabrenys",
      },
      {
        id: "17103",
        nm: "Maçanet de la Selva",
      },
      {
        id: "17097",
        nm: "Madremanya",
      },
      {
        id: "17098",
        nm: "Maià de Montcal",
      },
      {
        id: "17100",
        nm: "Masarac",
      },
      {
        id: "17101",
        nm: "Massanes",
      },
      {
        id: "17099",
        nm: "Meranges",
      },
      {
        id: "17105",
        nm: "Mieres",
      },
      {
        id: "17106",
        nm: "Mollet de Peralada",
      },
      {
        id: "17107",
        nm: "Molló",
      },
      {
        id: "17109",
        nm: "Montagut i Oix",
      },
      {
        id: "17110",
        nm: "Mont-ras",
      },
      {
        id: "17111",
        nm: "Navata",
      },
      {
        id: "17112",
        nm: "Ogassa",
      },
      {
        id: "17114",
        nm: "Olot",
      },
      {
        id: "17115",
        nm: "Ordis",
      },
      {
        id: "17116",
        nm: "Osor",
      },
      {
        id: "17117",
        nm: "Palafrugell",
      },
      {
        id: "17118",
        nm: "Palamós",
      },
      {
        id: "17119",
        nm: "Palau de Santa Eulàlia",
      },
      {
        id: "17121",
        nm: "Palau-sator",
      },
      {
        id: "17120",
        nm: "Palau-saverdera",
      },
      {
        id: "17123",
        nm: "Palol de Revardit",
      },
      {
        id: "17124",
        nm: "Pals",
      },
      {
        id: "17125",
        nm: "Pardines",
      },
      {
        id: "17126",
        nm: "Parlavà",
      },
      {
        id: "17128",
        nm: "Pau",
      },
      {
        id: "17129",
        nm: "Pedret i Marzà",
      },
      {
        id: "17130",
        nm: "Pera, La",
      },
      {
        id: "17132",
        nm: "Peralada",
      },
      {
        id: "17133",
        nm: "Planes d'Hostoles, Les",
      },
      {
        id: "17134",
        nm: "Planoles",
      },
      {
        id: "17135",
        nm: "Pont de Molins",
      },
      {
        id: "17136",
        nm: "Pontós",
      },
      {
        id: "17137",
        nm: "Porqueres",
      },
      {
        id: "17140",
        nm: "Port de la Selva, El",
      },
      {
        id: "17138",
        nm: "Portbou",
      },
      {
        id: "17139",
        nm: "Preses, Les",
      },
      {
        id: "17141",
        nm: "Puigcerdà",
      },
      {
        id: "17142",
        nm: "Quart",
      },
      {
        id: "17043",
        nm: "Queralbs",
      },
      {
        id: "17143",
        nm: "Rabós",
      },
      {
        id: "17144",
        nm: "Regencós",
      },
      {
        id: "17145",
        nm: "Ribes de Freser",
      },
      {
        id: "17146",
        nm: "Riells i Viabrea",
      },
      {
        id: "17147",
        nm: "Ripoll",
      },
      {
        id: "17148",
        nm: "Riudarenes",
      },
      {
        id: "17149",
        nm: "Riudaura",
      },
      {
        id: "17150",
        nm: "Riudellots de la Selva",
      },
      {
        id: "17151",
        nm: "Riumors",
      },
      {
        id: "17152",
        nm: "Roses",
      },
      {
        id: "17153",
        nm: "Rupià",
      },
      {
        id: "17154",
        nm: "Sales de Llierca",
      },
      {
        id: "17155",
        nm: "Salt",
      },
      {
        id: "17157",
        nm: "Sant Andreu Salou",
      },
      {
        id: "17183",
        nm: "Sant Aniol de Finestres",
      },
      {
        id: "17158",
        nm: "Sant Climent Sescebes",
      },
      {
        id: "17159",
        nm: "Sant Feliu de Buixalleu",
      },
      {
        id: "17160",
        nm: "Sant Feliu de Guíxols",
      },
      {
        id: "17161",
        nm: "Sant Feliu de Pallerols",
      },
      {
        id: "17162",
        nm: "Sant Ferriol",
      },
      {
        id: "17163",
        nm: "Sant Gregori",
      },
      {
        id: "17164",
        nm: "Sant Hilari Sacalm",
      },
      {
        id: "17165",
        nm: "Sant Jaume de Llierca",
      },
      {
        id: "17167",
        nm: "Sant Joan de les Abadesses",
      },
      {
        id: "17168",
        nm: "Sant Joan de Mollet",
      },
      {
        id: "17185",
        nm: "Sant Joan les Fonts",
      },
      {
        id: "17166",
        nm: "Sant Jordi Desvalls",
      },
      {
        id: "17169",
        nm: "Sant Julià de Ramis",
      },
      {
        id: "17903",
        nm: "Sant Julià del Llor i Bonmatí",
      },
      {
        id: "17171",
        nm: "Sant Llorenç de la Muga",
      },
      {
        id: "17172",
        nm: "Sant Martí de Llémena",
      },
      {
        id: "17173",
        nm: "Sant Martí Vell",
      },
      {
        id: "17174",
        nm: "Sant Miquel de Campmajor",
      },
      {
        id: "17175",
        nm: "Sant Miquel de Fluvià",
      },
      {
        id: "17176",
        nm: "Sant Mori",
      },
      {
        id: "17177",
        nm: "Sant Pau de Segúries",
      },
      {
        id: "17178",
        nm: "Sant Pere Pescador",
      },
      {
        id: "17180",
        nm: "Santa Coloma de Farners",
      },
      {
        id: "17181",
        nm: "Santa Cristina d'Aro",
      },
      {
        id: "17182",
        nm: "Santa Llogaia d'Àlguema",
      },
      {
        id: "17184",
        nm: "Santa Pau",
      },
      {
        id: "17186",
        nm: "Sarrià de Ter",
      },
      {
        id: "17187",
        nm: "Saus, Camallera i Llampaies",
      },
      {
        id: "17188",
        nm: "Selva de Mar, La",
      },
      {
        id: "17190",
        nm: "Serinyà",
      },
      {
        id: "17191",
        nm: "Serra de Daró",
      },
      {
        id: "17192",
        nm: "Setcases",
      },
      {
        id: "17193",
        nm: "Sils",
      },
      {
        id: "17052",
        nm: "Siurana",
      },
      {
        id: "17194",
        nm: "Susqueda",
      },
      {
        id: "17195",
        nm: "Tallada d'Empordà, La",
      },
      {
        id: "17196",
        nm: "Terrades",
      },
      {
        id: "17197",
        nm: "Torrent",
      },
      {
        id: "17198",
        nm: "Torroella de Fluvià",
      },
      {
        id: "17199",
        nm: "Torroella de Montgrí",
      },
      {
        id: "17200",
        nm: "Tortellà",
      },
      {
        id: "17201",
        nm: "Toses",
      },
      {
        id: "17202",
        nm: "Tossa de Mar",
      },
      {
        id: "17204",
        nm: "Ullà",
      },
      {
        id: "17205",
        nm: "Ullastret",
      },
      {
        id: "17203",
        nm: "Ultramort",
      },
      {
        id: "17206",
        nm: "Urús",
      },
      {
        id: "17014",
        nm: "Vajol, La",
      },
      {
        id: "17208",
        nm: "Vall de Bianya, La",
      },
      {
        id: "17207",
        nm: "Vall d'en Bas, La",
      },
      {
        id: "17170",
        nm: "Vallfogona de Ripollès",
      },
      {
        id: "17209",
        nm: "Vall-llobrega",
      },
      {
        id: "17210",
        nm: "Ventalló",
      },
      {
        id: "17211",
        nm: "Verges",
      },
      {
        id: "17212",
        nm: "Vidrà",
      },
      {
        id: "17213",
        nm: "Vidreres",
      },
      {
        id: "17214",
        nm: "Vilabertran",
      },
      {
        id: "17215",
        nm: "Vilablareix",
      },
      {
        id: "17217",
        nm: "Viladamat",
      },
      {
        id: "17216",
        nm: "Viladasens",
      },
      {
        id: "17218",
        nm: "Vilademuls",
      },
      {
        id: "17220",
        nm: "Viladrau",
      },
      {
        id: "17221",
        nm: "Vilafant",
      },
      {
        id: "17223",
        nm: "Vilajuïga",
      },
      {
        id: "17224",
        nm: "Vilallonga de Ter",
      },
      {
        id: "17225",
        nm: "Vilamacolum",
      },
      {
        id: "17226",
        nm: "Vilamalla",
      },
      {
        id: "17227",
        nm: "Vilamaniscle",
      },
      {
        id: "17228",
        nm: "Vilanant",
      },
      {
        id: "17230",
        nm: "Vila-sacra",
      },
      {
        id: "17222",
        nm: "Vilaür",
      },
      {
        id: "17233",
        nm: "Vilobí d'Onyar",
      },
      {
        id: "17232",
        nm: "Vilopriu",
      },
      {
        id: "18001",
        nm: "Agrón",
      },
      {
        id: "18002",
        nm: "Alamedilla",
      },
      {
        id: "18003",
        nm: "Albolote",
      },
      {
        id: "18004",
        nm: "Albondón",
      },
      {
        id: "18005",
        nm: "Albuñán",
      },
      {
        id: "18006",
        nm: "Albuñol",
      },
      {
        id: "18007",
        nm: "Albuñuelas",
      },
      {
        id: "18010",
        nm: "Aldeire",
      },
      {
        id: "18011",
        nm: "Alfacar",
      },
      {
        id: "18012",
        nm: "Algarinejo",
      },
      {
        id: "18013",
        nm: "Alhama de Granada",
      },
      {
        id: "18014",
        nm: "Alhendín",
      },
      {
        id: "18015",
        nm: "Alicún de Ortega",
      },
      {
        id: "18016",
        nm: "Almegíjar",
      },
      {
        id: "18017",
        nm: "Almuñécar",
      },
      {
        id: "18904",
        nm: "Alpujarra de la Sierra",
      },
      {
        id: "18018",
        nm: "Alquife",
      },
      {
        id: "18020",
        nm: "Arenas del Rey",
      },
      {
        id: "18021",
        nm: "Armilla",
      },
      {
        id: "18022",
        nm: "Atarfe",
      },
      {
        id: "18023",
        nm: "Baza",
      },
      {
        id: "18024",
        nm: "Beas de Granada",
      },
      {
        id: "18025",
        nm: "Beas de Guadix",
      },
      {
        id: "18027",
        nm: "Benalúa",
      },
      {
        id: "18028",
        nm: "Benalúa de las Villas",
      },
      {
        id: "18029",
        nm: "Benamaurel",
      },
      {
        id: "18030",
        nm: "Bérchules",
      },
      {
        id: "18032",
        nm: "Bubión",
      },
      {
        id: "18033",
        nm: "Busquístar",
      },
      {
        id: "18034",
        nm: "Cacín",
      },
      {
        id: "18035",
        nm: "Cádiar",
      },
      {
        id: "18036",
        nm: "Cájar",
      },
      {
        id: "18114",
        nm: "Calahorra, La",
      },
      {
        id: "18037",
        nm: "Calicasas",
      },
      {
        id: "18038",
        nm: "Campotéjar",
      },
      {
        id: "18039",
        nm: "Caniles",
      },
      {
        id: "18040",
        nm: "Cáñar",
      },
      {
        id: "18042",
        nm: "Capileira",
      },
      {
        id: "18043",
        nm: "Carataunas",
      },
      {
        id: "18044",
        nm: "Cástaras",
      },
      {
        id: "18045",
        nm: "Castilléjar",
      },
      {
        id: "18046",
        nm: "Castril",
      },
      {
        id: "18047",
        nm: "Cenes de la Vega",
      },
      {
        id: "18059",
        nm: "Chauchina",
      },
      {
        id: "18061",
        nm: "Chimeneas",
      },
      {
        id: "18062",
        nm: "Churriana de la Vega",
      },
      {
        id: "18048",
        nm: "Cijuela",
      },
      {
        id: "18049",
        nm: "Cogollos de Guadix",
      },
      {
        id: "18050",
        nm: "Cogollos de la Vega",
      },
      {
        id: "18051",
        nm: "Colomera",
      },
      {
        id: "18053",
        nm: "Cortes de Baza",
      },
      {
        id: "18054",
        nm: "Cortes y Graena",
      },
      {
        id: "18912",
        nm: "Cuevas del Campo",
      },
      {
        id: "18056",
        nm: "Cúllar",
      },
      {
        id: "18057",
        nm: "Cúllar Vega",
      },
      {
        id: "18063",
        nm: "Darro",
      },
      {
        id: "18064",
        nm: "Dehesas de Guadix",
      },
      {
        id: "18065",
        nm: "Dehesas Viejas",
      },
      {
        id: "18066",
        nm: "Deifontes",
      },
      {
        id: "18067",
        nm: "Diezma",
      },
      {
        id: "18068",
        nm: "Dílar",
      },
      {
        id: "18069",
        nm: "Dólar",
      },
      {
        id: "18915",
        nm: "Domingo Pérez de Granada",
      },
      {
        id: "18070",
        nm: "Dúdar",
      },
      {
        id: "18071",
        nm: "Dúrcal",
      },
      {
        id: "18072",
        nm: "Escúzar",
      },
      {
        id: "18074",
        nm: "Ferreira",
      },
      {
        id: "18076",
        nm: "Fonelas",
      },
      {
        id: "18078",
        nm: "Freila",
      },
      {
        id: "18079",
        nm: "Fuente Vaqueros",
      },
      {
        id: "18905",
        nm: "Gabias, Las",
      },
      {
        id: "18082",
        nm: "Galera",
      },
      {
        id: "18083",
        nm: "Gobernador",
      },
      {
        id: "18084",
        nm: "Gójar",
      },
      {
        id: "18085",
        nm: "Gor",
      },
      {
        id: "18086",
        nm: "Gorafe",
      },
      {
        id: "18087",
        nm: "Granada",
      },
      {
        id: "18088",
        nm: "Guadahortuna",
      },
      {
        id: "18089",
        nm: "Guadix",
      },
      {
        id: "18906",
        nm: "Guájares, Los",
      },
      {
        id: "18093",
        nm: "Gualchos",
      },
      {
        id: "18094",
        nm: "Güéjar Sierra",
      },
      {
        id: "18095",
        nm: "Güevéjar",
      },
      {
        id: "18096",
        nm: "Huélago",
      },
      {
        id: "18097",
        nm: "Huéneja",
      },
      {
        id: "18098",
        nm: "Huéscar",
      },
      {
        id: "18099",
        nm: "Huétor de Santillán",
      },
      {
        id: "18100",
        nm: "Huétor Tájar",
      },
      {
        id: "18101",
        nm: "Huétor Vega",
      },
      {
        id: "18102",
        nm: "Illora",
      },
      {
        id: "18103",
        nm: "Ítrabo",
      },
      {
        id: "18105",
        nm: "Iznalloz",
      },
      {
        id: "18106",
        nm: "Játar",
      },
      {
        id: "18107",
        nm: "Jayena",
      },
      {
        id: "18108",
        nm: "Jerez del Marquesado",
      },
      {
        id: "18109",
        nm: "Jete",
      },
      {
        id: "18111",
        nm: "Jun",
      },
      {
        id: "18112",
        nm: "Juviles",
      },
      {
        id: "18115",
        nm: "Láchar",
      },
      {
        id: "18116",
        nm: "Lanjarón",
      },
      {
        id: "18117",
        nm: "Lanteira",
      },
      {
        id: "18119",
        nm: "Lecrín",
      },
      {
        id: "18120",
        nm: "Lentegí",
      },
      {
        id: "18121",
        nm: "Lobras",
      },
      {
        id: "18122",
        nm: "Loja",
      },
      {
        id: "18123",
        nm: "Lugros",
      },
      {
        id: "18124",
        nm: "Lújar",
      },
      {
        id: "18126",
        nm: "Malahá, La",
      },
      {
        id: "18127",
        nm: "Maracena",
      },
      {
        id: "18128",
        nm: "Marchal",
      },
      {
        id: "18132",
        nm: "Moclín",
      },
      {
        id: "18133",
        nm: "Molvízar",
      },
      {
        id: "18134",
        nm: "Monachil",
      },
      {
        id: "18135",
        nm: "Montefrío",
      },
      {
        id: "18136",
        nm: "Montejícar",
      },
      {
        id: "18137",
        nm: "Montillana",
      },
      {
        id: "18138",
        nm: "Moraleda de Zafayona",
      },
      {
        id: "18909",
        nm: "Morelábor",
      },
      {
        id: "18140",
        nm: "Motril",
      },
      {
        id: "18141",
        nm: "Murtas",
      },
      {
        id: "18903",
        nm: "Nevada",
      },
      {
        id: "18143",
        nm: "Nigüelas",
      },
      {
        id: "18144",
        nm: "Nívar",
      },
      {
        id: "18145",
        nm: "Ogíjares",
      },
      {
        id: "18146",
        nm: "Orce",
      },
      {
        id: "18147",
        nm: "Órgiva",
      },
      {
        id: "18148",
        nm: "Otívar",
      },
      {
        id: "18150",
        nm: "Padul",
      },
      {
        id: "18151",
        nm: "Pampaneira",
      },
      {
        id: "18152",
        nm: "Pedro Martínez",
      },
      {
        id: "18153",
        nm: "Peligros",
      },
      {
        id: "18154",
        nm: "Peza, La",
      },
      {
        id: "18910",
        nm: "Pinar, El",
      },
      {
        id: "18157",
        nm: "Pinos Genil",
      },
      {
        id: "18158",
        nm: "Pinos Puente",
      },
      {
        id: "18159",
        nm: "Píñar",
      },
      {
        id: "18161",
        nm: "Polícar",
      },
      {
        id: "18162",
        nm: "Polopos",
      },
      {
        id: "18163",
        nm: "Pórtugos",
      },
      {
        id: "18164",
        nm: "Puebla de Don Fadrique",
      },
      {
        id: "18165",
        nm: "Pulianas",
      },
      {
        id: "18167",
        nm: "Purullena",
      },
      {
        id: "18168",
        nm: "Quéntar",
      },
      {
        id: "18170",
        nm: "Rubite",
      },
      {
        id: "18171",
        nm: "Salar",
      },
      {
        id: "18173",
        nm: "Salobreña",
      },
      {
        id: "18174",
        nm: "Santa Cruz del Comercio",
      },
      {
        id: "18175",
        nm: "Santa Fe",
      },
      {
        id: "18176",
        nm: "Soportújar",
      },
      {
        id: "18177",
        nm: "Sorvilán",
      },
      {
        id: "18901",
        nm: "Taha, La",
      },
      {
        id: "18178",
        nm: "Torre-Cardela",
      },
      {
        id: "18179",
        nm: "Torvizcón",
      },
      {
        id: "18180",
        nm: "Trevélez",
      },
      {
        id: "18181",
        nm: "Turón",
      },
      {
        id: "18182",
        nm: "Ugíjar",
      },
      {
        id: "18914",
        nm: "Valderrubio",
      },
      {
        id: "18907",
        nm: "Valle del Zalabí",
      },
      {
        id: "18902",
        nm: "Valle, El",
      },
      {
        id: "18183",
        nm: "Válor",
      },
      {
        id: "18911",
        nm: "Vegas del Genil",
      },
      {
        id: "18184",
        nm: "Vélez de Benaudalla",
      },
      {
        id: "18185",
        nm: "Ventas de Huelma",
      },
      {
        id: "18149",
        nm: "Villa de Otura",
      },
      {
        id: "18908",
        nm: "Villamena",
      },
      {
        id: "18187",
        nm: "Villanueva de las Torres",
      },
      {
        id: "18188",
        nm: "Villanueva Mesía",
      },
      {
        id: "18189",
        nm: "Víznar",
      },
      {
        id: "18192",
        nm: "Zafarraya",
      },
      {
        id: "18913",
        nm: "Zagra",
      },
      {
        id: "18193",
        nm: "Zubia, La",
      },
      {
        id: "18194",
        nm: "Zújar",
      },
      {
        id: "19001",
        nm: "Abánades",
      },
      {
        id: "19002",
        nm: "Ablanque",
      },
      {
        id: "19003",
        nm: "Adobes",
      },
      {
        id: "19004",
        nm: "Alaminos",
      },
      {
        id: "19005",
        nm: "Alarilla",
      },
      {
        id: "19006",
        nm: "Albalate de Zorita",
      },
      {
        id: "19007",
        nm: "Albares",
      },
      {
        id: "19008",
        nm: "Albendiego",
      },
      {
        id: "19009",
        nm: "Alcocer",
      },
      {
        id: "19010",
        nm: "Alcolea de las Peñas",
      },
      {
        id: "19011",
        nm: "Alcolea del Pinar",
      },
      {
        id: "19013",
        nm: "Alcoroches",
      },
      {
        id: "19015",
        nm: "Aldeanueva de Guadalajara",
      },
      {
        id: "19016",
        nm: "Algar de Mesa",
      },
      {
        id: "19017",
        nm: "Algora",
      },
      {
        id: "19018",
        nm: "Alhóndiga",
      },
      {
        id: "19019",
        nm: "Alique",
      },
      {
        id: "19020",
        nm: "Almadrones",
      },
      {
        id: "19021",
        nm: "Almoguera",
      },
      {
        id: "19022",
        nm: "Almonacid de Zorita",
      },
      {
        id: "19023",
        nm: "Alocén",
      },
      {
        id: "19024",
        nm: "Alovera",
      },
      {
        id: "19027",
        nm: "Alustante",
      },
      {
        id: "19031",
        nm: "Angón",
      },
      {
        id: "19032",
        nm: "Anguita",
      },
      {
        id: "19033",
        nm: "Anquela del Ducado",
      },
      {
        id: "19034",
        nm: "Anquela del Pedregal",
      },
      {
        id: "19036",
        nm: "Aranzueque",
      },
      {
        id: "19037",
        nm: "Arbancón",
      },
      {
        id: "19038",
        nm: "Arbeteta",
      },
      {
        id: "19039",
        nm: "Argecilla",
      },
      {
        id: "19040",
        nm: "Armallones",
      },
      {
        id: "19041",
        nm: "Armuña de Tajuña",
      },
      {
        id: "19042",
        nm: "Arroyo de las Fraguas",
      },
      {
        id: "19043",
        nm: "Atanzón",
      },
      {
        id: "19044",
        nm: "Atienza",
      },
      {
        id: "19045",
        nm: "Auñón",
      },
      {
        id: "19046",
        nm: "Azuqueca de Henares",
      },
      {
        id: "19047",
        nm: "Baides",
      },
      {
        id: "19048",
        nm: "Baños de Tajo",
      },
      {
        id: "19049",
        nm: "Bañuelos",
      },
      {
        id: "19050",
        nm: "Barriopedro",
      },
      {
        id: "19051",
        nm: "Berninches",
      },
      {
        id: "19052",
        nm: "Bodera, La",
      },
      {
        id: "19053",
        nm: "Brihuega",
      },
      {
        id: "19054",
        nm: "Budia",
      },
      {
        id: "19055",
        nm: "Bujalaro",
      },
      {
        id: "19057",
        nm: "Bustares",
      },
      {
        id: "19058",
        nm: "Cabanillas del Campo",
      },
      {
        id: "19059",
        nm: "Campillo de Dueñas",
      },
      {
        id: "19060",
        nm: "Campillo de Ranas",
      },
      {
        id: "19061",
        nm: "Campisábalos",
      },
      {
        id: "19064",
        nm: "Canredondo",
      },
      {
        id: "19065",
        nm: "Cantalojas",
      },
      {
        id: "19066",
        nm: "Cañizar",
      },
      {
        id: "19067",
        nm: "Cardoso de la Sierra, El",
      },
      {
        id: "19070",
        nm: "Casa de Uceda",
      },
      {
        id: "19071",
        nm: "Casar, El",
      },
      {
        id: "19073",
        nm: "Casas de San Galindo",
      },
      {
        id: "19074",
        nm: "Caspueñas",
      },
      {
        id: "19075",
        nm: "Castejón de Henares",
      },
      {
        id: "19076",
        nm: "Castellar de la Muela",
      },
      {
        id: "19078",
        nm: "Castilforte",
      },
      {
        id: "19079",
        nm: "Castilnuevo",
      },
      {
        id: "19080",
        nm: "Cendejas de Enmedio",
      },
      {
        id: "19081",
        nm: "Cendejas de la Torre",
      },
      {
        id: "19082",
        nm: "Centenera",
      },
      {
        id: "19103",
        nm: "Checa",
      },
      {
        id: "19104",
        nm: "Chequilla",
      },
      {
        id: "19106",
        nm: "Chillarón del Rey",
      },
      {
        id: "19105",
        nm: "Chiloeches",
      },
      {
        id: "19086",
        nm: "Cifuentes",
      },
      {
        id: "19087",
        nm: "Cincovillas",
      },
      {
        id: "19088",
        nm: "Ciruelas",
      },
      {
        id: "19089",
        nm: "Ciruelos del Pinar",
      },
      {
        id: "19090",
        nm: "Cobeta",
      },
      {
        id: "19091",
        nm: "Cogollor",
      },
      {
        id: "19092",
        nm: "Cogolludo",
      },
      {
        id: "19095",
        nm: "Condemios de Abajo",
      },
      {
        id: "19096",
        nm: "Condemios de Arriba",
      },
      {
        id: "19097",
        nm: "Congostrina",
      },
      {
        id: "19098",
        nm: "Copernal",
      },
      {
        id: "19099",
        nm: "Corduente",
      },
      {
        id: "19102",
        nm: "Cubillo de Uceda, El",
      },
      {
        id: "19107",
        nm: "Driebes",
      },
      {
        id: "19108",
        nm: "Durón",
      },
      {
        id: "19109",
        nm: "Embid",
      },
      {
        id: "19110",
        nm: "Escamilla",
      },
      {
        id: "19111",
        nm: "Escariche",
      },
      {
        id: "19112",
        nm: "Escopete",
      },
      {
        id: "19113",
        nm: "Espinosa de Henares",
      },
      {
        id: "19114",
        nm: "Esplegares",
      },
      {
        id: "19115",
        nm: "Establés",
      },
      {
        id: "19116",
        nm: "Estriégana",
      },
      {
        id: "19117",
        nm: "Fontanar",
      },
      {
        id: "19118",
        nm: "Fuembellida",
      },
      {
        id: "19119",
        nm: "Fuencemillán",
      },
      {
        id: "19120",
        nm: "Fuentelahiguera de Albatages",
      },
      {
        id: "19121",
        nm: "Fuentelencina",
      },
      {
        id: "19122",
        nm: "Fuentelsaz",
      },
      {
        id: "19123",
        nm: "Fuentelviejo",
      },
      {
        id: "19124",
        nm: "Fuentenovilla",
      },
      {
        id: "19125",
        nm: "Gajanejos",
      },
      {
        id: "19126",
        nm: "Galápagos",
      },
      {
        id: "19127",
        nm: "Galve de Sorbe",
      },
      {
        id: "19129",
        nm: "Gascueña de Bornova",
      },
      {
        id: "19130",
        nm: "Guadalajara",
      },
      {
        id: "19132",
        nm: "Henche",
      },
      {
        id: "19133",
        nm: "Heras de Ayuso",
      },
      {
        id: "19134",
        nm: "Herrería",
      },
      {
        id: "19135",
        nm: "Hiendelaencina",
      },
      {
        id: "19136",
        nm: "Hijes",
      },
      {
        id: "19138",
        nm: "Hita",
      },
      {
        id: "19139",
        nm: "Hombrados",
      },
      {
        id: "19142",
        nm: "Hontoba",
      },
      {
        id: "19143",
        nm: "Horche",
      },
      {
        id: "19145",
        nm: "Hortezuela de Océn",
      },
      {
        id: "19146",
        nm: "Huerce, La",
      },
      {
        id: "19147",
        nm: "Huérmeces del Cerro",
      },
      {
        id: "19148",
        nm: "Huertahernando",
      },
      {
        id: "19150",
        nm: "Hueva",
      },
      {
        id: "19151",
        nm: "Humanes",
      },
      {
        id: "19152",
        nm: "Illana",
      },
      {
        id: "19153",
        nm: "Iniéstola",
      },
      {
        id: "19154",
        nm: "Inviernas, Las",
      },
      {
        id: "19155",
        nm: "Irueste",
      },
      {
        id: "19156",
        nm: "Jadraque",
      },
      {
        id: "19157",
        nm: "Jirueque",
      },
      {
        id: "19159",
        nm: "Ledanca",
      },
      {
        id: "19160",
        nm: "Loranca de Tajuña",
      },
      {
        id: "19161",
        nm: "Lupiana",
      },
      {
        id: "19162",
        nm: "Luzaga",
      },
      {
        id: "19163",
        nm: "Luzón",
      },
      {
        id: "19165",
        nm: "Majaelrayo",
      },
      {
        id: "19166",
        nm: "Málaga del Fresno",
      },
      {
        id: "19167",
        nm: "Malaguilla",
      },
      {
        id: "19168",
        nm: "Mandayona",
      },
      {
        id: "19169",
        nm: "Mantiel",
      },
      {
        id: "19170",
        nm: "Maranchón",
      },
      {
        id: "19171",
        nm: "Marchamalo",
      },
      {
        id: "19172",
        nm: "Masegoso de Tajuña",
      },
      {
        id: "19173",
        nm: "Matarrubia",
      },
      {
        id: "19174",
        nm: "Matillas",
      },
      {
        id: "19175",
        nm: "Mazarete",
      },
      {
        id: "19176",
        nm: "Mazuecos",
      },
      {
        id: "19177",
        nm: "Medranda",
      },
      {
        id: "19178",
        nm: "Megina",
      },
      {
        id: "19179",
        nm: "Membrillera",
      },
      {
        id: "19181",
        nm: "Miedes de Atienza",
      },
      {
        id: "19182",
        nm: "Mierla, La",
      },
      {
        id: "19184",
        nm: "Millana",
      },
      {
        id: "19183",
        nm: "Milmarcos",
      },
      {
        id: "19185",
        nm: "Miñosa, La",
      },
      {
        id: "19186",
        nm: "Mirabueno",
      },
      {
        id: "19187",
        nm: "Miralrío",
      },
      {
        id: "19188",
        nm: "Mochales",
      },
      {
        id: "19189",
        nm: "Mohernando",
      },
      {
        id: "19190",
        nm: "Molina de Aragón",
      },
      {
        id: "19191",
        nm: "Monasterio",
      },
      {
        id: "19192",
        nm: "Mondéjar",
      },
      {
        id: "19193",
        nm: "Montarrón",
      },
      {
        id: "19194",
        nm: "Moratilla de los Meleros",
      },
      {
        id: "19195",
        nm: "Morenilla",
      },
      {
        id: "19196",
        nm: "Muduex",
      },
      {
        id: "19197",
        nm: "Navas de Jadraque, Las",
      },
      {
        id: "19198",
        nm: "Negredo",
      },
      {
        id: "19199",
        nm: "Ocentejo",
      },
      {
        id: "19200",
        nm: "Olivar, El",
      },
      {
        id: "19201",
        nm: "Olmeda de Cobeta",
      },
      {
        id: "19202",
        nm: "Olmeda de Jadraque, La",
      },
      {
        id: "19203",
        nm: "Ordial, El",
      },
      {
        id: "19204",
        nm: "Orea",
      },
      {
        id: "19208",
        nm: "Pálmaces de Jadraque",
      },
      {
        id: "19209",
        nm: "Pardos",
      },
      {
        id: "19210",
        nm: "Paredes de Sigüenza",
      },
      {
        id: "19211",
        nm: "Pareja",
      },
      {
        id: "19212",
        nm: "Pastrana",
      },
      {
        id: "19213",
        nm: "Pedregal, El",
      },
      {
        id: "19214",
        nm: "Peñalén",
      },
      {
        id: "19215",
        nm: "Peñalver",
      },
      {
        id: "19216",
        nm: "Peralejos de las Truchas",
      },
      {
        id: "19217",
        nm: "Peralveche",
      },
      {
        id: "19218",
        nm: "Pinilla de Jadraque",
      },
      {
        id: "19219",
        nm: "Pinilla de Molina",
      },
      {
        id: "19220",
        nm: "Pioz",
      },
      {
        id: "19221",
        nm: "Piqueras",
      },
      {
        id: "19222",
        nm: "Pobo de Dueñas, El",
      },
      {
        id: "19223",
        nm: "Poveda de la Sierra",
      },
      {
        id: "19224",
        nm: "Pozo de Almoguera",
      },
      {
        id: "19225",
        nm: "Pozo de Guadalajara",
      },
      {
        id: "19226",
        nm: "Prádena de Atienza",
      },
      {
        id: "19227",
        nm: "Prados Redondos",
      },
      {
        id: "19228",
        nm: "Puebla de Beleña",
      },
      {
        id: "19229",
        nm: "Puebla de Valles",
      },
      {
        id: "19230",
        nm: "Quer",
      },
      {
        id: "19231",
        nm: "Rebollosa de Jadraque",
      },
      {
        id: "19232",
        nm: "Recuenco, El",
      },
      {
        id: "19233",
        nm: "Renera",
      },
      {
        id: "19234",
        nm: "Retiendas",
      },
      {
        id: "19235",
        nm: "Riba de Saelices",
      },
      {
        id: "19237",
        nm: "Rillo de Gallo",
      },
      {
        id: "19238",
        nm: "Riofrío del Llano",
      },
      {
        id: "19239",
        nm: "Robledillo de Mohernando",
      },
      {
        id: "19240",
        nm: "Robledo de Corpes",
      },
      {
        id: "19241",
        nm: "Romanillos de Atienza",
      },
      {
        id: "19242",
        nm: "Romanones",
      },
      {
        id: "19243",
        nm: "Rueda de la Sierra",
      },
      {
        id: "19244",
        nm: "Sacecorbo",
      },
      {
        id: "19245",
        nm: "Sacedón",
      },
      {
        id: "19246",
        nm: "Saelices de la Sal",
      },
      {
        id: "19247",
        nm: "Salmerón",
      },
      {
        id: "19248",
        nm: "San Andrés del Congosto",
      },
      {
        id: "19249",
        nm: "San Andrés del Rey",
      },
      {
        id: "19250",
        nm: "Santiuste",
      },
      {
        id: "19251",
        nm: "Saúca",
      },
      {
        id: "19252",
        nm: "Sayatón",
      },
      {
        id: "19254",
        nm: "Selas",
      },
      {
        id: "19901",
        nm: "Semillas",
      },
      {
        id: "19255",
        nm: "Setiles",
      },
      {
        id: "19256",
        nm: "Sienes",
      },
      {
        id: "19257",
        nm: "Sigüenza",
      },
      {
        id: "19258",
        nm: "Solanillos del Extremo",
      },
      {
        id: "19259",
        nm: "Somolinos",
      },
      {
        id: "19260",
        nm: "Sotillo, El",
      },
      {
        id: "19261",
        nm: "Sotodosos",
      },
      {
        id: "19262",
        nm: "Tamajón",
      },
      {
        id: "19263",
        nm: "Taragudo",
      },
      {
        id: "19264",
        nm: "Taravilla",
      },
      {
        id: "19265",
        nm: "Tartanedo",
      },
      {
        id: "19266",
        nm: "Tendilla",
      },
      {
        id: "19267",
        nm: "Terzaga",
      },
      {
        id: "19268",
        nm: "Tierzo",
      },
      {
        id: "19269",
        nm: "Toba, La",
      },
      {
        id: "19271",
        nm: "Tordellego",
      },
      {
        id: "19270",
        nm: "Tordelrábano",
      },
      {
        id: "19272",
        nm: "Tordesilos",
      },
      {
        id: "19274",
        nm: "Torija",
      },
      {
        id: "19279",
        nm: "Torre del Burgo",
      },
      {
        id: "19277",
        nm: "Torrecuadrada de Molina",
      },
      {
        id: "19278",
        nm: "Torrecuadradilla",
      },
      {
        id: "19280",
        nm: "Torrejón del Rey",
      },
      {
        id: "19281",
        nm: "Torremocha de Jadraque",
      },
      {
        id: "19282",
        nm: "Torremocha del Campo",
      },
      {
        id: "19283",
        nm: "Torremocha del Pinar",
      },
      {
        id: "19284",
        nm: "Torremochuela",
      },
      {
        id: "19285",
        nm: "Torrubia",
      },
      {
        id: "19286",
        nm: "Tórtola de Henares",
      },
      {
        id: "19287",
        nm: "Tortuera",
      },
      {
        id: "19288",
        nm: "Tortuero",
      },
      {
        id: "19289",
        nm: "Traíd",
      },
      {
        id: "19290",
        nm: "Trijueque",
      },
      {
        id: "19291",
        nm: "Trillo",
      },
      {
        id: "19293",
        nm: "Uceda",
      },
      {
        id: "19294",
        nm: "Ujados",
      },
      {
        id: "19296",
        nm: "Utande",
      },
      {
        id: "19297",
        nm: "Valdarachas",
      },
      {
        id: "19298",
        nm: "Valdearenas",
      },
      {
        id: "19299",
        nm: "Valdeavellano",
      },
      {
        id: "19300",
        nm: "Valdeaveruelo",
      },
      {
        id: "19301",
        nm: "Valdeconcha",
      },
      {
        id: "19302",
        nm: "Valdegrudas",
      },
      {
        id: "19303",
        nm: "Valdelcubo",
      },
      {
        id: "19304",
        nm: "Valdenuño Fernández",
      },
      {
        id: "19305",
        nm: "Valdepeñas de la Sierra",
      },
      {
        id: "19306",
        nm: "Valderrebollo",
      },
      {
        id: "19307",
        nm: "Valdesotos",
      },
      {
        id: "19308",
        nm: "Valfermoso de Tajuña",
      },
      {
        id: "19309",
        nm: "Valhermoso",
      },
      {
        id: "19310",
        nm: "Valtablado del Río",
      },
      {
        id: "19311",
        nm: "Valverde de los Arroyos",
      },
      {
        id: "19314",
        nm: "Viana de Jadraque",
      },
      {
        id: "19317",
        nm: "Villanueva de Alcorón",
      },
      {
        id: "19318",
        nm: "Villanueva de Argecilla",
      },
      {
        id: "19319",
        nm: "Villanueva de la Torre",
      },
      {
        id: "19321",
        nm: "Villares de Jadraque",
      },
      {
        id: "19322",
        nm: "Villaseca de Henares",
      },
      {
        id: "19323",
        nm: "Villaseca de Uceda",
      },
      {
        id: "19324",
        nm: "Villel de Mesa",
      },
      {
        id: "19325",
        nm: "Viñuelas",
      },
      {
        id: "19326",
        nm: "Yebes",
      },
      {
        id: "19327",
        nm: "Yebra",
      },
      {
        id: "19329",
        nm: "Yélamos de Abajo",
      },
      {
        id: "19330",
        nm: "Yélamos de Arriba",
      },
      {
        id: "19331",
        nm: "Yunquera de Henares",
      },
      {
        id: "19332",
        nm: "Yunta, La",
      },
      {
        id: "19333",
        nm: "Zaorejas",
      },
      {
        id: "19334",
        nm: "Zarzuela de Jadraque",
      },
      {
        id: "19335",
        nm: "Zorita de los Canes",
      },
      {
        id: "20001",
        nm: "Abaltzisketa",
      },
      {
        id: "20002",
        nm: "Aduna",
      },
      {
        id: "20016",
        nm: "Aia",
      },
      {
        id: "20003",
        nm: "Aizarnazabal",
      },
      {
        id: "20004",
        nm: "Albiztur",
      },
      {
        id: "20005",
        nm: "Alegia",
      },
      {
        id: "20006",
        nm: "Alkiza",
      },
      {
        id: "20906",
        nm: "Altzaga",
      },
      {
        id: "20007",
        nm: "Altzo",
      },
      {
        id: "20008",
        nm: "Amezketa",
      },
      {
        id: "20009",
        nm: "Andoain",
      },
      {
        id: "20010",
        nm: "Anoeta",
      },
      {
        id: "20011",
        nm: "Antzuola",
      },
      {
        id: "20012",
        nm: "Arama",
      },
      {
        id: "20013",
        nm: "Aretxabaleta",
      },
      {
        id: "20055",
        nm: "Arrasate/Mondragón",
      },
      {
        id: "20014",
        nm: "Asteasu",
      },
      {
        id: "20903",
        nm: "Astigarraga",
      },
      {
        id: "20015",
        nm: "Ataun",
      },
      {
        id: "20017",
        nm: "Azkoitia",
      },
      {
        id: "20018",
        nm: "Azpeitia",
      },
      {
        id: "20904",
        nm: "Baliarrain",
      },
      {
        id: "20019",
        nm: "Beasain",
      },
      {
        id: "20020",
        nm: "Beizama",
      },
      {
        id: "20021",
        nm: "Belauntza",
      },
      {
        id: "20022",
        nm: "Berastegi",
      },
      {
        id: "20074",
        nm: "Bergara",
      },
      {
        id: "20023",
        nm: "Berrobi",
      },
      {
        id: "20024",
        nm: "Bidania-Goiatz",
      },
      {
        id: "20029",
        nm: "Deba",
      },
      {
        id: "20069",
        nm: "Donostia/San Sebastián",
      },
      {
        id: "20030",
        nm: "Eibar",
      },
      {
        id: "20031",
        nm: "Elduain",
      },
      {
        id: "20033",
        nm: "Elgeta",
      },
      {
        id: "20032",
        nm: "Elgoibar",
      },
      {
        id: "20067",
        nm: "Errenteria",
      },
      {
        id: "20066",
        nm: "Errezil",
      },
      {
        id: "20034",
        nm: "Eskoriatza",
      },
      {
        id: "20035",
        nm: "Ezkio-Itsaso",
      },
      {
        id: "20038",
        nm: "Gabiria",
      },
      {
        id: "20037",
        nm: "Gaintza",
      },
      {
        id: "20907",
        nm: "Gaztelu",
      },
      {
        id: "20039",
        nm: "Getaria",
      },
      {
        id: "20040",
        nm: "Hernani",
      },
      {
        id: "20041",
        nm: "Hernialde",
      },
      {
        id: "20036",
        nm: "Hondarribia",
      },
      {
        id: "20042",
        nm: "Ibarra",
      },
      {
        id: "20043",
        nm: "Idiazabal",
      },
      {
        id: "20044",
        nm: "Ikaztegieta",
      },
      {
        id: "20045",
        nm: "Irun",
      },
      {
        id: "20046",
        nm: "Irura",
      },
      {
        id: "20047",
        nm: "Itsasondo",
      },
      {
        id: "20048",
        nm: "Larraul",
      },
      {
        id: "20902",
        nm: "Lasarte-Oria",
      },
      {
        id: "20049",
        nm: "Lazkao",
      },
      {
        id: "20050",
        nm: "Leaburu",
      },
      {
        id: "20051",
        nm: "Legazpi",
      },
      {
        id: "20052",
        nm: "Legorreta",
      },
      {
        id: "20068",
        nm: "Leintz-Gatzaga",
      },
      {
        id: "20053",
        nm: "Lezo",
      },
      {
        id: "20054",
        nm: "Lizartza",
      },
      {
        id: "20901",
        nm: "Mendaro",
      },
      {
        id: "20057",
        nm: "Mutiloa",
      },
      {
        id: "20056",
        nm: "Mutriku",
      },
      {
        id: "20063",
        nm: "Oiartzun",
      },
      {
        id: "20058",
        nm: "Olaberria",
      },
      {
        id: "20059",
        nm: "Oñati",
      },
      {
        id: "20076",
        nm: "Ordizia",
      },
      {
        id: "20905",
        nm: "Orendain",
      },
      {
        id: "20060",
        nm: "Orexa",
      },
      {
        id: "20061",
        nm: "Orio",
      },
      {
        id: "20062",
        nm: "Ormaiztegi",
      },
      {
        id: "20064",
        nm: "Pasaia",
      },
      {
        id: "20070",
        nm: "Segura",
      },
      {
        id: "20065",
        nm: "Soraluze-Placencia de las Armas",
      },
      {
        id: "20071",
        nm: "Tolosa",
      },
      {
        id: "20072",
        nm: "Urnieta",
      },
      {
        id: "20077",
        nm: "Urretxu",
      },
      {
        id: "20073",
        nm: "Usurbil",
      },
      {
        id: "20075",
        nm: "Villabona",
      },
      {
        id: "20078",
        nm: "Zaldibia",
      },
      {
        id: "20079",
        nm: "Zarautz",
      },
      {
        id: "20025",
        nm: "Zegama",
      },
      {
        id: "20026",
        nm: "Zerain",
      },
      {
        id: "20027",
        nm: "Zestoa",
      },
      {
        id: "20028",
        nm: "Zizurkil",
      },
      {
        id: "20081",
        nm: "Zumaia",
      },
      {
        id: "20080",
        nm: "Zumarraga",
      },
      {
        id: "21001",
        nm: "Alájar",
      },
      {
        id: "21002",
        nm: "Aljaraque",
      },
      {
        id: "21003",
        nm: "Almendro, El",
      },
      {
        id: "21004",
        nm: "Almonaster la Real",
      },
      {
        id: "21005",
        nm: "Almonte",
      },
      {
        id: "21006",
        nm: "Alosno",
      },
      {
        id: "21007",
        nm: "Aracena",
      },
      {
        id: "21008",
        nm: "Aroche",
      },
      {
        id: "21009",
        nm: "Arroyomolinos de León",
      },
      {
        id: "21010",
        nm: "Ayamonte",
      },
      {
        id: "21011",
        nm: "Beas",
      },
      {
        id: "21012",
        nm: "Berrocal",
      },
      {
        id: "21013",
        nm: "Bollullos Par del Condado",
      },
      {
        id: "21014",
        nm: "Bonares",
      },
      {
        id: "21015",
        nm: "Cabezas Rubias",
      },
      {
        id: "21016",
        nm: "Cala",
      },
      {
        id: "21017",
        nm: "Calañas",
      },
      {
        id: "21018",
        nm: "Campillo, El",
      },
      {
        id: "21019",
        nm: "Campofrío",
      },
      {
        id: "21020",
        nm: "Cañaveral de León",
      },
      {
        id: "21021",
        nm: "Cartaya",
      },
      {
        id: "21022",
        nm: "Castaño del Robledo",
      },
      {
        id: "21023",
        nm: "Cerro de Andévalo, El",
      },
      {
        id: "21030",
        nm: "Chucena",
      },
      {
        id: "21024",
        nm: "Corteconcepción",
      },
      {
        id: "21025",
        nm: "Cortegana",
      },
      {
        id: "21026",
        nm: "Cortelazor",
      },
      {
        id: "21027",
        nm: "Cumbres de Enmedio",
      },
      {
        id: "21028",
        nm: "Cumbres de San Bartolomé",
      },
      {
        id: "21029",
        nm: "Cumbres Mayores",
      },
      {
        id: "21031",
        nm: "Encinasola",
      },
      {
        id: "21032",
        nm: "Escacena del Campo",
      },
      {
        id: "21033",
        nm: "Fuenteheridos",
      },
      {
        id: "21034",
        nm: "Galaroza",
      },
      {
        id: "21035",
        nm: "Gibraleón",
      },
      {
        id: "21036",
        nm: "Granada de Río-Tinto, La",
      },
      {
        id: "21037",
        nm: "Granado, El",
      },
      {
        id: "21038",
        nm: "Higuera de la Sierra",
      },
      {
        id: "21039",
        nm: "Hinojales",
      },
      {
        id: "21040",
        nm: "Hinojos",
      },
      {
        id: "21041",
        nm: "Huelva",
      },
      {
        id: "21042",
        nm: "Isla Cristina",
      },
      {
        id: "21043",
        nm: "Jabugo",
      },
      {
        id: "21044",
        nm: "Lepe",
      },
      {
        id: "21045",
        nm: "Linares de la Sierra",
      },
      {
        id: "21046",
        nm: "Lucena del Puerto",
      },
      {
        id: "21047",
        nm: "Manzanilla",
      },
      {
        id: "21048",
        nm: "Marines, Los",
      },
      {
        id: "21049",
        nm: "Minas de Riotinto",
      },
      {
        id: "21050",
        nm: "Moguer",
      },
      {
        id: "21051",
        nm: "Nava, La",
      },
      {
        id: "21052",
        nm: "Nerva",
      },
      {
        id: "21053",
        nm: "Niebla",
      },
      {
        id: "21054",
        nm: "Palma del Condado, La",
      },
      {
        id: "21055",
        nm: "Palos de la Frontera",
      },
      {
        id: "21056",
        nm: "Paterna del Campo",
      },
      {
        id: "21057",
        nm: "Paymogo",
      },
      {
        id: "21058",
        nm: "Puebla de Guzmán",
      },
      {
        id: "21059",
        nm: "Puerto Moral",
      },
      {
        id: "21060",
        nm: "Punta Umbría",
      },
      {
        id: "21061",
        nm: "Rociana del Condado",
      },
      {
        id: "21062",
        nm: "Rosal de la Frontera",
      },
      {
        id: "21063",
        nm: "San Bartolomé de la Torre",
      },
      {
        id: "21064",
        nm: "San Juan del Puerto",
      },
      {
        id: "21066",
        nm: "San Silvestre de Guzmán",
      },
      {
        id: "21065",
        nm: "Sanlúcar de Guadiana",
      },
      {
        id: "21067",
        nm: "Santa Ana la Real",
      },
      {
        id: "21068",
        nm: "Santa Bárbara de Casa",
      },
      {
        id: "21069",
        nm: "Santa Olalla del Cala",
      },
      {
        id: "21070",
        nm: "Trigueros",
      },
      {
        id: "21071",
        nm: "Valdelarco",
      },
      {
        id: "21072",
        nm: "Valverde del Camino",
      },
      {
        id: "21073",
        nm: "Villablanca",
      },
      {
        id: "21074",
        nm: "Villalba del Alcor",
      },
      {
        id: "21075",
        nm: "Villanueva de las Cruces",
      },
      {
        id: "21076",
        nm: "Villanueva de los Castillejos",
      },
      {
        id: "21077",
        nm: "Villarrasa",
      },
      {
        id: "21078",
        nm: "Zalamea la Real",
      },
      {
        id: "21079",
        nm: "Zufre",
      },
      {
        id: "22001",
        nm: "Abiego",
      },
      {
        id: "22002",
        nm: "Abizanda",
      },
      {
        id: "22003",
        nm: "Adahuesca",
      },
      {
        id: "22004",
        nm: "Agüero",
      },
      {
        id: "22907",
        nm: "Aínsa-Sobrarbe",
      },
      {
        id: "22006",
        nm: "Aisa",
      },
      {
        id: "22007",
        nm: "Albalate de Cinca",
      },
      {
        id: "22008",
        nm: "Albalatillo",
      },
      {
        id: "22009",
        nm: "Albelda",
      },
      {
        id: "22011",
        nm: "Albero Alto",
      },
      {
        id: "22012",
        nm: "Albero Bajo",
      },
      {
        id: "22013",
        nm: "Alberuela de Tubo",
      },
      {
        id: "22014",
        nm: "Alcalá de Gurrea",
      },
      {
        id: "22015",
        nm: "Alcalá del Obispo",
      },
      {
        id: "22016",
        nm: "Alcampell",
      },
      {
        id: "22017",
        nm: "Alcolea de Cinca",
      },
      {
        id: "22018",
        nm: "Alcubierre",
      },
      {
        id: "22019",
        nm: "Alerre",
      },
      {
        id: "22020",
        nm: "Alfántega",
      },
      {
        id: "22021",
        nm: "Almudévar",
      },
      {
        id: "22022",
        nm: "Almunia de San Juan",
      },
      {
        id: "22023",
        nm: "Almuniente",
      },
      {
        id: "22024",
        nm: "Alquézar",
      },
      {
        id: "22025",
        nm: "Altorricón",
      },
      {
        id: "22027",
        nm: "Angüés",
      },
      {
        id: "22028",
        nm: "Ansó",
      },
      {
        id: "22029",
        nm: "Antillón",
      },
      {
        id: "22032",
        nm: "Aragüés del Puerto",
      },
      {
        id: "22035",
        nm: "Arén",
      },
      {
        id: "22036",
        nm: "Argavieso",
      },
      {
        id: "22037",
        nm: "Arguis",
      },
      {
        id: "22039",
        nm: "Ayerbe",
      },
      {
        id: "22040",
        nm: "Azanuy-Alins",
      },
      {
        id: "22041",
        nm: "Azara",
      },
      {
        id: "22042",
        nm: "Azlor",
      },
      {
        id: "22043",
        nm: "Baélls",
      },
      {
        id: "22044",
        nm: "Bailo",
      },
      {
        id: "22045",
        nm: "Baldellou",
      },
      {
        id: "22046",
        nm: "Ballobar",
      },
      {
        id: "22047",
        nm: "Banastás",
      },
      {
        id: "22048",
        nm: "Barbastro",
      },
      {
        id: "22049",
        nm: "Barbués",
      },
      {
        id: "22050",
        nm: "Barbuñales",
      },
      {
        id: "22051",
        nm: "Bárcabo",
      },
      {
        id: "22052",
        nm: "Belver de Cinca",
      },
      {
        id: "22053",
        nm: "Benabarre",
      },
      {
        id: "22054",
        nm: "Benasque",
      },
      {
        id: "22246",
        nm: "Beranuy",
      },
      {
        id: "22055",
        nm: "Berbegal",
      },
      {
        id: "22057",
        nm: "Bielsa",
      },
      {
        id: "22058",
        nm: "Bierge",
      },
      {
        id: "22059",
        nm: "Biescas",
      },
      {
        id: "22060",
        nm: "Binaced",
      },
      {
        id: "22061",
        nm: "Binéfar",
      },
      {
        id: "22062",
        nm: "Bisaurri",
      },
      {
        id: "22063",
        nm: "Biscarrués",
      },
      {
        id: "22064",
        nm: "Blecua y Torres",
      },
      {
        id: "22066",
        nm: "Boltaña",
      },
      {
        id: "22067",
        nm: "Bonansa",
      },
      {
        id: "22068",
        nm: "Borau",
      },
      {
        id: "22069",
        nm: "Broto",
      },
      {
        id: "22072",
        nm: "Caldearenas",
      },
      {
        id: "22074",
        nm: "Campo",
      },
      {
        id: "22075",
        nm: "Camporrélls",
      },
      {
        id: "22076",
        nm: "Canal de Berdún",
      },
      {
        id: "22077",
        nm: "Candasnos",
      },
      {
        id: "22078",
        nm: "Canfranc",
      },
      {
        id: "22079",
        nm: "Capdesaso",
      },
      {
        id: "22080",
        nm: "Capella",
      },
      {
        id: "22081",
        nm: "Casbas de Huesca",
      },
      {
        id: "22083",
        nm: "Castejón de Monegros",
      },
      {
        id: "22084",
        nm: "Castejón de Sos",
      },
      {
        id: "22082",
        nm: "Castejón del Puente",
      },
      {
        id: "22085",
        nm: "Castelflorite",
      },
      {
        id: "22086",
        nm: "Castiello de Jaca",
      },
      {
        id: "22087",
        nm: "Castigaleu",
      },
      {
        id: "22088",
        nm: "Castillazuelo",
      },
      {
        id: "22089",
        nm: "Castillonroy",
      },
      {
        id: "22094",
        nm: "Chalamera",
      },
      {
        id: "22095",
        nm: "Chía",
      },
      {
        id: "22096",
        nm: "Chimillas",
      },
      {
        id: "22090",
        nm: "Colungo",
      },
      {
        id: "22099",
        nm: "Esplús",
      },
      {
        id: "22102",
        nm: "Estada",
      },
      {
        id: "22103",
        nm: "Estadilla",
      },
      {
        id: "22105",
        nm: "Estopiñán del Castillo",
      },
      {
        id: "22106",
        nm: "Fago",
      },
      {
        id: "22107",
        nm: "Fanlo",
      },
      {
        id: "22109",
        nm: "Fiscal",
      },
      {
        id: "22110",
        nm: "Fonz",
      },
      {
        id: "22111",
        nm: "Foradada del Toscar",
      },
      {
        id: "22112",
        nm: "Fraga",
      },
      {
        id: "22113",
        nm: "Fueva, La",
      },
      {
        id: "22114",
        nm: "Gistaín",
      },
      {
        id: "22115",
        nm: "Grado, El",
      },
      {
        id: "22116",
        nm: "Grañén",
      },
      {
        id: "22117",
        nm: "Graus",
      },
      {
        id: "22119",
        nm: "Gurrea de Gállego",
      },
      {
        id: "22122",
        nm: "Hoz de Jaca",
      },
      {
        id: "22908",
        nm: "Hoz y Costean",
      },
      {
        id: "22124",
        nm: "Huerto",
      },
      {
        id: "22125",
        nm: "Huesca",
      },
      {
        id: "22126",
        nm: "Ibieca",
      },
      {
        id: "22127",
        nm: "Igriés",
      },
      {
        id: "22128",
        nm: "Ilche",
      },
      {
        id: "22129",
        nm: "Isábena",
      },
      {
        id: "22130",
        nm: "Jaca",
      },
      {
        id: "22131",
        nm: "Jasa",
      },
      {
        id: "22133",
        nm: "Labuerda",
      },
      {
        id: "22135",
        nm: "Laluenga",
      },
      {
        id: "22136",
        nm: "Lalueza",
      },
      {
        id: "22137",
        nm: "Lanaja",
      },
      {
        id: "22139",
        nm: "Laperdiguera",
      },
      {
        id: "22141",
        nm: "Lascellas-Ponzano",
      },
      {
        id: "22142",
        nm: "Lascuarre",
      },
      {
        id: "22143",
        nm: "Laspaúles",
      },
      {
        id: "22144",
        nm: "Laspuña",
      },
      {
        id: "22149",
        nm: "Loarre",
      },
      {
        id: "22150",
        nm: "Loporzano",
      },
      {
        id: "22151",
        nm: "Loscorrales",
      },
      {
        id: "22905",
        nm: "Lupiñén-Ortilla",
      },
      {
        id: "22155",
        nm: "Monesma y Cajigar",
      },
      {
        id: "22156",
        nm: "Monflorite-Lascasas",
      },
      {
        id: "22157",
        nm: "Montanuy",
      },
      {
        id: "22158",
        nm: "Monzón",
      },
      {
        id: "22160",
        nm: "Naval",
      },
      {
        id: "22162",
        nm: "Novales",
      },
      {
        id: "22163",
        nm: "Nueno",
      },
      {
        id: "22164",
        nm: "Olvena",
      },
      {
        id: "22165",
        nm: "Ontiñena",
      },
      {
        id: "22167",
        nm: "Osso de Cinca",
      },
      {
        id: "22168",
        nm: "Palo",
      },
      {
        id: "22170",
        nm: "Panticosa",
      },
      {
        id: "22172",
        nm: "Peñalba",
      },
      {
        id: "22173",
        nm: "Peñas de Riglos, Las",
      },
      {
        id: "22174",
        nm: "Peralta de Alcofea",
      },
      {
        id: "22175",
        nm: "Peralta de Calasanz",
      },
      {
        id: "22176",
        nm: "Peraltilla",
      },
      {
        id: "22177",
        nm: "Perarrúa",
      },
      {
        id: "22178",
        nm: "Pertusa",
      },
      {
        id: "22181",
        nm: "Piracés",
      },
      {
        id: "22182",
        nm: "Plan",
      },
      {
        id: "22184",
        nm: "Poleñino",
      },
      {
        id: "22186",
        nm: "Pozán de Vero",
      },
      {
        id: "22187",
        nm: "Puebla de Castro, La",
      },
      {
        id: "22188",
        nm: "Puente de Montañana",
      },
      {
        id: "22902",
        nm: "Puente la Reina de Jaca",
      },
      {
        id: "22189",
        nm: "Puértolas",
      },
      {
        id: "22190",
        nm: "Pueyo de Araguás, El",
      },
      {
        id: "22193",
        nm: "Pueyo de Santa Cruz",
      },
      {
        id: "22195",
        nm: "Quicena",
      },
      {
        id: "22197",
        nm: "Robres",
      },
      {
        id: "22199",
        nm: "Sabiñánigo",
      },
      {
        id: "22200",
        nm: "Sahún",
      },
      {
        id: "22201",
        nm: "Salas Altas",
      },
      {
        id: "22202",
        nm: "Salas Bajas",
      },
      {
        id: "22203",
        nm: "Salillas",
      },
      {
        id: "22204",
        nm: "Sallent de Gállego",
      },
      {
        id: "22205",
        nm: "San Esteban de Litera",
      },
      {
        id: "22207",
        nm: "San Juan de Plan",
      },
      {
        id: "22903",
        nm: "San Miguel del Cinca",
      },
      {
        id: "22206",
        nm: "Sangarrén",
      },
      {
        id: "22208",
        nm: "Santa Cilia",
      },
      {
        id: "22209",
        nm: "Santa Cruz de la Serós",
      },
      {
        id: "22906",
        nm: "Santa María de Dulcis",
      },
      {
        id: "22212",
        nm: "Santaliestra y San Quílez",
      },
      {
        id: "22213",
        nm: "Sariñena",
      },
      {
        id: "22214",
        nm: "Secastilla",
      },
      {
        id: "22215",
        nm: "Seira",
      },
      {
        id: "22217",
        nm: "Sena",
      },
      {
        id: "22218",
        nm: "Senés de Alcubierre",
      },
      {
        id: "22220",
        nm: "Sesa",
      },
      {
        id: "22221",
        nm: "Sesué",
      },
      {
        id: "22222",
        nm: "Siétamo",
      },
      {
        id: "22223",
        nm: "Sopeira",
      },
      {
        id: "22904",
        nm: "Sotonera, La",
      },
      {
        id: "22225",
        nm: "Tamarite de Litera",
      },
      {
        id: "22226",
        nm: "Tardienta",
      },
      {
        id: "22227",
        nm: "Tella-Sin",
      },
      {
        id: "22228",
        nm: "Tierz",
      },
      {
        id: "22229",
        nm: "Tolva",
      },
      {
        id: "22230",
        nm: "Torla-Ordesa",
      },
      {
        id: "22232",
        nm: "Torralba de Aragón",
      },
      {
        id: "22233",
        nm: "Torre la Ribera",
      },
      {
        id: "22234",
        nm: "Torrente de Cinca",
      },
      {
        id: "22235",
        nm: "Torres de Alcanadre",
      },
      {
        id: "22236",
        nm: "Torres de Barbués",
      },
      {
        id: "22239",
        nm: "Tramaced",
      },
      {
        id: "22242",
        nm: "Valfarta",
      },
      {
        id: "22243",
        nm: "Valle de Bardají",
      },
      {
        id: "22901",
        nm: "Valle de Hecho",
      },
      {
        id: "22244",
        nm: "Valle de Lierp",
      },
      {
        id: "22245",
        nm: "Velilla de Cinca",
      },
      {
        id: "22909",
        nm: "Vencillón",
      },
      {
        id: "22247",
        nm: "Viacamp y Litera",
      },
      {
        id: "22248",
        nm: "Vicién",
      },
      {
        id: "22249",
        nm: "Villanova",
      },
      {
        id: "22250",
        nm: "Villanúa",
      },
      {
        id: "22251",
        nm: "Villanueva de Sigena",
      },
      {
        id: "22252",
        nm: "Yebra de Basa",
      },
      {
        id: "22253",
        nm: "Yésero",
      },
      {
        id: "22254",
        nm: "Zaidín",
      },
      {
        id: "23001",
        nm: "Albanchez de Mágina",
      },
      {
        id: "23002",
        nm: "Alcalá la Real",
      },
      {
        id: "23003",
        nm: "Alcaudete",
      },
      {
        id: "23004",
        nm: "Aldeaquemada",
      },
      {
        id: "23005",
        nm: "Andújar",
      },
      {
        id: "23006",
        nm: "Arjona",
      },
      {
        id: "23007",
        nm: "Arjonilla",
      },
      {
        id: "23008",
        nm: "Arquillos",
      },
      {
        id: "23905",
        nm: "Arroyo del Ojanco",
      },
      {
        id: "23009",
        nm: "Baeza",
      },
      {
        id: "23010",
        nm: "Bailén",
      },
      {
        id: "23011",
        nm: "Baños de la Encina",
      },
      {
        id: "23012",
        nm: "Beas de Segura",
      },
      {
        id: "23902",
        nm: "Bedmar y Garcíez",
      },
      {
        id: "23014",
        nm: "Begíjar",
      },
      {
        id: "23015",
        nm: "Bélmez de la Moraleda",
      },
      {
        id: "23016",
        nm: "Benatae",
      },
      {
        id: "23017",
        nm: "Cabra del Santo Cristo",
      },
      {
        id: "23018",
        nm: "Cambil",
      },
      {
        id: "23019",
        nm: "Campillo de Arenas",
      },
      {
        id: "23020",
        nm: "Canena",
      },
      {
        id: "23021",
        nm: "Carboneros",
      },
      {
        id: "23901",
        nm: "Cárcheles",
      },
      {
        id: "23024",
        nm: "Carolina, La",
      },
      {
        id: "23025",
        nm: "Castellar",
      },
      {
        id: "23026",
        nm: "Castillo de Locubín",
      },
      {
        id: "23027",
        nm: "Cazalilla",
      },
      {
        id: "23028",
        nm: "Cazorla",
      },
      {
        id: "23029",
        nm: "Chiclana de Segura",
      },
      {
        id: "23030",
        nm: "Chilluévar",
      },
      {
        id: "23031",
        nm: "Escañuela",
      },
      {
        id: "23032",
        nm: "Espelúy",
      },
      {
        id: "23033",
        nm: "Frailes",
      },
      {
        id: "23034",
        nm: "Fuensanta de Martos",
      },
      {
        id: "23035",
        nm: "Fuerte del Rey",
      },
      {
        id: "23037",
        nm: "Génave",
      },
      {
        id: "23038",
        nm: "Guardia de Jaén, La",
      },
      {
        id: "23039",
        nm: "Guarromán",
      },
      {
        id: "23041",
        nm: "Higuera de Calatrava",
      },
      {
        id: "23042",
        nm: "Hinojares",
      },
      {
        id: "23043",
        nm: "Hornos",
      },
      {
        id: "23044",
        nm: "Huelma",
      },
      {
        id: "23045",
        nm: "Huesa",
      },
      {
        id: "23046",
        nm: "Ibros",
      },
      {
        id: "23047",
        nm: "Iruela, La",
      },
      {
        id: "23048",
        nm: "Iznatoraf",
      },
      {
        id: "23049",
        nm: "Jabalquinto",
      },
      {
        id: "23050",
        nm: "Jaén",
      },
      {
        id: "23051",
        nm: "Jamilena",
      },
      {
        id: "23052",
        nm: "Jimena",
      },
      {
        id: "23053",
        nm: "Jódar",
      },
      {
        id: "23040",
        nm: "Lahiguera",
      },
      {
        id: "23054",
        nm: "Larva",
      },
      {
        id: "23055",
        nm: "Linares",
      },
      {
        id: "23056",
        nm: "Lopera",
      },
      {
        id: "23057",
        nm: "Lupión",
      },
      {
        id: "23058",
        nm: "Mancha Real",
      },
      {
        id: "23059",
        nm: "Marmolejo",
      },
      {
        id: "23060",
        nm: "Martos",
      },
      {
        id: "23061",
        nm: "Mengíbar",
      },
      {
        id: "23062",
        nm: "Montizón",
      },
      {
        id: "23063",
        nm: "Navas de San Juan",
      },
      {
        id: "23064",
        nm: "Noalejo",
      },
      {
        id: "23065",
        nm: "Orcera",
      },
      {
        id: "23066",
        nm: "Peal de Becerro",
      },
      {
        id: "23067",
        nm: "Pegalajar",
      },
      {
        id: "23069",
        nm: "Porcuna",
      },
      {
        id: "23070",
        nm: "Pozo Alcón",
      },
      {
        id: "23071",
        nm: "Puente de Génave",
      },
      {
        id: "23072",
        nm: "Puerta de Segura, La",
      },
      {
        id: "23073",
        nm: "Quesada",
      },
      {
        id: "23074",
        nm: "Rus",
      },
      {
        id: "23075",
        nm: "Sabiote",
      },
      {
        id: "23076",
        nm: "Santa Elena",
      },
      {
        id: "23077",
        nm: "Santiago de Calatrava",
      },
      {
        id: "23904",
        nm: "Santiago-Pontones",
      },
      {
        id: "23079",
        nm: "Santisteban del Puerto",
      },
      {
        id: "23080",
        nm: "Santo Tomé",
      },
      {
        id: "23081",
        nm: "Segura de la Sierra",
      },
      {
        id: "23082",
        nm: "Siles",
      },
      {
        id: "23084",
        nm: "Sorihuela del Guadalimar",
      },
      {
        id: "23085",
        nm: "Torreblascopedro",
      },
      {
        id: "23086",
        nm: "Torredelcampo",
      },
      {
        id: "23087",
        nm: "Torredonjimeno",
      },
      {
        id: "23088",
        nm: "Torreperogil",
      },
      {
        id: "23090",
        nm: "Torres",
      },
      {
        id: "23091",
        nm: "Torres de Albánchez",
      },
      {
        id: "23092",
        nm: "Úbeda",
      },
      {
        id: "23093",
        nm: "Valdepeñas de Jaén",
      },
      {
        id: "23094",
        nm: "Vilches",
      },
      {
        id: "23095",
        nm: "Villacarrillo",
      },
      {
        id: "23096",
        nm: "Villanueva de la Reina",
      },
      {
        id: "23097",
        nm: "Villanueva del Arzobispo",
      },
      {
        id: "23098",
        nm: "Villardompardo",
      },
      {
        id: "23099",
        nm: "Villares, Los",
      },
      {
        id: "23101",
        nm: "Villarrodrigo",
      },
      {
        id: "23903",
        nm: "Villatorres",
      },
      {
        id: "24001",
        nm: "Acebedo",
      },
      {
        id: "24002",
        nm: "Algadefe",
      },
      {
        id: "24003",
        nm: "Alija del Infantado",
      },
      {
        id: "24004",
        nm: "Almanza",
      },
      {
        id: "24005",
        nm: "Antigua, La",
      },
      {
        id: "24006",
        nm: "Ardón",
      },
      {
        id: "24007",
        nm: "Arganza",
      },
      {
        id: "24008",
        nm: "Astorga",
      },
      {
        id: "24009",
        nm: "Balboa",
      },
      {
        id: "24010",
        nm: "Bañeza, La",
      },
      {
        id: "24011",
        nm: "Barjas",
      },
      {
        id: "24012",
        nm: "Barrios de Luna, Los",
      },
      {
        id: "24014",
        nm: "Bembibre",
      },
      {
        id: "24015",
        nm: "Benavides",
      },
      {
        id: "24016",
        nm: "Benuza",
      },
      {
        id: "24017",
        nm: "Bercianos del Páramo",
      },
      {
        id: "24018",
        nm: "Bercianos del Real Camino",
      },
      {
        id: "24019",
        nm: "Berlanga del Bierzo",
      },
      {
        id: "24020",
        nm: "Boca de Huérgano",
      },
      {
        id: "24021",
        nm: "Boñar",
      },
      {
        id: "24022",
        nm: "Borrenes",
      },
      {
        id: "24023",
        nm: "Brazuelo",
      },
      {
        id: "24024",
        nm: "Burgo Ranero, El",
      },
      {
        id: "24025",
        nm: "Burón",
      },
      {
        id: "24026",
        nm: "Bustillo del Páramo",
      },
      {
        id: "24027",
        nm: "Cabañas Raras",
      },
      {
        id: "24028",
        nm: "Cabreros del Río",
      },
      {
        id: "24029",
        nm: "Cabrillanes",
      },
      {
        id: "24030",
        nm: "Cacabelos",
      },
      {
        id: "24031",
        nm: "Calzada del Coto",
      },
      {
        id: "24032",
        nm: "Campazas",
      },
      {
        id: "24033",
        nm: "Campo de Villavidel",
      },
      {
        id: "24034",
        nm: "Camponaraya",
      },
      {
        id: "24036",
        nm: "Candín",
      },
      {
        id: "24037",
        nm: "Cármenes",
      },
      {
        id: "24038",
        nm: "Carracedelo",
      },
      {
        id: "24039",
        nm: "Carrizo",
      },
      {
        id: "24040",
        nm: "Carrocera",
      },
      {
        id: "24041",
        nm: "Carucedo",
      },
      {
        id: "24042",
        nm: "Castilfalé",
      },
      {
        id: "24043",
        nm: "Castrillo de Cabrera",
      },
      {
        id: "24044",
        nm: "Castrillo de la Valduerna",
      },
      {
        id: "24046",
        nm: "Castrocalbón",
      },
      {
        id: "24047",
        nm: "Castrocontrigo",
      },
      {
        id: "24049",
        nm: "Castropodame",
      },
      {
        id: "24050",
        nm: "Castrotierra de Valmadrigal",
      },
      {
        id: "24051",
        nm: "Cea",
      },
      {
        id: "24052",
        nm: "Cebanico",
      },
      {
        id: "24053",
        nm: "Cebrones del Río",
      },
      {
        id: "24065",
        nm: "Chozas de Abajo",
      },
      {
        id: "24054",
        nm: "Cimanes de la Vega",
      },
      {
        id: "24055",
        nm: "Cimanes del Tejar",
      },
      {
        id: "24056",
        nm: "Cistierna",
      },
      {
        id: "24057",
        nm: "Congosto",
      },
      {
        id: "24058",
        nm: "Corbillos de los Oteros",
      },
      {
        id: "24059",
        nm: "Corullón",
      },
      {
        id: "24060",
        nm: "Crémenes",
      },
      {
        id: "24061",
        nm: "Cuadros",
      },
      {
        id: "24062",
        nm: "Cubillas de los Oteros",
      },
      {
        id: "24063",
        nm: "Cubillas de Rueda",
      },
      {
        id: "24064",
        nm: "Cubillos del Sil",
      },
      {
        id: "24066",
        nm: "Destriana",
      },
      {
        id: "24067",
        nm: "Encinedo",
      },
      {
        id: "24068",
        nm: "Ercina, La",
      },
      {
        id: "24069",
        nm: "Escobar de Campos",
      },
      {
        id: "24070",
        nm: "Fabero",
      },
      {
        id: "24071",
        nm: "Folgoso de la Ribera",
      },
      {
        id: "24073",
        nm: "Fresno de la Vega",
      },
      {
        id: "24074",
        nm: "Fuentes de Carbajal",
      },
      {
        id: "24076",
        nm: "Garrafe de Torío",
      },
      {
        id: "24077",
        nm: "Gordaliza del Pino",
      },
      {
        id: "24078",
        nm: "Gordoncillo",
      },
      {
        id: "24079",
        nm: "Gradefes",
      },
      {
        id: "24080",
        nm: "Grajal de Campos",
      },
      {
        id: "24081",
        nm: "Gusendos de los Oteros",
      },
      {
        id: "24082",
        nm: "Hospital de Órbigo",
      },
      {
        id: "24083",
        nm: "Igüeña",
      },
      {
        id: "24084",
        nm: "Izagre",
      },
      {
        id: "24086",
        nm: "Joarilla de las Matas",
      },
      {
        id: "24087",
        nm: "Laguna Dalga",
      },
      {
        id: "24088",
        nm: "Laguna de Negrillos",
      },
      {
        id: "24089",
        nm: "León",
      },
      {
        id: "24092",
        nm: "Llamas de la Ribera",
      },
      {
        id: "24090",
        nm: "Lucillo",
      },
      {
        id: "24091",
        nm: "Luyego",
      },
      {
        id: "24093",
        nm: "Magaz de Cepeda",
      },
      {
        id: "24094",
        nm: "Mansilla de las Mulas",
      },
      {
        id: "24095",
        nm: "Mansilla Mayor",
      },
      {
        id: "24096",
        nm: "Maraña",
      },
      {
        id: "24097",
        nm: "Matadeón de los Oteros",
      },
      {
        id: "24098",
        nm: "Matallana de Torío",
      },
      {
        id: "24099",
        nm: "Matanza",
      },
      {
        id: "24100",
        nm: "Molinaseca",
      },
      {
        id: "24101",
        nm: "Murias de Paredes",
      },
      {
        id: "24102",
        nm: "Noceda del Bierzo",
      },
      {
        id: "24103",
        nm: "Oencia",
      },
      {
        id: "24104",
        nm: "Omañas, Las",
      },
      {
        id: "24105",
        nm: "Onzonilla",
      },
      {
        id: "24106",
        nm: "Oseja de Sajambre",
      },
      {
        id: "24107",
        nm: "Pajares de los Oteros",
      },
      {
        id: "24108",
        nm: "Palacios de la Valduerna",
      },
      {
        id: "24109",
        nm: "Palacios del Sil",
      },
      {
        id: "24110",
        nm: "Páramo del Sil",
      },
      {
        id: "24112",
        nm: "Peranzanes",
      },
      {
        id: "24113",
        nm: "Pobladura de Pelayo García",
      },
      {
        id: "24114",
        nm: "Pola de Gordón, La",
      },
      {
        id: "24115",
        nm: "Ponferrada",
      },
      {
        id: "24116",
        nm: "Posada de Valdeón",
      },
      {
        id: "24117",
        nm: "Pozuelo del Páramo",
      },
      {
        id: "24118",
        nm: "Prado de la Guzpeña",
      },
      {
        id: "24119",
        nm: "Priaranza del Bierzo",
      },
      {
        id: "24120",
        nm: "Prioro",
      },
      {
        id: "24121",
        nm: "Puebla de Lillo",
      },
      {
        id: "24122",
        nm: "Puente de Domingo Flórez",
      },
      {
        id: "24123",
        nm: "Quintana del Castillo",
      },
      {
        id: "24124",
        nm: "Quintana del Marco",
      },
      {
        id: "24125",
        nm: "Quintana y Congosto",
      },
      {
        id: "24127",
        nm: "Regueras de Arriba",
      },
      {
        id: "24129",
        nm: "Reyero",
      },
      {
        id: "24130",
        nm: "Riaño",
      },
      {
        id: "24131",
        nm: "Riego de la Vega",
      },
      {
        id: "24132",
        nm: "Riello",
      },
      {
        id: "24133",
        nm: "Rioseco de Tapia",
      },
      {
        id: "24134",
        nm: "Robla, La",
      },
      {
        id: "24136",
        nm: "Roperuelos del Páramo",
      },
      {
        id: "24137",
        nm: "Sabero",
      },
      {
        id: "24139",
        nm: "Sahagún",
      },
      {
        id: "24141",
        nm: "San Adrián del Valle",
      },
      {
        id: "24142",
        nm: "San Andrés del Rabanedo",
      },
      {
        id: "24144",
        nm: "San Cristóbal de la Polantera",
      },
      {
        id: "24145",
        nm: "San Emiliano",
      },
      {
        id: "24146",
        nm: "San Esteban de Nogales",
      },
      {
        id: "24148",
        nm: "San Justo de la Vega",
      },
      {
        id: "24149",
        nm: "San Millán de los Caballeros",
      },
      {
        id: "24150",
        nm: "San Pedro Bercianos",
      },
      {
        id: "24143",
        nm: "Sancedo",
      },
      {
        id: "24151",
        nm: "Santa Colomba de Curueño",
      },
      {
        id: "24152",
        nm: "Santa Colomba de Somoza",
      },
      {
        id: "24153",
        nm: "Santa Cristina de Valmadrigal",
      },
      {
        id: "24154",
        nm: "Santa Elena de Jamuz",
      },
      {
        id: "24155",
        nm: "Santa María de la Isla",
      },
      {
        id: "24158",
        nm: "Santa María de Ordás",
      },
      {
        id: "24156",
        nm: "Santa María del Monte de Cea",
      },
      {
        id: "24157",
        nm: "Santa María del Páramo",
      },
      {
        id: "24159",
        nm: "Santa Marina del Rey",
      },
      {
        id: "24160",
        nm: "Santas Martas",
      },
      {
        id: "24161",
        nm: "Santiago Millas",
      },
      {
        id: "24162",
        nm: "Santovenia de la Valdoncina",
      },
      {
        id: "24163",
        nm: "Sariegos",
      },
      {
        id: "24164",
        nm: "Sena de Luna",
      },
      {
        id: "24165",
        nm: "Sobrado",
      },
      {
        id: "24166",
        nm: "Soto de la Vega",
      },
      {
        id: "24167",
        nm: "Soto y Amío",
      },
      {
        id: "24168",
        nm: "Toral de los Guzmanes",
      },
      {
        id: "24206",
        nm: "Toral de los Vados",
      },
      {
        id: "24169",
        nm: "Toreno",
      },
      {
        id: "24170",
        nm: "Torre del Bierzo",
      },
      {
        id: "24171",
        nm: "Trabadelo",
      },
      {
        id: "24172",
        nm: "Truchas",
      },
      {
        id: "24173",
        nm: "Turcia",
      },
      {
        id: "24174",
        nm: "Urdiales del Páramo",
      },
      {
        id: "24185",
        nm: "Val de San Lorenzo",
      },
      {
        id: "24175",
        nm: "Valdefresno",
      },
      {
        id: "24176",
        nm: "Valdefuentes del Páramo",
      },
      {
        id: "24177",
        nm: "Valdelugueros",
      },
      {
        id: "24178",
        nm: "Valdemora",
      },
      {
        id: "24179",
        nm: "Valdepiélago",
      },
      {
        id: "24180",
        nm: "Valdepolo",
      },
      {
        id: "24181",
        nm: "Valderas",
      },
      {
        id: "24182",
        nm: "Valderrey",
      },
      {
        id: "24183",
        nm: "Valderrueda",
      },
      {
        id: "24184",
        nm: "Valdesamario",
      },
      {
        id: "24187",
        nm: "Valdevimbre",
      },
      {
        id: "24188",
        nm: "Valencia de Don Juan",
      },
      {
        id: "24191",
        nm: "Vallecillo",
      },
      {
        id: "24189",
        nm: "Valverde de la Virgen",
      },
      {
        id: "24190",
        nm: "Valverde-Enrique",
      },
      {
        id: "24193",
        nm: "Vecilla, La",
      },
      {
        id: "24196",
        nm: "Vega de Espinareda",
      },
      {
        id: "24197",
        nm: "Vega de Infanzones",
      },
      {
        id: "24198",
        nm: "Vega de Valcarce",
      },
      {
        id: "24194",
        nm: "Vegacervera",
      },
      {
        id: "24199",
        nm: "Vegaquemada",
      },
      {
        id: "24201",
        nm: "Vegas del Condado",
      },
      {
        id: "24202",
        nm: "Villablino",
      },
      {
        id: "24203",
        nm: "Villabraz",
      },
      {
        id: "24205",
        nm: "Villadangos del Páramo",
      },
      {
        id: "24207",
        nm: "Villademor de la Vega",
      },
      {
        id: "24209",
        nm: "Villafranca del Bierzo",
      },
      {
        id: "24210",
        nm: "Villagatón",
      },
      {
        id: "24211",
        nm: "Villamandos",
      },
      {
        id: "24901",
        nm: "Villamanín",
      },
      {
        id: "24212",
        nm: "Villamañán",
      },
      {
        id: "24213",
        nm: "Villamartín de Don Sancho",
      },
      {
        id: "24214",
        nm: "Villamejil",
      },
      {
        id: "24215",
        nm: "Villamol",
      },
      {
        id: "24216",
        nm: "Villamontán de la Valduerna",
      },
      {
        id: "24217",
        nm: "Villamoratiel de las Matas",
      },
      {
        id: "24218",
        nm: "Villanueva de las Manzanas",
      },
      {
        id: "24219",
        nm: "Villaobispo de Otero",
      },
      {
        id: "24902",
        nm: "Villaornate y Castro",
      },
      {
        id: "24221",
        nm: "Villaquejida",
      },
      {
        id: "24222",
        nm: "Villaquilambre",
      },
      {
        id: "24223",
        nm: "Villarejo de Órbigo",
      },
      {
        id: "24224",
        nm: "Villares de Órbigo",
      },
      {
        id: "24225",
        nm: "Villasabariego",
      },
      {
        id: "24226",
        nm: "Villaselán",
      },
      {
        id: "24227",
        nm: "Villaturiel",
      },
      {
        id: "24228",
        nm: "Villazala",
      },
      {
        id: "24229",
        nm: "Villazanzo de Valderaduey",
      },
      {
        id: "24230",
        nm: "Zotes del Páramo",
      },
      {
        id: "25001",
        nm: "Abella de la Conca",
      },
      {
        id: "25002",
        nm: "Àger",
      },
      {
        id: "25003",
        nm: "Agramunt",
      },
      {
        id: "25038",
        nm: "Aitona",
      },
      {
        id: "25004",
        nm: "Alamús, Els",
      },
      {
        id: "25005",
        nm: "Alàs i Cerc",
      },
      {
        id: "25006",
        nm: "Albagés, L'",
      },
      {
        id: "25007",
        nm: "Albatàrrec",
      },
      {
        id: "25008",
        nm: "Albesa",
      },
      {
        id: "25009",
        nm: "Albi, L'",
      },
      {
        id: "25010",
        nm: "Alcanó",
      },
      {
        id: "25011",
        nm: "Alcarràs",
      },
      {
        id: "25012",
        nm: "Alcoletge",
      },
      {
        id: "25013",
        nm: "Alfarràs",
      },
      {
        id: "25014",
        nm: "Alfés",
      },
      {
        id: "25015",
        nm: "Algerri",
      },
      {
        id: "25016",
        nm: "Alguaire",
      },
      {
        id: "25017",
        nm: "Alins",
      },
      {
        id: "25019",
        nm: "Almacelles",
      },
      {
        id: "25020",
        nm: "Almatret",
      },
      {
        id: "25021",
        nm: "Almenar",
      },
      {
        id: "25022",
        nm: "Alòs de Balaguer",
      },
      {
        id: "25023",
        nm: "Alpicat",
      },
      {
        id: "25024",
        nm: "Alt Àneu",
      },
      {
        id: "25027",
        nm: "Anglesola",
      },
      {
        id: "25029",
        nm: "Arbeca",
      },
      {
        id: "25031",
        nm: "Arres",
      },
      {
        id: "25032",
        nm: "Arsèguel",
      },
      {
        id: "25033",
        nm: "Artesa de Lleida",
      },
      {
        id: "25034",
        nm: "Artesa de Segre",
      },
      {
        id: "25036",
        nm: "Aspa",
      },
      {
        id: "25037",
        nm: "Avellanes i Santa Linya, Les",
      },
      {
        id: "25039",
        nm: "Baix Pallars",
      },
      {
        id: "25040",
        nm: "Balaguer",
      },
      {
        id: "25041",
        nm: "Barbens",
      },
      {
        id: "25042",
        nm: "Baronia de Rialb, La",
      },
      {
        id: "25044",
        nm: "Bassella",
      },
      {
        id: "25045",
        nm: "Bausen",
      },
      {
        id: "25046",
        nm: "Belianes",
      },
      {
        id: "25170",
        nm: "Bellaguarda",
      },
      {
        id: "25047",
        nm: "Bellcaire d'Urgell",
      },
      {
        id: "25048",
        nm: "Bell-lloc d'Urgell",
      },
      {
        id: "25049",
        nm: "Bellmunt d'Urgell",
      },
      {
        id: "25050",
        nm: "Bellpuig",
      },
      {
        id: "25051",
        nm: "Bellver de Cerdanya",
      },
      {
        id: "25052",
        nm: "Bellvís",
      },
      {
        id: "25053",
        nm: "Benavent de Segrià",
      },
      {
        id: "25055",
        nm: "Biosca",
      },
      {
        id: "25057",
        nm: "Bòrdes, Es",
      },
      {
        id: "25058",
        nm: "Borges Blanques, Les",
      },
      {
        id: "25059",
        nm: "Bossòst",
      },
      {
        id: "25056",
        nm: "Bovera",
      },
      {
        id: "25060",
        nm: "Cabanabona",
      },
      {
        id: "25061",
        nm: "Cabó",
      },
      {
        id: "25062",
        nm: "Camarasa",
      },
      {
        id: "25063",
        nm: "Canejan",
      },
      {
        id: "25904",
        nm: "Castell de Mur",
      },
      {
        id: "25064",
        nm: "Castellar de la Ribera",
      },
      {
        id: "25067",
        nm: "Castelldans",
      },
      {
        id: "25068",
        nm: "Castellnou de Seana",
      },
      {
        id: "25069",
        nm: "Castelló de Farfanya",
      },
      {
        id: "25070",
        nm: "Castellserà",
      },
      {
        id: "25071",
        nm: "Cava",
      },
      {
        id: "25072",
        nm: "Cervera",
      },
      {
        id: "25073",
        nm: "Cervià de les Garrigues",
      },
      {
        id: "25074",
        nm: "Ciutadilla",
      },
      {
        id: "25075",
        nm: "Clariana de Cardener",
      },
      {
        id: "25076",
        nm: "Cogul, El",
      },
      {
        id: "25077",
        nm: "Coll de Nargó",
      },
      {
        id: "25163",
        nm: "Coma i la Pedra, La",
      },
      {
        id: "25161",
        nm: "Conca de Dalt",
      },
      {
        id: "25078",
        nm: "Corbins",
      },
      {
        id: "25079",
        nm: "Cubells",
      },
      {
        id: "25081",
        nm: "Espluga Calba, L'",
      },
      {
        id: "25082",
        nm: "Espot",
      },
      {
        id: "25088",
        nm: "Estamariu",
      },
      {
        id: "25085",
        nm: "Estaràs",
      },
      {
        id: "25086",
        nm: "Esterri d'Àneu",
      },
      {
        id: "25087",
        nm: "Esterri de Cardós",
      },
      {
        id: "25089",
        nm: "Farrera",
      },
      {
        id: "25908",
        nm: "Fígols i Alinyà",
      },
      {
        id: "25092",
        nm: "Floresta, La",
      },
      {
        id: "25093",
        nm: "Fondarella",
      },
      {
        id: "25094",
        nm: "Foradada",
      },
      {
        id: "25096",
        nm: "Fuliola, La",
      },
      {
        id: "25097",
        nm: "Fulleda",
      },
      {
        id: "25098",
        nm: "Gavet de la Conca",
      },
      {
        id: "25912",
        nm: "Gimenells i el Pla de la Font",
      },
      {
        id: "25099",
        nm: "Golmés",
      },
      {
        id: "25100",
        nm: "Gósol",
      },
      {
        id: "25101",
        nm: "Granadella, La",
      },
      {
        id: "25102",
        nm: "Granja d'Escarp, La",
      },
      {
        id: "25103",
        nm: "Granyanella",
      },
      {
        id: "25105",
        nm: "Granyena de les Garrigues",
      },
      {
        id: "25104",
        nm: "Granyena de Segarra",
      },
      {
        id: "25109",
        nm: "Guimerà",
      },
      {
        id: "25903",
        nm: "Guingueta d'Àneu, La",
      },
      {
        id: "25110",
        nm: "Guissona",
      },
      {
        id: "25111",
        nm: "Guixers",
      },
      {
        id: "25115",
        nm: "Isona i Conca Dellà",
      },
      {
        id: "25112",
        nm: "Ivars de Noguera",
      },
      {
        id: "25113",
        nm: "Ivars d'Urgell",
      },
      {
        id: "25114",
        nm: "Ivorra",
      },
      {
        id: "25910",
        nm: "Josa i Tuixén",
      },
      {
        id: "25118",
        nm: "Juncosa",
      },
      {
        id: "25119",
        nm: "Juneda",
      },
      {
        id: "25121",
        nm: "Les",
      },
      {
        id: "25122",
        nm: "Linyola",
      },
      {
        id: "25123",
        nm: "Lladorre",
      },
      {
        id: "25124",
        nm: "Lladurs",
      },
      {
        id: "25125",
        nm: "Llardecans",
      },
      {
        id: "25126",
        nm: "Llavorsí",
      },
      {
        id: "25120",
        nm: "Lleida",
      },
      {
        id: "25127",
        nm: "Lles de Cerdanya",
      },
      {
        id: "25128",
        nm: "Llimiana",
      },
      {
        id: "25129",
        nm: "Llobera",
      },
      {
        id: "25133",
        nm: "Maials",
      },
      {
        id: "25130",
        nm: "Maldà",
      },
      {
        id: "25131",
        nm: "Massalcoreig",
      },
      {
        id: "25132",
        nm: "Massoteres",
      },
      {
        id: "25134",
        nm: "Menàrguens",
      },
      {
        id: "25135",
        nm: "Miralcamp",
      },
      {
        id: "25137",
        nm: "Mollerussa",
      },
      {
        id: "25136",
        nm: "Molsosa, La",
      },
      {
        id: "25139",
        nm: "Montellà i Martinet",
      },
      {
        id: "25140",
        nm: "Montferrer i Castellbò",
      },
      {
        id: "25138",
        nm: "Montgai",
      },
      {
        id: "25142",
        nm: "Montoliu de Lleida",
      },
      {
        id: "25141",
        nm: "Montoliu de Segarra",
      },
      {
        id: "25143",
        nm: "Montornès de Segarra",
      },
      {
        id: "25145",
        nm: "Nalec",
      },
      {
        id: "25025",
        nm: "Naut Aran",
      },
      {
        id: "25146",
        nm: "Navès",
      },
      {
        id: "25148",
        nm: "Odèn",
      },
      {
        id: "25149",
        nm: "Oliana",
      },
      {
        id: "25150",
        nm: "Oliola",
      },
      {
        id: "25151",
        nm: "Olius",
      },
      {
        id: "25152",
        nm: "Oluges, Les",
      },
      {
        id: "25153",
        nm: "Omellons, Els",
      },
      {
        id: "25154",
        nm: "Omells de na Gaia, Els",
      },
      {
        id: "25155",
        nm: "Organyà",
      },
      {
        id: "25156",
        nm: "Os de Balaguer",
      },
      {
        id: "25157",
        nm: "Ossó de Sió",
      },
      {
        id: "25158",
        nm: "Palau d'Anglesola, El",
      },
      {
        id: "25164",
        nm: "Penelles",
      },
      {
        id: "25165",
        nm: "Peramola",
      },
      {
        id: "25166",
        nm: "Pinell de Solsonès",
      },
      {
        id: "25167",
        nm: "Pinós",
      },
      {
        id: "25911",
        nm: "Plans de Sió, Els",
      },
      {
        id: "25168",
        nm: "Poal, El",
      },
      {
        id: "25169",
        nm: "Pobla de Cérvoles, La",
      },
      {
        id: "25171",
        nm: "Pobla de Segur, La",
      },
      {
        id: "25030",
        nm: "Pont de Bar, El",
      },
      {
        id: "25173",
        nm: "Pont de Suert, El",
      },
      {
        id: "25172",
        nm: "Ponts",
      },
      {
        id: "25174",
        nm: "Portella, La",
      },
      {
        id: "25175",
        nm: "Prats i Sansor",
      },
      {
        id: "25176",
        nm: "Preixana",
      },
      {
        id: "25177",
        nm: "Preixens",
      },
      {
        id: "25179",
        nm: "Prullans",
      },
      {
        id: "25180",
        nm: "Puiggròs",
      },
      {
        id: "25181",
        nm: "Puigverd d'Agramunt",
      },
      {
        id: "25182",
        nm: "Puigverd de Lleida",
      },
      {
        id: "25183",
        nm: "Rialp",
      },
      {
        id: "25905",
        nm: "Ribera d'Ondara",
      },
      {
        id: "25185",
        nm: "Ribera d'Urgellet",
      },
      {
        id: "25186",
        nm: "Riner",
      },
      {
        id: "25913",
        nm: "Riu de Cerdanya",
      },
      {
        id: "25189",
        nm: "Rosselló",
      },
      {
        id: "25190",
        nm: "Salàs de Pallars",
      },
      {
        id: "25191",
        nm: "Sanaüja",
      },
      {
        id: "25196",
        nm: "Sant Esteve de la Sarga",
      },
      {
        id: "25192",
        nm: "Sant Guim de Freixenet",
      },
      {
        id: "25197",
        nm: "Sant Guim de la Plana",
      },
      {
        id: "25193",
        nm: "Sant Llorenç de Morunys",
      },
      {
        id: "25902",
        nm: "Sant Martí de Riucorb",
      },
      {
        id: "25194",
        nm: "Sant Ramon",
      },
      {
        id: "25201",
        nm: "Sarroca de Bellera",
      },
      {
        id: "25200",
        nm: "Sarroca de Lleida",
      },
      {
        id: "25202",
        nm: "Senterada",
      },
      {
        id: "25035",
        nm: "Sentiu de Sió, La",
      },
      {
        id: "25204",
        nm: "Seròs",
      },
      {
        id: "25203",
        nm: "Seu d'Urgell, La",
      },
      {
        id: "25205",
        nm: "Sidamon",
      },
      {
        id: "25206",
        nm: "Soleràs, El",
      },
      {
        id: "25207",
        nm: "Solsona",
      },
      {
        id: "25208",
        nm: "Soriguera",
      },
      {
        id: "25209",
        nm: "Sort",
      },
      {
        id: "25210",
        nm: "Soses",
      },
      {
        id: "25211",
        nm: "Sudanell",
      },
      {
        id: "25212",
        nm: "Sunyer",
      },
      {
        id: "25215",
        nm: "Talarn",
      },
      {
        id: "25216",
        nm: "Talavera",
      },
      {
        id: "25217",
        nm: "Tàrrega",
      },
      {
        id: "25218",
        nm: "Tarrés",
      },
      {
        id: "25219",
        nm: "Tarroja de Segarra",
      },
      {
        id: "25220",
        nm: "Térmens",
      },
      {
        id: "25221",
        nm: "Tírvia",
      },
      {
        id: "25222",
        nm: "Tiurana",
      },
      {
        id: "25223",
        nm: "Torà",
      },
      {
        id: "25224",
        nm: "Torms, Els",
      },
      {
        id: "25225",
        nm: "Tornabous",
      },
      {
        id: "25227",
        nm: "Torre de Cabdella, La",
      },
      {
        id: "25226",
        nm: "Torrebesses",
      },
      {
        id: "25228",
        nm: "Torrefarrera",
      },
      {
        id: "25907",
        nm: "Torrefeta i Florejacs",
      },
      {
        id: "25230",
        nm: "Torregrossa",
      },
      {
        id: "25231",
        nm: "Torrelameu",
      },
      {
        id: "25232",
        nm: "Torres de Segre",
      },
      {
        id: "25233",
        nm: "Torre-serona",
      },
      {
        id: "25234",
        nm: "Tremp",
      },
      {
        id: "25043",
        nm: "Vall de Boí, La",
      },
      {
        id: "25901",
        nm: "Vall de Cardós",
      },
      {
        id: "25238",
        nm: "Vallbona de les Monges",
      },
      {
        id: "25240",
        nm: "Vallfogona de Balaguer",
      },
      {
        id: "25906",
        nm: "Valls d'Aguilar, Les",
      },
      {
        id: "25239",
        nm: "Valls de Valira, Les",
      },
      {
        id: "25909",
        nm: "Vansa i Fórnols, La",
      },
      {
        id: "25242",
        nm: "Verdú",
      },
      {
        id: "25243",
        nm: "Vielha e Mijaran",
      },
      {
        id: "25244",
        nm: "Vilagrassa",
      },
      {
        id: "25245",
        nm: "Vilaller",
      },
      {
        id: "25247",
        nm: "Vilamòs",
      },
      {
        id: "25248",
        nm: "Vilanova de Bellpuig",
      },
      {
        id: "25254",
        nm: "Vilanova de la Barca",
      },
      {
        id: "25249",
        nm: "Vilanova de l'Aguda",
      },
      {
        id: "25250",
        nm: "Vilanova de Meià",
      },
      {
        id: "25251",
        nm: "Vilanova de Segrià",
      },
      {
        id: "25252",
        nm: "Vila-sana",
      },
      {
        id: "25253",
        nm: "Vilosell, El",
      },
      {
        id: "25255",
        nm: "Vinaixa",
      },
      {
        id: "26001",
        nm: "Ábalos",
      },
      {
        id: "26002",
        nm: "Agoncillo",
      },
      {
        id: "26003",
        nm: "Aguilar del Río Alhama",
      },
      {
        id: "26004",
        nm: "Ajamil de Cameros",
      },
      {
        id: "26005",
        nm: "Albelda de Iregua",
      },
      {
        id: "26006",
        nm: "Alberite",
      },
      {
        id: "26007",
        nm: "Alcanadre",
      },
      {
        id: "26008",
        nm: "Aldeanueva de Ebro",
      },
      {
        id: "26009",
        nm: "Alesanco",
      },
      {
        id: "26010",
        nm: "Alesón",
      },
      {
        id: "26011",
        nm: "Alfaro",
      },
      {
        id: "26012",
        nm: "Almarza de Cameros",
      },
      {
        id: "26013",
        nm: "Anguciana",
      },
      {
        id: "26014",
        nm: "Anguiano",
      },
      {
        id: "26015",
        nm: "Arenzana de Abajo",
      },
      {
        id: "26016",
        nm: "Arenzana de Arriba",
      },
      {
        id: "26017",
        nm: "Arnedillo",
      },
      {
        id: "26018",
        nm: "Arnedo",
      },
      {
        id: "26019",
        nm: "Arrúbal",
      },
      {
        id: "26020",
        nm: "Ausejo",
      },
      {
        id: "26021",
        nm: "Autol",
      },
      {
        id: "26022",
        nm: "Azofra",
      },
      {
        id: "26023",
        nm: "Badarán",
      },
      {
        id: "26024",
        nm: "Bañares",
      },
      {
        id: "26026",
        nm: "Baños de Río Tobía",
      },
      {
        id: "26025",
        nm: "Baños de Rioja",
      },
      {
        id: "26027",
        nm: "Berceo",
      },
      {
        id: "26028",
        nm: "Bergasa",
      },
      {
        id: "26029",
        nm: "Bergasillas Bajera",
      },
      {
        id: "26030",
        nm: "Bezares",
      },
      {
        id: "26031",
        nm: "Bobadilla",
      },
      {
        id: "26032",
        nm: "Brieva de Cameros",
      },
      {
        id: "26033",
        nm: "Briñas",
      },
      {
        id: "26034",
        nm: "Briones",
      },
      {
        id: "26035",
        nm: "Cabezón de Cameros",
      },
      {
        id: "26036",
        nm: "Calahorra",
      },
      {
        id: "26037",
        nm: "Camprovín",
      },
      {
        id: "26038",
        nm: "Canales de la Sierra",
      },
      {
        id: "26039",
        nm: "Canillas de Río Tuerto",
      },
      {
        id: "26040",
        nm: "Cañas",
      },
      {
        id: "26041",
        nm: "Cárdenas",
      },
      {
        id: "26042",
        nm: "Casalarreina",
      },
      {
        id: "26043",
        nm: "Castañares de Rioja",
      },
      {
        id: "26044",
        nm: "Castroviejo",
      },
      {
        id: "26045",
        nm: "Cellorigo",
      },
      {
        id: "26046",
        nm: "Cenicero",
      },
      {
        id: "26047",
        nm: "Cervera del Río Alhama",
      },
      {
        id: "26048",
        nm: "Cidamón",
      },
      {
        id: "26049",
        nm: "Cihuri",
      },
      {
        id: "26050",
        nm: "Cirueña",
      },
      {
        id: "26051",
        nm: "Clavijo",
      },
      {
        id: "26052",
        nm: "Cordovín",
      },
      {
        id: "26053",
        nm: "Corera",
      },
      {
        id: "26054",
        nm: "Cornago",
      },
      {
        id: "26055",
        nm: "Corporales",
      },
      {
        id: "26056",
        nm: "Cuzcurrita de Río Tirón",
      },
      {
        id: "26057",
        nm: "Daroca de Rioja",
      },
      {
        id: "26058",
        nm: "Enciso",
      },
      {
        id: "26059",
        nm: "Entrena",
      },
      {
        id: "26060",
        nm: "Estollo",
      },
      {
        id: "26061",
        nm: "Ezcaray",
      },
      {
        id: "26062",
        nm: "Foncea",
      },
      {
        id: "26063",
        nm: "Fonzaleche",
      },
      {
        id: "26064",
        nm: "Fuenmayor",
      },
      {
        id: "26065",
        nm: "Galbárruli",
      },
      {
        id: "26066",
        nm: "Galilea",
      },
      {
        id: "26067",
        nm: "Gallinero de Cameros",
      },
      {
        id: "26068",
        nm: "Gimileo",
      },
      {
        id: "26069",
        nm: "Grañón",
      },
      {
        id: "26070",
        nm: "Grávalos",
      },
      {
        id: "26071",
        nm: "Haro",
      },
      {
        id: "26072",
        nm: "Herce",
      },
      {
        id: "26073",
        nm: "Herramélluri",
      },
      {
        id: "26074",
        nm: "Hervías",
      },
      {
        id: "26075",
        nm: "Hormilla",
      },
      {
        id: "26076",
        nm: "Hormilleja",
      },
      {
        id: "26077",
        nm: "Hornillos de Cameros",
      },
      {
        id: "26078",
        nm: "Hornos de Moncalvillo",
      },
      {
        id: "26079",
        nm: "Huércanos",
      },
      {
        id: "26080",
        nm: "Igea",
      },
      {
        id: "26081",
        nm: "Jalón de Cameros",
      },
      {
        id: "26082",
        nm: "Laguna de Cameros",
      },
      {
        id: "26083",
        nm: "Lagunilla del Jubera",
      },
      {
        id: "26084",
        nm: "Lardero",
      },
      {
        id: "26086",
        nm: "Ledesma de la Cogolla",
      },
      {
        id: "26087",
        nm: "Leiva",
      },
      {
        id: "26088",
        nm: "Leza de Río Leza",
      },
      {
        id: "26089",
        nm: "Logroño",
      },
      {
        id: "26091",
        nm: "Lumbreras",
      },
      {
        id: "26092",
        nm: "Manjarrés",
      },
      {
        id: "26093",
        nm: "Mansilla de la Sierra",
      },
      {
        id: "26094",
        nm: "Manzanares de Rioja",
      },
      {
        id: "26095",
        nm: "Matute",
      },
      {
        id: "26096",
        nm: "Medrano",
      },
      {
        id: "26098",
        nm: "Munilla",
      },
      {
        id: "26099",
        nm: "Murillo de Río Leza",
      },
      {
        id: "26100",
        nm: "Muro de Aguas",
      },
      {
        id: "26101",
        nm: "Muro en Cameros",
      },
      {
        id: "26102",
        nm: "Nájera",
      },
      {
        id: "26103",
        nm: "Nalda",
      },
      {
        id: "26104",
        nm: "Navajún",
      },
      {
        id: "26105",
        nm: "Navarrete",
      },
      {
        id: "26106",
        nm: "Nestares",
      },
      {
        id: "26107",
        nm: "Nieva de Cameros",
      },
      {
        id: "26109",
        nm: "Ochánduri",
      },
      {
        id: "26108",
        nm: "Ocón",
      },
      {
        id: "26110",
        nm: "Ojacastro",
      },
      {
        id: "26111",
        nm: "Ollauri",
      },
      {
        id: "26112",
        nm: "Ortigosa de Cameros",
      },
      {
        id: "26113",
        nm: "Pazuengos",
      },
      {
        id: "26114",
        nm: "Pedroso",
      },
      {
        id: "26115",
        nm: "Pinillos",
      },
      {
        id: "26117",
        nm: "Pradejón",
      },
      {
        id: "26118",
        nm: "Pradillo",
      },
      {
        id: "26119",
        nm: "Préjano",
      },
      {
        id: "26120",
        nm: "Quel",
      },
      {
        id: "26121",
        nm: "Rabanera",
      },
      {
        id: "26122",
        nm: "Rasillo de Cameros, El",
      },
      {
        id: "26123",
        nm: "Redal, El",
      },
      {
        id: "26124",
        nm: "Ribafrecha",
      },
      {
        id: "26125",
        nm: "Rincón de Soto",
      },
      {
        id: "26126",
        nm: "Robres del Castillo",
      },
      {
        id: "26127",
        nm: "Rodezno",
      },
      {
        id: "26128",
        nm: "Sajazarra",
      },
      {
        id: "26129",
        nm: "San Asensio",
      },
      {
        id: "26130",
        nm: "San Millán de la Cogolla",
      },
      {
        id: "26131",
        nm: "San Millán de Yécora",
      },
      {
        id: "26132",
        nm: "San Román de Cameros",
      },
      {
        id: "26139",
        nm: "San Torcuato",
      },
      {
        id: "26142",
        nm: "San Vicente de la Sonsierra",
      },
      {
        id: "26134",
        nm: "Santa Coloma",
      },
      {
        id: "26135",
        nm: "Santa Engracia del Jubera",
      },
      {
        id: "26136",
        nm: "Santa Eulalia Bajera",
      },
      {
        id: "26138",
        nm: "Santo Domingo de la Calzada",
      },
      {
        id: "26140",
        nm: "Santurde de Rioja",
      },
      {
        id: "26141",
        nm: "Santurdejo",
      },
      {
        id: "26143",
        nm: "Sojuela",
      },
      {
        id: "26144",
        nm: "Sorzano",
      },
      {
        id: "26145",
        nm: "Sotés",
      },
      {
        id: "26146",
        nm: "Soto en Cameros",
      },
      {
        id: "26147",
        nm: "Terroba",
      },
      {
        id: "26148",
        nm: "Tirgo",
      },
      {
        id: "26149",
        nm: "Tobía",
      },
      {
        id: "26150",
        nm: "Tormantos",
      },
      {
        id: "26153",
        nm: "Torre en Cameros",
      },
      {
        id: "26151",
        nm: "Torrecilla en Cameros",
      },
      {
        id: "26152",
        nm: "Torrecilla sobre Alesanco",
      },
      {
        id: "26154",
        nm: "Torremontalbo",
      },
      {
        id: "26155",
        nm: "Treviana",
      },
      {
        id: "26157",
        nm: "Tricio",
      },
      {
        id: "26158",
        nm: "Tudelilla",
      },
      {
        id: "26160",
        nm: "Uruñuela",
      },
      {
        id: "26161",
        nm: "Valdemadera",
      },
      {
        id: "26162",
        nm: "Valgañón",
      },
      {
        id: "26163",
        nm: "Ventosa",
      },
      {
        id: "26164",
        nm: "Ventrosa",
      },
      {
        id: "26165",
        nm: "Viguera",
      },
      {
        id: "26166",
        nm: "Villalba de Rioja",
      },
      {
        id: "26167",
        nm: "Villalobar de Rioja",
      },
      {
        id: "26168",
        nm: "Villamediana de Iregua",
      },
      {
        id: "26169",
        nm: "Villanueva de Cameros",
      },
      {
        id: "26170",
        nm: "Villar de Arnedo, El",
      },
      {
        id: "26171",
        nm: "Villar de Torre",
      },
      {
        id: "26172",
        nm: "Villarejo",
      },
      {
        id: "26173",
        nm: "Villarroya",
      },
      {
        id: "26174",
        nm: "Villarta-Quintana",
      },
      {
        id: "26175",
        nm: "Villavelayo",
      },
      {
        id: "26176",
        nm: "Villaverde de Rioja",
      },
      {
        id: "26177",
        nm: "Villoslada de Cameros",
      },
      {
        id: "26178",
        nm: "Viniegra de Abajo",
      },
      {
        id: "26179",
        nm: "Viniegra de Arriba",
      },
      {
        id: "26180",
        nm: "Zarratón",
      },
      {
        id: "26181",
        nm: "Zarzosa",
      },
      {
        id: "26183",
        nm: "Zorraquín",
      },
      {
        id: "27001",
        nm: "Abadín",
      },
      {
        id: "27002",
        nm: "Alfoz",
      },
      {
        id: "27003",
        nm: "Antas de Ulla",
      },
      {
        id: "27004",
        nm: "Baleira",
      },
      {
        id: "27901",
        nm: "Baralla",
      },
      {
        id: "27005",
        nm: "Barreiros",
      },
      {
        id: "27006",
        nm: "Becerreá",
      },
      {
        id: "27007",
        nm: "Begonte",
      },
      {
        id: "27008",
        nm: "Bóveda",
      },
      {
        id: "27902",
        nm: "Burela",
      },
      {
        id: "27009",
        nm: "Carballedo",
      },
      {
        id: "27010",
        nm: "Castro de Rei",
      },
      {
        id: "27011",
        nm: "Castroverde",
      },
      {
        id: "27012",
        nm: "Cervantes",
      },
      {
        id: "27013",
        nm: "Cervo",
      },
      {
        id: "27016",
        nm: "Chantada",
      },
      {
        id: "27014",
        nm: "Corgo, O",
      },
      {
        id: "27015",
        nm: "Cospeito",
      },
      {
        id: "27017",
        nm: "Folgoso do Courel",
      },
      {
        id: "27018",
        nm: "Fonsagrada, A",
      },
      {
        id: "27019",
        nm: "Foz",
      },
      {
        id: "27020",
        nm: "Friol",
      },
      {
        id: "27022",
        nm: "Guitiriz",
      },
      {
        id: "27023",
        nm: "Guntín",
      },
      {
        id: "27024",
        nm: "Incio, O",
      },
      {
        id: "27026",
        nm: "Láncara",
      },
      {
        id: "27027",
        nm: "Lourenzá",
      },
      {
        id: "27028",
        nm: "Lugo",
      },
      {
        id: "27029",
        nm: "Meira",
      },
      {
        id: "27030",
        nm: "Mondoñedo",
      },
      {
        id: "27031",
        nm: "Monforte de Lemos",
      },
      {
        id: "27032",
        nm: "Monterroso",
      },
      {
        id: "27033",
        nm: "Muras",
      },
      {
        id: "27034",
        nm: "Navia de Suarna",
      },
      {
        id: "27035",
        nm: "Negueira de Muñiz",
      },
      {
        id: "27037",
        nm: "Nogais, As",
      },
      {
        id: "27038",
        nm: "Ourol",
      },
      {
        id: "27039",
        nm: "Outeiro de Rei",
      },
      {
        id: "27040",
        nm: "Palas de Rei",
      },
      {
        id: "27041",
        nm: "Pantón",
      },
      {
        id: "27042",
        nm: "Paradela",
      },
      {
        id: "27043",
        nm: "Páramo, O",
      },
      {
        id: "27044",
        nm: "Pastoriza, A",
      },
      {
        id: "27045",
        nm: "Pedrafita do Cebreiro",
      },
      {
        id: "27047",
        nm: "Pobra do Brollón, A",
      },
      {
        id: "27046",
        nm: "Pol",
      },
      {
        id: "27048",
        nm: "Pontenova, A",
      },
      {
        id: "27049",
        nm: "Portomarín",
      },
      {
        id: "27050",
        nm: "Quiroga",
      },
      {
        id: "27056",
        nm: "Rábade",
      },
      {
        id: "27051",
        nm: "Ribadeo",
      },
      {
        id: "27052",
        nm: "Ribas de Sil",
      },
      {
        id: "27053",
        nm: "Ribeira de Piquín",
      },
      {
        id: "27054",
        nm: "Riotorto",
      },
      {
        id: "27055",
        nm: "Samos",
      },
      {
        id: "27057",
        nm: "Sarria",
      },
      {
        id: "27058",
        nm: "Saviñao, O",
      },
      {
        id: "27059",
        nm: "Sober",
      },
      {
        id: "27060",
        nm: "Taboada",
      },
      {
        id: "27061",
        nm: "Trabada",
      },
      {
        id: "27062",
        nm: "Triacastela",
      },
      {
        id: "27063",
        nm: "Valadouro, O",
      },
      {
        id: "27064",
        nm: "Vicedo, O",
      },
      {
        id: "27065",
        nm: "Vilalba",
      },
      {
        id: "27066",
        nm: "Viveiro",
      },
      {
        id: "27021",
        nm: "Xermade",
      },
      {
        id: "27025",
        nm: "Xove",
      },
      {
        id: "28001",
        nm: "Acebeda, La",
      },
      {
        id: "28002",
        nm: "Ajalvir",
      },
      {
        id: "28003",
        nm: "Alameda del Valle",
      },
      {
        id: "28004",
        nm: "Álamo, El",
      },
      {
        id: "28005",
        nm: "Alcalá de Henares",
      },
      {
        id: "28006",
        nm: "Alcobendas",
      },
      {
        id: "28007",
        nm: "Alcorcón",
      },
      {
        id: "28008",
        nm: "Aldea del Fresno",
      },
      {
        id: "28009",
        nm: "Algete",
      },
      {
        id: "28010",
        nm: "Alpedrete",
      },
      {
        id: "28011",
        nm: "Ambite",
      },
      {
        id: "28012",
        nm: "Anchuelo",
      },
      {
        id: "28013",
        nm: "Aranjuez",
      },
      {
        id: "28014",
        nm: "Arganda del Rey",
      },
      {
        id: "28015",
        nm: "Arroyomolinos",
      },
      {
        id: "28016",
        nm: "Atazar, El",
      },
      {
        id: "28017",
        nm: "Batres",
      },
      {
        id: "28018",
        nm: "Becerril de la Sierra",
      },
      {
        id: "28019",
        nm: "Belmonte de Tajo",
      },
      {
        id: "28021",
        nm: "Berrueco, El",
      },
      {
        id: "28020",
        nm: "Berzosa del Lozoya",
      },
      {
        id: "28022",
        nm: "Boadilla del Monte",
      },
      {
        id: "28023",
        nm: "Boalo, El",
      },
      {
        id: "28024",
        nm: "Braojos",
      },
      {
        id: "28025",
        nm: "Brea de Tajo",
      },
      {
        id: "28026",
        nm: "Brunete",
      },
      {
        id: "28027",
        nm: "Buitrago del Lozoya",
      },
      {
        id: "28028",
        nm: "Bustarviejo",
      },
      {
        id: "28029",
        nm: "Cabanillas de la Sierra",
      },
      {
        id: "28030",
        nm: "Cabrera, La",
      },
      {
        id: "28031",
        nm: "Cadalso de los Vidrios",
      },
      {
        id: "28032",
        nm: "Camarma de Esteruelas",
      },
      {
        id: "28033",
        nm: "Campo Real",
      },
      {
        id: "28034",
        nm: "Canencia",
      },
      {
        id: "28035",
        nm: "Carabaña",
      },
      {
        id: "28036",
        nm: "Casarrubuelos",
      },
      {
        id: "28037",
        nm: "Cenicientos",
      },
      {
        id: "28038",
        nm: "Cercedilla",
      },
      {
        id: "28039",
        nm: "Cervera de Buitrago",
      },
      {
        id: "28051",
        nm: "Chapinería",
      },
      {
        id: "28052",
        nm: "Chinchón",
      },
      {
        id: "28040",
        nm: "Ciempozuelos",
      },
      {
        id: "28041",
        nm: "Cobeña",
      },
      {
        id: "28046",
        nm: "Collado Mediano",
      },
      {
        id: "28047",
        nm: "Collado Villalba",
      },
      {
        id: "28043",
        nm: "Colmenar de Oreja",
      },
      {
        id: "28042",
        nm: "Colmenar del Arroyo",
      },
      {
        id: "28045",
        nm: "Colmenar Viejo",
      },
      {
        id: "28044",
        nm: "Colmenarejo",
      },
      {
        id: "28048",
        nm: "Corpa",
      },
      {
        id: "28049",
        nm: "Coslada",
      },
      {
        id: "28050",
        nm: "Cubas de la Sagra",
      },
      {
        id: "28053",
        nm: "Daganzo de Arriba",
      },
      {
        id: "28054",
        nm: "Escorial, El",
      },
      {
        id: "28055",
        nm: "Estremera",
      },
      {
        id: "28056",
        nm: "Fresnedillas de la Oliva",
      },
      {
        id: "28057",
        nm: "Fresno de Torote",
      },
      {
        id: "28058",
        nm: "Fuenlabrada",
      },
      {
        id: "28059",
        nm: "Fuente el Saz de Jarama",
      },
      {
        id: "28060",
        nm: "Fuentidueña de Tajo",
      },
      {
        id: "28061",
        nm: "Galapagar",
      },
      {
        id: "28062",
        nm: "Garganta de los Montes",
      },
      {
        id: "28063",
        nm: "Gargantilla del Lozoya y Pinilla de Buitrago",
      },
      {
        id: "28064",
        nm: "Gascones",
      },
      {
        id: "28065",
        nm: "Getafe",
      },
      {
        id: "28066",
        nm: "Griñón",
      },
      {
        id: "28067",
        nm: "Guadalix de la Sierra",
      },
      {
        id: "28068",
        nm: "Guadarrama",
      },
      {
        id: "28069",
        nm: "Hiruela, La",
      },
      {
        id: "28070",
        nm: "Horcajo de la Sierra-Aoslos",
      },
      {
        id: "28071",
        nm: "Horcajuelo de la Sierra",
      },
      {
        id: "28072",
        nm: "Hoyo de Manzanares",
      },
      {
        id: "28073",
        nm: "Humanes de Madrid",
      },
      {
        id: "28074",
        nm: "Leganés",
      },
      {
        id: "28075",
        nm: "Loeches",
      },
      {
        id: "28076",
        nm: "Lozoya",
      },
      {
        id: "28901",
        nm: "Lozoyuela-Navas-Sieteiglesias",
      },
      {
        id: "28078",
        nm: "Madarcos",
      },
      {
        id: "28079",
        nm: "Madrid",
      },
      {
        id: "28080",
        nm: "Majadahonda",
      },
      {
        id: "28082",
        nm: "Manzanares el Real",
      },
      {
        id: "28083",
        nm: "Meco",
      },
      {
        id: "28084",
        nm: "Mejorada del Campo",
      },
      {
        id: "28085",
        nm: "Miraflores de la Sierra",
      },
      {
        id: "28086",
        nm: "Molar, El",
      },
      {
        id: "28087",
        nm: "Molinos, Los",
      },
      {
        id: "28088",
        nm: "Montejo de la Sierra",
      },
      {
        id: "28089",
        nm: "Moraleja de Enmedio",
      },
      {
        id: "28090",
        nm: "Moralzarzal",
      },
      {
        id: "28091",
        nm: "Morata de Tajuña",
      },
      {
        id: "28092",
        nm: "Móstoles",
      },
      {
        id: "28093",
        nm: "Navacerrada",
      },
      {
        id: "28094",
        nm: "Navalafuente",
      },
      {
        id: "28095",
        nm: "Navalagamella",
      },
      {
        id: "28096",
        nm: "Navalcarnero",
      },
      {
        id: "28097",
        nm: "Navarredonda y San Mamés",
      },
      {
        id: "28099",
        nm: "Navas del Rey",
      },
      {
        id: "28100",
        nm: "Nuevo Baztán",
      },
      {
        id: "28101",
        nm: "Olmeda de las Fuentes",
      },
      {
        id: "28102",
        nm: "Orusco de Tajuña",
      },
      {
        id: "28104",
        nm: "Paracuellos de Jarama",
      },
      {
        id: "28106",
        nm: "Parla",
      },
      {
        id: "28107",
        nm: "Patones",
      },
      {
        id: "28108",
        nm: "Pedrezuela",
      },
      {
        id: "28109",
        nm: "Pelayos de la Presa",
      },
      {
        id: "28110",
        nm: "Perales de Tajuña",
      },
      {
        id: "28111",
        nm: "Pezuela de las Torres",
      },
      {
        id: "28112",
        nm: "Pinilla del Valle",
      },
      {
        id: "28113",
        nm: "Pinto",
      },
      {
        id: "28114",
        nm: "Piñuécar-Gandullas",
      },
      {
        id: "28115",
        nm: "Pozuelo de Alarcón",
      },
      {
        id: "28116",
        nm: "Pozuelo del Rey",
      },
      {
        id: "28117",
        nm: "Prádena del Rincón",
      },
      {
        id: "28118",
        nm: "Puebla de la Sierra",
      },
      {
        id: "28902",
        nm: "Puentes Viejas",
      },
      {
        id: "28119",
        nm: "Quijorna",
      },
      {
        id: "28120",
        nm: "Rascafría",
      },
      {
        id: "28121",
        nm: "Redueña",
      },
      {
        id: "28122",
        nm: "Ribatejada",
      },
      {
        id: "28123",
        nm: "Rivas-Vaciamadrid",
      },
      {
        id: "28124",
        nm: "Robledillo de la Jara",
      },
      {
        id: "28125",
        nm: "Robledo de Chavela",
      },
      {
        id: "28126",
        nm: "Robregordo",
      },
      {
        id: "28127",
        nm: "Rozas de Madrid, Las",
      },
      {
        id: "28128",
        nm: "Rozas de Puerto Real",
      },
      {
        id: "28129",
        nm: "San Agustín del Guadalix",
      },
      {
        id: "28130",
        nm: "San Fernando de Henares",
      },
      {
        id: "28131",
        nm: "San Lorenzo de El Escorial",
      },
      {
        id: "28132",
        nm: "San Martín de la Vega",
      },
      {
        id: "28133",
        nm: "San Martín de Valdeiglesias",
      },
      {
        id: "28134",
        nm: "San Sebastián de los Reyes",
      },
      {
        id: "28135",
        nm: "Santa María de la Alameda",
      },
      {
        id: "28136",
        nm: "Santorcaz",
      },
      {
        id: "28137",
        nm: "Santos de la Humosa, Los",
      },
      {
        id: "28138",
        nm: "Serna del Monte, La",
      },
      {
        id: "28140",
        nm: "Serranillos del Valle",
      },
      {
        id: "28141",
        nm: "Sevilla la Nueva",
      },
      {
        id: "28143",
        nm: "Somosierra",
      },
      {
        id: "28144",
        nm: "Soto del Real",
      },
      {
        id: "28145",
        nm: "Talamanca de Jarama",
      },
      {
        id: "28146",
        nm: "Tielmes",
      },
      {
        id: "28147",
        nm: "Titulcia",
      },
      {
        id: "28148",
        nm: "Torrejón de Ardoz",
      },
      {
        id: "28149",
        nm: "Torrejón de la Calzada",
      },
      {
        id: "28150",
        nm: "Torrejón de Velasco",
      },
      {
        id: "28151",
        nm: "Torrelaguna",
      },
      {
        id: "28152",
        nm: "Torrelodones",
      },
      {
        id: "28153",
        nm: "Torremocha de Jarama",
      },
      {
        id: "28154",
        nm: "Torres de la Alameda",
      },
      {
        id: "28903",
        nm: "Tres Cantos",
      },
      {
        id: "28155",
        nm: "Valdaracete",
      },
      {
        id: "28156",
        nm: "Valdeavero",
      },
      {
        id: "28157",
        nm: "Valdelaguna",
      },
      {
        id: "28158",
        nm: "Valdemanco",
      },
      {
        id: "28159",
        nm: "Valdemaqueda",
      },
      {
        id: "28160",
        nm: "Valdemorillo",
      },
      {
        id: "28161",
        nm: "Valdemoro",
      },
      {
        id: "28162",
        nm: "Valdeolmos-Alalpardo",
      },
      {
        id: "28163",
        nm: "Valdepiélagos",
      },
      {
        id: "28164",
        nm: "Valdetorres de Jarama",
      },
      {
        id: "28165",
        nm: "Valdilecha",
      },
      {
        id: "28166",
        nm: "Valverde de Alcalá",
      },
      {
        id: "28167",
        nm: "Velilla de San Antonio",
      },
      {
        id: "28168",
        nm: "Vellón, El",
      },
      {
        id: "28169",
        nm: "Venturada",
      },
      {
        id: "28171",
        nm: "Villa del Prado",
      },
      {
        id: "28170",
        nm: "Villaconejos",
      },
      {
        id: "28172",
        nm: "Villalbilla",
      },
      {
        id: "28173",
        nm: "Villamanrique de Tajo",
      },
      {
        id: "28174",
        nm: "Villamanta",
      },
      {
        id: "28175",
        nm: "Villamantilla",
      },
      {
        id: "28176",
        nm: "Villanueva de la Cañada",
      },
      {
        id: "28178",
        nm: "Villanueva de Perales",
      },
      {
        id: "28177",
        nm: "Villanueva del Pardillo",
      },
      {
        id: "28179",
        nm: "Villar del Olmo",
      },
      {
        id: "28180",
        nm: "Villarejo de Salvanés",
      },
      {
        id: "28181",
        nm: "Villaviciosa de Odón",
      },
      {
        id: "28182",
        nm: "Villavieja del Lozoya",
      },
      {
        id: "28183",
        nm: "Zarzalejo",
      },
      {
        id: "29001",
        nm: "Alameda",
      },
      {
        id: "29002",
        nm: "Alcaucín",
      },
      {
        id: "29003",
        nm: "Alfarnate",
      },
      {
        id: "29004",
        nm: "Alfarnatejo",
      },
      {
        id: "29005",
        nm: "Algarrobo",
      },
      {
        id: "29006",
        nm: "Algatocín",
      },
      {
        id: "29007",
        nm: "Alhaurín de la Torre",
      },
      {
        id: "29008",
        nm: "Alhaurín el Grande",
      },
      {
        id: "29009",
        nm: "Almáchar",
      },
      {
        id: "29010",
        nm: "Almargen",
      },
      {
        id: "29011",
        nm: "Almogía",
      },
      {
        id: "29012",
        nm: "Álora",
      },
      {
        id: "29013",
        nm: "Alozaina",
      },
      {
        id: "29014",
        nm: "Alpandeire",
      },
      {
        id: "29015",
        nm: "Antequera",
      },
      {
        id: "29016",
        nm: "Árchez",
      },
      {
        id: "29017",
        nm: "Archidona",
      },
      {
        id: "29018",
        nm: "Ardales",
      },
      {
        id: "29019",
        nm: "Arenas",
      },
      {
        id: "29020",
        nm: "Arriate",
      },
      {
        id: "29021",
        nm: "Atajate",
      },
      {
        id: "29022",
        nm: "Benadalid",
      },
      {
        id: "29023",
        nm: "Benahavís",
      },
      {
        id: "29024",
        nm: "Benalauría",
      },
      {
        id: "29025",
        nm: "Benalmádena",
      },
      {
        id: "29026",
        nm: "Benamargosa",
      },
      {
        id: "29027",
        nm: "Benamocarra",
      },
      {
        id: "29028",
        nm: "Benaoján",
      },
      {
        id: "29029",
        nm: "Benarrabá",
      },
      {
        id: "29030",
        nm: "Borge, El",
      },
      {
        id: "29031",
        nm: "Burgo, El",
      },
      {
        id: "29032",
        nm: "Campillos",
      },
      {
        id: "29033",
        nm: "Canillas de Aceituno",
      },
      {
        id: "29034",
        nm: "Canillas de Albaida",
      },
      {
        id: "29035",
        nm: "Cañete la Real",
      },
      {
        id: "29036",
        nm: "Carratraca",
      },
      {
        id: "29037",
        nm: "Cartajima",
      },
      {
        id: "29038",
        nm: "Cártama",
      },
      {
        id: "29039",
        nm: "Casabermeja",
      },
      {
        id: "29040",
        nm: "Casarabonela",
      },
      {
        id: "29041",
        nm: "Casares",
      },
      {
        id: "29042",
        nm: "Coín",
      },
      {
        id: "29043",
        nm: "Colmenar",
      },
      {
        id: "29044",
        nm: "Comares",
      },
      {
        id: "29045",
        nm: "Cómpeta",
      },
      {
        id: "29046",
        nm: "Cortes de la Frontera",
      },
      {
        id: "29047",
        nm: "Cuevas Bajas",
      },
      {
        id: "29049",
        nm: "Cuevas de San Marcos",
      },
      {
        id: "29048",
        nm: "Cuevas del Becerro",
      },
      {
        id: "29050",
        nm: "Cútar",
      },
      {
        id: "29051",
        nm: "Estepona",
      },
      {
        id: "29052",
        nm: "Faraján",
      },
      {
        id: "29053",
        nm: "Frigiliana",
      },
      {
        id: "29054",
        nm: "Fuengirola",
      },
      {
        id: "29055",
        nm: "Fuente de Piedra",
      },
      {
        id: "29056",
        nm: "Gaucín",
      },
      {
        id: "29057",
        nm: "Genalguacil",
      },
      {
        id: "29058",
        nm: "Guaro",
      },
      {
        id: "29059",
        nm: "Humilladero",
      },
      {
        id: "29060",
        nm: "Igualeja",
      },
      {
        id: "29061",
        nm: "Istán",
      },
      {
        id: "29062",
        nm: "Iznate",
      },
      {
        id: "29063",
        nm: "Jimera de Líbar",
      },
      {
        id: "29064",
        nm: "Jubrique",
      },
      {
        id: "29065",
        nm: "Júzcar",
      },
      {
        id: "29066",
        nm: "Macharaviaya",
      },
      {
        id: "29067",
        nm: "Málaga",
      },
      {
        id: "29068",
        nm: "Manilva",
      },
      {
        id: "29069",
        nm: "Marbella",
      },
      {
        id: "29070",
        nm: "Mijas",
      },
      {
        id: "29071",
        nm: "Moclinejo",
      },
      {
        id: "29072",
        nm: "Mollina",
      },
      {
        id: "29073",
        nm: "Monda",
      },
      {
        id: "29903",
        nm: "Montecorto",
      },
      {
        id: "29074",
        nm: "Montejaque",
      },
      {
        id: "29075",
        nm: "Nerja",
      },
      {
        id: "29076",
        nm: "Ojén",
      },
      {
        id: "29077",
        nm: "Parauta",
      },
      {
        id: "29079",
        nm: "Periana",
      },
      {
        id: "29080",
        nm: "Pizarra",
      },
      {
        id: "29081",
        nm: "Pujerra",
      },
      {
        id: "29082",
        nm: "Rincón de la Victoria",
      },
      {
        id: "29083",
        nm: "Riogordo",
      },
      {
        id: "29084",
        nm: "Ronda",
      },
      {
        id: "29085",
        nm: "Salares",
      },
      {
        id: "29086",
        nm: "Sayalonga",
      },
      {
        id: "29087",
        nm: "Sedella",
      },
      {
        id: "29904",
        nm: "Serrato",
      },
      {
        id: "29088",
        nm: "Sierra de Yeguas",
      },
      {
        id: "29089",
        nm: "Teba",
      },
      {
        id: "29090",
        nm: "Tolox",
      },
      {
        id: "29901",
        nm: "Torremolinos",
      },
      {
        id: "29091",
        nm: "Torrox",
      },
      {
        id: "29092",
        nm: "Totalán",
      },
      {
        id: "29093",
        nm: "Valle de Abdalajís",
      },
      {
        id: "29094",
        nm: "Vélez-Málaga",
      },
      {
        id: "29095",
        nm: "Villanueva de Algaidas",
      },
      {
        id: "29902",
        nm: "Villanueva de la Concepción",
      },
      {
        id: "29098",
        nm: "Villanueva de Tapia",
      },
      {
        id: "29096",
        nm: "Villanueva del Rosario",
      },
      {
        id: "29097",
        nm: "Villanueva del Trabuco",
      },
      {
        id: "29099",
        nm: "Viñuela",
      },
      {
        id: "29100",
        nm: "Yunquera",
      },
      {
        id: "30001",
        nm: "Abanilla",
      },
      {
        id: "30002",
        nm: "Abarán",
      },
      {
        id: "30003",
        nm: "Águilas",
      },
      {
        id: "30004",
        nm: "Albudeite",
      },
      {
        id: "30005",
        nm: "Alcantarilla",
      },
      {
        id: "30902",
        nm: "Alcázares, Los",
      },
      {
        id: "30006",
        nm: "Aledo",
      },
      {
        id: "30007",
        nm: "Alguazas",
      },
      {
        id: "30008",
        nm: "Alhama de Murcia",
      },
      {
        id: "30009",
        nm: "Archena",
      },
      {
        id: "30010",
        nm: "Beniel",
      },
      {
        id: "30011",
        nm: "Blanca",
      },
      {
        id: "30012",
        nm: "Bullas",
      },
      {
        id: "30013",
        nm: "Calasparra",
      },
      {
        id: "30014",
        nm: "Campos del Río",
      },
      {
        id: "30015",
        nm: "Caravaca de la Cruz",
      },
      {
        id: "30016",
        nm: "Cartagena",
      },
      {
        id: "30017",
        nm: "Cehegín",
      },
      {
        id: "30018",
        nm: "Ceutí",
      },
      {
        id: "30019",
        nm: "Cieza",
      },
      {
        id: "30020",
        nm: "Fortuna",
      },
      {
        id: "30021",
        nm: "Fuente Álamo de Murcia",
      },
      {
        id: "30022",
        nm: "Jumilla",
      },
      {
        id: "30023",
        nm: "Librilla",
      },
      {
        id: "30024",
        nm: "Lorca",
      },
      {
        id: "30025",
        nm: "Lorquí",
      },
      {
        id: "30026",
        nm: "Mazarrón",
      },
      {
        id: "30027",
        nm: "Molina de Segura",
      },
      {
        id: "30028",
        nm: "Moratalla",
      },
      {
        id: "30029",
        nm: "Mula",
      },
      {
        id: "30030",
        nm: "Murcia",
      },
      {
        id: "30031",
        nm: "Ojós",
      },
      {
        id: "30032",
        nm: "Pliego",
      },
      {
        id: "30033",
        nm: "Puerto Lumbreras",
      },
      {
        id: "30034",
        nm: "Ricote",
      },
      {
        id: "30035",
        nm: "San Javier",
      },
      {
        id: "30036",
        nm: "San Pedro del Pinatar",
      },
      {
        id: "30901",
        nm: "Santomera",
      },
      {
        id: "30037",
        nm: "Torre-Pacheco",
      },
      {
        id: "30038",
        nm: "Torres de Cotillas, Las",
      },
      {
        id: "30039",
        nm: "Totana",
      },
      {
        id: "30040",
        nm: "Ulea",
      },
      {
        id: "30041",
        nm: "Unión, La",
      },
      {
        id: "30042",
        nm: "Villanueva del Río Segura",
      },
      {
        id: "30043",
        nm: "Yecla",
      },
      {
        id: "31001",
        nm: "Abáigar",
      },
      {
        id: "31002",
        nm: "Abárzuza/Abartzuza",
      },
      {
        id: "31003",
        nm: "Abaurregaina/Abaurrea Alta",
      },
      {
        id: "31004",
        nm: "Abaurrepea/Abaurrea Baja",
      },
      {
        id: "31005",
        nm: "Aberin",
      },
      {
        id: "31006",
        nm: "Ablitas",
      },
      {
        id: "31007",
        nm: "Adiós",
      },
      {
        id: "31008",
        nm: "Aguilar de Codés",
      },
      {
        id: "31009",
        nm: "Aibar/Oibar",
      },
      {
        id: "31011",
        nm: "Allín/Allin",
      },
      {
        id: "31012",
        nm: "Allo",
      },
      {
        id: "31010",
        nm: "Altsasu/Alsasua",
      },
      {
        id: "31013",
        nm: "Améscoa Baja",
      },
      {
        id: "31014",
        nm: "Ancín/Antzin",
      },
      {
        id: "31015",
        nm: "Andosilla",
      },
      {
        id: "31016",
        nm: "Ansoáin/Antsoain",
      },
      {
        id: "31017",
        nm: "Anue",
      },
      {
        id: "31018",
        nm: "Añorbe",
      },
      {
        id: "31019",
        nm: "Aoiz/Agoitz",
      },
      {
        id: "31020",
        nm: "Araitz",
      },
      {
        id: "31025",
        nm: "Arakil",
      },
      {
        id: "31021",
        nm: "Aranarache/Aranaratxe",
      },
      {
        id: "31023",
        nm: "Aranguren",
      },
      {
        id: "31024",
        nm: "Arano",
      },
      {
        id: "31022",
        nm: "Arantza",
      },
      {
        id: "31026",
        nm: "Aras",
      },
      {
        id: "31027",
        nm: "Arbizu",
      },
      {
        id: "31028",
        nm: "Arce/Artzi",
      },
      {
        id: "31029",
        nm: "Arcos, Los",
      },
      {
        id: "31030",
        nm: "Arellano",
      },
      {
        id: "31031",
        nm: "Areso",
      },
      {
        id: "31032",
        nm: "Arguedas",
      },
      {
        id: "31033",
        nm: "Aria",
      },
      {
        id: "31034",
        nm: "Aribe",
      },
      {
        id: "31035",
        nm: "Armañanzas",
      },
      {
        id: "31036",
        nm: "Arróniz",
      },
      {
        id: "31037",
        nm: "Arruazu",
      },
      {
        id: "31038",
        nm: "Artajona",
      },
      {
        id: "31039",
        nm: "Artazu",
      },
      {
        id: "31040",
        nm: "Atez/Atetz",
      },
      {
        id: "31058",
        nm: "Auritz/Burguete",
      },
      {
        id: "31041",
        nm: "Ayegui/Aiegi",
      },
      {
        id: "31042",
        nm: "Azagra",
      },
      {
        id: "31043",
        nm: "Azuelo",
      },
      {
        id: "31044",
        nm: "Bakaiku",
      },
      {
        id: "31901",
        nm: "Barañain",
      },
      {
        id: "31045",
        nm: "Barásoain",
      },
      {
        id: "31046",
        nm: "Barbarin",
      },
      {
        id: "31047",
        nm: "Bargota",
      },
      {
        id: "31048",
        nm: "Barillas",
      },
      {
        id: "31049",
        nm: "Basaburua",
      },
      {
        id: "31050",
        nm: "Baztan",
      },
      {
        id: "31137",
        nm: "Beintza-Labaien",
      },
      {
        id: "31051",
        nm: "Beire",
      },
      {
        id: "31052",
        nm: "Belascoáin",
      },
      {
        id: "31250",
        nm: "Bera",
      },
      {
        id: "31053",
        nm: "Berbinzana",
      },
      {
        id: "31905",
        nm: "Beriáin",
      },
      {
        id: "31902",
        nm: "Berrioplano/Berriobeiti",
      },
      {
        id: "31903",
        nm: "Berriozar",
      },
      {
        id: "31054",
        nm: "Bertizarana",
      },
      {
        id: "31055",
        nm: "Betelu",
      },
      {
        id: "31253",
        nm: "Bidaurreta",
      },
      {
        id: "31056",
        nm: "Biurrun-Olcoz",
      },
      {
        id: "31057",
        nm: "Buñuel",
      },
      {
        id: "31059",
        nm: "Burgui/Burgi",
      },
      {
        id: "31060",
        nm: "Burlada/Burlata",
      },
      {
        id: "31061",
        nm: "Busto, El",
      },
      {
        id: "31062",
        nm: "Cabanillas",
      },
      {
        id: "31063",
        nm: "Cabredo",
      },
      {
        id: "31064",
        nm: "Cadreita",
      },
      {
        id: "31065",
        nm: "Caparroso",
      },
      {
        id: "31066",
        nm: "Cárcar",
      },
      {
        id: "31067",
        nm: "Carcastillo",
      },
      {
        id: "31068",
        nm: "Cascante",
      },
      {
        id: "31069",
        nm: "Cáseda",
      },
      {
        id: "31070",
        nm: "Castejón",
      },
      {
        id: "31071",
        nm: "Castillonuevo",
      },
      {
        id: "31193",
        nm: "Cendea de Olza/Oltza Zendea",
      },
      {
        id: "31072",
        nm: "Cintruénigo",
      },
      {
        id: "31074",
        nm: "Cirauqui/Zirauki",
      },
      {
        id: "31075",
        nm: "Ciriza/Ziritza",
      },
      {
        id: "31076",
        nm: "Cizur",
      },
      {
        id: "31077",
        nm: "Corella",
      },
      {
        id: "31078",
        nm: "Cortes",
      },
      {
        id: "31079",
        nm: "Desojo",
      },
      {
        id: "31080",
        nm: "Dicastillo",
      },
      {
        id: "31081",
        nm: "Donamaria",
      },
      {
        id: "31221",
        nm: "Doneztebe/Santesteban",
      },
      {
        id: "31083",
        nm: "Echarri",
      },
      {
        id: "31087",
        nm: "Elgorriaga",
      },
      {
        id: "31089",
        nm: "Enériz/Eneritz",
      },
      {
        id: "31090",
        nm: "Eratsun",
      },
      {
        id: "31091",
        nm: "Ergoiena",
      },
      {
        id: "31092",
        nm: "Erro",
      },
      {
        id: "31094",
        nm: "Eslava",
      },
      {
        id: "31095",
        nm: "Esparza de Salazar/Espartza Zaraitzu",
      },
      {
        id: "31096",
        nm: "Espronceda",
      },
      {
        id: "31097",
        nm: "Estella-Lizarra",
      },
      {
        id: "31098",
        nm: "Esteribar",
      },
      {
        id: "31099",
        nm: "Etayo",
      },
      {
        id: "31082",
        nm: "Etxalar",
      },
      {
        id: "31084",
        nm: "Etxarri Aranatz",
      },
      {
        id: "31085",
        nm: "Etxauri",
      },
      {
        id: "31100",
        nm: "Eulate",
      },
      {
        id: "31101",
        nm: "Ezcabarte",
      },
      {
        id: "31093",
        nm: "Ezcároz/Ezkaroze",
      },
      {
        id: "31102",
        nm: "Ezkurra",
      },
      {
        id: "31103",
        nm: "Ezprogui",
      },
      {
        id: "31104",
        nm: "Falces",
      },
      {
        id: "31105",
        nm: "Fitero",
      },
      {
        id: "31106",
        nm: "Fontellas",
      },
      {
        id: "31107",
        nm: "Funes",
      },
      {
        id: "31108",
        nm: "Fustiñana",
      },
      {
        id: "31109",
        nm: "Galar",
      },
      {
        id: "31110",
        nm: "Gallipienzo/Galipentzu",
      },
      {
        id: "31111",
        nm: "Gallués/Galoze",
      },
      {
        id: "31112",
        nm: "Garaioa",
      },
      {
        id: "31113",
        nm: "Garde",
      },
      {
        id: "31114",
        nm: "Garínoain",
      },
      {
        id: "31115",
        nm: "Garralda",
      },
      {
        id: "31116",
        nm: "Genevilla",
      },
      {
        id: "31117",
        nm: "Goizueta",
      },
      {
        id: "31118",
        nm: "Goñi",
      },
      {
        id: "31119",
        nm: "Güesa/Gorza",
      },
      {
        id: "31120",
        nm: "Guesálaz/Gesalatz",
      },
      {
        id: "31121",
        nm: "Guirguillano",
      },
      {
        id: "31256",
        nm: "Hiriberri/Villanueva de Aezkoa",
      },
      {
        id: "31122",
        nm: "Huarte/Uharte",
      },
      {
        id: "31124",
        nm: "Ibargoiti",
      },
      {
        id: "31259",
        nm: "Igantzi",
      },
      {
        id: "31125",
        nm: "Igúzquiza",
      },
      {
        id: "31126",
        nm: "Imotz",
      },
      {
        id: "31127",
        nm: "Irañeta",
      },
      {
        id: "31904",
        nm: "Irurtzun",
      },
      {
        id: "31128",
        nm: "Isaba/Izaba",
      },
      {
        id: "31129",
        nm: "Ituren",
      },
      {
        id: "31130",
        nm: "Iturmendi",
      },
      {
        id: "31131",
        nm: "Iza/Itza",
      },
      {
        id: "31132",
        nm: "Izagaondoa",
      },
      {
        id: "31133",
        nm: "Izalzu/Itzaltzu",
      },
      {
        id: "31134",
        nm: "Jaurrieta",
      },
      {
        id: "31135",
        nm: "Javier",
      },
      {
        id: "31136",
        nm: "Juslapeña",
      },
      {
        id: "31138",
        nm: "Lakuntza",
      },
      {
        id: "31139",
        nm: "Lana",
      },
      {
        id: "31140",
        nm: "Lantz",
      },
      {
        id: "31141",
        nm: "Lapoblación",
      },
      {
        id: "31142",
        nm: "Larraga",
      },
      {
        id: "31143",
        nm: "Larraona",
      },
      {
        id: "31144",
        nm: "Larraun",
      },
      {
        id: "31145",
        nm: "Lazagurría",
      },
      {
        id: "31146",
        nm: "Leache/Leatxe",
      },
      {
        id: "31147",
        nm: "Legarda",
      },
      {
        id: "31148",
        nm: "Legaria",
      },
      {
        id: "31149",
        nm: "Leitza",
      },
      {
        id: "31908",
        nm: "Lekunberri",
      },
      {
        id: "31150",
        nm: "Leoz/Leotz",
      },
      {
        id: "31151",
        nm: "Lerga",
      },
      {
        id: "31152",
        nm: "Lerín",
      },
      {
        id: "31153",
        nm: "Lesaka",
      },
      {
        id: "31154",
        nm: "Lezáun",
      },
      {
        id: "31155",
        nm: "Liédena",
      },
      {
        id: "31156",
        nm: "Lizoáin-Arriasgoiti",
      },
      {
        id: "31157",
        nm: "Lodosa",
      },
      {
        id: "31158",
        nm: "Lónguida/Longida",
      },
      {
        id: "31159",
        nm: "Lumbier",
      },
      {
        id: "31160",
        nm: "Luquin",
      },
      {
        id: "31248",
        nm: "Luzaide/Valcarlos",
      },
      {
        id: "31161",
        nm: "Mañeru",
      },
      {
        id: "31162",
        nm: "Marañón",
      },
      {
        id: "31163",
        nm: "Marcilla",
      },
      {
        id: "31164",
        nm: "Mélida",
      },
      {
        id: "31165",
        nm: "Mendavia",
      },
      {
        id: "31166",
        nm: "Mendaza",
      },
      {
        id: "31167",
        nm: "Mendigorría",
      },
      {
        id: "31168",
        nm: "Metauten",
      },
      {
        id: "31169",
        nm: "Milagro",
      },
      {
        id: "31170",
        nm: "Mirafuentes",
      },
      {
        id: "31171",
        nm: "Miranda de Arga",
      },
      {
        id: "31172",
        nm: "Monreal/Elo",
      },
      {
        id: "31173",
        nm: "Monteagudo",
      },
      {
        id: "31174",
        nm: "Morentin",
      },
      {
        id: "31175",
        nm: "Mues",
      },
      {
        id: "31176",
        nm: "Murchante",
      },
      {
        id: "31177",
        nm: "Murieta",
      },
      {
        id: "31178",
        nm: "Murillo el Cuende",
      },
      {
        id: "31179",
        nm: "Murillo el Fruto",
      },
      {
        id: "31180",
        nm: "Muruzábal",
      },
      {
        id: "31181",
        nm: "Navascués/Nabaskoze",
      },
      {
        id: "31182",
        nm: "Nazar",
      },
      {
        id: "31088",
        nm: "Noáin (Valle de Elorz)/Noain (Elortzibar)",
      },
      {
        id: "31183",
        nm: "Obanos",
      },
      {
        id: "31185",
        nm: "Ochagavía/Otsagabia",
      },
      {
        id: "31184",
        nm: "Oco",
      },
      {
        id: "31186",
        nm: "Odieta",
      },
      {
        id: "31187",
        nm: "Oiz",
      },
      {
        id: "31188",
        nm: "Olaibar",
      },
      {
        id: "31189",
        nm: "Olazti/Olazagutía",
      },
      {
        id: "31190",
        nm: "Olejua",
      },
      {
        id: "31191",
        nm: "Olite/Erriberri",
      },
      {
        id: "31194",
        nm: "Ollo",
      },
      {
        id: "31192",
        nm: "Olóriz/Oloritz",
      },
      {
        id: "31195",
        nm: "Orbaizeta",
      },
      {
        id: "31196",
        nm: "Orbara",
      },
      {
        id: "31197",
        nm: "Orísoain",
      },
      {
        id: "31906",
        nm: "Orkoien",
      },
      {
        id: "31198",
        nm: "Oronz/Orontze",
      },
      {
        id: "31199",
        nm: "Oroz-Betelu/Orotz-Betelu",
      },
      {
        id: "31211",
        nm: "Orreaga/Roncesvalles",
      },
      {
        id: "31200",
        nm: "Oteiza",
      },
      {
        id: "31201",
        nm: "Pamplona/Iruña",
      },
      {
        id: "31202",
        nm: "Peralta/Azkoien",
      },
      {
        id: "31203",
        nm: "Petilla de Aragón",
      },
      {
        id: "31204",
        nm: "Piedramillera",
      },
      {
        id: "31205",
        nm: "Pitillas",
      },
      {
        id: "31206",
        nm: "Puente la Reina/Gares",
      },
      {
        id: "31207",
        nm: "Pueyo",
      },
      {
        id: "31208",
        nm: "Ribaforada",
      },
      {
        id: "31209",
        nm: "Romanzado",
      },
      {
        id: "31210",
        nm: "Roncal/Erronkari",
      },
      {
        id: "31212",
        nm: "Sada",
      },
      {
        id: "31213",
        nm: "Saldías",
      },
      {
        id: "31214",
        nm: "Salinas de Oro/Jaitz",
      },
      {
        id: "31215",
        nm: "San Adrián",
      },
      {
        id: "31217",
        nm: "San Martín de Unx",
      },
      {
        id: "31216",
        nm: "Sangüesa/Zangoza",
      },
      {
        id: "31219",
        nm: "Sansol",
      },
      {
        id: "31220",
        nm: "Santacara",
      },
      {
        id: "31222",
        nm: "Sarriés/Sartze",
      },
      {
        id: "31223",
        nm: "Sartaguda",
      },
      {
        id: "31224",
        nm: "Sesma",
      },
      {
        id: "31225",
        nm: "Sorlada",
      },
      {
        id: "31226",
        nm: "Sunbilla",
      },
      {
        id: "31227",
        nm: "Tafalla",
      },
      {
        id: "31228",
        nm: "Tiebas-Muruarte de Reta",
      },
      {
        id: "31229",
        nm: "Tirapu",
      },
      {
        id: "31230",
        nm: "Torralba del Río",
      },
      {
        id: "31231",
        nm: "Torres del Río",
      },
      {
        id: "31232",
        nm: "Tudela",
      },
      {
        id: "31233",
        nm: "Tulebras",
      },
      {
        id: "31234",
        nm: "Ucar",
      },
      {
        id: "31123",
        nm: "Uharte Arakil",
      },
      {
        id: "31235",
        nm: "Ujué",
      },
      {
        id: "31236",
        nm: "Ultzama",
      },
      {
        id: "31237",
        nm: "Unciti",
      },
      {
        id: "31238",
        nm: "Unzué/Untzue",
      },
      {
        id: "31239",
        nm: "Urdazubi/Urdax",
      },
      {
        id: "31240",
        nm: "Urdiain",
      },
      {
        id: "31241",
        nm: "Urraul Alto",
      },
      {
        id: "31242",
        nm: "Urraul Bajo",
      },
      {
        id: "31244",
        nm: "Urroz",
      },
      {
        id: "31243",
        nm: "Urroz-Villa",
      },
      {
        id: "31245",
        nm: "Urzainqui/Urzainki",
      },
      {
        id: "31246",
        nm: "Uterga",
      },
      {
        id: "31247",
        nm: "Uztárroz/Uztarroze",
      },
      {
        id: "31086",
        nm: "Valle de Egüés/Eguesibar",
      },
      {
        id: "31260",
        nm: "Valle de Yerri/Deierri",
      },
      {
        id: "31249",
        nm: "Valtierra",
      },
      {
        id: "31251",
        nm: "Viana",
      },
      {
        id: "31252",
        nm: "Vidángoz/Bidankoze",
      },
      {
        id: "31254",
        nm: "Villafranca",
      },
      {
        id: "31255",
        nm: "Villamayor de Monjardín",
      },
      {
        id: "31257",
        nm: "Villatuerta",
      },
      {
        id: "31258",
        nm: "Villava/Atarrabia",
      },
      {
        id: "31261",
        nm: "Yesa",
      },
      {
        id: "31262",
        nm: "Zabalza/Zabaltza",
      },
      {
        id: "31073",
        nm: "Ziordia",
      },
      {
        id: "31907",
        nm: "Zizur Mayor/Zizur Nagusia",
      },
      {
        id: "31263",
        nm: "Zubieta",
      },
      {
        id: "31264",
        nm: "Zugarramurdi",
      },
      {
        id: "31265",
        nm: "Zúñiga",
      },
      {
        id: "32001",
        nm: "Allariz",
      },
      {
        id: "32002",
        nm: "Amoeiro",
      },
      {
        id: "32003",
        nm: "Arnoia, A",
      },
      {
        id: "32004",
        nm: "Avión",
      },
      {
        id: "32005",
        nm: "Baltar",
      },
      {
        id: "32006",
        nm: "Bande",
      },
      {
        id: "32007",
        nm: "Baños de Molgas",
      },
      {
        id: "32008",
        nm: "Barbadás",
      },
      {
        id: "32009",
        nm: "Barco de Valdeorras, O",
      },
      {
        id: "32010",
        nm: "Beade",
      },
      {
        id: "32011",
        nm: "Beariz",
      },
      {
        id: "32012",
        nm: "Blancos, Os",
      },
      {
        id: "32013",
        nm: "Boborás",
      },
      {
        id: "32014",
        nm: "Bola, A",
      },
      {
        id: "32015",
        nm: "Bolo, O",
      },
      {
        id: "32016",
        nm: "Calvos de Randín",
      },
      {
        id: "32018",
        nm: "Carballeda de Avia",
      },
      {
        id: "32017",
        nm: "Carballeda de Valdeorras",
      },
      {
        id: "32019",
        nm: "Carballiño, O",
      },
      {
        id: "32020",
        nm: "Cartelle",
      },
      {
        id: "32022",
        nm: "Castrelo de Miño",
      },
      {
        id: "32021",
        nm: "Castrelo do Val",
      },
      {
        id: "32023",
        nm: "Castro Caldelas",
      },
      {
        id: "32024",
        nm: "Celanova",
      },
      {
        id: "32025",
        nm: "Cenlle",
      },
      {
        id: "32029",
        nm: "Chandrexa de Queixa",
      },
      {
        id: "32026",
        nm: "Coles",
      },
      {
        id: "32027",
        nm: "Cortegada",
      },
      {
        id: "32028",
        nm: "Cualedro",
      },
      {
        id: "32030",
        nm: "Entrimo",
      },
      {
        id: "32031",
        nm: "Esgos",
      },
      {
        id: "32033",
        nm: "Gomesende",
      },
      {
        id: "32034",
        nm: "Gudiña, A",
      },
      {
        id: "32035",
        nm: "Irixo, O",
      },
      {
        id: "32038",
        nm: "Larouco",
      },
      {
        id: "32039",
        nm: "Laza",
      },
      {
        id: "32040",
        nm: "Leiro",
      },
      {
        id: "32041",
        nm: "Lobeira",
      },
      {
        id: "32042",
        nm: "Lobios",
      },
      {
        id: "32043",
        nm: "Maceda",
      },
      {
        id: "32044",
        nm: "Manzaneda",
      },
      {
        id: "32045",
        nm: "Maside",
      },
      {
        id: "32046",
        nm: "Melón",
      },
      {
        id: "32047",
        nm: "Merca, A",
      },
      {
        id: "32048",
        nm: "Mezquita, A",
      },
      {
        id: "32049",
        nm: "Montederramo",
      },
      {
        id: "32050",
        nm: "Monterrei",
      },
      {
        id: "32051",
        nm: "Muíños",
      },
      {
        id: "32052",
        nm: "Nogueira de Ramuín",
      },
      {
        id: "32053",
        nm: "Oímbra",
      },
      {
        id: "32054",
        nm: "Ourense",
      },
      {
        id: "32055",
        nm: "Paderne de Allariz",
      },
      {
        id: "32056",
        nm: "Padrenda",
      },
      {
        id: "32057",
        nm: "Parada de Sil",
      },
      {
        id: "32058",
        nm: "Pereiro de Aguiar, O",
      },
      {
        id: "32059",
        nm: "Peroxa, A",
      },
      {
        id: "32060",
        nm: "Petín",
      },
      {
        id: "32061",
        nm: "Piñor",
      },
      {
        id: "32063",
        nm: "Pobra de Trives, A",
      },
      {
        id: "32064",
        nm: "Pontedeva",
      },
      {
        id: "32062",
        nm: "Porqueira",
      },
      {
        id: "32065",
        nm: "Punxín",
      },
      {
        id: "32066",
        nm: "Quintela de Leirado",
      },
      {
        id: "32067",
        nm: "Rairiz de Veiga",
      },
      {
        id: "32068",
        nm: "Ramirás",
      },
      {
        id: "32069",
        nm: "Ribadavia",
      },
      {
        id: "32071",
        nm: "Riós",
      },
      {
        id: "32072",
        nm: "Rúa, A",
      },
      {
        id: "32073",
        nm: "Rubiá",
      },
      {
        id: "32074",
        nm: "San Amaro",
      },
      {
        id: "32075",
        nm: "San Cibrao das Viñas",
      },
      {
        id: "32076",
        nm: "San Cristovo de Cea",
      },
      {
        id: "32070",
        nm: "San Xoán de Río",
      },
      {
        id: "32077",
        nm: "Sandiás",
      },
      {
        id: "32078",
        nm: "Sarreaus",
      },
      {
        id: "32079",
        nm: "Taboadela",
      },
      {
        id: "32080",
        nm: "Teixeira, A",
      },
      {
        id: "32081",
        nm: "Toén",
      },
      {
        id: "32082",
        nm: "Trasmiras",
      },
      {
        id: "32083",
        nm: "Veiga, A",
      },
      {
        id: "32084",
        nm: "Verea",
      },
      {
        id: "32085",
        nm: "Verín",
      },
      {
        id: "32086",
        nm: "Viana do Bolo",
      },
      {
        id: "32087",
        nm: "Vilamarín",
      },
      {
        id: "32088",
        nm: "Vilamartín de Valdeorras",
      },
      {
        id: "32089",
        nm: "Vilar de Barrio",
      },
      {
        id: "32090",
        nm: "Vilar de Santos",
      },
      {
        id: "32091",
        nm: "Vilardevós",
      },
      {
        id: "32092",
        nm: "Vilariño de Conso",
      },
      {
        id: "32032",
        nm: "Xinzo de Limia",
      },
      {
        id: "32036",
        nm: "Xunqueira de Ambía",
      },
      {
        id: "32037",
        nm: "Xunqueira de Espadanedo",
      },
      {
        id: "33001",
        nm: "Allande",
      },
      {
        id: "33002",
        nm: "Aller",
      },
      {
        id: "33003",
        nm: "Amieva",
      },
      {
        id: "33004",
        nm: "Avilés",
      },
      {
        id: "33005",
        nm: "Belmonte de Miranda",
      },
      {
        id: "33006",
        nm: "Bimenes",
      },
      {
        id: "33007",
        nm: "Boal",
      },
      {
        id: "33008",
        nm: "Cabrales",
      },
      {
        id: "33009",
        nm: "Cabranes",
      },
      {
        id: "33010",
        nm: "Candamo",
      },
      {
        id: "33012",
        nm: "Cangas de Onís",
      },
      {
        id: "33011",
        nm: "Cangas del Narcea",
      },
      {
        id: "33013",
        nm: "Caravia",
      },
      {
        id: "33014",
        nm: "Carreño",
      },
      {
        id: "33015",
        nm: "Caso",
      },
      {
        id: "33016",
        nm: "Castrillón",
      },
      {
        id: "33017",
        nm: "Castropol",
      },
      {
        id: "33018",
        nm: "Coaña",
      },
      {
        id: "33019",
        nm: "Colunga",
      },
      {
        id: "33020",
        nm: "Corvera de Asturias",
      },
      {
        id: "33021",
        nm: "Cudillero",
      },
      {
        id: "33022",
        nm: "Degaña",
      },
      {
        id: "33023",
        nm: "Franco, El",
      },
      {
        id: "33024",
        nm: "Gijón",
      },
      {
        id: "33025",
        nm: "Gozón",
      },
      {
        id: "33026",
        nm: "Grado",
      },
      {
        id: "33027",
        nm: "Grandas de Salime",
      },
      {
        id: "33028",
        nm: "Ibias",
      },
      {
        id: "33029",
        nm: "Illano",
      },
      {
        id: "33030",
        nm: "Illas",
      },
      {
        id: "33031",
        nm: "Langreo",
      },
      {
        id: "33032",
        nm: "Laviana",
      },
      {
        id: "33033",
        nm: "Lena",
      },
      {
        id: "33035",
        nm: "Llanera",
      },
      {
        id: "33036",
        nm: "Llanes",
      },
      {
        id: "33037",
        nm: "Mieres",
      },
      {
        id: "33038",
        nm: "Morcín",
      },
      {
        id: "33039",
        nm: "Muros de Nalón",
      },
      {
        id: "33040",
        nm: "Nava",
      },
      {
        id: "33041",
        nm: "Navia",
      },
      {
        id: "33042",
        nm: "Noreña",
      },
      {
        id: "33043",
        nm: "Onís",
      },
      {
        id: "33044",
        nm: "Oviedo",
      },
      {
        id: "33045",
        nm: "Parres",
      },
      {
        id: "33046",
        nm: "Peñamellera Alta",
      },
      {
        id: "33047",
        nm: "Peñamellera Baja",
      },
      {
        id: "33048",
        nm: "Pesoz",
      },
      {
        id: "33049",
        nm: "Piloña",
      },
      {
        id: "33050",
        nm: "Ponga",
      },
      {
        id: "33051",
        nm: "Pravia",
      },
      {
        id: "33052",
        nm: "Proaza",
      },
      {
        id: "33053",
        nm: "Quirós",
      },
      {
        id: "33054",
        nm: "Regueras, Las",
      },
      {
        id: "33055",
        nm: "Ribadedeva",
      },
      {
        id: "33056",
        nm: "Ribadesella",
      },
      {
        id: "33057",
        nm: "Ribera de Arriba",
      },
      {
        id: "33058",
        nm: "Riosa",
      },
      {
        id: "33059",
        nm: "Salas",
      },
      {
        id: "33061",
        nm: "San Martín de Oscos",
      },
      {
        id: "33060",
        nm: "San Martín del Rey Aurelio",
      },
      {
        id: "33063",
        nm: "San Tirso de Abres",
      },
      {
        id: "33062",
        nm: "Santa Eulalia de Oscos",
      },
      {
        id: "33064",
        nm: "Santo Adriano",
      },
      {
        id: "33065",
        nm: "Sariego",
      },
      {
        id: "33066",
        nm: "Siero",
      },
      {
        id: "33067",
        nm: "Sobrescobio",
      },
      {
        id: "33068",
        nm: "Somiedo",
      },
      {
        id: "33069",
        nm: "Soto del Barco",
      },
      {
        id: "33070",
        nm: "Tapia de Casariego",
      },
      {
        id: "33071",
        nm: "Taramundi",
      },
      {
        id: "33072",
        nm: "Teverga",
      },
      {
        id: "33073",
        nm: "Tineo",
      },
      {
        id: "33034",
        nm: "Valdés",
      },
      {
        id: "33074",
        nm: "Vegadeo",
      },
      {
        id: "33075",
        nm: "Villanueva de Oscos",
      },
      {
        id: "33076",
        nm: "Villaviciosa",
      },
      {
        id: "33077",
        nm: "Villayón",
      },
      {
        id: "33078",
        nm: "Yernes y Tameza",
      },
      {
        id: "34001",
        nm: "Abarca de Campos",
      },
      {
        id: "34003",
        nm: "Abia de las Torres",
      },
      {
        id: "34004",
        nm: "Aguilar de Campoo",
      },
      {
        id: "34005",
        nm: "Alar del Rey",
      },
      {
        id: "34006",
        nm: "Alba de Cerrato",
      },
      {
        id: "34009",
        nm: "Amayuelas de Arriba",
      },
      {
        id: "34010",
        nm: "Ampudia",
      },
      {
        id: "34011",
        nm: "Amusco",
      },
      {
        id: "34012",
        nm: "Antigüedad",
      },
      {
        id: "34015",
        nm: "Arconada",
      },
      {
        id: "34017",
        nm: "Astudillo",
      },
      {
        id: "34018",
        nm: "Autilla del Pino",
      },
      {
        id: "34019",
        nm: "Autillo de Campos",
      },
      {
        id: "34020",
        nm: "Ayuela",
      },
      {
        id: "34022",
        nm: "Baltanás",
      },
      {
        id: "34024",
        nm: "Baquerín de Campos",
      },
      {
        id: "34025",
        nm: "Bárcena de Campos",
      },
      {
        id: "34027",
        nm: "Barruelo de Santullán",
      },
      {
        id: "34028",
        nm: "Báscones de Ojeda",
      },
      {
        id: "34029",
        nm: "Becerril de Campos",
      },
      {
        id: "34031",
        nm: "Belmonte de Campos",
      },
      {
        id: "34032",
        nm: "Berzosilla",
      },
      {
        id: "34033",
        nm: "Boada de Campos",
      },
      {
        id: "34035",
        nm: "Boadilla de Rioseco",
      },
      {
        id: "34034",
        nm: "Boadilla del Camino",
      },
      {
        id: "34036",
        nm: "Brañosera",
      },
      {
        id: "34037",
        nm: "Buenavista de Valdavia",
      },
      {
        id: "34038",
        nm: "Bustillo de la Vega",
      },
      {
        id: "34039",
        nm: "Bustillo del Páramo de Carrión",
      },
      {
        id: "34041",
        nm: "Calahorra de Boedo",
      },
      {
        id: "34042",
        nm: "Calzada de los Molinos",
      },
      {
        id: "34045",
        nm: "Capillas",
      },
      {
        id: "34046",
        nm: "Cardeñosa de Volpejera",
      },
      {
        id: "34047",
        nm: "Carrión de los Condes",
      },
      {
        id: "34048",
        nm: "Castil de Vela",
      },
      {
        id: "34049",
        nm: "Castrejón de la Peña",
      },
      {
        id: "34050",
        nm: "Castrillo de Don Juan",
      },
      {
        id: "34051",
        nm: "Castrillo de Onielo",
      },
      {
        id: "34052",
        nm: "Castrillo de Villavega",
      },
      {
        id: "34053",
        nm: "Castromocho",
      },
      {
        id: "34055",
        nm: "Cervatos de la Cueza",
      },
      {
        id: "34056",
        nm: "Cervera de Pisuerga",
      },
      {
        id: "34057",
        nm: "Cevico de la Torre",
      },
      {
        id: "34058",
        nm: "Cevico Navero",
      },
      {
        id: "34059",
        nm: "Cisneros",
      },
      {
        id: "34060",
        nm: "Cobos de Cerrato",
      },
      {
        id: "34061",
        nm: "Collazos de Boedo",
      },
      {
        id: "34062",
        nm: "Congosto de Valdavia",
      },
      {
        id: "34063",
        nm: "Cordovilla la Real",
      },
      {
        id: "34066",
        nm: "Cubillas de Cerrato",
      },
      {
        id: "34067",
        nm: "Dehesa de Montejo",
      },
      {
        id: "34068",
        nm: "Dehesa de Romanos",
      },
      {
        id: "34069",
        nm: "Dueñas",
      },
      {
        id: "34070",
        nm: "Espinosa de Cerrato",
      },
      {
        id: "34071",
        nm: "Espinosa de Villagonzalo",
      },
      {
        id: "34072",
        nm: "Frechilla",
      },
      {
        id: "34073",
        nm: "Fresno del Río",
      },
      {
        id: "34074",
        nm: "Frómista",
      },
      {
        id: "34076",
        nm: "Fuentes de Nava",
      },
      {
        id: "34077",
        nm: "Fuentes de Valdepero",
      },
      {
        id: "34079",
        nm: "Grijota",
      },
      {
        id: "34080",
        nm: "Guardo",
      },
      {
        id: "34081",
        nm: "Guaza de Campos",
      },
      {
        id: "34082",
        nm: "Hérmedes de Cerrato",
      },
      {
        id: "34083",
        nm: "Herrera de Pisuerga",
      },
      {
        id: "34084",
        nm: "Herrera de Valdecañas",
      },
      {
        id: "34086",
        nm: "Hontoria de Cerrato",
      },
      {
        id: "34087",
        nm: "Hornillos de Cerrato",
      },
      {
        id: "34088",
        nm: "Husillos",
      },
      {
        id: "34089",
        nm: "Itero de la Vega",
      },
      {
        id: "34091",
        nm: "Lagartos",
      },
      {
        id: "34092",
        nm: "Lantadilla",
      },
      {
        id: "34094",
        nm: "Ledigos",
      },
      {
        id: "34903",
        nm: "Loma de Ucieza",
      },
      {
        id: "34096",
        nm: "Lomas",
      },
      {
        id: "34098",
        nm: "Magaz de Pisuerga",
      },
      {
        id: "34099",
        nm: "Manquillos",
      },
      {
        id: "34100",
        nm: "Mantinos",
      },
      {
        id: "34101",
        nm: "Marcilla de Campos",
      },
      {
        id: "34102",
        nm: "Mazariegos",
      },
      {
        id: "34103",
        nm: "Mazuecos de Valdeginate",
      },
      {
        id: "34104",
        nm: "Melgar de Yuso",
      },
      {
        id: "34106",
        nm: "Meneses de Campos",
      },
      {
        id: "34107",
        nm: "Micieces de Ojeda",
      },
      {
        id: "34108",
        nm: "Monzón de Campos",
      },
      {
        id: "34109",
        nm: "Moratinos",
      },
      {
        id: "34110",
        nm: "Mudá",
      },
      {
        id: "34112",
        nm: "Nogal de las Huertas",
      },
      {
        id: "34113",
        nm: "Olea de Boedo",
      },
      {
        id: "34114",
        nm: "Olmos de Ojeda",
      },
      {
        id: "34116",
        nm: "Osornillo",
      },
      {
        id: "34901",
        nm: "Osorno la Mayor",
      },
      {
        id: "34120",
        nm: "Palencia",
      },
      {
        id: "34121",
        nm: "Palenzuela",
      },
      {
        id: "34122",
        nm: "Páramo de Boedo",
      },
      {
        id: "34123",
        nm: "Paredes de Nava",
      },
      {
        id: "34124",
        nm: "Payo de Ojeda",
      },
      {
        id: "34125",
        nm: "Pedraza de Campos",
      },
      {
        id: "34126",
        nm: "Pedrosa de la Vega",
      },
      {
        id: "34127",
        nm: "Perales",
      },
      {
        id: "34904",
        nm: "Pernía, La",
      },
      {
        id: "34129",
        nm: "Pino del Río",
      },
      {
        id: "34130",
        nm: "Piña de Campos",
      },
      {
        id: "34131",
        nm: "Población de Arroyo",
      },
      {
        id: "34132",
        nm: "Población de Campos",
      },
      {
        id: "34133",
        nm: "Población de Cerrato",
      },
      {
        id: "34134",
        nm: "Polentinos",
      },
      {
        id: "34135",
        nm: "Pomar de Valdivia",
      },
      {
        id: "34136",
        nm: "Poza de la Vega",
      },
      {
        id: "34137",
        nm: "Pozo de Urama",
      },
      {
        id: "34139",
        nm: "Prádanos de Ojeda",
      },
      {
        id: "34140",
        nm: "Puebla de Valdavia, La",
      },
      {
        id: "34141",
        nm: "Quintana del Puente",
      },
      {
        id: "34143",
        nm: "Quintanilla de Onsoña",
      },
      {
        id: "34146",
        nm: "Reinoso de Cerrato",
      },
      {
        id: "34147",
        nm: "Renedo de la Vega",
      },
      {
        id: "34149",
        nm: "Requena de Campos",
      },
      {
        id: "34151",
        nm: "Respenda de la Peña",
      },
      {
        id: "34152",
        nm: "Revenga de Campos",
      },
      {
        id: "34154",
        nm: "Revilla de Collazos",
      },
      {
        id: "34155",
        nm: "Ribas de Campos",
      },
      {
        id: "34156",
        nm: "Riberos de la Cueza",
      },
      {
        id: "34157",
        nm: "Saldaña",
      },
      {
        id: "34158",
        nm: "Salinas de Pisuerga",
      },
      {
        id: "34159",
        nm: "San Cebrián de Campos",
      },
      {
        id: "34160",
        nm: "San Cebrián de Mudá",
      },
      {
        id: "34161",
        nm: "San Cristóbal de Boedo",
      },
      {
        id: "34163",
        nm: "San Mamés de Campos",
      },
      {
        id: "34165",
        nm: "San Román de la Cuba",
      },
      {
        id: "34167",
        nm: "Santa Cecilia del Alcor",
      },
      {
        id: "34168",
        nm: "Santa Cruz de Boedo",
      },
      {
        id: "34169",
        nm: "Santervás de la Vega",
      },
      {
        id: "34170",
        nm: "Santibáñez de Ecla",
      },
      {
        id: "34171",
        nm: "Santibáñez de la Peña",
      },
      {
        id: "34174",
        nm: "Santoyo",
      },
      {
        id: "34175",
        nm: "Serna, La",
      },
      {
        id: "34177",
        nm: "Soto de Cerrato",
      },
      {
        id: "34176",
        nm: "Sotobañado y Priorato",
      },
      {
        id: "34178",
        nm: "Tabanera de Cerrato",
      },
      {
        id: "34179",
        nm: "Tabanera de Valdavia",
      },
      {
        id: "34180",
        nm: "Támara de Campos",
      },
      {
        id: "34181",
        nm: "Tariego de Cerrato",
      },
      {
        id: "34182",
        nm: "Torquemada",
      },
      {
        id: "34184",
        nm: "Torremormojón",
      },
      {
        id: "34185",
        nm: "Triollo",
      },
      {
        id: "34186",
        nm: "Valbuena de Pisuerga",
      },
      {
        id: "34189",
        nm: "Valdeolmillos",
      },
      {
        id: "34190",
        nm: "Valderrábano",
      },
      {
        id: "34192",
        nm: "Valde-Ucieza",
      },
      {
        id: "34196",
        nm: "Valle de Cerrato",
      },
      {
        id: "34902",
        nm: "Valle del Retortillo",
      },
      {
        id: "34199",
        nm: "Velilla del Río Carrión",
      },
      {
        id: "34023",
        nm: "Venta de Baños",
      },
      {
        id: "34201",
        nm: "Vertavillo",
      },
      {
        id: "34093",
        nm: "Vid de Ojeda, La",
      },
      {
        id: "34202",
        nm: "Villabasta de Valdavia",
      },
      {
        id: "34204",
        nm: "Villacidaler",
      },
      {
        id: "34205",
        nm: "Villaconancio",
      },
      {
        id: "34206",
        nm: "Villada",
      },
      {
        id: "34208",
        nm: "Villaeles de Valdavia",
      },
      {
        id: "34210",
        nm: "Villahán",
      },
      {
        id: "34211",
        nm: "Villaherreros",
      },
      {
        id: "34213",
        nm: "Villalaco",
      },
      {
        id: "34214",
        nm: "Villalba de Guardo",
      },
      {
        id: "34215",
        nm: "Villalcázar de Sirga",
      },
      {
        id: "34216",
        nm: "Villalcón",
      },
      {
        id: "34217",
        nm: "Villalobón",
      },
      {
        id: "34218",
        nm: "Villaluenga de la Vega",
      },
      {
        id: "34220",
        nm: "Villamartín de Campos",
      },
      {
        id: "34221",
        nm: "Villamediana",
      },
      {
        id: "34222",
        nm: "Villameriel",
      },
      {
        id: "34223",
        nm: "Villamoronta",
      },
      {
        id: "34224",
        nm: "Villamuera de la Cueza",
      },
      {
        id: "34225",
        nm: "Villamuriel de Cerrato",
      },
      {
        id: "34227",
        nm: "Villanueva del Rebollar",
      },
      {
        id: "34228",
        nm: "Villanuño de Valdavia",
      },
      {
        id: "34229",
        nm: "Villaprovedo",
      },
      {
        id: "34230",
        nm: "Villarmentero de Campos",
      },
      {
        id: "34231",
        nm: "Villarrabé",
      },
      {
        id: "34232",
        nm: "Villarramiel",
      },
      {
        id: "34233",
        nm: "Villasarracino",
      },
      {
        id: "34234",
        nm: "Villasila de Valdavia",
      },
      {
        id: "34236",
        nm: "Villaturde",
      },
      {
        id: "34237",
        nm: "Villaumbrales",
      },
      {
        id: "34238",
        nm: "Villaviudas",
      },
      {
        id: "34240",
        nm: "Villerías de Campos",
      },
      {
        id: "34241",
        nm: "Villodre",
      },
      {
        id: "34242",
        nm: "Villodrigo",
      },
      {
        id: "34243",
        nm: "Villoldo",
      },
      {
        id: "34245",
        nm: "Villota del Páramo",
      },
      {
        id: "34246",
        nm: "Villovieco",
      },
      {
        id: "35001",
        nm: "Agaete",
      },
      {
        id: "35002",
        nm: "Agüimes",
      },
      {
        id: "35020",
        nm: "Aldea de San Nicolás, La",
      },
      {
        id: "35003",
        nm: "Antigua",
      },
      {
        id: "35004",
        nm: "Arrecife",
      },
      {
        id: "35005",
        nm: "Artenara",
      },
      {
        id: "35006",
        nm: "Arucas",
      },
      {
        id: "35007",
        nm: "Betancuria",
      },
      {
        id: "35008",
        nm: "Firgas",
      },
      {
        id: "35009",
        nm: "Gáldar",
      },
      {
        id: "35010",
        nm: "Haría",
      },
      {
        id: "35011",
        nm: "Ingenio",
      },
      {
        id: "35012",
        nm: "Mogán",
      },
      {
        id: "35013",
        nm: "Moya",
      },
      {
        id: "35014",
        nm: "Oliva, La",
      },
      {
        id: "35015",
        nm: "Pájara",
      },
      {
        id: "35016",
        nm: "Palmas de Gran Canaria, Las",
      },
      {
        id: "35017",
        nm: "Puerto del Rosario",
      },
      {
        id: "35018",
        nm: "San Bartolomé",
      },
      {
        id: "35019",
        nm: "San Bartolomé de Tirajana",
      },
      {
        id: "35021",
        nm: "Santa Brígida",
      },
      {
        id: "35022",
        nm: "Santa Lucía de Tirajana",
      },
      {
        id: "35023",
        nm: "Santa María de Guía de Gran Canaria",
      },
      {
        id: "35024",
        nm: "Teguise",
      },
      {
        id: "35025",
        nm: "Tejeda",
      },
      {
        id: "35026",
        nm: "Telde",
      },
      {
        id: "35027",
        nm: "Teror",
      },
      {
        id: "35028",
        nm: "Tías",
      },
      {
        id: "35029",
        nm: "Tinajo",
      },
      {
        id: "35030",
        nm: "Tuineje",
      },
      {
        id: "35032",
        nm: "Valleseco",
      },
      {
        id: "35031",
        nm: "Valsequillo de Gran Canaria",
      },
      {
        id: "35033",
        nm: "Vega de San Mateo",
      },
      {
        id: "35034",
        nm: "Yaiza",
      },
      {
        id: "36020",
        nm: "Agolada",
      },
      {
        id: "36001",
        nm: "Arbo",
      },
      {
        id: "36003",
        nm: "Baiona",
      },
      {
        id: "36002",
        nm: "Barro",
      },
      {
        id: "36004",
        nm: "Bueu",
      },
      {
        id: "36005",
        nm: "Caldas de Reis",
      },
      {
        id: "36006",
        nm: "Cambados",
      },
      {
        id: "36007",
        nm: "Campo Lameiro",
      },
      {
        id: "36008",
        nm: "Cangas",
      },
      {
        id: "36009",
        nm: "Cañiza, A",
      },
      {
        id: "36010",
        nm: "Catoira",
      },
      {
        id: "36011",
        nm: "Cerdedo",
      },
      {
        id: "36012",
        nm: "Cotobade",
      },
      {
        id: "36013",
        nm: "Covelo",
      },
      {
        id: "36014",
        nm: "Crecente",
      },
      {
        id: "36015",
        nm: "Cuntis",
      },
      {
        id: "36016",
        nm: "Dozón",
      },
      {
        id: "36017",
        nm: "Estrada, A",
      },
      {
        id: "36018",
        nm: "Forcarei",
      },
      {
        id: "36019",
        nm: "Fornelos de Montes",
      },
      {
        id: "36021",
        nm: "Gondomar",
      },
      {
        id: "36022",
        nm: "Grove, O",
      },
      {
        id: "36023",
        nm: "Guarda, A",
      },
      {
        id: "36901",
        nm: "Illa de Arousa, A",
      },
      {
        id: "36024",
        nm: "Lalín",
      },
      {
        id: "36025",
        nm: "Lama, A",
      },
      {
        id: "36026",
        nm: "Marín",
      },
      {
        id: "36027",
        nm: "Meaño",
      },
      {
        id: "36028",
        nm: "Meis",
      },
      {
        id: "36029",
        nm: "Moaña",
      },
      {
        id: "36030",
        nm: "Mondariz",
      },
      {
        id: "36031",
        nm: "Mondariz-Balneario",
      },
      {
        id: "36032",
        nm: "Moraña",
      },
      {
        id: "36033",
        nm: "Mos",
      },
      {
        id: "36034",
        nm: "Neves, As",
      },
      {
        id: "36035",
        nm: "Nigrán",
      },
      {
        id: "36036",
        nm: "Oia",
      },
      {
        id: "36037",
        nm: "Pazos de Borbén",
      },
      {
        id: "36041",
        nm: "Poio",
      },
      {
        id: "36043",
        nm: "Ponte Caldelas",
      },
      {
        id: "36042",
        nm: "Ponteareas",
      },
      {
        id: "36044",
        nm: "Pontecesures",
      },
      {
        id: "36038",
        nm: "Pontevedra",
      },
      {
        id: "36039",
        nm: "Porriño, O",
      },
      {
        id: "36040",
        nm: "Portas",
      },
      {
        id: "36045",
        nm: "Redondela",
      },
      {
        id: "36046",
        nm: "Ribadumia",
      },
      {
        id: "36047",
        nm: "Rodeiro",
      },
      {
        id: "36048",
        nm: "Rosal, O",
      },
      {
        id: "36049",
        nm: "Salceda de Caselas",
      },
      {
        id: "36050",
        nm: "Salvaterra de Miño",
      },
      {
        id: "36051",
        nm: "Sanxenxo",
      },
      {
        id: "36052",
        nm: "Silleda",
      },
      {
        id: "36053",
        nm: "Soutomaior",
      },
      {
        id: "36054",
        nm: "Tomiño",
      },
      {
        id: "36055",
        nm: "Tui",
      },
      {
        id: "36056",
        nm: "Valga",
      },
      {
        id: "36057",
        nm: "Vigo",
      },
      {
        id: "36059",
        nm: "Vila de Cruces",
      },
      {
        id: "36058",
        nm: "Vilaboa",
      },
      {
        id: "36060",
        nm: "Vilagarcía de Arousa",
      },
      {
        id: "36061",
        nm: "Vilanova de Arousa",
      },
      {
        id: "37001",
        nm: "Abusejo",
      },
      {
        id: "37002",
        nm: "Agallas",
      },
      {
        id: "37003",
        nm: "Ahigal de los Aceiteros",
      },
      {
        id: "37004",
        nm: "Ahigal de Villarino",
      },
      {
        id: "37005",
        nm: "Alameda de Gardón, La",
      },
      {
        id: "37006",
        nm: "Alamedilla, La",
      },
      {
        id: "37007",
        nm: "Alaraz",
      },
      {
        id: "37008",
        nm: "Alba de Tormes",
      },
      {
        id: "37009",
        nm: "Alba de Yeltes",
      },
      {
        id: "37010",
        nm: "Alberca, La",
      },
      {
        id: "37011",
        nm: "Alberguería de Argañán, La",
      },
      {
        id: "37012",
        nm: "Alconada",
      },
      {
        id: "37015",
        nm: "Aldea del Obispo",
      },
      {
        id: "37013",
        nm: "Aldeacipreste",
      },
      {
        id: "37014",
        nm: "Aldeadávila de la Ribera",
      },
      {
        id: "37016",
        nm: "Aldealengua",
      },
      {
        id: "37017",
        nm: "Aldeanueva de Figueroa",
      },
      {
        id: "37018",
        nm: "Aldeanueva de la Sierra",
      },
      {
        id: "37019",
        nm: "Aldearrodrigo",
      },
      {
        id: "37020",
        nm: "Aldearrubia",
      },
      {
        id: "37021",
        nm: "Aldeaseca de Alba",
      },
      {
        id: "37022",
        nm: "Aldeaseca de la Frontera",
      },
      {
        id: "37023",
        nm: "Aldeatejada",
      },
      {
        id: "37024",
        nm: "Aldeavieja de Tormes",
      },
      {
        id: "37025",
        nm: "Aldehuela de la Bóveda",
      },
      {
        id: "37026",
        nm: "Aldehuela de Yeltes",
      },
      {
        id: "37027",
        nm: "Almenara de Tormes",
      },
      {
        id: "37028",
        nm: "Almendra",
      },
      {
        id: "37029",
        nm: "Anaya de Alba",
      },
      {
        id: "37030",
        nm: "Añover de Tormes",
      },
      {
        id: "37031",
        nm: "Arabayona de Mógica",
      },
      {
        id: "37032",
        nm: "Arapiles",
      },
      {
        id: "37033",
        nm: "Arcediano",
      },
      {
        id: "37034",
        nm: "Arco, El",
      },
      {
        id: "37035",
        nm: "Armenteros",
      },
      {
        id: "37037",
        nm: "Atalaya, La",
      },
      {
        id: "37038",
        nm: "Babilafuente",
      },
      {
        id: "37039",
        nm: "Bañobárez",
      },
      {
        id: "37040",
        nm: "Barbadillo",
      },
      {
        id: "37041",
        nm: "Barbalos",
      },
      {
        id: "37042",
        nm: "Barceo",
      },
      {
        id: "37044",
        nm: "Barruecopardo",
      },
      {
        id: "37045",
        nm: "Bastida, La",
      },
      {
        id: "37046",
        nm: "Béjar",
      },
      {
        id: "37047",
        nm: "Beleña",
      },
      {
        id: "37049",
        nm: "Bermellar",
      },
      {
        id: "37050",
        nm: "Berrocal de Huebra",
      },
      {
        id: "37051",
        nm: "Berrocal de Salvatierra",
      },
      {
        id: "37052",
        nm: "Boada",
      },
      {
        id: "37054",
        nm: "Bodón, El",
      },
      {
        id: "37055",
        nm: "Bogajo",
      },
      {
        id: "37056",
        nm: "Bouza, La",
      },
      {
        id: "37057",
        nm: "Bóveda del Río Almar",
      },
      {
        id: "37058",
        nm: "Brincones",
      },
      {
        id: "37059",
        nm: "Buenamadre",
      },
      {
        id: "37060",
        nm: "Buenavista",
      },
      {
        id: "37061",
        nm: "Cabaco, El",
      },
      {
        id: "37063",
        nm: "Cabeza de Béjar, La",
      },
      {
        id: "37065",
        nm: "Cabeza del Caballo",
      },
      {
        id: "37062",
        nm: "Cabezabellosa de la Calzada",
      },
      {
        id: "37067",
        nm: "Cabrerizos",
      },
      {
        id: "37068",
        nm: "Cabrillas",
      },
      {
        id: "37069",
        nm: "Calvarrasa de Abajo",
      },
      {
        id: "37070",
        nm: "Calvarrasa de Arriba",
      },
      {
        id: "37071",
        nm: "Calzada de Béjar, La",
      },
      {
        id: "37072",
        nm: "Calzada de Don Diego",
      },
      {
        id: "37073",
        nm: "Calzada de Valdunciel",
      },
      {
        id: "37074",
        nm: "Campillo de Azaba",
      },
      {
        id: "37077",
        nm: "Campo de Peñaranda, El",
      },
      {
        id: "37078",
        nm: "Candelario",
      },
      {
        id: "37079",
        nm: "Canillas de Abajo",
      },
      {
        id: "37080",
        nm: "Cantagallo",
      },
      {
        id: "37081",
        nm: "Cantalapiedra",
      },
      {
        id: "37082",
        nm: "Cantalpino",
      },
      {
        id: "37083",
        nm: "Cantaracillo",
      },
      {
        id: "37085",
        nm: "Carbajosa de la Sagrada",
      },
      {
        id: "37086",
        nm: "Carpio de Azaba",
      },
      {
        id: "37087",
        nm: "Carrascal de Barregas",
      },
      {
        id: "37088",
        nm: "Carrascal del Obispo",
      },
      {
        id: "37089",
        nm: "Casafranca",
      },
      {
        id: "37090",
        nm: "Casas del Conde, Las",
      },
      {
        id: "37091",
        nm: "Casillas de Flores",
      },
      {
        id: "37092",
        nm: "Castellanos de Moriscos",
      },
      {
        id: "37185",
        nm: "Castellanos de Villiquera",
      },
      {
        id: "37096",
        nm: "Castillejo de Martín Viejo",
      },
      {
        id: "37097",
        nm: "Castraz",
      },
      {
        id: "37098",
        nm: "Cepeda",
      },
      {
        id: "37099",
        nm: "Cereceda de la Sierra",
      },
      {
        id: "37100",
        nm: "Cerezal de Peñahorcada",
      },
      {
        id: "37101",
        nm: "Cerralbo",
      },
      {
        id: "37102",
        nm: "Cerro, El",
      },
      {
        id: "37103",
        nm: "Cespedosa de Tormes",
      },
      {
        id: "37114",
        nm: "Chagarcía Medianero",
      },
      {
        id: "37104",
        nm: "Cilleros de la Bastida",
      },
      {
        id: "37106",
        nm: "Cipérez",
      },
      {
        id: "37107",
        nm: "Ciudad Rodrigo",
      },
      {
        id: "37108",
        nm: "Coca de Alba",
      },
      {
        id: "37109",
        nm: "Colmenar de Montemayor",
      },
      {
        id: "37110",
        nm: "Cordovilla",
      },
      {
        id: "37112",
        nm: "Cristóbal",
      },
      {
        id: "37113",
        nm: "Cubo de Don Sancho, El",
      },
      {
        id: "37115",
        nm: "Dios le Guarde",
      },
      {
        id: "37116",
        nm: "Doñinos de Ledesma",
      },
      {
        id: "37117",
        nm: "Doñinos de Salamanca",
      },
      {
        id: "37118",
        nm: "Ejeme",
      },
      {
        id: "37120",
        nm: "Encina de San Silvestre",
      },
      {
        id: "37119",
        nm: "Encina, La",
      },
      {
        id: "37121",
        nm: "Encinas de Abajo",
      },
      {
        id: "37122",
        nm: "Encinas de Arriba",
      },
      {
        id: "37123",
        nm: "Encinasola de los Comendadores",
      },
      {
        id: "37124",
        nm: "Endrinal",
      },
      {
        id: "37125",
        nm: "Escurial de la Sierra",
      },
      {
        id: "37126",
        nm: "Espadaña",
      },
      {
        id: "37127",
        nm: "Espeja",
      },
      {
        id: "37128",
        nm: "Espino de la Orbada",
      },
      {
        id: "37129",
        nm: "Florida de Liébana",
      },
      {
        id: "37130",
        nm: "Forfoleda",
      },
      {
        id: "37131",
        nm: "Frades de la Sierra",
      },
      {
        id: "37132",
        nm: "Fregeneda, La",
      },
      {
        id: "37133",
        nm: "Fresnedoso",
      },
      {
        id: "37134",
        nm: "Fresno Alhándiga",
      },
      {
        id: "37135",
        nm: "Fuente de San Esteban, La",
      },
      {
        id: "37136",
        nm: "Fuenteguinaldo",
      },
      {
        id: "37137",
        nm: "Fuenteliante",
      },
      {
        id: "37138",
        nm: "Fuenterroble de Salvatierra",
      },
      {
        id: "37139",
        nm: "Fuentes de Béjar",
      },
      {
        id: "37140",
        nm: "Fuentes de Oñoro",
      },
      {
        id: "37141",
        nm: "Gajates",
      },
      {
        id: "37142",
        nm: "Galindo y Perahuy",
      },
      {
        id: "37143",
        nm: "Galinduste",
      },
      {
        id: "37144",
        nm: "Galisancho",
      },
      {
        id: "37145",
        nm: "Gallegos de Argañán",
      },
      {
        id: "37146",
        nm: "Gallegos de Solmirón",
      },
      {
        id: "37147",
        nm: "Garcibuey",
      },
      {
        id: "37148",
        nm: "Garcihernández",
      },
      {
        id: "37149",
        nm: "Garcirrey",
      },
      {
        id: "37150",
        nm: "Gejuelo del Barro",
      },
      {
        id: "37151",
        nm: "Golpejas",
      },
      {
        id: "37152",
        nm: "Gomecello",
      },
      {
        id: "37154",
        nm: "Guadramiro",
      },
      {
        id: "37155",
        nm: "Guijo de Ávila",
      },
      {
        id: "37156",
        nm: "Guijuelo",
      },
      {
        id: "37157",
        nm: "Herguijuela de Ciudad Rodrigo",
      },
      {
        id: "37158",
        nm: "Herguijuela de la Sierra",
      },
      {
        id: "37159",
        nm: "Herguijuela del Campo",
      },
      {
        id: "37160",
        nm: "Hinojosa de Duero",
      },
      {
        id: "37161",
        nm: "Horcajo de Montemayor",
      },
      {
        id: "37162",
        nm: "Horcajo Medianero",
      },
      {
        id: "37163",
        nm: "Hoya, La",
      },
      {
        id: "37164",
        nm: "Huerta",
      },
      {
        id: "37165",
        nm: "Iruelos",
      },
      {
        id: "37166",
        nm: "Ituero de Azaba",
      },
      {
        id: "37167",
        nm: "Juzbado",
      },
      {
        id: "37168",
        nm: "Lagunilla",
      },
      {
        id: "37169",
        nm: "Larrodrigo",
      },
      {
        id: "37170",
        nm: "Ledesma",
      },
      {
        id: "37171",
        nm: "Ledrada",
      },
      {
        id: "37172",
        nm: "Linares de Riofrío",
      },
      {
        id: "37173",
        nm: "Lumbrales",
      },
      {
        id: "37175",
        nm: "Machacón",
      },
      {
        id: "37174",
        nm: "Macotera",
      },
      {
        id: "37176",
        nm: "Madroñal",
      },
      {
        id: "37177",
        nm: "Maíllo, El",
      },
      {
        id: "37178",
        nm: "Malpartida",
      },
      {
        id: "37179",
        nm: "Mancera de Abajo",
      },
      {
        id: "37180",
        nm: "Manzano, El",
      },
      {
        id: "37181",
        nm: "Martiago",
      },
      {
        id: "37183",
        nm: "Martín de Yeltes",
      },
      {
        id: "37182",
        nm: "Martinamor",
      },
      {
        id: "37184",
        nm: "Masueco",
      },
      {
        id: "37186",
        nm: "Mata de Ledesma, La",
      },
      {
        id: "37187",
        nm: "Matilla de los Caños del Río",
      },
      {
        id: "37188",
        nm: "Maya, La",
      },
      {
        id: "37189",
        nm: "Membribe de la Sierra",
      },
      {
        id: "37190",
        nm: "Mieza",
      },
      {
        id: "37191",
        nm: "Milano, El",
      },
      {
        id: "37192",
        nm: "Miranda de Azán",
      },
      {
        id: "37193",
        nm: "Miranda del Castañar",
      },
      {
        id: "37194",
        nm: "Mogarraz",
      },
      {
        id: "37195",
        nm: "Molinillo",
      },
      {
        id: "37196",
        nm: "Monforte de la Sierra",
      },
      {
        id: "37197",
        nm: "Monleón",
      },
      {
        id: "37198",
        nm: "Monleras",
      },
      {
        id: "37199",
        nm: "Monsagro",
      },
      {
        id: "37200",
        nm: "Montejo",
      },
      {
        id: "37201",
        nm: "Montemayor del Río",
      },
      {
        id: "37202",
        nm: "Monterrubio de Armuña",
      },
      {
        id: "37203",
        nm: "Monterrubio de la Sierra",
      },
      {
        id: "37204",
        nm: "Morasverdes",
      },
      {
        id: "37205",
        nm: "Morille",
      },
      {
        id: "37206",
        nm: "Moríñigo",
      },
      {
        id: "37207",
        nm: "Moriscos",
      },
      {
        id: "37208",
        nm: "Moronta",
      },
      {
        id: "37209",
        nm: "Mozárbez",
      },
      {
        id: "37211",
        nm: "Narros de Matalayegua",
      },
      {
        id: "37213",
        nm: "Nava de Béjar",
      },
      {
        id: "37214",
        nm: "Nava de Francia",
      },
      {
        id: "37215",
        nm: "Nava de Sotrobal",
      },
      {
        id: "37212",
        nm: "Navacarros",
      },
      {
        id: "37216",
        nm: "Navales",
      },
      {
        id: "37217",
        nm: "Navalmoral de Béjar",
      },
      {
        id: "37218",
        nm: "Navamorales",
      },
      {
        id: "37219",
        nm: "Navarredonda de la Rinconada",
      },
      {
        id: "37221",
        nm: "Navasfrías",
      },
      {
        id: "37222",
        nm: "Negrilla de Palencia",
      },
      {
        id: "37223",
        nm: "Olmedo de Camaces",
      },
      {
        id: "37224",
        nm: "Orbada, La",
      },
      {
        id: "37225",
        nm: "Pajares de la Laguna",
      },
      {
        id: "37226",
        nm: "Palacios del Arzobispo",
      },
      {
        id: "37228",
        nm: "Palaciosrubios",
      },
      {
        id: "37229",
        nm: "Palencia de Negrilla",
      },
      {
        id: "37230",
        nm: "Parada de Arriba",
      },
      {
        id: "37231",
        nm: "Parada de Rubiales",
      },
      {
        id: "37232",
        nm: "Paradinas de San Juan",
      },
      {
        id: "37233",
        nm: "Pastores",
      },
      {
        id: "37234",
        nm: "Payo, El",
      },
      {
        id: "37235",
        nm: "Pedraza de Alba",
      },
      {
        id: "37236",
        nm: "Pedrosillo de Alba",
      },
      {
        id: "37237",
        nm: "Pedrosillo de los Aires",
      },
      {
        id: "37238",
        nm: "Pedrosillo el Ralo",
      },
      {
        id: "37239",
        nm: "Pedroso de la Armuña, El",
      },
      {
        id: "37240",
        nm: "Pelabravo",
      },
      {
        id: "37241",
        nm: "Pelarrodríguez",
      },
      {
        id: "37242",
        nm: "Pelayos",
      },
      {
        id: "37243",
        nm: "Peña, La",
      },
      {
        id: "37244",
        nm: "Peñacaballera",
      },
      {
        id: "37245",
        nm: "Peñaparda",
      },
      {
        id: "37246",
        nm: "Peñaranda de Bracamonte",
      },
      {
        id: "37247",
        nm: "Peñarandilla",
      },
      {
        id: "37248",
        nm: "Peralejos de Abajo",
      },
      {
        id: "37249",
        nm: "Peralejos de Arriba",
      },
      {
        id: "37250",
        nm: "Pereña de la Ribera",
      },
      {
        id: "37251",
        nm: "Peromingo",
      },
      {
        id: "37252",
        nm: "Pinedas",
      },
      {
        id: "37253",
        nm: "Pino de Tormes, El",
      },
      {
        id: "37254",
        nm: "Pitiegua",
      },
      {
        id: "37255",
        nm: "Pizarral",
      },
      {
        id: "37256",
        nm: "Poveda de las Cintas",
      },
      {
        id: "37257",
        nm: "Pozos de Hinojo",
      },
      {
        id: "37258",
        nm: "Puebla de Azaba",
      },
      {
        id: "37259",
        nm: "Puebla de San Medel",
      },
      {
        id: "37260",
        nm: "Puebla de Yeltes",
      },
      {
        id: "37261",
        nm: "Puente del Congosto",
      },
      {
        id: "37262",
        nm: "Puertas",
      },
      {
        id: "37263",
        nm: "Puerto de Béjar",
      },
      {
        id: "37264",
        nm: "Puerto Seguro",
      },
      {
        id: "37265",
        nm: "Rágama",
      },
      {
        id: "37266",
        nm: "Redonda, La",
      },
      {
        id: "37267",
        nm: "Retortillo",
      },
      {
        id: "37268",
        nm: "Rinconada de la Sierra, La",
      },
      {
        id: "37269",
        nm: "Robleda",
      },
      {
        id: "37270",
        nm: "Robliza de Cojos",
      },
      {
        id: "37271",
        nm: "Rollán",
      },
      {
        id: "37272",
        nm: "Saelices el Chico",
      },
      {
        id: "37273",
        nm: "Sagrada, La",
      },
      {
        id: "37303",
        nm: "Sahugo, El",
      },
      {
        id: "37274",
        nm: "Salamanca",
      },
      {
        id: "37275",
        nm: "Saldeana",
      },
      {
        id: "37276",
        nm: "Salmoral",
      },
      {
        id: "37277",
        nm: "Salvatierra de Tormes",
      },
      {
        id: "37278",
        nm: "San Cristóbal de la Cuesta",
      },
      {
        id: "37284",
        nm: "San Esteban de la Sierra",
      },
      {
        id: "37285",
        nm: "San Felices de los Gallegos",
      },
      {
        id: "37286",
        nm: "San Martín del Castañar",
      },
      {
        id: "37287",
        nm: "San Miguel de Valero",
      },
      {
        id: "37036",
        nm: "San Miguel del Robledo",
      },
      {
        id: "37288",
        nm: "San Morales",
      },
      {
        id: "37289",
        nm: "San Muñoz",
      },
      {
        id: "37291",
        nm: "San Pedro de Rozados",
      },
      {
        id: "37290",
        nm: "San Pedro del Valle",
      },
      {
        id: "37292",
        nm: "San Pelayo de Guareña",
      },
      {
        id: "37280",
        nm: "Sanchón de la Ribera",
      },
      {
        id: "37281",
        nm: "Sanchón de la Sagrada",
      },
      {
        id: "37282",
        nm: "Sanchotello",
      },
      {
        id: "37279",
        nm: "Sancti-Spíritus",
      },
      {
        id: "37283",
        nm: "Sando",
      },
      {
        id: "37293",
        nm: "Santa María de Sando",
      },
      {
        id: "37294",
        nm: "Santa Marta de Tormes",
      },
      {
        id: "37296",
        nm: "Santiago de la Puebla",
      },
      {
        id: "37297",
        nm: "Santibáñez de Béjar",
      },
      {
        id: "37298",
        nm: "Santibáñez de la Sierra",
      },
      {
        id: "37299",
        nm: "Santiz",
      },
      {
        id: "37300",
        nm: "Santos, Los",
      },
      {
        id: "37301",
        nm: "Sardón de los Frailes",
      },
      {
        id: "37302",
        nm: "Saucelle",
      },
      {
        id: "37304",
        nm: "Sepulcro-Hilario",
      },
      {
        id: "37305",
        nm: "Sequeros",
      },
      {
        id: "37306",
        nm: "Serradilla del Arroyo",
      },
      {
        id: "37307",
        nm: "Serradilla del Llano",
      },
      {
        id: "37309",
        nm: "Sierpe, La",
      },
      {
        id: "37310",
        nm: "Sieteiglesias de Tormes",
      },
      {
        id: "37311",
        nm: "Sobradillo",
      },
      {
        id: "37312",
        nm: "Sorihuela",
      },
      {
        id: "37313",
        nm: "Sotoserrano",
      },
      {
        id: "37314",
        nm: "Tabera de Abajo",
      },
      {
        id: "37315",
        nm: "Tala, La",
      },
      {
        id: "37316",
        nm: "Tamames",
      },
      {
        id: "37317",
        nm: "Tarazona de Guareña",
      },
      {
        id: "37318",
        nm: "Tardáguila",
      },
      {
        id: "37319",
        nm: "Tejado, El",
      },
      {
        id: "37320",
        nm: "Tejeda y Segoyuela",
      },
      {
        id: "37321",
        nm: "Tenebrón",
      },
      {
        id: "37322",
        nm: "Terradillos",
      },
      {
        id: "37323",
        nm: "Topas",
      },
      {
        id: "37324",
        nm: "Tordillos",
      },
      {
        id: "37325",
        nm: "Tornadizo, El",
      },
      {
        id: "37327",
        nm: "Torresmenudas",
      },
      {
        id: "37328",
        nm: "Trabanca",
      },
      {
        id: "37329",
        nm: "Tremedal de Tormes",
      },
      {
        id: "37330",
        nm: "Valdecarros",
      },
      {
        id: "37331",
        nm: "Valdefuentes de Sangusín",
      },
      {
        id: "37332",
        nm: "Valdehijaderos",
      },
      {
        id: "37333",
        nm: "Valdelacasa",
      },
      {
        id: "37334",
        nm: "Valdelageve",
      },
      {
        id: "37335",
        nm: "Valdelosa",
      },
      {
        id: "37336",
        nm: "Valdemierque",
      },
      {
        id: "37337",
        nm: "Valderrodrigo",
      },
      {
        id: "37338",
        nm: "Valdunciel",
      },
      {
        id: "37339",
        nm: "Valero",
      },
      {
        id: "37343",
        nm: "Vallejera de Riofrío",
      },
      {
        id: "37340",
        nm: "Valsalabroso",
      },
      {
        id: "37341",
        nm: "Valverde de Valdelacasa",
      },
      {
        id: "37342",
        nm: "Valverdón",
      },
      {
        id: "37344",
        nm: "Vecinos",
      },
      {
        id: "37345",
        nm: "Vega de Tirados",
      },
      {
        id: "37346",
        nm: "Veguillas, Las",
      },
      {
        id: "37347",
        nm: "Vellés, La",
      },
      {
        id: "37348",
        nm: "Ventosa del Río Almar",
      },
      {
        id: "37349",
        nm: "Vídola, La",
      },
      {
        id: "37351",
        nm: "Villaflores",
      },
      {
        id: "37352",
        nm: "Villagonzalo de Tormes",
      },
      {
        id: "37353",
        nm: "Villalba de los Llanos",
      },
      {
        id: "37354",
        nm: "Villamayor",
      },
      {
        id: "37355",
        nm: "Villanueva del Conde",
      },
      {
        id: "37356",
        nm: "Villar de Argañán",
      },
      {
        id: "37357",
        nm: "Villar de Ciervo",
      },
      {
        id: "37358",
        nm: "Villar de Gallimazo",
      },
      {
        id: "37359",
        nm: "Villar de la Yegua",
      },
      {
        id: "37360",
        nm: "Villar de Peralonso",
      },
      {
        id: "37361",
        nm: "Villar de Samaniego",
      },
      {
        id: "37362",
        nm: "Villares de la Reina",
      },
      {
        id: "37363",
        nm: "Villares de Yeltes",
      },
      {
        id: "37364",
        nm: "Villarino de los Aires",
      },
      {
        id: "37365",
        nm: "Villarmayor",
      },
      {
        id: "37366",
        nm: "Villarmuerto",
      },
      {
        id: "37367",
        nm: "Villasbuenas",
      },
      {
        id: "37368",
        nm: "Villasdardo",
      },
      {
        id: "37369",
        nm: "Villaseco de los Gamitos",
      },
      {
        id: "37370",
        nm: "Villaseco de los Reyes",
      },
      {
        id: "37371",
        nm: "Villasrubias",
      },
      {
        id: "37372",
        nm: "Villaverde de Guareña",
      },
      {
        id: "37373",
        nm: "Villavieja de Yeltes",
      },
      {
        id: "37374",
        nm: "Villoria",
      },
      {
        id: "37375",
        nm: "Villoruela",
      },
      {
        id: "37350",
        nm: "Vilvestre",
      },
      {
        id: "37376",
        nm: "Vitigudino",
      },
      {
        id: "37377",
        nm: "Yecla de Yeltes",
      },
      {
        id: "37378",
        nm: "Zamarra",
      },
      {
        id: "37379",
        nm: "Zamayón",
      },
      {
        id: "37380",
        nm: "Zarapicos",
      },
      {
        id: "37381",
        nm: "Zarza de Pumareda, La",
      },
      {
        id: "37382",
        nm: "Zorita de la Frontera",
      },
      {
        id: "38001",
        nm: "Adeje",
      },
      {
        id: "38002",
        nm: "Agulo",
      },
      {
        id: "38003",
        nm: "Alajeró",
      },
      {
        id: "38004",
        nm: "Arafo",
      },
      {
        id: "38005",
        nm: "Arico",
      },
      {
        id: "38006",
        nm: "Arona",
      },
      {
        id: "38007",
        nm: "Barlovento",
      },
      {
        id: "38008",
        nm: "Breña Alta",
      },
      {
        id: "38009",
        nm: "Breña Baja",
      },
      {
        id: "38010",
        nm: "Buenavista del Norte",
      },
      {
        id: "38011",
        nm: "Candelaria",
      },
      {
        id: "38012",
        nm: "Fasnia",
      },
      {
        id: "38013",
        nm: "Frontera",
      },
      {
        id: "38014",
        nm: "Fuencaliente de la Palma",
      },
      {
        id: "38015",
        nm: "Garachico",
      },
      {
        id: "38016",
        nm: "Garafía",
      },
      {
        id: "38017",
        nm: "Granadilla de Abona",
      },
      {
        id: "38018",
        nm: "Guancha, La",
      },
      {
        id: "38019",
        nm: "Guía de Isora",
      },
      {
        id: "38020",
        nm: "Güímar",
      },
      {
        id: "38021",
        nm: "Hermigua",
      },
      {
        id: "38022",
        nm: "Icod de los Vinos",
      },
      {
        id: "38024",
        nm: "Llanos de Aridane, Los",
      },
      {
        id: "38025",
        nm: "Matanza de Acentejo, La",
      },
      {
        id: "38026",
        nm: "Orotava, La",
      },
      {
        id: "38027",
        nm: "Paso, El",
      },
      {
        id: "38901",
        nm: "Pinar de El Hierro, El",
      },
      {
        id: "38028",
        nm: "Puerto de la Cruz",
      },
      {
        id: "38029",
        nm: "Puntagorda",
      },
      {
        id: "38030",
        nm: "Puntallana",
      },
      {
        id: "38031",
        nm: "Realejos, Los",
      },
      {
        id: "38032",
        nm: "Rosario, El",
      },
      {
        id: "38033",
        nm: "San Andrés y Sauces",
      },
      {
        id: "38023",
        nm: "San Cristóbal de La Laguna",
      },
      {
        id: "38034",
        nm: "San Juan de la Rambla",
      },
      {
        id: "38035",
        nm: "San Miguel de Abona",
      },
      {
        id: "38036",
        nm: "San Sebastián de la Gomera",
      },
      {
        id: "38037",
        nm: "Santa Cruz de la Palma",
      },
      {
        id: "38038",
        nm: "Santa Cruz de Tenerife",
      },
      {
        id: "38039",
        nm: "Santa Úrsula",
      },
      {
        id: "38040",
        nm: "Santiago del Teide",
      },
      {
        id: "38041",
        nm: "Sauzal, El",
      },
      {
        id: "38042",
        nm: "Silos, Los",
      },
      {
        id: "38043",
        nm: "Tacoronte",
      },
      {
        id: "38044",
        nm: "Tanque, El",
      },
      {
        id: "38045",
        nm: "Tazacorte",
      },
      {
        id: "38046",
        nm: "Tegueste",
      },
      {
        id: "38047",
        nm: "Tijarafe",
      },
      {
        id: "38049",
        nm: "Valle Gran Rey",
      },
      {
        id: "38050",
        nm: "Vallehermoso",
      },
      {
        id: "38048",
        nm: "Valverde",
      },
      {
        id: "38051",
        nm: "Victoria de Acentejo, La",
      },
      {
        id: "38052",
        nm: "Vilaflor de Chasna",
      },
      {
        id: "38053",
        nm: "Villa de Mazo",
      },
      {
        id: "39001",
        nm: "Alfoz de Lloredo",
      },
      {
        id: "39002",
        nm: "Ampuero",
      },
      {
        id: "39003",
        nm: "Anievas",
      },
      {
        id: "39004",
        nm: "Arenas de Iguña",
      },
      {
        id: "39005",
        nm: "Argoños",
      },
      {
        id: "39006",
        nm: "Arnuero",
      },
      {
        id: "39007",
        nm: "Arredondo",
      },
      {
        id: "39008",
        nm: "Astillero, El",
      },
      {
        id: "39009",
        nm: "Bárcena de Cicero",
      },
      {
        id: "39010",
        nm: "Bárcena de Pie de Concha",
      },
      {
        id: "39011",
        nm: "Bareyo",
      },
      {
        id: "39012",
        nm: "Cabezón de la Sal",
      },
      {
        id: "39013",
        nm: "Cabezón de Liébana",
      },
      {
        id: "39014",
        nm: "Cabuérniga",
      },
      {
        id: "39015",
        nm: "Camaleño",
      },
      {
        id: "39016",
        nm: "Camargo",
      },
      {
        id: "39027",
        nm: "Campoo de Enmedio",
      },
      {
        id: "39017",
        nm: "Campoo de Yuso",
      },
      {
        id: "39018",
        nm: "Cartes",
      },
      {
        id: "39019",
        nm: "Castañeda",
      },
      {
        id: "39020",
        nm: "Castro-Urdiales",
      },
      {
        id: "39021",
        nm: "Cieza",
      },
      {
        id: "39022",
        nm: "Cillorigo de Liébana",
      },
      {
        id: "39023",
        nm: "Colindres",
      },
      {
        id: "39024",
        nm: "Comillas",
      },
      {
        id: "39025",
        nm: "Corrales de Buelna, Los",
      },
      {
        id: "39026",
        nm: "Corvera de Toranzo",
      },
      {
        id: "39028",
        nm: "Entrambasaguas",
      },
      {
        id: "39029",
        nm: "Escalante",
      },
      {
        id: "39030",
        nm: "Guriezo",
      },
      {
        id: "39031",
        nm: "Hazas de Cesto",
      },
      {
        id: "39032",
        nm: "Hermandad de Campoo de Suso",
      },
      {
        id: "39033",
        nm: "Herrerías",
      },
      {
        id: "39034",
        nm: "Lamasón",
      },
      {
        id: "39035",
        nm: "Laredo",
      },
      {
        id: "39036",
        nm: "Liendo",
      },
      {
        id: "39037",
        nm: "Liérganes",
      },
      {
        id: "39038",
        nm: "Limpias",
      },
      {
        id: "39039",
        nm: "Luena",
      },
      {
        id: "39040",
        nm: "Marina de Cudeyo",
      },
      {
        id: "39041",
        nm: "Mazcuerras",
      },
      {
        id: "39042",
        nm: "Medio Cudeyo",
      },
      {
        id: "39043",
        nm: "Meruelo",
      },
      {
        id: "39044",
        nm: "Miengo",
      },
      {
        id: "39045",
        nm: "Miera",
      },
      {
        id: "39046",
        nm: "Molledo",
      },
      {
        id: "39047",
        nm: "Noja",
      },
      {
        id: "39048",
        nm: "Penagos",
      },
      {
        id: "39049",
        nm: "Peñarrubia",
      },
      {
        id: "39050",
        nm: "Pesaguero",
      },
      {
        id: "39051",
        nm: "Pesquera",
      },
      {
        id: "39052",
        nm: "Piélagos",
      },
      {
        id: "39053",
        nm: "Polaciones",
      },
      {
        id: "39054",
        nm: "Polanco",
      },
      {
        id: "39055",
        nm: "Potes",
      },
      {
        id: "39056",
        nm: "Puente Viesgo",
      },
      {
        id: "39057",
        nm: "Ramales de la Victoria",
      },
      {
        id: "39058",
        nm: "Rasines",
      },
      {
        id: "39059",
        nm: "Reinosa",
      },
      {
        id: "39060",
        nm: "Reocín",
      },
      {
        id: "39061",
        nm: "Ribamontán al Mar",
      },
      {
        id: "39062",
        nm: "Ribamontán al Monte",
      },
      {
        id: "39063",
        nm: "Rionansa",
      },
      {
        id: "39064",
        nm: "Riotuerto",
      },
      {
        id: "39065",
        nm: "Rozas de Valdearroyo, Las",
      },
      {
        id: "39066",
        nm: "Ruente",
      },
      {
        id: "39067",
        nm: "Ruesga",
      },
      {
        id: "39068",
        nm: "Ruiloba",
      },
      {
        id: "39069",
        nm: "San Felices de Buelna",
      },
      {
        id: "39070",
        nm: "San Miguel de Aguayo",
      },
      {
        id: "39071",
        nm: "San Pedro del Romeral",
      },
      {
        id: "39072",
        nm: "San Roque de Riomiera",
      },
      {
        id: "39080",
        nm: "San Vicente de la Barquera",
      },
      {
        id: "39073",
        nm: "Santa Cruz de Bezana",
      },
      {
        id: "39074",
        nm: "Santa María de Cayón",
      },
      {
        id: "39075",
        nm: "Santander",
      },
      {
        id: "39076",
        nm: "Santillana del Mar",
      },
      {
        id: "39077",
        nm: "Santiurde de Reinosa",
      },
      {
        id: "39078",
        nm: "Santiurde de Toranzo",
      },
      {
        id: "39079",
        nm: "Santoña",
      },
      {
        id: "39081",
        nm: "Saro",
      },
      {
        id: "39082",
        nm: "Selaya",
      },
      {
        id: "39083",
        nm: "Soba",
      },
      {
        id: "39084",
        nm: "Solórzano",
      },
      {
        id: "39085",
        nm: "Suances",
      },
      {
        id: "39086",
        nm: "Tojos, Los",
      },
      {
        id: "39087",
        nm: "Torrelavega",
      },
      {
        id: "39088",
        nm: "Tresviso",
      },
      {
        id: "39089",
        nm: "Tudanca",
      },
      {
        id: "39090",
        nm: "Udías",
      },
      {
        id: "39095",
        nm: "Val de San Vicente",
      },
      {
        id: "39091",
        nm: "Valdáliga",
      },
      {
        id: "39092",
        nm: "Valdeolea",
      },
      {
        id: "39093",
        nm: "Valdeprado del Río",
      },
      {
        id: "39094",
        nm: "Valderredible",
      },
      {
        id: "39101",
        nm: "Valle de Villaverde",
      },
      {
        id: "39096",
        nm: "Vega de Liébana",
      },
      {
        id: "39097",
        nm: "Vega de Pas",
      },
      {
        id: "39098",
        nm: "Villacarriedo",
      },
      {
        id: "39099",
        nm: "Villaescusa",
      },
      {
        id: "39100",
        nm: "Villafufre",
      },
      {
        id: "39102",
        nm: "Voto",
      },
      {
        id: "40001",
        nm: "Abades",
      },
      {
        id: "40002",
        nm: "Adrada de Pirón",
      },
      {
        id: "40003",
        nm: "Adrados",
      },
      {
        id: "40004",
        nm: "Aguilafuente",
      },
      {
        id: "40005",
        nm: "Alconada de Maderuelo",
      },
      {
        id: "40012",
        nm: "Aldea Real",
      },
      {
        id: "40006",
        nm: "Aldealcorvo",
      },
      {
        id: "40007",
        nm: "Aldealengua de Pedraza",
      },
      {
        id: "40008",
        nm: "Aldealengua de Santa María",
      },
      {
        id: "40009",
        nm: "Aldeanueva de la Serrezuela",
      },
      {
        id: "40010",
        nm: "Aldeanueva del Codonal",
      },
      {
        id: "40013",
        nm: "Aldeasoña",
      },
      {
        id: "40014",
        nm: "Aldehorno",
      },
      {
        id: "40015",
        nm: "Aldehuela del Codonal",
      },
      {
        id: "40016",
        nm: "Aldeonte",
      },
      {
        id: "40017",
        nm: "Anaya",
      },
      {
        id: "40018",
        nm: "Añe",
      },
      {
        id: "40019",
        nm: "Arahuetes",
      },
      {
        id: "40020",
        nm: "Arcones",
      },
      {
        id: "40021",
        nm: "Arevalillo de Cega",
      },
      {
        id: "40022",
        nm: "Armuña",
      },
      {
        id: "40024",
        nm: "Ayllón",
      },
      {
        id: "40025",
        nm: "Barbolla",
      },
      {
        id: "40026",
        nm: "Basardilla",
      },
      {
        id: "40028",
        nm: "Bercial",
      },
      {
        id: "40029",
        nm: "Bercimuel",
      },
      {
        id: "40030",
        nm: "Bernardos",
      },
      {
        id: "40031",
        nm: "Bernuy de Porreros",
      },
      {
        id: "40032",
        nm: "Boceguillas",
      },
      {
        id: "40033",
        nm: "Brieva",
      },
      {
        id: "40034",
        nm: "Caballar",
      },
      {
        id: "40035",
        nm: "Cabañas de Polendos",
      },
      {
        id: "40036",
        nm: "Cabezuela",
      },
      {
        id: "40037",
        nm: "Calabazas de Fuentidueña",
      },
      {
        id: "40039",
        nm: "Campo de San Pedro",
      },
      {
        id: "40040",
        nm: "Cantalejo",
      },
      {
        id: "40041",
        nm: "Cantimpalos",
      },
      {
        id: "40043",
        nm: "Carbonero el Mayor",
      },
      {
        id: "40044",
        nm: "Carrascal del Río",
      },
      {
        id: "40045",
        nm: "Casla",
      },
      {
        id: "40046",
        nm: "Castillejo de Mesleón",
      },
      {
        id: "40047",
        nm: "Castro de Fuentidueña",
      },
      {
        id: "40048",
        nm: "Castrojimeno",
      },
      {
        id: "40049",
        nm: "Castroserna de Abajo",
      },
      {
        id: "40051",
        nm: "Castroserracín",
      },
      {
        id: "40052",
        nm: "Cedillo de la Torre",
      },
      {
        id: "40053",
        nm: "Cerezo de Abajo",
      },
      {
        id: "40054",
        nm: "Cerezo de Arriba",
      },
      {
        id: "40065",
        nm: "Chañe",
      },
      {
        id: "40055",
        nm: "Cilleruelo de San Mamés",
      },
      {
        id: "40056",
        nm: "Cobos de Fuentidueña",
      },
      {
        id: "40057",
        nm: "Coca",
      },
      {
        id: "40058",
        nm: "Codorniz",
      },
      {
        id: "40059",
        nm: "Collado Hermoso",
      },
      {
        id: "40060",
        nm: "Condado de Castilnovo",
      },
      {
        id: "40061",
        nm: "Corral de Ayllón",
      },
      {
        id: "40902",
        nm: "Cozuelos de Fuentidueña",
      },
      {
        id: "40062",
        nm: "Cubillo",
      },
      {
        id: "40063",
        nm: "Cuéllar",
      },
      {
        id: "40905",
        nm: "Cuevas de Provanco",
      },
      {
        id: "40068",
        nm: "Domingo García",
      },
      {
        id: "40069",
        nm: "Donhierro",
      },
      {
        id: "40070",
        nm: "Duruelo",
      },
      {
        id: "40071",
        nm: "Encinas",
      },
      {
        id: "40072",
        nm: "Encinillas",
      },
      {
        id: "40073",
        nm: "Escalona del Prado",
      },
      {
        id: "40074",
        nm: "Escarabajosa de Cabezas",
      },
      {
        id: "40075",
        nm: "Escobar de Polendos",
      },
      {
        id: "40076",
        nm: "Espinar, El",
      },
      {
        id: "40077",
        nm: "Espirdo",
      },
      {
        id: "40078",
        nm: "Fresneda de Cuéllar",
      },
      {
        id: "40079",
        nm: "Fresno de Cantespino",
      },
      {
        id: "40080",
        nm: "Fresno de la Fuente",
      },
      {
        id: "40081",
        nm: "Frumales",
      },
      {
        id: "40082",
        nm: "Fuente de Santa Cruz",
      },
      {
        id: "40083",
        nm: "Fuente el Olmo de Fuentidueña",
      },
      {
        id: "40084",
        nm: "Fuente el Olmo de Íscar",
      },
      {
        id: "40086",
        nm: "Fuentepelayo",
      },
      {
        id: "40087",
        nm: "Fuentepiñel",
      },
      {
        id: "40088",
        nm: "Fuenterrebollo",
      },
      {
        id: "40089",
        nm: "Fuentesaúco de Fuentidueña",
      },
      {
        id: "40091",
        nm: "Fuentesoto",
      },
      {
        id: "40092",
        nm: "Fuentidueña",
      },
      {
        id: "40093",
        nm: "Gallegos",
      },
      {
        id: "40094",
        nm: "Garcillán",
      },
      {
        id: "40095",
        nm: "Gomezserracín",
      },
      {
        id: "40097",
        nm: "Grajera",
      },
      {
        id: "40099",
        nm: "Honrubia de la Cuesta",
      },
      {
        id: "40100",
        nm: "Hontalbilla",
      },
      {
        id: "40101",
        nm: "Hontanares de Eresma",
      },
      {
        id: "40103",
        nm: "Huertos, Los",
      },
      {
        id: "40104",
        nm: "Ituero y Lama",
      },
      {
        id: "40105",
        nm: "Juarros de Riomoros",
      },
      {
        id: "40106",
        nm: "Juarros de Voltoya",
      },
      {
        id: "40107",
        nm: "Labajos",
      },
      {
        id: "40108",
        nm: "Laguna de Contreras",
      },
      {
        id: "40109",
        nm: "Languilla",
      },
      {
        id: "40110",
        nm: "Lastras de Cuéllar",
      },
      {
        id: "40111",
        nm: "Lastras del Pozo",
      },
      {
        id: "40112",
        nm: "Lastrilla, La",
      },
      {
        id: "40113",
        nm: "Losa, La",
      },
      {
        id: "40115",
        nm: "Maderuelo",
      },
      {
        id: "40903",
        nm: "Marazoleja",
      },
      {
        id: "40118",
        nm: "Marazuela",
      },
      {
        id: "40119",
        nm: "Martín Miguel",
      },
      {
        id: "40120",
        nm: "Martín Muñoz de la Dehesa",
      },
      {
        id: "40121",
        nm: "Martín Muñoz de las Posadas",
      },
      {
        id: "40122",
        nm: "Marugán",
      },
      {
        id: "40124",
        nm: "Mata de Cuéllar",
      },
      {
        id: "40123",
        nm: "Matabuena",
      },
      {
        id: "40125",
        nm: "Matilla, La",
      },
      {
        id: "40126",
        nm: "Melque de Cercos",
      },
      {
        id: "40127",
        nm: "Membibre de la Hoz",
      },
      {
        id: "40128",
        nm: "Migueláñez",
      },
      {
        id: "40129",
        nm: "Montejo de Arévalo",
      },
      {
        id: "40130",
        nm: "Montejo de la Vega de la Serrezuela",
      },
      {
        id: "40131",
        nm: "Monterrubio",
      },
      {
        id: "40132",
        nm: "Moral de Hornuez",
      },
      {
        id: "40134",
        nm: "Mozoncillo",
      },
      {
        id: "40135",
        nm: "Muñopedro",
      },
      {
        id: "40136",
        nm: "Muñoveros",
      },
      {
        id: "40138",
        nm: "Nava de la Asunción",
      },
      {
        id: "40139",
        nm: "Navafría",
      },
      {
        id: "40140",
        nm: "Navalilla",
      },
      {
        id: "40141",
        nm: "Navalmanzano",
      },
      {
        id: "40142",
        nm: "Navares de Ayuso",
      },
      {
        id: "40143",
        nm: "Navares de Enmedio",
      },
      {
        id: "40144",
        nm: "Navares de las Cuevas",
      },
      {
        id: "40145",
        nm: "Navas de Oro",
      },
      {
        id: "40904",
        nm: "Navas de Riofrío",
      },
      {
        id: "40146",
        nm: "Navas de San Antonio",
      },
      {
        id: "40148",
        nm: "Nieva",
      },
      {
        id: "40149",
        nm: "Olombrada",
      },
      {
        id: "40150",
        nm: "Orejana",
      },
      {
        id: "40151",
        nm: "Ortigosa de Pestaño",
      },
      {
        id: "40901",
        nm: "Ortigosa del Monte",
      },
      {
        id: "40152",
        nm: "Otero de Herreros",
      },
      {
        id: "40154",
        nm: "Pajarejos",
      },
      {
        id: "40155",
        nm: "Palazuelos de Eresma",
      },
      {
        id: "40156",
        nm: "Pedraza",
      },
      {
        id: "40157",
        nm: "Pelayos del Arroyo",
      },
      {
        id: "40158",
        nm: "Perosillo",
      },
      {
        id: "40159",
        nm: "Pinarejos",
      },
      {
        id: "40160",
        nm: "Pinarnegrillo",
      },
      {
        id: "40161",
        nm: "Pradales",
      },
      {
        id: "40162",
        nm: "Prádena",
      },
      {
        id: "40163",
        nm: "Puebla de Pedraza",
      },
      {
        id: "40164",
        nm: "Rapariegos",
      },
      {
        id: "40181",
        nm: "Real Sitio de San Ildefonso",
      },
      {
        id: "40165",
        nm: "Rebollo",
      },
      {
        id: "40166",
        nm: "Remondo",
      },
      {
        id: "40168",
        nm: "Riaguas de San Bartolomé",
      },
      {
        id: "40170",
        nm: "Riaza",
      },
      {
        id: "40171",
        nm: "Ribota",
      },
      {
        id: "40172",
        nm: "Riofrío de Riaza",
      },
      {
        id: "40173",
        nm: "Roda de Eresma",
      },
      {
        id: "40174",
        nm: "Sacramenia",
      },
      {
        id: "40176",
        nm: "Samboal",
      },
      {
        id: "40177",
        nm: "San Cristóbal de Cuéllar",
      },
      {
        id: "40178",
        nm: "San Cristóbal de la Vega",
      },
      {
        id: "40906",
        nm: "San Cristóbal de Segovia",
      },
      {
        id: "40182",
        nm: "San Martín y Mudrián",
      },
      {
        id: "40183",
        nm: "San Miguel de Bernuy",
      },
      {
        id: "40184",
        nm: "San Pedro de Gaíllos",
      },
      {
        id: "40179",
        nm: "Sanchonuño",
      },
      {
        id: "40180",
        nm: "Sangarcía",
      },
      {
        id: "40185",
        nm: "Santa María la Real de Nieva",
      },
      {
        id: "40186",
        nm: "Santa Marta del Cerro",
      },
      {
        id: "40188",
        nm: "Santiuste de Pedraza",
      },
      {
        id: "40189",
        nm: "Santiuste de San Juan Bautista",
      },
      {
        id: "40190",
        nm: "Santo Domingo de Pirón",
      },
      {
        id: "40191",
        nm: "Santo Tomé del Puerto",
      },
      {
        id: "40192",
        nm: "Sauquillo de Cabezas",
      },
      {
        id: "40193",
        nm: "Sebúlcor",
      },
      {
        id: "40194",
        nm: "Segovia",
      },
      {
        id: "40195",
        nm: "Sepúlveda",
      },
      {
        id: "40196",
        nm: "Sequera de Fresno",
      },
      {
        id: "40198",
        nm: "Sotillo",
      },
      {
        id: "40199",
        nm: "Sotosalbos",
      },
      {
        id: "40200",
        nm: "Tabanera la Luenga",
      },
      {
        id: "40201",
        nm: "Tolocirio",
      },
      {
        id: "40206",
        nm: "Torre Val de San Pedro",
      },
      {
        id: "40202",
        nm: "Torreadrada",
      },
      {
        id: "40203",
        nm: "Torrecaballeros",
      },
      {
        id: "40204",
        nm: "Torrecilla del Pinar",
      },
      {
        id: "40205",
        nm: "Torreiglesias",
      },
      {
        id: "40207",
        nm: "Trescasas",
      },
      {
        id: "40208",
        nm: "Turégano",
      },
      {
        id: "40210",
        nm: "Urueñas",
      },
      {
        id: "40211",
        nm: "Valdeprados",
      },
      {
        id: "40212",
        nm: "Valdevacas de Montejo",
      },
      {
        id: "40213",
        nm: "Valdevacas y Guijar",
      },
      {
        id: "40218",
        nm: "Valle de Tabladillo",
      },
      {
        id: "40219",
        nm: "Vallelado",
      },
      {
        id: "40220",
        nm: "Valleruela de Pedraza",
      },
      {
        id: "40221",
        nm: "Valleruela de Sepúlveda",
      },
      {
        id: "40214",
        nm: "Valseca",
      },
      {
        id: "40215",
        nm: "Valtiendas",
      },
      {
        id: "40216",
        nm: "Valverde del Majano",
      },
      {
        id: "40222",
        nm: "Veganzones",
      },
      {
        id: "40223",
        nm: "Vegas de Matute",
      },
      {
        id: "40224",
        nm: "Ventosilla y Tejadilla",
      },
      {
        id: "40225",
        nm: "Villacastín",
      },
      {
        id: "40228",
        nm: "Villaverde de Íscar",
      },
      {
        id: "40229",
        nm: "Villaverde de Montejo",
      },
      {
        id: "40230",
        nm: "Villeguillo",
      },
      {
        id: "40231",
        nm: "Yanguas de Eresma",
      },
      {
        id: "40233",
        nm: "Zarzuela del Monte",
      },
      {
        id: "40234",
        nm: "Zarzuela del Pinar",
      },
      {
        id: "41001",
        nm: "Aguadulce",
      },
      {
        id: "41002",
        nm: "Alanís",
      },
      {
        id: "41003",
        nm: "Albaida del Aljarafe",
      },
      {
        id: "41004",
        nm: "Alcalá de Guadaíra",
      },
      {
        id: "41005",
        nm: "Alcalá del Río",
      },
      {
        id: "41006",
        nm: "Alcolea del Río",
      },
      {
        id: "41007",
        nm: "Algaba, La",
      },
      {
        id: "41008",
        nm: "Algámitas",
      },
      {
        id: "41009",
        nm: "Almadén de la Plata",
      },
      {
        id: "41010",
        nm: "Almensilla",
      },
      {
        id: "41011",
        nm: "Arahal",
      },
      {
        id: "41012",
        nm: "Aznalcázar",
      },
      {
        id: "41013",
        nm: "Aznalcóllar",
      },
      {
        id: "41014",
        nm: "Badolatosa",
      },
      {
        id: "41015",
        nm: "Benacazón",
      },
      {
        id: "41016",
        nm: "Bollullos de la Mitación",
      },
      {
        id: "41017",
        nm: "Bormujos",
      },
      {
        id: "41018",
        nm: "Brenes",
      },
      {
        id: "41019",
        nm: "Burguillos",
      },
      {
        id: "41020",
        nm: "Cabezas de San Juan, Las",
      },
      {
        id: "41021",
        nm: "Camas",
      },
      {
        id: "41022",
        nm: "Campana, La",
      },
      {
        id: "41023",
        nm: "Cantillana",
      },
      {
        id: "41901",
        nm: "Cañada Rosal",
      },
      {
        id: "41024",
        nm: "Carmona",
      },
      {
        id: "41025",
        nm: "Carrión de los Céspedes",
      },
      {
        id: "41026",
        nm: "Casariche",
      },
      {
        id: "41027",
        nm: "Castilblanco de los Arroyos",
      },
      {
        id: "41028",
        nm: "Castilleja de Guzmán",
      },
      {
        id: "41029",
        nm: "Castilleja de la Cuesta",
      },
      {
        id: "41030",
        nm: "Castilleja del Campo",
      },
      {
        id: "41031",
        nm: "Castillo de las Guardas, El",
      },
      {
        id: "41032",
        nm: "Cazalla de la Sierra",
      },
      {
        id: "41033",
        nm: "Constantina",
      },
      {
        id: "41034",
        nm: "Coria del Río",
      },
      {
        id: "41035",
        nm: "Coripe",
      },
      {
        id: "41036",
        nm: "Coronil, El",
      },
      {
        id: "41037",
        nm: "Corrales, Los",
      },
      {
        id: "41903",
        nm: "Cuervo de Sevilla, El",
      },
      {
        id: "41038",
        nm: "Dos Hermanas",
      },
      {
        id: "41039",
        nm: "Écija",
      },
      {
        id: "41040",
        nm: "Espartinas",
      },
      {
        id: "41041",
        nm: "Estepa",
      },
      {
        id: "41042",
        nm: "Fuentes de Andalucía",
      },
      {
        id: "41043",
        nm: "Garrobo, El",
      },
      {
        id: "41044",
        nm: "Gelves",
      },
      {
        id: "41045",
        nm: "Gerena",
      },
      {
        id: "41046",
        nm: "Gilena",
      },
      {
        id: "41047",
        nm: "Gines",
      },
      {
        id: "41048",
        nm: "Guadalcanal",
      },
      {
        id: "41049",
        nm: "Guillena",
      },
      {
        id: "41050",
        nm: "Herrera",
      },
      {
        id: "41051",
        nm: "Huévar del Aljarafe",
      },
      {
        id: "41902",
        nm: "Isla Mayor",
      },
      {
        id: "41052",
        nm: "Lantejuela",
      },
      {
        id: "41053",
        nm: "Lebrija",
      },
      {
        id: "41054",
        nm: "Lora de Estepa",
      },
      {
        id: "41055",
        nm: "Lora del Río",
      },
      {
        id: "41056",
        nm: "Luisiana, La",
      },
      {
        id: "41057",
        nm: "Madroño, El",
      },
      {
        id: "41058",
        nm: "Mairena del Alcor",
      },
      {
        id: "41059",
        nm: "Mairena del Aljarafe",
      },
      {
        id: "41060",
        nm: "Marchena",
      },
      {
        id: "41061",
        nm: "Marinaleda",
      },
      {
        id: "41062",
        nm: "Martín de la Jara",
      },
      {
        id: "41063",
        nm: "Molares, Los",
      },
      {
        id: "41064",
        nm: "Montellano",
      },
      {
        id: "41065",
        nm: "Morón de la Frontera",
      },
      {
        id: "41066",
        nm: "Navas de la Concepción, Las",
      },
      {
        id: "41067",
        nm: "Olivares",
      },
      {
        id: "41068",
        nm: "Osuna",
      },
      {
        id: "41069",
        nm: "Palacios y Villafranca, Los",
      },
      {
        id: "41070",
        nm: "Palomares del Río",
      },
      {
        id: "41071",
        nm: "Paradas",
      },
      {
        id: "41072",
        nm: "Pedrera",
      },
      {
        id: "41073",
        nm: "Pedroso, El",
      },
      {
        id: "41074",
        nm: "Peñaflor",
      },
      {
        id: "41075",
        nm: "Pilas",
      },
      {
        id: "41076",
        nm: "Pruna",
      },
      {
        id: "41077",
        nm: "Puebla de Cazalla, La",
      },
      {
        id: "41078",
        nm: "Puebla de los Infantes, La",
      },
      {
        id: "41079",
        nm: "Puebla del Río, La",
      },
      {
        id: "41080",
        nm: "Real de la Jara, El",
      },
      {
        id: "41081",
        nm: "Rinconada, La",
      },
      {
        id: "41082",
        nm: "Roda de Andalucía, La",
      },
      {
        id: "41083",
        nm: "Ronquillo, El",
      },
      {
        id: "41084",
        nm: "Rubio, El",
      },
      {
        id: "41085",
        nm: "Salteras",
      },
      {
        id: "41086",
        nm: "San Juan de Aznalfarache",
      },
      {
        id: "41088",
        nm: "San Nicolás del Puerto",
      },
      {
        id: "41087",
        nm: "Sanlúcar la Mayor",
      },
      {
        id: "41089",
        nm: "Santiponce",
      },
      {
        id: "41090",
        nm: "Saucejo, El",
      },
      {
        id: "41091",
        nm: "Sevilla",
      },
      {
        id: "41092",
        nm: "Tocina",
      },
      {
        id: "41093",
        nm: "Tomares",
      },
      {
        id: "41094",
        nm: "Umbrete",
      },
      {
        id: "41095",
        nm: "Utrera",
      },
      {
        id: "41096",
        nm: "Valencina de la Concepción",
      },
      {
        id: "41097",
        nm: "Villamanrique de la Condesa",
      },
      {
        id: "41100",
        nm: "Villanueva de San Juan",
      },
      {
        id: "41098",
        nm: "Villanueva del Ariscal",
      },
      {
        id: "41099",
        nm: "Villanueva del Río y Minas",
      },
      {
        id: "41101",
        nm: "Villaverde del Río",
      },
      {
        id: "41102",
        nm: "Viso del Alcor, El",
      },
      {
        id: "42001",
        nm: "Abejar",
      },
      {
        id: "42003",
        nm: "Adradas",
      },
      {
        id: "42004",
        nm: "Ágreda",
      },
      {
        id: "42006",
        nm: "Alconaba",
      },
      {
        id: "42007",
        nm: "Alcubilla de Avellaneda",
      },
      {
        id: "42008",
        nm: "Alcubilla de las Peñas",
      },
      {
        id: "42009",
        nm: "Aldealafuente",
      },
      {
        id: "42010",
        nm: "Aldealices",
      },
      {
        id: "42011",
        nm: "Aldealpozo",
      },
      {
        id: "42012",
        nm: "Aldealseñor",
      },
      {
        id: "42013",
        nm: "Aldehuela de Periáñez",
      },
      {
        id: "42014",
        nm: "Aldehuelas, Las",
      },
      {
        id: "42015",
        nm: "Alentisque",
      },
      {
        id: "42016",
        nm: "Aliud",
      },
      {
        id: "42017",
        nm: "Almajano",
      },
      {
        id: "42018",
        nm: "Almaluez",
      },
      {
        id: "42019",
        nm: "Almarza",
      },
      {
        id: "42020",
        nm: "Almazán",
      },
      {
        id: "42021",
        nm: "Almazul",
      },
      {
        id: "42022",
        nm: "Almenar de Soria",
      },
      {
        id: "42023",
        nm: "Alpanseque",
      },
      {
        id: "42024",
        nm: "Arancón",
      },
      {
        id: "42025",
        nm: "Arcos de Jalón",
      },
      {
        id: "42026",
        nm: "Arenillas",
      },
      {
        id: "42027",
        nm: "Arévalo de la Sierra",
      },
      {
        id: "42028",
        nm: "Ausejo de la Sierra",
      },
      {
        id: "42029",
        nm: "Baraona",
      },
      {
        id: "42030",
        nm: "Barca",
      },
      {
        id: "42031",
        nm: "Barcones",
      },
      {
        id: "42032",
        nm: "Bayubas de Abajo",
      },
      {
        id: "42033",
        nm: "Bayubas de Arriba",
      },
      {
        id: "42034",
        nm: "Beratón",
      },
      {
        id: "42035",
        nm: "Berlanga de Duero",
      },
      {
        id: "42036",
        nm: "Blacos",
      },
      {
        id: "42037",
        nm: "Bliecos",
      },
      {
        id: "42038",
        nm: "Borjabad",
      },
      {
        id: "42039",
        nm: "Borobia",
      },
      {
        id: "42041",
        nm: "Buberos",
      },
      {
        id: "42042",
        nm: "Buitrago",
      },
      {
        id: "42043",
        nm: "Burgo de Osma-Ciudad de Osma",
      },
      {
        id: "42044",
        nm: "Cabrejas del Campo",
      },
      {
        id: "42045",
        nm: "Cabrejas del Pinar",
      },
      {
        id: "42046",
        nm: "Calatañazor",
      },
      {
        id: "42048",
        nm: "Caltojar",
      },
      {
        id: "42049",
        nm: "Candilichera",
      },
      {
        id: "42050",
        nm: "Cañamaque",
      },
      {
        id: "42051",
        nm: "Carabantes",
      },
      {
        id: "42052",
        nm: "Caracena",
      },
      {
        id: "42053",
        nm: "Carrascosa de Abajo",
      },
      {
        id: "42054",
        nm: "Carrascosa de la Sierra",
      },
      {
        id: "42055",
        nm: "Casarejos",
      },
      {
        id: "42056",
        nm: "Castilfrío de la Sierra",
      },
      {
        id: "42058",
        nm: "Castillejo de Robledo",
      },
      {
        id: "42057",
        nm: "Castilruiz",
      },
      {
        id: "42059",
        nm: "Centenera de Andaluz",
      },
      {
        id: "42060",
        nm: "Cerbón",
      },
      {
        id: "42061",
        nm: "Cidones",
      },
      {
        id: "42062",
        nm: "Cigudosa",
      },
      {
        id: "42063",
        nm: "Cihuela",
      },
      {
        id: "42064",
        nm: "Ciria",
      },
      {
        id: "42065",
        nm: "Cirujales del Río",
      },
      {
        id: "42068",
        nm: "Coscurita",
      },
      {
        id: "42069",
        nm: "Covaleda",
      },
      {
        id: "42070",
        nm: "Cubilla",
      },
      {
        id: "42071",
        nm: "Cubo de la Solana",
      },
      {
        id: "42073",
        nm: "Cueva de Ágreda",
      },
      {
        id: "42075",
        nm: "Dévanos",
      },
      {
        id: "42076",
        nm: "Deza",
      },
      {
        id: "42078",
        nm: "Duruelo de la Sierra",
      },
      {
        id: "42079",
        nm: "Escobosa de Almazán",
      },
      {
        id: "42080",
        nm: "Espeja de San Marcelino",
      },
      {
        id: "42081",
        nm: "Espejón",
      },
      {
        id: "42082",
        nm: "Estepa de San Juan",
      },
      {
        id: "42083",
        nm: "Frechilla de Almazán",
      },
      {
        id: "42084",
        nm: "Fresno de Caracena",
      },
      {
        id: "42085",
        nm: "Fuentearmegil",
      },
      {
        id: "42086",
        nm: "Fuentecambrón",
      },
      {
        id: "42087",
        nm: "Fuentecantos",
      },
      {
        id: "42088",
        nm: "Fuentelmonge",
      },
      {
        id: "42089",
        nm: "Fuentelsaz de Soria",
      },
      {
        id: "42090",
        nm: "Fuentepinilla",
      },
      {
        id: "42092",
        nm: "Fuentes de Magaña",
      },
      {
        id: "42093",
        nm: "Fuentestrún",
      },
      {
        id: "42094",
        nm: "Garray",
      },
      {
        id: "42095",
        nm: "Golmayo",
      },
      {
        id: "42096",
        nm: "Gómara",
      },
      {
        id: "42097",
        nm: "Gormaz",
      },
      {
        id: "42098",
        nm: "Herrera de Soria",
      },
      {
        id: "42100",
        nm: "Hinojosa del Campo",
      },
      {
        id: "42103",
        nm: "Langa de Duero",
      },
      {
        id: "42105",
        nm: "Liceras",
      },
      {
        id: "42106",
        nm: "Losilla, La",
      },
      {
        id: "42107",
        nm: "Magaña",
      },
      {
        id: "42108",
        nm: "Maján",
      },
      {
        id: "42110",
        nm: "Matalebreras",
      },
      {
        id: "42111",
        nm: "Matamala de Almazán",
      },
      {
        id: "42113",
        nm: "Medinaceli",
      },
      {
        id: "42115",
        nm: "Miño de Medinaceli",
      },
      {
        id: "42116",
        nm: "Miño de San Esteban",
      },
      {
        id: "42117",
        nm: "Molinos de Duero",
      },
      {
        id: "42118",
        nm: "Momblona",
      },
      {
        id: "42119",
        nm: "Monteagudo de las Vicarías",
      },
      {
        id: "42120",
        nm: "Montejo de Tiermes",
      },
      {
        id: "42121",
        nm: "Montenegro de Cameros",
      },
      {
        id: "42123",
        nm: "Morón de Almazán",
      },
      {
        id: "42124",
        nm: "Muriel de la Fuente",
      },
      {
        id: "42125",
        nm: "Muriel Viejo",
      },
      {
        id: "42127",
        nm: "Nafría de Ucero",
      },
      {
        id: "42128",
        nm: "Narros",
      },
      {
        id: "42129",
        nm: "Navaleno",
      },
      {
        id: "42130",
        nm: "Nepas",
      },
      {
        id: "42131",
        nm: "Nolay",
      },
      {
        id: "42132",
        nm: "Noviercas",
      },
      {
        id: "42134",
        nm: "Ólvega",
      },
      {
        id: "42135",
        nm: "Oncala",
      },
      {
        id: "42139",
        nm: "Pinilla del Campo",
      },
      {
        id: "42140",
        nm: "Portillo de Soria",
      },
      {
        id: "42141",
        nm: "Póveda de Soria, La",
      },
      {
        id: "42142",
        nm: "Pozalmuro",
      },
      {
        id: "42144",
        nm: "Quintana Redonda",
      },
      {
        id: "42145",
        nm: "Quintanas de Gormaz",
      },
      {
        id: "42148",
        nm: "Quiñonería",
      },
      {
        id: "42149",
        nm: "Rábanos, Los",
      },
      {
        id: "42151",
        nm: "Rebollar",
      },
      {
        id: "42152",
        nm: "Recuerda",
      },
      {
        id: "42153",
        nm: "Rello",
      },
      {
        id: "42154",
        nm: "Renieblas",
      },
      {
        id: "42155",
        nm: "Retortillo de Soria",
      },
      {
        id: "42156",
        nm: "Reznos",
      },
      {
        id: "42157",
        nm: "Riba de Escalote, La",
      },
      {
        id: "42158",
        nm: "Rioseco de Soria",
      },
      {
        id: "42159",
        nm: "Rollamienta",
      },
      {
        id: "42160",
        nm: "Royo, El",
      },
      {
        id: "42161",
        nm: "Salduero",
      },
      {
        id: "42162",
        nm: "San Esteban de Gormaz",
      },
      {
        id: "42163",
        nm: "San Felices",
      },
      {
        id: "42164",
        nm: "San Leonardo de Yagüe",
      },
      {
        id: "42165",
        nm: "San Pedro Manrique",
      },
      {
        id: "42166",
        nm: "Santa Cruz de Yanguas",
      },
      {
        id: "42167",
        nm: "Santa María de Huerta",
      },
      {
        id: "42168",
        nm: "Santa María de las Hoyas",
      },
      {
        id: "42171",
        nm: "Serón de Nágima",
      },
      {
        id: "42172",
        nm: "Soliedra",
      },
      {
        id: "42173",
        nm: "Soria",
      },
      {
        id: "42174",
        nm: "Sotillo del Rincón",
      },
      {
        id: "42175",
        nm: "Suellacabras",
      },
      {
        id: "42176",
        nm: "Tajahuerce",
      },
      {
        id: "42177",
        nm: "Tajueco",
      },
      {
        id: "42178",
        nm: "Talveila",
      },
      {
        id: "42181",
        nm: "Tardelcuende",
      },
      {
        id: "42182",
        nm: "Taroda",
      },
      {
        id: "42183",
        nm: "Tejado",
      },
      {
        id: "42184",
        nm: "Torlengua",
      },
      {
        id: "42185",
        nm: "Torreblacos",
      },
      {
        id: "42187",
        nm: "Torrubia de Soria",
      },
      {
        id: "42188",
        nm: "Trévago",
      },
      {
        id: "42189",
        nm: "Ucero",
      },
      {
        id: "42190",
        nm: "Vadillo",
      },
      {
        id: "42191",
        nm: "Valdeavellano de Tera",
      },
      {
        id: "42192",
        nm: "Valdegeña",
      },
      {
        id: "42193",
        nm: "Valdelagua del Cerro",
      },
      {
        id: "42194",
        nm: "Valdemaluque",
      },
      {
        id: "42195",
        nm: "Valdenebro",
      },
      {
        id: "42196",
        nm: "Valdeprado",
      },
      {
        id: "42197",
        nm: "Valderrodilla",
      },
      {
        id: "42198",
        nm: "Valtajeros",
      },
      {
        id: "42200",
        nm: "Velamazán",
      },
      {
        id: "42201",
        nm: "Velilla de la Sierra",
      },
      {
        id: "42202",
        nm: "Velilla de los Ajos",
      },
      {
        id: "42204",
        nm: "Viana de Duero",
      },
      {
        id: "42205",
        nm: "Villaciervos",
      },
      {
        id: "42206",
        nm: "Villanueva de Gormaz",
      },
      {
        id: "42207",
        nm: "Villar del Ala",
      },
      {
        id: "42208",
        nm: "Villar del Campo",
      },
      {
        id: "42209",
        nm: "Villar del Río",
      },
      {
        id: "42211",
        nm: "Villares de Soria, Los",
      },
      {
        id: "42212",
        nm: "Villasayas",
      },
      {
        id: "42213",
        nm: "Villaseca de Arciel",
      },
      {
        id: "42215",
        nm: "Vinuesa",
      },
      {
        id: "42216",
        nm: "Vizmanos",
      },
      {
        id: "42217",
        nm: "Vozmediano",
      },
      {
        id: "42218",
        nm: "Yanguas",
      },
      {
        id: "42219",
        nm: "Yelo",
      },
      {
        id: "43001",
        nm: "Aiguamúrcia",
      },
      {
        id: "43002",
        nm: "Albinyana",
      },
      {
        id: "43003",
        nm: "Albiol, L'",
      },
      {
        id: "43004",
        nm: "Alcanar",
      },
      {
        id: "43005",
        nm: "Alcover",
      },
      {
        id: "43904",
        nm: "Aldea, L'",
      },
      {
        id: "43006",
        nm: "Aldover",
      },
      {
        id: "43007",
        nm: "Aleixar, L'",
      },
      {
        id: "43008",
        nm: "Alfara de Carles",
      },
      {
        id: "43009",
        nm: "Alforja",
      },
      {
        id: "43010",
        nm: "Alió",
      },
      {
        id: "43011",
        nm: "Almoster",
      },
      {
        id: "43012",
        nm: "Altafulla",
      },
      {
        id: "43013",
        nm: "Ametlla de Mar, L'",
      },
      {
        id: "43906",
        nm: "Ampolla, L'",
      },
      {
        id: "43014",
        nm: "Amposta",
      },
      {
        id: "43016",
        nm: "Arboç, L'",
      },
      {
        id: "43015",
        nm: "Arbolí",
      },
      {
        id: "43017",
        nm: "Argentera, L'",
      },
      {
        id: "43018",
        nm: "Arnes",
      },
      {
        id: "43019",
        nm: "Ascó",
      },
      {
        id: "43020",
        nm: "Banyeres del Penedès",
      },
      {
        id: "43021",
        nm: "Barberà de la Conca",
      },
      {
        id: "43022",
        nm: "Batea",
      },
      {
        id: "43023",
        nm: "Bellmunt del Priorat",
      },
      {
        id: "43024",
        nm: "Bellvei",
      },
      {
        id: "43025",
        nm: "Benifallet",
      },
      {
        id: "43026",
        nm: "Benissanet",
      },
      {
        id: "43027",
        nm: "Bisbal de Falset, La",
      },
      {
        id: "43028",
        nm: "Bisbal del Penedès, La",
      },
      {
        id: "43029",
        nm: "Blancafort",
      },
      {
        id: "43030",
        nm: "Bonastre",
      },
      {
        id: "43031",
        nm: "Borges del Camp, Les",
      },
      {
        id: "43032",
        nm: "Bot",
      },
      {
        id: "43033",
        nm: "Botarell",
      },
      {
        id: "43034",
        nm: "Bràfim",
      },
      {
        id: "43035",
        nm: "Cabacés",
      },
      {
        id: "43036",
        nm: "Cabra del Camp",
      },
      {
        id: "43037",
        nm: "Calafell",
      },
      {
        id: "43903",
        nm: "Camarles",
      },
      {
        id: "43038",
        nm: "Cambrils",
      },
      {
        id: "43907",
        nm: "Canonja, La",
      },
      {
        id: "43039",
        nm: "Capafonts",
      },
      {
        id: "43040",
        nm: "Capçanes",
      },
      {
        id: "43041",
        nm: "Caseres",
      },
      {
        id: "43042",
        nm: "Castellvell del Camp",
      },
      {
        id: "43043",
        nm: "Catllar, El",
      },
      {
        id: "43045",
        nm: "Colldejou",
      },
      {
        id: "43046",
        nm: "Conesa",
      },
      {
        id: "43047",
        nm: "Constantí",
      },
      {
        id: "43048",
        nm: "Corbera d'Ebre",
      },
      {
        id: "43049",
        nm: "Cornudella de Montsant",
      },
      {
        id: "43050",
        nm: "Creixell",
      },
      {
        id: "43051",
        nm: "Cunit",
      },
      {
        id: "43901",
        nm: "Deltebre",
      },
      {
        id: "43053",
        nm: "Duesaigües",
      },
      {
        id: "43054",
        nm: "Espluga de Francolí, L'",
      },
      {
        id: "43055",
        nm: "Falset",
      },
      {
        id: "43056",
        nm: "Fatarella, La",
      },
      {
        id: "43057",
        nm: "Febró, La",
      },
      {
        id: "43058",
        nm: "Figuera, La",
      },
      {
        id: "43059",
        nm: "Figuerola del Camp",
      },
      {
        id: "43060",
        nm: "Flix",
      },
      {
        id: "43061",
        nm: "Forès",
      },
      {
        id: "43062",
        nm: "Freginals",
      },
      {
        id: "43063",
        nm: "Galera, La",
      },
      {
        id: "43064",
        nm: "Gandesa",
      },
      {
        id: "43065",
        nm: "Garcia",
      },
      {
        id: "43066",
        nm: "Garidells, Els",
      },
      {
        id: "43067",
        nm: "Ginestar",
      },
      {
        id: "43068",
        nm: "Godall",
      },
      {
        id: "43069",
        nm: "Gratallops",
      },
      {
        id: "43070",
        nm: "Guiamets, Els",
      },
      {
        id: "43071",
        nm: "Horta de Sant Joan",
      },
      {
        id: "43072",
        nm: "Lloar, El",
      },
      {
        id: "43073",
        nm: "Llorac",
      },
      {
        id: "43074",
        nm: "Llorenç del Penedès",
      },
      {
        id: "43076",
        nm: "Marçà",
      },
      {
        id: "43075",
        nm: "Margalef",
      },
      {
        id: "43077",
        nm: "Mas de Barberans",
      },
      {
        id: "43078",
        nm: "Masdenverge",
      },
      {
        id: "43079",
        nm: "Masllorenç",
      },
      {
        id: "43080",
        nm: "Masó, La",
      },
      {
        id: "43081",
        nm: "Maspujols",
      },
      {
        id: "43082",
        nm: "Masroig, El",
      },
      {
        id: "43083",
        nm: "Milà, El",
      },
      {
        id: "43084",
        nm: "Miravet",
      },
      {
        id: "43085",
        nm: "Molar, El",
      },
      {
        id: "43086",
        nm: "Montblanc",
      },
      {
        id: "43088",
        nm: "Montbrió del Camp",
      },
      {
        id: "43089",
        nm: "Montferri",
      },
      {
        id: "43090",
        nm: "Montmell, El",
      },
      {
        id: "43091",
        nm: "Mont-ral",
      },
      {
        id: "43092",
        nm: "Mont-roig del Camp",
      },
      {
        id: "43093",
        nm: "Móra d'Ebre",
      },
      {
        id: "43094",
        nm: "Móra la Nova",
      },
      {
        id: "43095",
        nm: "Morell, El",
      },
      {
        id: "43096",
        nm: "Morera de Montsant, La",
      },
      {
        id: "43097",
        nm: "Nou de Gaià, La",
      },
      {
        id: "43098",
        nm: "Nulles",
      },
      {
        id: "43100",
        nm: "Pallaresos, Els",
      },
      {
        id: "43099",
        nm: "Palma d'Ebre, La",
      },
      {
        id: "43101",
        nm: "Passanant i Belltall",
      },
      {
        id: "43102",
        nm: "Paüls",
      },
      {
        id: "43103",
        nm: "Perafort",
      },
      {
        id: "43104",
        nm: "Perelló, El",
      },
      {
        id: "43105",
        nm: "Piles, Les",
      },
      {
        id: "43106",
        nm: "Pinell de Brai, El",
      },
      {
        id: "43107",
        nm: "Pira",
      },
      {
        id: "43108",
        nm: "Pla de Santa Maria, El",
      },
      {
        id: "43109",
        nm: "Pobla de Mafumet, La",
      },
      {
        id: "43110",
        nm: "Pobla de Massaluca, La",
      },
      {
        id: "43111",
        nm: "Pobla de Montornès, La",
      },
      {
        id: "43112",
        nm: "Poboleda",
      },
      {
        id: "43113",
        nm: "Pont d'Armentera, El",
      },
      {
        id: "43141",
        nm: "Pontils",
      },
      {
        id: "43114",
        nm: "Porrera",
      },
      {
        id: "43115",
        nm: "Pradell de la Teixeta",
      },
      {
        id: "43116",
        nm: "Prades",
      },
      {
        id: "43117",
        nm: "Prat de Comte",
      },
      {
        id: "43118",
        nm: "Pratdip",
      },
      {
        id: "43119",
        nm: "Puigpelat",
      },
      {
        id: "43120",
        nm: "Querol",
      },
      {
        id: "43121",
        nm: "Rasquera",
      },
      {
        id: "43122",
        nm: "Renau",
      },
      {
        id: "43123",
        nm: "Reus",
      },
      {
        id: "43124",
        nm: "Riba, La",
      },
      {
        id: "43125",
        nm: "Riba-roja d'Ebre",
      },
      {
        id: "43126",
        nm: "Riera de Gaià, La",
      },
      {
        id: "43127",
        nm: "Riudecanyes",
      },
      {
        id: "43128",
        nm: "Riudecols",
      },
      {
        id: "43129",
        nm: "Riudoms",
      },
      {
        id: "43130",
        nm: "Rocafort de Queralt",
      },
      {
        id: "43131",
        nm: "Roda de Berà",
      },
      {
        id: "43132",
        nm: "Rodonyà",
      },
      {
        id: "43133",
        nm: "Roquetes",
      },
      {
        id: "43134",
        nm: "Rourell, El",
      },
      {
        id: "43135",
        nm: "Salomó",
      },
      {
        id: "43905",
        nm: "Salou",
      },
      {
        id: "43136",
        nm: "Sant Carles de la Ràpita",
      },
      {
        id: "43137",
        nm: "Sant Jaume dels Domenys",
      },
      {
        id: "43902",
        nm: "Sant Jaume d'Enveja",
      },
      {
        id: "43138",
        nm: "Santa Bàrbara",
      },
      {
        id: "43139",
        nm: "Santa Coloma de Queralt",
      },
      {
        id: "43140",
        nm: "Santa Oliva",
      },
      {
        id: "43142",
        nm: "Sarral",
      },
      {
        id: "43143",
        nm: "Savallà del Comtat",
      },
      {
        id: "43144",
        nm: "Secuita, La",
      },
      {
        id: "43145",
        nm: "Selva del Camp, La",
      },
      {
        id: "43146",
        nm: "Senan",
      },
      {
        id: "43044",
        nm: "Sénia, La",
      },
      {
        id: "43147",
        nm: "Solivella",
      },
      {
        id: "43148",
        nm: "Tarragona",
      },
      {
        id: "43149",
        nm: "Tivenys",
      },
      {
        id: "43150",
        nm: "Tivissa",
      },
      {
        id: "43151",
        nm: "Torre de Fontaubella, La",
      },
      {
        id: "43152",
        nm: "Torre de l'Espanyol, La",
      },
      {
        id: "43153",
        nm: "Torredembarra",
      },
      {
        id: "43154",
        nm: "Torroja del Priorat",
      },
      {
        id: "43155",
        nm: "Tortosa",
      },
      {
        id: "43156",
        nm: "Ulldecona",
      },
      {
        id: "43157",
        nm: "Ulldemolins",
      },
      {
        id: "43158",
        nm: "Vallclara",
      },
      {
        id: "43159",
        nm: "Vallfogona de Riucorb",
      },
      {
        id: "43160",
        nm: "Vallmoll",
      },
      {
        id: "43161",
        nm: "Valls",
      },
      {
        id: "43162",
        nm: "Vandellòs i l'Hospitalet de l'Infant",
      },
      {
        id: "43163",
        nm: "Vendrell, El",
      },
      {
        id: "43164",
        nm: "Vespella de Gaià",
      },
      {
        id: "43165",
        nm: "Vilabella",
      },
      {
        id: "43175",
        nm: "Vilalba dels Arcs",
      },
      {
        id: "43166",
        nm: "Vilallonga del Camp",
      },
      {
        id: "43168",
        nm: "Vilanova de Prades",
      },
      {
        id: "43167",
        nm: "Vilanova d'Escornalbou",
      },
      {
        id: "43169",
        nm: "Vilaplana",
      },
      {
        id: "43170",
        nm: "Vila-rodona",
      },
      {
        id: "43171",
        nm: "Vila-seca",
      },
      {
        id: "43172",
        nm: "Vilaverd",
      },
      {
        id: "43173",
        nm: "Vilella Alta, La",
      },
      {
        id: "43174",
        nm: "Vilella Baixa, La",
      },
      {
        id: "43176",
        nm: "Vimbodí i Poblet",
      },
      {
        id: "43177",
        nm: "Vinebre",
      },
      {
        id: "43178",
        nm: "Vinyols i els Arcs",
      },
      {
        id: "43052",
        nm: "Xerta",
      },
      {
        id: "44001",
        nm: "Ababuj",
      },
      {
        id: "44002",
        nm: "Abejuela",
      },
      {
        id: "44003",
        nm: "Aguatón",
      },
      {
        id: "44004",
        nm: "Aguaviva",
      },
      {
        id: "44005",
        nm: "Aguilar del Alfambra",
      },
      {
        id: "44006",
        nm: "Alacón",
      },
      {
        id: "44007",
        nm: "Alba",
      },
      {
        id: "44008",
        nm: "Albalate del Arzobispo",
      },
      {
        id: "44009",
        nm: "Albarracín",
      },
      {
        id: "44010",
        nm: "Albentosa",
      },
      {
        id: "44011",
        nm: "Alcaine",
      },
      {
        id: "44012",
        nm: "Alcalá de la Selva",
      },
      {
        id: "44013",
        nm: "Alcañiz",
      },
      {
        id: "44014",
        nm: "Alcorisa",
      },
      {
        id: "44016",
        nm: "Alfambra",
      },
      {
        id: "44017",
        nm: "Aliaga",
      },
      {
        id: "44021",
        nm: "Allepuz",
      },
      {
        id: "44022",
        nm: "Alloza",
      },
      {
        id: "44023",
        nm: "Allueva",
      },
      {
        id: "44018",
        nm: "Almohaja",
      },
      {
        id: "44019",
        nm: "Alobras",
      },
      {
        id: "44020",
        nm: "Alpeñés",
      },
      {
        id: "44024",
        nm: "Anadón",
      },
      {
        id: "44025",
        nm: "Andorra",
      },
      {
        id: "44026",
        nm: "Arcos de las Salinas",
      },
      {
        id: "44027",
        nm: "Arens de Lledó",
      },
      {
        id: "44028",
        nm: "Argente",
      },
      {
        id: "44029",
        nm: "Ariño",
      },
      {
        id: "44031",
        nm: "Azaila",
      },
      {
        id: "44032",
        nm: "Bádenas",
      },
      {
        id: "44033",
        nm: "Báguena",
      },
      {
        id: "44034",
        nm: "Bañón",
      },
      {
        id: "44035",
        nm: "Barrachina",
      },
      {
        id: "44036",
        nm: "Bea",
      },
      {
        id: "44037",
        nm: "Beceite",
      },
      {
        id: "44039",
        nm: "Bello",
      },
      {
        id: "44038",
        nm: "Belmonte de San José",
      },
      {
        id: "44040",
        nm: "Berge",
      },
      {
        id: "44041",
        nm: "Bezas",
      },
      {
        id: "44042",
        nm: "Blancas",
      },
      {
        id: "44043",
        nm: "Blesa",
      },
      {
        id: "44044",
        nm: "Bordón",
      },
      {
        id: "44045",
        nm: "Bronchales",
      },
      {
        id: "44046",
        nm: "Bueña",
      },
      {
        id: "44047",
        nm: "Burbáguena",
      },
      {
        id: "44048",
        nm: "Cabra de Mora",
      },
      {
        id: "44049",
        nm: "Calaceite",
      },
      {
        id: "44050",
        nm: "Calamocha",
      },
      {
        id: "44051",
        nm: "Calanda",
      },
      {
        id: "44052",
        nm: "Calomarde",
      },
      {
        id: "44053",
        nm: "Camañas",
      },
      {
        id: "44054",
        nm: "Camarena de la Sierra",
      },
      {
        id: "44055",
        nm: "Camarillas",
      },
      {
        id: "44056",
        nm: "Caminreal",
      },
      {
        id: "44059",
        nm: "Cantavieja",
      },
      {
        id: "44060",
        nm: "Cañada de Benatanduz",
      },
      {
        id: "44061",
        nm: "Cañada de Verich, La",
      },
      {
        id: "44062",
        nm: "Cañada Vellida",
      },
      {
        id: "44063",
        nm: "Cañizar del Olivar",
      },
      {
        id: "44064",
        nm: "Cascante del Río",
      },
      {
        id: "44065",
        nm: "Castejón de Tornos",
      },
      {
        id: "44066",
        nm: "Castel de Cabra",
      },
      {
        id: "44070",
        nm: "Castellar, El",
      },
      {
        id: "44071",
        nm: "Castellote",
      },
      {
        id: "44067",
        nm: "Castelnou",
      },
      {
        id: "44068",
        nm: "Castelserás",
      },
      {
        id: "44074",
        nm: "Cedrillas",
      },
      {
        id: "44075",
        nm: "Celadas",
      },
      {
        id: "44076",
        nm: "Cella",
      },
      {
        id: "44077",
        nm: "Cerollera, La",
      },
      {
        id: "44080",
        nm: "Codoñera, La",
      },
      {
        id: "44082",
        nm: "Corbalán",
      },
      {
        id: "44084",
        nm: "Cortes de Aragón",
      },
      {
        id: "44085",
        nm: "Cosa",
      },
      {
        id: "44086",
        nm: "Cretas",
      },
      {
        id: "44087",
        nm: "Crivillén",
      },
      {
        id: "44088",
        nm: "Cuba, La",
      },
      {
        id: "44089",
        nm: "Cubla",
      },
      {
        id: "44090",
        nm: "Cucalón",
      },
      {
        id: "44092",
        nm: "Cuervo, El",
      },
      {
        id: "44093",
        nm: "Cuevas de Almudén",
      },
      {
        id: "44094",
        nm: "Cuevas Labradas",
      },
      {
        id: "44096",
        nm: "Ejulve",
      },
      {
        id: "44097",
        nm: "Escorihuela",
      },
      {
        id: "44099",
        nm: "Escucha",
      },
      {
        id: "44100",
        nm: "Estercuel",
      },
      {
        id: "44101",
        nm: "Ferreruela de Huerva",
      },
      {
        id: "44102",
        nm: "Fonfría",
      },
      {
        id: "44103",
        nm: "Formiche Alto",
      },
      {
        id: "44105",
        nm: "Fórnoles",
      },
      {
        id: "44106",
        nm: "Fortanete",
      },
      {
        id: "44107",
        nm: "Foz-Calanda",
      },
      {
        id: "44108",
        nm: "Fresneda, La",
      },
      {
        id: "44109",
        nm: "Frías de Albarracín",
      },
      {
        id: "44110",
        nm: "Fuenferrada",
      },
      {
        id: "44111",
        nm: "Fuentes Calientes",
      },
      {
        id: "44112",
        nm: "Fuentes Claras",
      },
      {
        id: "44113",
        nm: "Fuentes de Rubielos",
      },
      {
        id: "44114",
        nm: "Fuentespalda",
      },
      {
        id: "44115",
        nm: "Galve",
      },
      {
        id: "44116",
        nm: "Gargallo",
      },
      {
        id: "44117",
        nm: "Gea de Albarracín",
      },
      {
        id: "44118",
        nm: "Ginebrosa, La",
      },
      {
        id: "44119",
        nm: "Griegos",
      },
      {
        id: "44120",
        nm: "Guadalaviar",
      },
      {
        id: "44121",
        nm: "Gúdar",
      },
      {
        id: "44122",
        nm: "Híjar",
      },
      {
        id: "44123",
        nm: "Hinojosa de Jarque",
      },
      {
        id: "44124",
        nm: "Hoz de la Vieja, La",
      },
      {
        id: "44125",
        nm: "Huesa del Común",
      },
      {
        id: "44126",
        nm: "Iglesuela del Cid, La",
      },
      {
        id: "44127",
        nm: "Jabaloyas",
      },
      {
        id: "44128",
        nm: "Jarque de la Val",
      },
      {
        id: "44129",
        nm: "Jatiel",
      },
      {
        id: "44130",
        nm: "Jorcas",
      },
      {
        id: "44131",
        nm: "Josa",
      },
      {
        id: "44132",
        nm: "Lagueruela",
      },
      {
        id: "44133",
        nm: "Lanzuela",
      },
      {
        id: "44135",
        nm: "Libros",
      },
      {
        id: "44136",
        nm: "Lidón",
      },
      {
        id: "44137",
        nm: "Linares de Mora",
      },
      {
        id: "44141",
        nm: "Lledó",
      },
      {
        id: "44138",
        nm: "Loscos",
      },
      {
        id: "44142",
        nm: "Maicas",
      },
      {
        id: "44143",
        nm: "Manzanera",
      },
      {
        id: "44144",
        nm: "Martín del Río",
      },
      {
        id: "44145",
        nm: "Mas de las Matas",
      },
      {
        id: "44146",
        nm: "Mata de los Olmos, La",
      },
      {
        id: "44147",
        nm: "Mazaleón",
      },
      {
        id: "44148",
        nm: "Mezquita de Jarque",
      },
      {
        id: "44149",
        nm: "Mirambel",
      },
      {
        id: "44150",
        nm: "Miravete de la Sierra",
      },
      {
        id: "44151",
        nm: "Molinos",
      },
      {
        id: "44152",
        nm: "Monforte de Moyuela",
      },
      {
        id: "44153",
        nm: "Monreal del Campo",
      },
      {
        id: "44154",
        nm: "Monroyo",
      },
      {
        id: "44155",
        nm: "Montalbán",
      },
      {
        id: "44156",
        nm: "Monteagudo del Castillo",
      },
      {
        id: "44157",
        nm: "Monterde de Albarracín",
      },
      {
        id: "44158",
        nm: "Mora de Rubielos",
      },
      {
        id: "44159",
        nm: "Moscardón",
      },
      {
        id: "44160",
        nm: "Mosqueruela",
      },
      {
        id: "44161",
        nm: "Muniesa",
      },
      {
        id: "44163",
        nm: "Noguera de Albarracín",
      },
      {
        id: "44164",
        nm: "Nogueras",
      },
      {
        id: "44165",
        nm: "Nogueruelas",
      },
      {
        id: "44167",
        nm: "Obón",
      },
      {
        id: "44168",
        nm: "Odón",
      },
      {
        id: "44169",
        nm: "Ojos Negros",
      },
      {
        id: "44171",
        nm: "Olba",
      },
      {
        id: "44172",
        nm: "Oliete",
      },
      {
        id: "44173",
        nm: "Olmos, Los",
      },
      {
        id: "44174",
        nm: "Orihuela del Tremedal",
      },
      {
        id: "44175",
        nm: "Orrios",
      },
      {
        id: "44176",
        nm: "Palomar de Arroyos",
      },
      {
        id: "44177",
        nm: "Pancrudo",
      },
      {
        id: "44178",
        nm: "Parras de Castellote, Las",
      },
      {
        id: "44179",
        nm: "Peñarroya de Tastavins",
      },
      {
        id: "44180",
        nm: "Peracense",
      },
      {
        id: "44181",
        nm: "Peralejos",
      },
      {
        id: "44182",
        nm: "Perales del Alfambra",
      },
      {
        id: "44183",
        nm: "Pitarque",
      },
      {
        id: "44184",
        nm: "Plou",
      },
      {
        id: "44185",
        nm: "Pobo, El",
      },
      {
        id: "44187",
        nm: "Portellada, La",
      },
      {
        id: "44189",
        nm: "Pozondón",
      },
      {
        id: "44190",
        nm: "Pozuel del Campo",
      },
      {
        id: "44191",
        nm: "Puebla de Híjar, La",
      },
      {
        id: "44192",
        nm: "Puebla de Valverde, La",
      },
      {
        id: "44193",
        nm: "Puertomingalvo",
      },
      {
        id: "44194",
        nm: "Ráfales",
      },
      {
        id: "44195",
        nm: "Rillo",
      },
      {
        id: "44196",
        nm: "Riodeva",
      },
      {
        id: "44197",
        nm: "Ródenas",
      },
      {
        id: "44198",
        nm: "Royuela",
      },
      {
        id: "44199",
        nm: "Rubiales",
      },
      {
        id: "44200",
        nm: "Rubielos de la Cérida",
      },
      {
        id: "44201",
        nm: "Rubielos de Mora",
      },
      {
        id: "44203",
        nm: "Salcedillo",
      },
      {
        id: "44204",
        nm: "Saldón",
      },
      {
        id: "44205",
        nm: "Samper de Calanda",
      },
      {
        id: "44206",
        nm: "San Agustín",
      },
      {
        id: "44207",
        nm: "San Martín del Río",
      },
      {
        id: "44208",
        nm: "Santa Cruz de Nogueras",
      },
      {
        id: "44209",
        nm: "Santa Eulalia",
      },
      {
        id: "44210",
        nm: "Sarrión",
      },
      {
        id: "44211",
        nm: "Segura de los Baños",
      },
      {
        id: "44212",
        nm: "Seno",
      },
      {
        id: "44213",
        nm: "Singra",
      },
      {
        id: "44215",
        nm: "Terriente",
      },
      {
        id: "44216",
        nm: "Teruel",
      },
      {
        id: "44217",
        nm: "Toril y Masegoso",
      },
      {
        id: "44218",
        nm: "Tormón",
      },
      {
        id: "44219",
        nm: "Tornos",
      },
      {
        id: "44220",
        nm: "Torralba de los Sisones",
      },
      {
        id: "44223",
        nm: "Torre de Arcas",
      },
      {
        id: "44224",
        nm: "Torre de las Arcas",
      },
      {
        id: "44225",
        nm: "Torre del Compte",
      },
      {
        id: "44227",
        nm: "Torre los Negros",
      },
      {
        id: "44221",
        nm: "Torrecilla de Alcañiz",
      },
      {
        id: "44222",
        nm: "Torrecilla del Rebollar",
      },
      {
        id: "44226",
        nm: "Torrelacárcel",
      },
      {
        id: "44228",
        nm: "Torremocha de Jiloca",
      },
      {
        id: "44229",
        nm: "Torres de Albarracín",
      },
      {
        id: "44230",
        nm: "Torrevelilla",
      },
      {
        id: "44231",
        nm: "Torrijas",
      },
      {
        id: "44232",
        nm: "Torrijo del Campo",
      },
      {
        id: "44234",
        nm: "Tramacastiel",
      },
      {
        id: "44235",
        nm: "Tramacastilla",
      },
      {
        id: "44236",
        nm: "Tronchón",
      },
      {
        id: "44237",
        nm: "Urrea de Gaén",
      },
      {
        id: "44238",
        nm: "Utrillas",
      },
      {
        id: "44239",
        nm: "Valacloche",
      },
      {
        id: "44240",
        nm: "Valbona",
      },
      {
        id: "44241",
        nm: "Valdealgorfa",
      },
      {
        id: "44243",
        nm: "Valdecuenca",
      },
      {
        id: "44244",
        nm: "Valdelinares",
      },
      {
        id: "44245",
        nm: "Valdeltormo",
      },
      {
        id: "44246",
        nm: "Valderrobres",
      },
      {
        id: "44247",
        nm: "Valjunquera",
      },
      {
        id: "44249",
        nm: "Vallecillo, El",
      },
      {
        id: "44250",
        nm: "Veguillas de la Sierra",
      },
      {
        id: "44251",
        nm: "Villafranca del Campo",
      },
      {
        id: "44252",
        nm: "Villahermosa del Campo",
      },
      {
        id: "44256",
        nm: "Villanueva del Rebollar de la Sierra",
      },
      {
        id: "44257",
        nm: "Villar del Cobo",
      },
      {
        id: "44258",
        nm: "Villar del Salz",
      },
      {
        id: "44260",
        nm: "Villarluengo",
      },
      {
        id: "44261",
        nm: "Villarquemado",
      },
      {
        id: "44262",
        nm: "Villarroya de los Pinares",
      },
      {
        id: "44263",
        nm: "Villastar",
      },
      {
        id: "44264",
        nm: "Villel",
      },
      {
        id: "44265",
        nm: "Vinaceite",
      },
      {
        id: "44266",
        nm: "Visiedo",
      },
      {
        id: "44267",
        nm: "Vivel del Río Martín",
      },
      {
        id: "44268",
        nm: "Zoma, La",
      },
      {
        id: "45001",
        nm: "Ajofrín",
      },
      {
        id: "45002",
        nm: "Alameda de la Sagra",
      },
      {
        id: "45003",
        nm: "Albarreal de Tajo",
      },
      {
        id: "45004",
        nm: "Alcabón",
      },
      {
        id: "45005",
        nm: "Alcañizo",
      },
      {
        id: "45006",
        nm: "Alcaudete de la Jara",
      },
      {
        id: "45007",
        nm: "Alcolea de Tajo",
      },
      {
        id: "45008",
        nm: "Aldea en Cabo",
      },
      {
        id: "45009",
        nm: "Aldeanueva de Barbarroya",
      },
      {
        id: "45010",
        nm: "Aldeanueva de San Bartolomé",
      },
      {
        id: "45011",
        nm: "Almendral de la Cañada",
      },
      {
        id: "45012",
        nm: "Almonacid de Toledo",
      },
      {
        id: "45013",
        nm: "Almorox",
      },
      {
        id: "45014",
        nm: "Añover de Tajo",
      },
      {
        id: "45015",
        nm: "Arcicóllar",
      },
      {
        id: "45016",
        nm: "Argés",
      },
      {
        id: "45017",
        nm: "Azután",
      },
      {
        id: "45018",
        nm: "Barcience",
      },
      {
        id: "45019",
        nm: "Bargas",
      },
      {
        id: "45020",
        nm: "Belvís de la Jara",
      },
      {
        id: "45021",
        nm: "Borox",
      },
      {
        id: "45022",
        nm: "Buenaventura",
      },
      {
        id: "45023",
        nm: "Burguillos de Toledo",
      },
      {
        id: "45024",
        nm: "Burujón",
      },
      {
        id: "45025",
        nm: "Cabañas de la Sagra",
      },
      {
        id: "45026",
        nm: "Cabañas de Yepes",
      },
      {
        id: "45027",
        nm: "Cabezamesada",
      },
      {
        id: "45028",
        nm: "Calera y Chozas",
      },
      {
        id: "45029",
        nm: "Caleruela",
      },
      {
        id: "45030",
        nm: "Calzada de Oropesa",
      },
      {
        id: "45031",
        nm: "Camarena",
      },
      {
        id: "45032",
        nm: "Camarenilla",
      },
      {
        id: "45033",
        nm: "Campillo de la Jara, El",
      },
      {
        id: "45034",
        nm: "Camuñas",
      },
      {
        id: "45035",
        nm: "Cardiel de los Montes",
      },
      {
        id: "45036",
        nm: "Carmena",
      },
      {
        id: "45037",
        nm: "Carpio de Tajo, El",
      },
      {
        id: "45038",
        nm: "Carranque",
      },
      {
        id: "45039",
        nm: "Carriches",
      },
      {
        id: "45040",
        nm: "Casar de Escalona, El",
      },
      {
        id: "45041",
        nm: "Casarrubios del Monte",
      },
      {
        id: "45042",
        nm: "Casasbuenas",
      },
      {
        id: "45043",
        nm: "Castillo de Bayuela",
      },
      {
        id: "45045",
        nm: "Cazalegas",
      },
      {
        id: "45046",
        nm: "Cebolla",
      },
      {
        id: "45047",
        nm: "Cedillo del Condado",
      },
      {
        id: "45048",
        nm: "Cerralbos, Los",
      },
      {
        id: "45049",
        nm: "Cervera de los Montes",
      },
      {
        id: "45056",
        nm: "Chozas de Canales",
      },
      {
        id: "45057",
        nm: "Chueca",
      },
      {
        id: "45050",
        nm: "Ciruelos",
      },
      {
        id: "45051",
        nm: "Cobeja",
      },
      {
        id: "45052",
        nm: "Cobisa",
      },
      {
        id: "45053",
        nm: "Consuegra",
      },
      {
        id: "45054",
        nm: "Corral de Almaguer",
      },
      {
        id: "45055",
        nm: "Cuerva",
      },
      {
        id: "45058",
        nm: "Domingo Pérez",
      },
      {
        id: "45059",
        nm: "Dosbarrios",
      },
      {
        id: "45060",
        nm: "Erustes",
      },
      {
        id: "45061",
        nm: "Escalona",
      },
      {
        id: "45062",
        nm: "Escalonilla",
      },
      {
        id: "45063",
        nm: "Espinoso del Rey",
      },
      {
        id: "45064",
        nm: "Esquivias",
      },
      {
        id: "45065",
        nm: "Estrella, La",
      },
      {
        id: "45066",
        nm: "Fuensalida",
      },
      {
        id: "45067",
        nm: "Gálvez",
      },
      {
        id: "45068",
        nm: "Garciotum",
      },
      {
        id: "45069",
        nm: "Gerindote",
      },
      {
        id: "45070",
        nm: "Guadamur",
      },
      {
        id: "45071",
        nm: "Guardia, La",
      },
      {
        id: "45072",
        nm: "Herencias, Las",
      },
      {
        id: "45073",
        nm: "Herreruela de Oropesa",
      },
      {
        id: "45074",
        nm: "Hinojosa de San Vicente",
      },
      {
        id: "45075",
        nm: "Hontanar",
      },
      {
        id: "45076",
        nm: "Hormigos",
      },
      {
        id: "45077",
        nm: "Huecas",
      },
      {
        id: "45078",
        nm: "Huerta de Valdecarábanos",
      },
      {
        id: "45079",
        nm: "Iglesuela, La",
      },
      {
        id: "45080",
        nm: "Illán de Vacas",
      },
      {
        id: "45081",
        nm: "Illescas",
      },
      {
        id: "45082",
        nm: "Lagartera",
      },
      {
        id: "45083",
        nm: "Layos",
      },
      {
        id: "45084",
        nm: "Lillo",
      },
      {
        id: "45085",
        nm: "Lominchar",
      },
      {
        id: "45086",
        nm: "Lucillos",
      },
      {
        id: "45087",
        nm: "Madridejos",
      },
      {
        id: "45088",
        nm: "Magán",
      },
      {
        id: "45089",
        nm: "Malpica de Tajo",
      },
      {
        id: "45090",
        nm: "Manzaneque",
      },
      {
        id: "45091",
        nm: "Maqueda",
      },
      {
        id: "45092",
        nm: "Marjaliza",
      },
      {
        id: "45093",
        nm: "Marrupe",
      },
      {
        id: "45094",
        nm: "Mascaraque",
      },
      {
        id: "45095",
        nm: "Mata, La",
      },
      {
        id: "45096",
        nm: "Mazarambroz",
      },
      {
        id: "45097",
        nm: "Mejorada",
      },
      {
        id: "45098",
        nm: "Menasalbas",
      },
      {
        id: "45099",
        nm: "Méntrida",
      },
      {
        id: "45100",
        nm: "Mesegar de Tajo",
      },
      {
        id: "45101",
        nm: "Miguel Esteban",
      },
      {
        id: "45102",
        nm: "Mocejón",
      },
      {
        id: "45103",
        nm: "Mohedas de la Jara",
      },
      {
        id: "45104",
        nm: "Montearagón",
      },
      {
        id: "45105",
        nm: "Montesclaros",
      },
      {
        id: "45106",
        nm: "Mora",
      },
      {
        id: "45107",
        nm: "Nambroca",
      },
      {
        id: "45108",
        nm: "Nava de Ricomalillo, La",
      },
      {
        id: "45109",
        nm: "Navahermosa",
      },
      {
        id: "45110",
        nm: "Navalcán",
      },
      {
        id: "45111",
        nm: "Navalmoralejo",
      },
      {
        id: "45112",
        nm: "Navalmorales, Los",
      },
      {
        id: "45113",
        nm: "Navalucillos, Los",
      },
      {
        id: "45114",
        nm: "Navamorcuende",
      },
      {
        id: "45115",
        nm: "Noblejas",
      },
      {
        id: "45116",
        nm: "Noez",
      },
      {
        id: "45117",
        nm: "Nombela",
      },
      {
        id: "45118",
        nm: "Novés",
      },
      {
        id: "45119",
        nm: "Numancia de la Sagra",
      },
      {
        id: "45120",
        nm: "Nuño Gómez",
      },
      {
        id: "45121",
        nm: "Ocaña",
      },
      {
        id: "45122",
        nm: "Olías del Rey",
      },
      {
        id: "45123",
        nm: "Ontígola",
      },
      {
        id: "45124",
        nm: "Orgaz",
      },
      {
        id: "45125",
        nm: "Oropesa",
      },
      {
        id: "45126",
        nm: "Otero",
      },
      {
        id: "45127",
        nm: "Palomeque",
      },
      {
        id: "45128",
        nm: "Pantoja",
      },
      {
        id: "45129",
        nm: "Paredes de Escalona",
      },
      {
        id: "45130",
        nm: "Parrillas",
      },
      {
        id: "45131",
        nm: "Pelahustán",
      },
      {
        id: "45132",
        nm: "Pepino",
      },
      {
        id: "45133",
        nm: "Polán",
      },
      {
        id: "45134",
        nm: "Portillo de Toledo",
      },
      {
        id: "45135",
        nm: "Puebla de Almoradiel, La",
      },
      {
        id: "45136",
        nm: "Puebla de Montalbán, La",
      },
      {
        id: "45137",
        nm: "Pueblanueva, La",
      },
      {
        id: "45138",
        nm: "Puente del Arzobispo, El",
      },
      {
        id: "45139",
        nm: "Puerto de San Vicente",
      },
      {
        id: "45140",
        nm: "Pulgar",
      },
      {
        id: "45141",
        nm: "Quero",
      },
      {
        id: "45142",
        nm: "Quintanar de la Orden",
      },
      {
        id: "45143",
        nm: "Quismondo",
      },
      {
        id: "45144",
        nm: "Real de San Vicente, El",
      },
      {
        id: "45145",
        nm: "Recas",
      },
      {
        id: "45146",
        nm: "Retamoso de la Jara",
      },
      {
        id: "45147",
        nm: "Rielves",
      },
      {
        id: "45148",
        nm: "Robledo del Mazo",
      },
      {
        id: "45149",
        nm: "Romeral, El",
      },
      {
        id: "45150",
        nm: "San Bartolomé de las Abiertas",
      },
      {
        id: "45151",
        nm: "San Martín de Montalbán",
      },
      {
        id: "45152",
        nm: "San Martín de Pusa",
      },
      {
        id: "45153",
        nm: "San Pablo de los Montes",
      },
      {
        id: "45154",
        nm: "San Román de los Montes",
      },
      {
        id: "45155",
        nm: "Santa Ana de Pusa",
      },
      {
        id: "45156",
        nm: "Santa Cruz de la Zarza",
      },
      {
        id: "45157",
        nm: "Santa Cruz del Retamar",
      },
      {
        id: "45158",
        nm: "Santa Olalla",
      },
      {
        id: "45901",
        nm: "Santo Domingo-Caudilla",
      },
      {
        id: "45159",
        nm: "Sartajada",
      },
      {
        id: "45160",
        nm: "Segurilla",
      },
      {
        id: "45161",
        nm: "Seseña",
      },
      {
        id: "45162",
        nm: "Sevilleja de la Jara",
      },
      {
        id: "45163",
        nm: "Sonseca",
      },
      {
        id: "45164",
        nm: "Sotillo de las Palomas",
      },
      {
        id: "45165",
        nm: "Talavera de la Reina",
      },
      {
        id: "45166",
        nm: "Tembleque",
      },
      {
        id: "45167",
        nm: "Toboso, El",
      },
      {
        id: "45168",
        nm: "Toledo",
      },
      {
        id: "45169",
        nm: "Torralba de Oropesa",
      },
      {
        id: "45171",
        nm: "Torre de Esteban Hambrán, La",
      },
      {
        id: "45170",
        nm: "Torrecilla de la Jara",
      },
      {
        id: "45172",
        nm: "Torrico",
      },
      {
        id: "45173",
        nm: "Torrijos",
      },
      {
        id: "45174",
        nm: "Totanés",
      },
      {
        id: "45175",
        nm: "Turleque",
      },
      {
        id: "45176",
        nm: "Ugena",
      },
      {
        id: "45177",
        nm: "Urda",
      },
      {
        id: "45179",
        nm: "Valdeverdeja",
      },
      {
        id: "45180",
        nm: "Valmojado",
      },
      {
        id: "45181",
        nm: "Velada",
      },
      {
        id: "45182",
        nm: "Ventas con Peña Aguilera, Las",
      },
      {
        id: "45183",
        nm: "Ventas de Retamosa, Las",
      },
      {
        id: "45184",
        nm: "Ventas de San Julián, Las",
      },
      {
        id: "45186",
        nm: "Villa de Don Fadrique, La",
      },
      {
        id: "45185",
        nm: "Villacañas",
      },
      {
        id: "45187",
        nm: "Villafranca de los Caballeros",
      },
      {
        id: "45188",
        nm: "Villaluenga de la Sagra",
      },
      {
        id: "45189",
        nm: "Villamiel de Toledo",
      },
      {
        id: "45190",
        nm: "Villaminaya",
      },
      {
        id: "45191",
        nm: "Villamuelas",
      },
      {
        id: "45192",
        nm: "Villanueva de Alcardete",
      },
      {
        id: "45193",
        nm: "Villanueva de Bogas",
      },
      {
        id: "45194",
        nm: "Villarejo de Montalbán",
      },
      {
        id: "45195",
        nm: "Villarrubia de Santiago",
      },
      {
        id: "45196",
        nm: "Villaseca de la Sagra",
      },
      {
        id: "45197",
        nm: "Villasequilla",
      },
      {
        id: "45198",
        nm: "Villatobas",
      },
      {
        id: "45199",
        nm: "Viso de San Juan, El",
      },
      {
        id: "45200",
        nm: "Yébenes, Los",
      },
      {
        id: "45201",
        nm: "Yeles",
      },
      {
        id: "45202",
        nm: "Yepes",
      },
      {
        id: "45203",
        nm: "Yuncler",
      },
      {
        id: "45204",
        nm: "Yunclillos",
      },
      {
        id: "45205",
        nm: "Yuncos",
      },
      {
        id: "46001",
        nm: "Ademuz",
      },
      {
        id: "46002",
        nm: "Ador",
      },
      {
        id: "46004",
        nm: "Agullent",
      },
      {
        id: "46042",
        nm: "Aielo de Malferit",
      },
      {
        id: "46043",
        nm: "Aielo de Rugat",
      },
      {
        id: "46005",
        nm: "Alaquàs",
      },
      {
        id: "46006",
        nm: "Albaida",
      },
      {
        id: "46007",
        nm: "Albal",
      },
      {
        id: "46008",
        nm: "Albalat de la Ribera",
      },
      {
        id: "46009",
        nm: "Albalat dels Sorells",
      },
      {
        id: "46010",
        nm: "Albalat dels Tarongers",
      },
      {
        id: "46011",
        nm: "Alberic",
      },
      {
        id: "46012",
        nm: "Alborache",
      },
      {
        id: "46013",
        nm: "Alboraya",
      },
      {
        id: "46014",
        nm: "Albuixech",
      },
      {
        id: "46016",
        nm: "Alcàntera de Xúquer",
      },
      {
        id: "46015",
        nm: "Alcàsser",
      },
      {
        id: "46018",
        nm: "Alcublas",
      },
      {
        id: "46020",
        nm: "Alcúdia de Crespins, l'",
      },
      {
        id: "46019",
        nm: "Alcúdia, l'",
      },
      {
        id: "46021",
        nm: "Aldaia",
      },
      {
        id: "46022",
        nm: "Alfafar",
      },
      {
        id: "46024",
        nm: "Alfara de la Baronia",
      },
      {
        id: "46025",
        nm: "Alfara del Patriarca",
      },
      {
        id: "46026",
        nm: "Alfarp",
      },
      {
        id: "46027",
        nm: "Alfarrasí",
      },
      {
        id: "46023",
        nm: "Alfauir",
      },
      {
        id: "46028",
        nm: "Algar de Palancia",
      },
      {
        id: "46029",
        nm: "Algemesí",
      },
      {
        id: "46030",
        nm: "Algimia de Alfara",
      },
      {
        id: "46031",
        nm: "Alginet",
      },
      {
        id: "46032",
        nm: "Almàssera",
      },
      {
        id: "46033",
        nm: "Almiserà",
      },
      {
        id: "46034",
        nm: "Almoines",
      },
      {
        id: "46035",
        nm: "Almussafes",
      },
      {
        id: "46036",
        nm: "Alpuente",
      },
      {
        id: "46037",
        nm: "Alqueria de la Comtessa, l'",
      },
      {
        id: "46017",
        nm: "Alzira",
      },
      {
        id: "46038",
        nm: "Andilla",
      },
      {
        id: "46039",
        nm: "Anna",
      },
      {
        id: "46040",
        nm: "Antella",
      },
      {
        id: "46041",
        nm: "Aras de los Olmos",
      },
      {
        id: "46003",
        nm: "Atzeneta d'Albaida",
      },
      {
        id: "46044",
        nm: "Ayora",
      },
      {
        id: "46046",
        nm: "Barx",
      },
      {
        id: "46045",
        nm: "Barxeta",
      },
      {
        id: "46047",
        nm: "Bèlgida",
      },
      {
        id: "46048",
        nm: "Bellreguard",
      },
      {
        id: "46049",
        nm: "Bellús",
      },
      {
        id: "46050",
        nm: "Benagéber",
      },
      {
        id: "46051",
        nm: "Benaguasil",
      },
      {
        id: "46052",
        nm: "Benavites",
      },
      {
        id: "46053",
        nm: "Beneixida",
      },
      {
        id: "46054",
        nm: "Benetússer",
      },
      {
        id: "46055",
        nm: "Beniarjó",
      },
      {
        id: "46056",
        nm: "Beniatjar",
      },
      {
        id: "46057",
        nm: "Benicolet",
      },
      {
        id: "46904",
        nm: "Benicull de Xúquer",
      },
      {
        id: "46060",
        nm: "Benifaió",
      },
      {
        id: "46059",
        nm: "Benifairó de la Valldigna",
      },
      {
        id: "46058",
        nm: "Benifairó de les Valls",
      },
      {
        id: "46061",
        nm: "Beniflá",
      },
      {
        id: "46062",
        nm: "Benigànim",
      },
      {
        id: "46063",
        nm: "Benimodo",
      },
      {
        id: "46064",
        nm: "Benimuslem",
      },
      {
        id: "46065",
        nm: "Beniparrell",
      },
      {
        id: "46066",
        nm: "Benirredrà",
      },
      {
        id: "46067",
        nm: "Benisanó",
      },
      {
        id: "46068",
        nm: "Benissoda",
      },
      {
        id: "46069",
        nm: "Benisuera",
      },
      {
        id: "46070",
        nm: "Bétera",
      },
      {
        id: "46071",
        nm: "Bicorp",
      },
      {
        id: "46072",
        nm: "Bocairent",
      },
      {
        id: "46073",
        nm: "Bolbaite",
      },
      {
        id: "46074",
        nm: "Bonrepòs i Mirambell",
      },
      {
        id: "46075",
        nm: "Bufali",
      },
      {
        id: "46076",
        nm: "Bugarra",
      },
      {
        id: "46077",
        nm: "Buñol",
      },
      {
        id: "46078",
        nm: "Burjassot",
      },
      {
        id: "46079",
        nm: "Calles",
      },
      {
        id: "46080",
        nm: "Camporrobles",
      },
      {
        id: "46081",
        nm: "Canals",
      },
      {
        id: "46082",
        nm: "Canet d'En Berenguer",
      },
      {
        id: "46083",
        nm: "Carcaixent",
      },
      {
        id: "46084",
        nm: "Càrcer",
      },
      {
        id: "46085",
        nm: "Carlet",
      },
      {
        id: "46086",
        nm: "Carrícola",
      },
      {
        id: "46087",
        nm: "Casas Altas",
      },
      {
        id: "46088",
        nm: "Casas Bajas",
      },
      {
        id: "46089",
        nm: "Casinos",
      },
      {
        id: "46090",
        nm: "Castelló de Rugat",
      },
      {
        id: "46091",
        nm: "Castellonet de la Conquesta",
      },
      {
        id: "46092",
        nm: "Castielfabib",
      },
      {
        id: "46093",
        nm: "Catadau",
      },
      {
        id: "46094",
        nm: "Catarroja",
      },
      {
        id: "46095",
        nm: "Caudete de las Fuentes",
      },
      {
        id: "46096",
        nm: "Cerdà",
      },
      {
        id: "46107",
        nm: "Chella",
      },
      {
        id: "46106",
        nm: "Chelva",
      },
      {
        id: "46108",
        nm: "Chera",
      },
      {
        id: "46109",
        nm: "Cheste",
      },
      {
        id: "46111",
        nm: "Chiva",
      },
      {
        id: "46112",
        nm: "Chulilla",
      },
      {
        id: "46097",
        nm: "Cofrentes",
      },
      {
        id: "46098",
        nm: "Corbera",
      },
      {
        id: "46099",
        nm: "Cortes de Pallás",
      },
      {
        id: "46100",
        nm: "Cotes",
      },
      {
        id: "46105",
        nm: "Cullera",
      },
      {
        id: "46113",
        nm: "Daimús",
      },
      {
        id: "46114",
        nm: "Domeño",
      },
      {
        id: "46115",
        nm: "Dos Aguas",
      },
      {
        id: "46116",
        nm: "Eliana, l'",
      },
      {
        id: "46117",
        nm: "Emperador",
      },
      {
        id: "46118",
        nm: "Enguera",
      },
      {
        id: "46119",
        nm: "Ènova, l'",
      },
      {
        id: "46120",
        nm: "Estivella",
      },
      {
        id: "46121",
        nm: "Estubeny",
      },
      {
        id: "46122",
        nm: "Faura",
      },
      {
        id: "46123",
        nm: "Favara",
      },
      {
        id: "46126",
        nm: "Foios",
      },
      {
        id: "46128",
        nm: "Font de la Figuera, la",
      },
      {
        id: "46127",
        nm: "Font d'En Carròs, la",
      },
      {
        id: "46124",
        nm: "Fontanars dels Alforins",
      },
      {
        id: "46125",
        nm: "Fortaleny",
      },
      {
        id: "46129",
        nm: "Fuenterrobles",
      },
      {
        id: "46131",
        nm: "Gandia",
      },
      {
        id: "46902",
        nm: "Gátova",
      },
      {
        id: "46130",
        nm: "Gavarda",
      },
      {
        id: "46132",
        nm: "Genovés",
      },
      {
        id: "46133",
        nm: "Gestalgar",
      },
      {
        id: "46134",
        nm: "Gilet",
      },
      {
        id: "46135",
        nm: "Godella",
      },
      {
        id: "46136",
        nm: "Godelleta",
      },
      {
        id: "46137",
        nm: "Granja de la Costera, la",
      },
      {
        id: "46138",
        nm: "Guadasséquies",
      },
      {
        id: "46139",
        nm: "Guadassuar",
      },
      {
        id: "46140",
        nm: "Guardamar de la Safor",
      },
      {
        id: "46141",
        nm: "Higueruelas",
      },
      {
        id: "46142",
        nm: "Jalance",
      },
      {
        id: "46144",
        nm: "Jarafuel",
      },
      {
        id: "46154",
        nm: "Llanera de Ranes",
      },
      {
        id: "46155",
        nm: "Llaurí",
      },
      {
        id: "46147",
        nm: "Llíria",
      },
      {
        id: "46152",
        nm: "Llocnou de la Corona",
      },
      {
        id: "46153",
        nm: "Llocnou de Sant Jeroni",
      },
      {
        id: "46151",
        nm: "Llocnou d'En Fenollet",
      },
      {
        id: "46156",
        nm: "Llombai",
      },
      {
        id: "46157",
        nm: "Llosa de Ranes, la",
      },
      {
        id: "46150",
        nm: "Llutxent",
      },
      {
        id: "46148",
        nm: "Loriguilla",
      },
      {
        id: "46149",
        nm: "Losa del Obispo",
      },
      {
        id: "46158",
        nm: "Macastre",
      },
      {
        id: "46159",
        nm: "Manises",
      },
      {
        id: "46160",
        nm: "Manuel",
      },
      {
        id: "46161",
        nm: "Marines",
      },
      {
        id: "46162",
        nm: "Massalavés",
      },
      {
        id: "46163",
        nm: "Massalfassar",
      },
      {
        id: "46164",
        nm: "Massamagrell",
      },
      {
        id: "46165",
        nm: "Massanassa",
      },
      {
        id: "46166",
        nm: "Meliana",
      },
      {
        id: "46167",
        nm: "Millares",
      },
      {
        id: "46168",
        nm: "Miramar",
      },
      {
        id: "46169",
        nm: "Mislata",
      },
      {
        id: "46170",
        nm: "Mogente/Moixent",
      },
      {
        id: "46171",
        nm: "Moncada",
      },
      {
        id: "46173",
        nm: "Montaverner",
      },
      {
        id: "46174",
        nm: "Montesa",
      },
      {
        id: "46175",
        nm: "Montitxelvo/Montichelvo",
      },
      {
        id: "46176",
        nm: "Montroi/Montroy",
      },
      {
        id: "46172",
        nm: "Montserrat",
      },
      {
        id: "46177",
        nm: "Museros",
      },
      {
        id: "46178",
        nm: "Náquera",
      },
      {
        id: "46179",
        nm: "Navarrés",
      },
      {
        id: "46180",
        nm: "Novelé/Novetlè",
      },
      {
        id: "46181",
        nm: "Oliva",
      },
      {
        id: "46183",
        nm: "Olleria, l'",
      },
      {
        id: "46182",
        nm: "Olocau",
      },
      {
        id: "46184",
        nm: "Ontinyent",
      },
      {
        id: "46185",
        nm: "Otos",
      },
      {
        id: "46186",
        nm: "Paiporta",
      },
      {
        id: "46187",
        nm: "Palma de Gandía",
      },
      {
        id: "46188",
        nm: "Palmera",
      },
      {
        id: "46189",
        nm: "Palomar, el",
      },
      {
        id: "46190",
        nm: "Paterna",
      },
      {
        id: "46191",
        nm: "Pedralba",
      },
      {
        id: "46192",
        nm: "Petrés",
      },
      {
        id: "46193",
        nm: "Picanya",
      },
      {
        id: "46194",
        nm: "Picassent",
      },
      {
        id: "46195",
        nm: "Piles",
      },
      {
        id: "46196",
        nm: "Pinet",
      },
      {
        id: "46199",
        nm: "Pobla de Farnals, la",
      },
      {
        id: "46202",
        nm: "Pobla de Vallbona, la",
      },
      {
        id: "46200",
        nm: "Pobla del Duc, la",
      },
      {
        id: "46203",
        nm: "Pobla Llarga, la",
      },
      {
        id: "46197",
        nm: "Polinyà de Xúquer",
      },
      {
        id: "46198",
        nm: "Potries",
      },
      {
        id: "46205",
        nm: "Puçol",
      },
      {
        id: "46201",
        nm: "Puebla de San Miguel",
      },
      {
        id: "46204",
        nm: "Puig de Santa Maria, el",
      },
      {
        id: "46101",
        nm: "Quart de les Valls",
      },
      {
        id: "46102",
        nm: "Quart de Poblet",
      },
      {
        id: "46103",
        nm: "Quartell",
      },
      {
        id: "46104",
        nm: "Quatretonda",
      },
      {
        id: "46206",
        nm: "Quesa",
      },
      {
        id: "46207",
        nm: "Rafelbunyol",
      },
      {
        id: "46208",
        nm: "Rafelcofer",
      },
      {
        id: "46209",
        nm: "Rafelguaraf",
      },
      {
        id: "46210",
        nm: "Ráfol de Salem",
      },
      {
        id: "46212",
        nm: "Real",
      },
      {
        id: "46211",
        nm: "Real de Gandía",
      },
      {
        id: "46213",
        nm: "Requena",
      },
      {
        id: "46214",
        nm: "Riba-roja de Túria",
      },
      {
        id: "46215",
        nm: "Riola",
      },
      {
        id: "46216",
        nm: "Rocafort",
      },
      {
        id: "46217",
        nm: "Rotglà i Corberà",
      },
      {
        id: "46218",
        nm: "Rótova",
      },
      {
        id: "46219",
        nm: "Rugat",
      },
      {
        id: "46220",
        nm: "Sagunto/Sagunt",
      },
      {
        id: "46221",
        nm: "Salem",
      },
      {
        id: "46903",
        nm: "San Antonio de Benagéber",
      },
      {
        id: "46222",
        nm: "Sant Joanet",
      },
      {
        id: "46223",
        nm: "Sedaví",
      },
      {
        id: "46224",
        nm: "Segart",
      },
      {
        id: "46225",
        nm: "Sellent",
      },
      {
        id: "46226",
        nm: "Sempere",
      },
      {
        id: "46227",
        nm: "Senyera",
      },
      {
        id: "46228",
        nm: "Serra",
      },
      {
        id: "46229",
        nm: "Siete Aguas",
      },
      {
        id: "46230",
        nm: "Silla",
      },
      {
        id: "46231",
        nm: "Simat de la Valldigna",
      },
      {
        id: "46232",
        nm: "Sinarcas",
      },
      {
        id: "46233",
        nm: "Sollana",
      },
      {
        id: "46234",
        nm: "Sot de Chera",
      },
      {
        id: "46235",
        nm: "Sueca",
      },
      {
        id: "46236",
        nm: "Sumacàrcer",
      },
      {
        id: "46237",
        nm: "Tavernes Blanques",
      },
      {
        id: "46238",
        nm: "Tavernes de la Valldigna",
      },
      {
        id: "46239",
        nm: "Teresa de Cofrentes",
      },
      {
        id: "46240",
        nm: "Terrateig",
      },
      {
        id: "46241",
        nm: "Titaguas",
      },
      {
        id: "46242",
        nm: "Torrebaja",
      },
      {
        id: "46243",
        nm: "Torrella",
      },
      {
        id: "46244",
        nm: "Torrent",
      },
      {
        id: "46245",
        nm: "Torres Torres",
      },
      {
        id: "46246",
        nm: "Tous",
      },
      {
        id: "46247",
        nm: "Tuéjar",
      },
      {
        id: "46248",
        nm: "Turís",
      },
      {
        id: "46249",
        nm: "Utiel",
      },
      {
        id: "46250",
        nm: "Valencia",
      },
      {
        id: "46251",
        nm: "Vallada",
      },
      {
        id: "46252",
        nm: "Vallanca",
      },
      {
        id: "46253",
        nm: "Vallés",
      },
      {
        id: "46254",
        nm: "Venta del Moro",
      },
      {
        id: "46255",
        nm: "Vilallonga/Villalonga",
      },
      {
        id: "46256",
        nm: "Vilamarxant",
      },
      {
        id: "46257",
        nm: "Villanueva de Castellón",
      },
      {
        id: "46258",
        nm: "Villar del Arzobispo",
      },
      {
        id: "46259",
        nm: "Villargordo del Cabriel",
      },
      {
        id: "46260",
        nm: "Vinalesa",
      },
      {
        id: "46145",
        nm: "Xàtiva",
      },
      {
        id: "46143",
        nm: "Xeraco",
      },
      {
        id: "46146",
        nm: "Xeresa",
      },
      {
        id: "46110",
        nm: "Xirivella",
      },
      {
        id: "46261",
        nm: "Yátova",
      },
      {
        id: "46262",
        nm: "Yesa, La",
      },
      {
        id: "46263",
        nm: "Zarra",
      },
      {
        id: "47001",
        nm: "Adalia",
      },
      {
        id: "47002",
        nm: "Aguasal",
      },
      {
        id: "47003",
        nm: "Aguilar de Campos",
      },
      {
        id: "47004",
        nm: "Alaejos",
      },
      {
        id: "47005",
        nm: "Alcazarén",
      },
      {
        id: "47006",
        nm: "Aldea de San Miguel",
      },
      {
        id: "47007",
        nm: "Aldeamayor de San Martín",
      },
      {
        id: "47008",
        nm: "Almenara de Adaja",
      },
      {
        id: "47009",
        nm: "Amusquillo",
      },
      {
        id: "47010",
        nm: "Arroyo de la Encomienda",
      },
      {
        id: "47011",
        nm: "Ataquines",
      },
      {
        id: "47012",
        nm: "Bahabón",
      },
      {
        id: "47013",
        nm: "Barcial de la Loma",
      },
      {
        id: "47014",
        nm: "Barruelo del Valle",
      },
      {
        id: "47015",
        nm: "Becilla de Valderaduey",
      },
      {
        id: "47016",
        nm: "Benafarces",
      },
      {
        id: "47017",
        nm: "Bercero",
      },
      {
        id: "47018",
        nm: "Berceruelo",
      },
      {
        id: "47019",
        nm: "Berrueces",
      },
      {
        id: "47020",
        nm: "Bobadilla del Campo",
      },
      {
        id: "47021",
        nm: "Bocigas",
      },
      {
        id: "47022",
        nm: "Bocos de Duero",
      },
      {
        id: "47023",
        nm: "Boecillo",
      },
      {
        id: "47024",
        nm: "Bolaños de Campos",
      },
      {
        id: "47025",
        nm: "Brahojos de Medina",
      },
      {
        id: "47026",
        nm: "Bustillo de Chaves",
      },
      {
        id: "47027",
        nm: "Cabezón de Pisuerga",
      },
      {
        id: "47028",
        nm: "Cabezón de Valderaduey",
      },
      {
        id: "47029",
        nm: "Cabreros del Monte",
      },
      {
        id: "47030",
        nm: "Campaspero",
      },
      {
        id: "47031",
        nm: "Campillo, El",
      },
      {
        id: "47032",
        nm: "Camporredondo",
      },
      {
        id: "47033",
        nm: "Canalejas de Peñafiel",
      },
      {
        id: "47034",
        nm: "Canillas de Esgueva",
      },
      {
        id: "47035",
        nm: "Carpio",
      },
      {
        id: "47036",
        nm: "Casasola de Arión",
      },
      {
        id: "47037",
        nm: "Castrejón de Trabancos",
      },
      {
        id: "47038",
        nm: "Castrillo de Duero",
      },
      {
        id: "47039",
        nm: "Castrillo-Tejeriego",
      },
      {
        id: "47040",
        nm: "Castrobol",
      },
      {
        id: "47041",
        nm: "Castrodeza",
      },
      {
        id: "47042",
        nm: "Castromembibre",
      },
      {
        id: "47043",
        nm: "Castromonte",
      },
      {
        id: "47044",
        nm: "Castronuevo de Esgueva",
      },
      {
        id: "47045",
        nm: "Castronuño",
      },
      {
        id: "47046",
        nm: "Castroponce",
      },
      {
        id: "47047",
        nm: "Castroverde de Cerrato",
      },
      {
        id: "47048",
        nm: "Ceinos de Campos",
      },
      {
        id: "47049",
        nm: "Cervillego de la Cruz",
      },
      {
        id: "47050",
        nm: "Cigales",
      },
      {
        id: "47051",
        nm: "Ciguñuela",
      },
      {
        id: "47052",
        nm: "Cistérniga",
      },
      {
        id: "47053",
        nm: "Cogeces de Íscar",
      },
      {
        id: "47054",
        nm: "Cogeces del Monte",
      },
      {
        id: "47055",
        nm: "Corcos",
      },
      {
        id: "47056",
        nm: "Corrales de Duero",
      },
      {
        id: "47057",
        nm: "Cubillas de Santa Marta",
      },
      {
        id: "47058",
        nm: "Cuenca de Campos",
      },
      {
        id: "47059",
        nm: "Curiel de Duero",
      },
      {
        id: "47060",
        nm: "Encinas de Esgueva",
      },
      {
        id: "47061",
        nm: "Esguevillas de Esgueva",
      },
      {
        id: "47062",
        nm: "Fombellida",
      },
      {
        id: "47063",
        nm: "Fompedraza",
      },
      {
        id: "47064",
        nm: "Fontihoyuelo",
      },
      {
        id: "47065",
        nm: "Fresno el Viejo",
      },
      {
        id: "47066",
        nm: "Fuensaldaña",
      },
      {
        id: "47067",
        nm: "Fuente el Sol",
      },
      {
        id: "47068",
        nm: "Fuente-Olmedo",
      },
      {
        id: "47069",
        nm: "Gallegos de Hornija",
      },
      {
        id: "47070",
        nm: "Gatón de Campos",
      },
      {
        id: "47071",
        nm: "Geria",
      },
      {
        id: "47073",
        nm: "Herrín de Campos",
      },
      {
        id: "47074",
        nm: "Hornillos de Eresma",
      },
      {
        id: "47075",
        nm: "Íscar",
      },
      {
        id: "47076",
        nm: "Laguna de Duero",
      },
      {
        id: "47077",
        nm: "Langayo",
      },
      {
        id: "47079",
        nm: "Llano de Olmedo",
      },
      {
        id: "47078",
        nm: "Lomoviejo",
      },
      {
        id: "47080",
        nm: "Manzanillo",
      },
      {
        id: "47081",
        nm: "Marzales",
      },
      {
        id: "47082",
        nm: "Matapozuelos",
      },
      {
        id: "47083",
        nm: "Matilla de los Caños",
      },
      {
        id: "47084",
        nm: "Mayorga",
      },
      {
        id: "47086",
        nm: "Medina de Rioseco",
      },
      {
        id: "47085",
        nm: "Medina del Campo",
      },
      {
        id: "47087",
        nm: "Megeces",
      },
      {
        id: "47088",
        nm: "Melgar de Abajo",
      },
      {
        id: "47089",
        nm: "Melgar de Arriba",
      },
      {
        id: "47090",
        nm: "Mojados",
      },
      {
        id: "47091",
        nm: "Monasterio de Vega",
      },
      {
        id: "47092",
        nm: "Montealegre de Campos",
      },
      {
        id: "47093",
        nm: "Montemayor de Pililla",
      },
      {
        id: "47094",
        nm: "Moral de la Reina",
      },
      {
        id: "47095",
        nm: "Moraleja de las Panaderas",
      },
      {
        id: "47096",
        nm: "Morales de Campos",
      },
      {
        id: "47097",
        nm: "Mota del Marqués",
      },
      {
        id: "47098",
        nm: "Mucientes",
      },
      {
        id: "47099",
        nm: "Mudarra, La",
      },
      {
        id: "47100",
        nm: "Muriel",
      },
      {
        id: "47101",
        nm: "Nava del Rey",
      },
      {
        id: "47102",
        nm: "Nueva Villa de las Torres",
      },
      {
        id: "47103",
        nm: "Olivares de Duero",
      },
      {
        id: "47104",
        nm: "Olmedo",
      },
      {
        id: "47105",
        nm: "Olmos de Esgueva",
      },
      {
        id: "47106",
        nm: "Olmos de Peñafiel",
      },
      {
        id: "47109",
        nm: "Palazuelo de Vedija",
      },
      {
        id: "47110",
        nm: "Parrilla, La",
      },
      {
        id: "47111",
        nm: "Pedraja de Portillo, La",
      },
      {
        id: "47112",
        nm: "Pedrajas de San Esteban",
      },
      {
        id: "47113",
        nm: "Pedrosa del Rey",
      },
      {
        id: "47114",
        nm: "Peñafiel",
      },
      {
        id: "47115",
        nm: "Peñaflor de Hornija",
      },
      {
        id: "47116",
        nm: "Pesquera de Duero",
      },
      {
        id: "47117",
        nm: "Piña de Esgueva",
      },
      {
        id: "47118",
        nm: "Piñel de Abajo",
      },
      {
        id: "47119",
        nm: "Piñel de Arriba",
      },
      {
        id: "47121",
        nm: "Pollos",
      },
      {
        id: "47122",
        nm: "Portillo",
      },
      {
        id: "47123",
        nm: "Pozal de Gallinas",
      },
      {
        id: "47124",
        nm: "Pozaldez",
      },
      {
        id: "47125",
        nm: "Pozuelo de la Orden",
      },
      {
        id: "47126",
        nm: "Puras",
      },
      {
        id: "47127",
        nm: "Quintanilla de Arriba",
      },
      {
        id: "47129",
        nm: "Quintanilla de Onésimo",
      },
      {
        id: "47130",
        nm: "Quintanilla de Trigueros",
      },
      {
        id: "47128",
        nm: "Quintanilla del Molar",
      },
      {
        id: "47131",
        nm: "Rábano",
      },
      {
        id: "47132",
        nm: "Ramiro",
      },
      {
        id: "47133",
        nm: "Renedo de Esgueva",
      },
      {
        id: "47134",
        nm: "Roales de Campos",
      },
      {
        id: "47135",
        nm: "Robladillo",
      },
      {
        id: "47137",
        nm: "Roturas",
      },
      {
        id: "47138",
        nm: "Rubí de Bracamonte",
      },
      {
        id: "47139",
        nm: "Rueda",
      },
      {
        id: "47140",
        nm: "Saelices de Mayorga",
      },
      {
        id: "47141",
        nm: "Salvador de Zapardiel",
      },
      {
        id: "47142",
        nm: "San Cebrián de Mazote",
      },
      {
        id: "47143",
        nm: "San Llorente",
      },
      {
        id: "47144",
        nm: "San Martín de Valvení",
      },
      {
        id: "47145",
        nm: "San Miguel del Arroyo",
      },
      {
        id: "47146",
        nm: "San Miguel del Pino",
      },
      {
        id: "47147",
        nm: "San Pablo de la Moraleja",
      },
      {
        id: "47148",
        nm: "San Pedro de Latarce",
      },
      {
        id: "47149",
        nm: "San Pelayo",
      },
      {
        id: "47150",
        nm: "San Román de Hornija",
      },
      {
        id: "47151",
        nm: "San Salvador",
      },
      {
        id: "47156",
        nm: "San Vicente del Palacio",
      },
      {
        id: "47152",
        nm: "Santa Eufemia del Arroyo",
      },
      {
        id: "47153",
        nm: "Santervás de Campos",
      },
      {
        id: "47154",
        nm: "Santibáñez de Valcorba",
      },
      {
        id: "47155",
        nm: "Santovenia de Pisuerga",
      },
      {
        id: "47157",
        nm: "Sardón de Duero",
      },
      {
        id: "47158",
        nm: "Seca, La",
      },
      {
        id: "47159",
        nm: "Serrada",
      },
      {
        id: "47160",
        nm: "Siete Iglesias de Trabancos",
      },
      {
        id: "47161",
        nm: "Simancas",
      },
      {
        id: "47162",
        nm: "Tamariz de Campos",
      },
      {
        id: "47163",
        nm: "Tiedra",
      },
      {
        id: "47164",
        nm: "Tordehumos",
      },
      {
        id: "47165",
        nm: "Tordesillas",
      },
      {
        id: "47169",
        nm: "Torre de Esgueva",
      },
      {
        id: "47170",
        nm: "Torre de Peñafiel",
      },
      {
        id: "47166",
        nm: "Torrecilla de la Abadesa",
      },
      {
        id: "47167",
        nm: "Torrecilla de la Orden",
      },
      {
        id: "47168",
        nm: "Torrecilla de la Torre",
      },
      {
        id: "47171",
        nm: "Torrelobatón",
      },
      {
        id: "47172",
        nm: "Torrescárcela",
      },
      {
        id: "47173",
        nm: "Traspinedo",
      },
      {
        id: "47174",
        nm: "Trigueros del Valle",
      },
      {
        id: "47175",
        nm: "Tudela de Duero",
      },
      {
        id: "47176",
        nm: "Unión de Campos, La",
      },
      {
        id: "47177",
        nm: "Urones de Castroponce",
      },
      {
        id: "47178",
        nm: "Urueña",
      },
      {
        id: "47179",
        nm: "Valbuena de Duero",
      },
      {
        id: "47180",
        nm: "Valdearcos de la Vega",
      },
      {
        id: "47181",
        nm: "Valdenebro de los Valles",
      },
      {
        id: "47182",
        nm: "Valdestillas",
      },
      {
        id: "47183",
        nm: "Valdunquillo",
      },
      {
        id: "47186",
        nm: "Valladolid",
      },
      {
        id: "47184",
        nm: "Valoria la Buena",
      },
      {
        id: "47185",
        nm: "Valverde de Campos",
      },
      {
        id: "47187",
        nm: "Vega de Ruiponce",
      },
      {
        id: "47188",
        nm: "Vega de Valdetronco",
      },
      {
        id: "47189",
        nm: "Velascálvaro",
      },
      {
        id: "47190",
        nm: "Velilla",
      },
      {
        id: "47191",
        nm: "Velliza",
      },
      {
        id: "47192",
        nm: "Ventosa de la Cuesta",
      },
      {
        id: "47193",
        nm: "Viana de Cega",
      },
      {
        id: "47195",
        nm: "Villabáñez",
      },
      {
        id: "47196",
        nm: "Villabaruz de Campos",
      },
      {
        id: "47197",
        nm: "Villabrágima",
      },
      {
        id: "47198",
        nm: "Villacarralón",
      },
      {
        id: "47199",
        nm: "Villacid de Campos",
      },
      {
        id: "47200",
        nm: "Villaco",
      },
      {
        id: "47203",
        nm: "Villafrades de Campos",
      },
      {
        id: "47204",
        nm: "Villafranca de Duero",
      },
      {
        id: "47205",
        nm: "Villafrechós",
      },
      {
        id: "47206",
        nm: "Villafuerte",
      },
      {
        id: "47207",
        nm: "Villagarcía de Campos",
      },
      {
        id: "47208",
        nm: "Villagómez la Nueva",
      },
      {
        id: "47209",
        nm: "Villalán de Campos",
      },
      {
        id: "47210",
        nm: "Villalar de los Comuneros",
      },
      {
        id: "47211",
        nm: "Villalba de la Loma",
      },
      {
        id: "47212",
        nm: "Villalba de los Alcores",
      },
      {
        id: "47213",
        nm: "Villalbarba",
      },
      {
        id: "47214",
        nm: "Villalón de Campos",
      },
      {
        id: "47215",
        nm: "Villamuriel de Campos",
      },
      {
        id: "47216",
        nm: "Villán de Tordesillas",
      },
      {
        id: "47217",
        nm: "Villanubla",
      },
      {
        id: "47218",
        nm: "Villanueva de Duero",
      },
      {
        id: "47219",
        nm: "Villanueva de la Condesa",
      },
      {
        id: "47220",
        nm: "Villanueva de los Caballeros",
      },
      {
        id: "47221",
        nm: "Villanueva de los Infantes",
      },
      {
        id: "47222",
        nm: "Villanueva de San Mancio",
      },
      {
        id: "47223",
        nm: "Villardefrades",
      },
      {
        id: "47224",
        nm: "Villarmentero de Esgueva",
      },
      {
        id: "47225",
        nm: "Villasexmir",
      },
      {
        id: "47226",
        nm: "Villavaquerín",
      },
      {
        id: "47227",
        nm: "Villavellid",
      },
      {
        id: "47228",
        nm: "Villaverde de Medina",
      },
      {
        id: "47229",
        nm: "Villavicencio de los Caballeros",
      },
      {
        id: "47194",
        nm: "Viloria",
      },
      {
        id: "47230",
        nm: "Wamba",
      },
      {
        id: "47231",
        nm: "Zaratán",
      },
      {
        id: "47232",
        nm: "Zarza, La",
      },
      {
        id: "48001",
        nm: "Abadiño",
      },
      {
        id: "48002",
        nm: "Abanto y Ciérvana-Abanto Zierbena",
      },
      {
        id: "48911",
        nm: "Ajangiz",
      },
      {
        id: "48912",
        nm: "Alonsotegi",
      },
      {
        id: "48003",
        nm: "Amorebieta-Etxano",
      },
      {
        id: "48004",
        nm: "Amoroto",
      },
      {
        id: "48005",
        nm: "Arakaldo",
      },
      {
        id: "48006",
        nm: "Arantzazu",
      },
      {
        id: "48093",
        nm: "Areatza",
      },
      {
        id: "48009",
        nm: "Arrankudiaga",
      },
      {
        id: "48914",
        nm: "Arratzu",
      },
      {
        id: "48010",
        nm: "Arrieta",
      },
      {
        id: "48011",
        nm: "Arrigorriaga",
      },
      {
        id: "48023",
        nm: "Artea",
      },
      {
        id: "48008",
        nm: "Artzentales",
      },
      {
        id: "48091",
        nm: "Atxondo",
      },
      {
        id: "48070",
        nm: "Aulesti",
      },
      {
        id: "48012",
        nm: "Bakio",
      },
      {
        id: "48090",
        nm: "Balmaseda",
      },
      {
        id: "48013",
        nm: "Barakaldo",
      },
      {
        id: "48014",
        nm: "Barrika",
      },
      {
        id: "48015",
        nm: "Basauri",
      },
      {
        id: "48092",
        nm: "Bedia",
      },
      {
        id: "48016",
        nm: "Berango",
      },
      {
        id: "48017",
        nm: "Bermeo",
      },
      {
        id: "48018",
        nm: "Berriatua",
      },
      {
        id: "48019",
        nm: "Berriz",
      },
      {
        id: "48020",
        nm: "Bilbao",
      },
      {
        id: "48021",
        nm: "Busturia",
      },
      {
        id: "48901",
        nm: "Derio",
      },
      {
        id: "48026",
        nm: "Dima",
      },
      {
        id: "48027",
        nm: "Durango",
      },
      {
        id: "48028",
        nm: "Ea",
      },
      {
        id: "48031",
        nm: "Elantxobe",
      },
      {
        id: "48032",
        nm: "Elorrio",
      },
      {
        id: "48902",
        nm: "Erandio",
      },
      {
        id: "48033",
        nm: "Ereño",
      },
      {
        id: "48034",
        nm: "Ermua",
      },
      {
        id: "48079",
        nm: "Errigoiti",
      },
      {
        id: "48029",
        nm: "Etxebarri",
      },
      {
        id: "48030",
        nm: "Etxebarria",
      },
      {
        id: "48906",
        nm: "Forua",
      },
      {
        id: "48035",
        nm: "Fruiz",
      },
      {
        id: "48036",
        nm: "Galdakao",
      },
      {
        id: "48037",
        nm: "Galdames",
      },
      {
        id: "48038",
        nm: "Gamiz-Fika",
      },
      {
        id: "48039",
        nm: "Garai",
      },
      {
        id: "48040",
        nm: "Gatika",
      },
      {
        id: "48041",
        nm: "Gautegiz Arteaga",
      },
      {
        id: "48046",
        nm: "Gernika-Lumo",
      },
      {
        id: "48044",
        nm: "Getxo",
      },
      {
        id: "48047",
        nm: "Gizaburuaga",
      },
      {
        id: "48042",
        nm: "Gordexola",
      },
      {
        id: "48043",
        nm: "Gorliz",
      },
      {
        id: "48045",
        nm: "Güeñes",
      },
      {
        id: "48048",
        nm: "Ibarrangelu",
      },
      {
        id: "48094",
        nm: "Igorre",
      },
      {
        id: "48049",
        nm: "Ispaster",
      },
      {
        id: "48910",
        nm: "Iurreta",
      },
      {
        id: "48050",
        nm: "Izurtza",
      },
      {
        id: "48022",
        nm: "Karrantza Harana/Valle de Carranza",
      },
      {
        id: "48907",
        nm: "Kortezubi",
      },
      {
        id: "48051",
        nm: "Lanestosa",
      },
      {
        id: "48052",
        nm: "Larrabetzu",
      },
      {
        id: "48053",
        nm: "Laukiz",
      },
      {
        id: "48054",
        nm: "Leioa",
      },
      {
        id: "48057",
        nm: "Lekeitio",
      },
      {
        id: "48055",
        nm: "Lemoa",
      },
      {
        id: "48056",
        nm: "Lemoiz",
      },
      {
        id: "48081",
        nm: "Lezama",
      },
      {
        id: "48903",
        nm: "Loiu",
      },
      {
        id: "48058",
        nm: "Mallabia",
      },
      {
        id: "48059",
        nm: "Mañaria",
      },
      {
        id: "48060",
        nm: "Markina-Xemein",
      },
      {
        id: "48061",
        nm: "Maruri-Jatabe",
      },
      {
        id: "48062",
        nm: "Mendata",
      },
      {
        id: "48063",
        nm: "Mendexa",
      },
      {
        id: "48064",
        nm: "Meñaka",
      },
      {
        id: "48066",
        nm: "Morga",
      },
      {
        id: "48068",
        nm: "Mundaka",
      },
      {
        id: "48069",
        nm: "Mungia",
      },
      {
        id: "48007",
        nm: "Munitibar-Arbatzegi Gerrikaitz",
      },
      {
        id: "48908",
        nm: "Murueta",
      },
      {
        id: "48071",
        nm: "Muskiz",
      },
      {
        id: "48067",
        nm: "Muxika",
      },
      {
        id: "48909",
        nm: "Nabarniz",
      },
      {
        id: "48073",
        nm: "Ondarroa",
      },
      {
        id: "48075",
        nm: "Orozko",
      },
      {
        id: "48083",
        nm: "Ortuella",
      },
      {
        id: "48072",
        nm: "Otxandio",
      },
      {
        id: "48077",
        nm: "Plentzia",
      },
      {
        id: "48078",
        nm: "Portugalete",
      },
      {
        id: "48082",
        nm: "Santurtzi",
      },
      {
        id: "48084",
        nm: "Sestao",
      },
      {
        id: "48904",
        nm: "Sondika",
      },
      {
        id: "48085",
        nm: "Sopela",
      },
      {
        id: "48086",
        nm: "Sopuerta",
      },
      {
        id: "48076",
        nm: "Sukarrieta",
      },
      {
        id: "48087",
        nm: "Trucios-Turtzioz",
      },
      {
        id: "48088",
        nm: "Ubide",
      },
      {
        id: "48065",
        nm: "Ugao-Miraballes",
      },
      {
        id: "48089",
        nm: "Urduliz",
      },
      {
        id: "48074",
        nm: "Urduña/Orduña",
      },
      {
        id: "48080",
        nm: "Valle de Trápaga-Trapagaran",
      },
      {
        id: "48095",
        nm: "Zaldibar",
      },
      {
        id: "48096",
        nm: "Zalla",
      },
      {
        id: "48905",
        nm: "Zamudio",
      },
      {
        id: "48097",
        nm: "Zaratamo",
      },
      {
        id: "48024",
        nm: "Zeanuri",
      },
      {
        id: "48025",
        nm: "Zeberio",
      },
      {
        id: "48913",
        nm: "Zierbena",
      },
      {
        id: "48915",
        nm: "Ziortza-Bolibar",
      },
      {
        id: "49002",
        nm: "Abezames",
      },
      {
        id: "49003",
        nm: "Alcañices",
      },
      {
        id: "49004",
        nm: "Alcubilla de Nogales",
      },
      {
        id: "49005",
        nm: "Alfaraz de Sayago",
      },
      {
        id: "49006",
        nm: "Algodre",
      },
      {
        id: "49007",
        nm: "Almaraz de Duero",
      },
      {
        id: "49008",
        nm: "Almeida de Sayago",
      },
      {
        id: "49009",
        nm: "Andavías",
      },
      {
        id: "49010",
        nm: "Arcenillas",
      },
      {
        id: "49011",
        nm: "Arcos de la Polvorosa",
      },
      {
        id: "49012",
        nm: "Argañín",
      },
      {
        id: "49013",
        nm: "Argujillo",
      },
      {
        id: "49014",
        nm: "Arquillinos",
      },
      {
        id: "49015",
        nm: "Arrabalde",
      },
      {
        id: "49016",
        nm: "Aspariegos",
      },
      {
        id: "49017",
        nm: "Asturianos",
      },
      {
        id: "49018",
        nm: "Ayoó de Vidriales",
      },
      {
        id: "49019",
        nm: "Barcial del Barco",
      },
      {
        id: "49020",
        nm: "Belver de los Montes",
      },
      {
        id: "49021",
        nm: "Benavente",
      },
      {
        id: "49022",
        nm: "Benegiles",
      },
      {
        id: "49023",
        nm: "Bermillo de Sayago",
      },
      {
        id: "49024",
        nm: "Bóveda de Toro, La",
      },
      {
        id: "49025",
        nm: "Bretó",
      },
      {
        id: "49026",
        nm: "Bretocino",
      },
      {
        id: "49027",
        nm: "Brime de Sog",
      },
      {
        id: "49028",
        nm: "Brime de Urz",
      },
      {
        id: "49029",
        nm: "Burganes de Valverde",
      },
      {
        id: "49030",
        nm: "Bustillo del Oro",
      },
      {
        id: "49031",
        nm: "Cabañas de Sayago",
      },
      {
        id: "49032",
        nm: "Calzadilla de Tera",
      },
      {
        id: "49033",
        nm: "Camarzana de Tera",
      },
      {
        id: "49034",
        nm: "Cañizal",
      },
      {
        id: "49035",
        nm: "Cañizo",
      },
      {
        id: "49036",
        nm: "Carbajales de Alba",
      },
      {
        id: "49037",
        nm: "Carbellino",
      },
      {
        id: "49038",
        nm: "Casaseca de Campeán",
      },
      {
        id: "49039",
        nm: "Casaseca de las Chanas",
      },
      {
        id: "49040",
        nm: "Castrillo de la Guareña",
      },
      {
        id: "49041",
        nm: "Castrogonzalo",
      },
      {
        id: "49042",
        nm: "Castronuevo",
      },
      {
        id: "49043",
        nm: "Castroverde de Campos",
      },
      {
        id: "49044",
        nm: "Cazurra",
      },
      {
        id: "49046",
        nm: "Cerecinos de Campos",
      },
      {
        id: "49047",
        nm: "Cerecinos del Carrizal",
      },
      {
        id: "49048",
        nm: "Cernadilla",
      },
      {
        id: "49050",
        nm: "Cobreros",
      },
      {
        id: "49052",
        nm: "Coomonte",
      },
      {
        id: "49053",
        nm: "Coreses",
      },
      {
        id: "49054",
        nm: "Corrales del Vino",
      },
      {
        id: "49055",
        nm: "Cotanes del Monte",
      },
      {
        id: "49056",
        nm: "Cubillos",
      },
      {
        id: "49057",
        nm: "Cubo de Benavente",
      },
      {
        id: "49058",
        nm: "Cubo de Tierra del Vino, El",
      },
      {
        id: "49059",
        nm: "Cuelgamures",
      },
      {
        id: "49061",
        nm: "Entrala",
      },
      {
        id: "49062",
        nm: "Espadañedo",
      },
      {
        id: "49063",
        nm: "Faramontanos de Tábara",
      },
      {
        id: "49064",
        nm: "Fariza",
      },
      {
        id: "49065",
        nm: "Fermoselle",
      },
      {
        id: "49066",
        nm: "Ferreras de Abajo",
      },
      {
        id: "49067",
        nm: "Ferreras de Arriba",
      },
      {
        id: "49068",
        nm: "Ferreruela",
      },
      {
        id: "49069",
        nm: "Figueruela de Arriba",
      },
      {
        id: "49071",
        nm: "Fonfría",
      },
      {
        id: "49075",
        nm: "Fresno de la Polvorosa",
      },
      {
        id: "49076",
        nm: "Fresno de la Ribera",
      },
      {
        id: "49077",
        nm: "Fresno de Sayago",
      },
      {
        id: "49078",
        nm: "Friera de Valverde",
      },
      {
        id: "49079",
        nm: "Fuente Encalada",
      },
      {
        id: "49080",
        nm: "Fuentelapeña",
      },
      {
        id: "49082",
        nm: "Fuentes de Ropel",
      },
      {
        id: "49081",
        nm: "Fuentesaúco",
      },
      {
        id: "49083",
        nm: "Fuentesecas",
      },
      {
        id: "49084",
        nm: "Fuentespreadas",
      },
      {
        id: "49085",
        nm: "Galende",
      },
      {
        id: "49086",
        nm: "Gallegos del Pan",
      },
      {
        id: "49087",
        nm: "Gallegos del Río",
      },
      {
        id: "49088",
        nm: "Gamones",
      },
      {
        id: "49090",
        nm: "Gema",
      },
      {
        id: "49091",
        nm: "Granja de Moreruela",
      },
      {
        id: "49092",
        nm: "Granucillo",
      },
      {
        id: "49093",
        nm: "Guarrate",
      },
      {
        id: "49094",
        nm: "Hermisende",
      },
      {
        id: "49095",
        nm: "Hiniesta, La",
      },
      {
        id: "49096",
        nm: "Jambrina",
      },
      {
        id: "49097",
        nm: "Justel",
      },
      {
        id: "49098",
        nm: "Losacino",
      },
      {
        id: "49099",
        nm: "Losacio",
      },
      {
        id: "49100",
        nm: "Lubián",
      },
      {
        id: "49101",
        nm: "Luelmo",
      },
      {
        id: "49102",
        nm: "Maderal, El",
      },
      {
        id: "49103",
        nm: "Madridanos",
      },
      {
        id: "49104",
        nm: "Mahide",
      },
      {
        id: "49105",
        nm: "Maire de Castroponce",
      },
      {
        id: "49107",
        nm: "Malva",
      },
      {
        id: "49108",
        nm: "Manganeses de la Lampreana",
      },
      {
        id: "49109",
        nm: "Manganeses de la Polvorosa",
      },
      {
        id: "49110",
        nm: "Manzanal de Arriba",
      },
      {
        id: "49112",
        nm: "Manzanal de los Infantes",
      },
      {
        id: "49111",
        nm: "Manzanal del Barco",
      },
      {
        id: "49113",
        nm: "Matilla de Arzón",
      },
      {
        id: "49114",
        nm: "Matilla la Seca",
      },
      {
        id: "49115",
        nm: "Mayalde",
      },
      {
        id: "49116",
        nm: "Melgar de Tera",
      },
      {
        id: "49117",
        nm: "Micereces de Tera",
      },
      {
        id: "49118",
        nm: "Milles de la Polvorosa",
      },
      {
        id: "49119",
        nm: "Molacillos",
      },
      {
        id: "49120",
        nm: "Molezuelas de la Carballeda",
      },
      {
        id: "49121",
        nm: "Mombuey",
      },
      {
        id: "49122",
        nm: "Monfarracinos",
      },
      {
        id: "49123",
        nm: "Montamarta",
      },
      {
        id: "49124",
        nm: "Moral de Sayago",
      },
      {
        id: "49126",
        nm: "Moraleja de Sayago",
      },
      {
        id: "49125",
        nm: "Moraleja del Vino",
      },
      {
        id: "49128",
        nm: "Morales de Rey",
      },
      {
        id: "49129",
        nm: "Morales de Toro",
      },
      {
        id: "49130",
        nm: "Morales de Valverde",
      },
      {
        id: "49127",
        nm: "Morales del Vino",
      },
      {
        id: "49131",
        nm: "Moralina",
      },
      {
        id: "49132",
        nm: "Moreruela de los Infanzones",
      },
      {
        id: "49133",
        nm: "Moreruela de Tábara",
      },
      {
        id: "49134",
        nm: "Muelas de los Caballeros",
      },
      {
        id: "49135",
        nm: "Muelas del Pan",
      },
      {
        id: "49136",
        nm: "Muga de Sayago",
      },
      {
        id: "49137",
        nm: "Navianos de Valverde",
      },
      {
        id: "49138",
        nm: "Olmillos de Castro",
      },
      {
        id: "49139",
        nm: "Otero de Bodas",
      },
      {
        id: "49141",
        nm: "Pajares de la Lampreana",
      },
      {
        id: "49143",
        nm: "Palacios de Sanabria",
      },
      {
        id: "49142",
        nm: "Palacios del Pan",
      },
      {
        id: "49145",
        nm: "Pedralba de la Pradería",
      },
      {
        id: "49146",
        nm: "Pego, El",
      },
      {
        id: "49147",
        nm: "Peleagonzalo",
      },
      {
        id: "49148",
        nm: "Peleas de Abajo",
      },
      {
        id: "49149",
        nm: "Peñausende",
      },
      {
        id: "49150",
        nm: "Peque",
      },
      {
        id: "49151",
        nm: "Perdigón, El",
      },
      {
        id: "49152",
        nm: "Pereruela",
      },
      {
        id: "49153",
        nm: "Perilla de Castro",
      },
      {
        id: "49154",
        nm: "Pías",
      },
      {
        id: "49155",
        nm: "Piedrahita de Castro",
      },
      {
        id: "49156",
        nm: "Pinilla de Toro",
      },
      {
        id: "49157",
        nm: "Pino del Oro",
      },
      {
        id: "49158",
        nm: "Piñero, El",
      },
      {
        id: "49160",
        nm: "Pobladura de Valderaduey",
      },
      {
        id: "49159",
        nm: "Pobladura del Valle",
      },
      {
        id: "49162",
        nm: "Porto",
      },
      {
        id: "49163",
        nm: "Pozoantiguo",
      },
      {
        id: "49164",
        nm: "Pozuelo de Tábara",
      },
      {
        id: "49165",
        nm: "Prado",
      },
      {
        id: "49166",
        nm: "Puebla de Sanabria",
      },
      {
        id: "49167",
        nm: "Pueblica de Valverde",
      },
      {
        id: "49170",
        nm: "Quintanilla de Urz",
      },
      {
        id: "49168",
        nm: "Quintanilla del Monte",
      },
      {
        id: "49169",
        nm: "Quintanilla del Olmo",
      },
      {
        id: "49171",
        nm: "Quiruelas de Vidriales",
      },
      {
        id: "49172",
        nm: "Rabanales",
      },
      {
        id: "49173",
        nm: "Rábano de Aliste",
      },
      {
        id: "49174",
        nm: "Requejo",
      },
      {
        id: "49175",
        nm: "Revellinos",
      },
      {
        id: "49176",
        nm: "Riofrío de Aliste",
      },
      {
        id: "49177",
        nm: "Rionegro del Puente",
      },
      {
        id: "49178",
        nm: "Roales",
      },
      {
        id: "49179",
        nm: "Robleda-Cervantes",
      },
      {
        id: "49180",
        nm: "Roelos de Sayago",
      },
      {
        id: "49181",
        nm: "Rosinos de la Requejada",
      },
      {
        id: "49183",
        nm: "Salce",
      },
      {
        id: "49184",
        nm: "Samir de los Caños",
      },
      {
        id: "49185",
        nm: "San Agustín del Pozo",
      },
      {
        id: "49186",
        nm: "San Cebrián de Castro",
      },
      {
        id: "49187",
        nm: "San Cristóbal de Entreviñas",
      },
      {
        id: "49188",
        nm: "San Esteban del Molar",
      },
      {
        id: "49189",
        nm: "San Justo",
      },
      {
        id: "49190",
        nm: "San Martín de Valderaduey",
      },
      {
        id: "49191",
        nm: "San Miguel de la Ribera",
      },
      {
        id: "49192",
        nm: "San Miguel del Valle",
      },
      {
        id: "49193",
        nm: "San Pedro de Ceque",
      },
      {
        id: "49194",
        nm: "San Pedro de la Nave-Almendra",
      },
      {
        id: "49208",
        nm: "San Vicente de la Cabeza",
      },
      {
        id: "49209",
        nm: "San Vitero",
      },
      {
        id: "49197",
        nm: "Santa Clara de Avedillo",
      },
      {
        id: "49199",
        nm: "Santa Colomba de las Monjas",
      },
      {
        id: "49200",
        nm: "Santa Cristina de la Polvorosa",
      },
      {
        id: "49201",
        nm: "Santa Croya de Tera",
      },
      {
        id: "49202",
        nm: "Santa Eufemia del Barco",
      },
      {
        id: "49203",
        nm: "Santa María de la Vega",
      },
      {
        id: "49204",
        nm: "Santa María de Valverde",
      },
      {
        id: "49205",
        nm: "Santibáñez de Tera",
      },
      {
        id: "49206",
        nm: "Santibáñez de Vidriales",
      },
      {
        id: "49207",
        nm: "Santovenia",
      },
      {
        id: "49210",
        nm: "Sanzoles",
      },
      {
        id: "49214",
        nm: "Tábara",
      },
      {
        id: "49216",
        nm: "Tapioles",
      },
      {
        id: "49219",
        nm: "Toro",
      },
      {
        id: "49220",
        nm: "Torre del Valle, La",
      },
      {
        id: "49221",
        nm: "Torregamones",
      },
      {
        id: "49222",
        nm: "Torres del Carrizal",
      },
      {
        id: "49223",
        nm: "Trabazos",
      },
      {
        id: "49224",
        nm: "Trefacio",
      },
      {
        id: "49225",
        nm: "Uña de Quintana",
      },
      {
        id: "49226",
        nm: "Vadillo de la Guareña",
      },
      {
        id: "49227",
        nm: "Valcabado",
      },
      {
        id: "49228",
        nm: "Valdefinjas",
      },
      {
        id: "49229",
        nm: "Valdescorriel",
      },
      {
        id: "49230",
        nm: "Vallesa de la Guareña",
      },
      {
        id: "49231",
        nm: "Vega de Tera",
      },
      {
        id: "49232",
        nm: "Vega de Villalobos",
      },
      {
        id: "49233",
        nm: "Vegalatrave",
      },
      {
        id: "49234",
        nm: "Venialbo",
      },
      {
        id: "49235",
        nm: "Vezdemarbán",
      },
      {
        id: "49236",
        nm: "Vidayanes",
      },
      {
        id: "49237",
        nm: "Videmala",
      },
      {
        id: "49238",
        nm: "Villabrázaro",
      },
      {
        id: "49239",
        nm: "Villabuena del Puente",
      },
      {
        id: "49240",
        nm: "Villadepera",
      },
      {
        id: "49241",
        nm: "Villaescusa",
      },
      {
        id: "49242",
        nm: "Villafáfila",
      },
      {
        id: "49243",
        nm: "Villaferrueña",
      },
      {
        id: "49244",
        nm: "Villageriz",
      },
      {
        id: "49245",
        nm: "Villalazán",
      },
      {
        id: "49246",
        nm: "Villalba de la Lampreana",
      },
      {
        id: "49247",
        nm: "Villalcampo",
      },
      {
        id: "49248",
        nm: "Villalobos",
      },
      {
        id: "49249",
        nm: "Villalonso",
      },
      {
        id: "49250",
        nm: "Villalpando",
      },
      {
        id: "49251",
        nm: "Villalube",
      },
      {
        id: "49252",
        nm: "Villamayor de Campos",
      },
      {
        id: "49255",
        nm: "Villamor de los Escuderos",
      },
      {
        id: "49256",
        nm: "Villanázar",
      },
      {
        id: "49257",
        nm: "Villanueva de Azoague",
      },
      {
        id: "49258",
        nm: "Villanueva de Campeán",
      },
      {
        id: "49259",
        nm: "Villanueva de las Peras",
      },
      {
        id: "49260",
        nm: "Villanueva del Campo",
      },
      {
        id: "49263",
        nm: "Villar de Fallaves",
      },
      {
        id: "49264",
        nm: "Villar del Buey",
      },
      {
        id: "49261",
        nm: "Villaralbo",
      },
      {
        id: "49262",
        nm: "Villardeciervos",
      },
      {
        id: "49265",
        nm: "Villardiegua de la Ribera",
      },
      {
        id: "49266",
        nm: "Villárdiga",
      },
      {
        id: "49267",
        nm: "Villardondiego",
      },
      {
        id: "49268",
        nm: "Villarrín de Campos",
      },
      {
        id: "49269",
        nm: "Villaseco del Pan",
      },
      {
        id: "49270",
        nm: "Villavendimio",
      },
      {
        id: "49272",
        nm: "Villaveza de Valverde",
      },
      {
        id: "49271",
        nm: "Villaveza del Agua",
      },
      {
        id: "49273",
        nm: "Viñas",
      },
      {
        id: "49275",
        nm: "Zamora",
      },
      {
        id: "50001",
        nm: "Abanto",
      },
      {
        id: "50002",
        nm: "Acered",
      },
      {
        id: "50003",
        nm: "Agón",
      },
      {
        id: "50004",
        nm: "Aguarón",
      },
      {
        id: "50005",
        nm: "Aguilón",
      },
      {
        id: "50006",
        nm: "Ainzón",
      },
      {
        id: "50007",
        nm: "Aladrén",
      },
      {
        id: "50008",
        nm: "Alagón",
      },
      {
        id: "50009",
        nm: "Alarba",
      },
      {
        id: "50010",
        nm: "Alberite de San Juan",
      },
      {
        id: "50011",
        nm: "Albeta",
      },
      {
        id: "50012",
        nm: "Alborge",
      },
      {
        id: "50013",
        nm: "Alcalá de Ebro",
      },
      {
        id: "50014",
        nm: "Alcalá de Moncayo",
      },
      {
        id: "50015",
        nm: "Alconchel de Ariza",
      },
      {
        id: "50016",
        nm: "Aldehuela de Liestos",
      },
      {
        id: "50017",
        nm: "Alfajarín",
      },
      {
        id: "50018",
        nm: "Alfamén",
      },
      {
        id: "50019",
        nm: "Alforque",
      },
      {
        id: "50020",
        nm: "Alhama de Aragón",
      },
      {
        id: "50021",
        nm: "Almochuel",
      },
      {
        id: "50022",
        nm: "Almolda, La",
      },
      {
        id: "50023",
        nm: "Almonacid de la Cuba",
      },
      {
        id: "50024",
        nm: "Almonacid de la Sierra",
      },
      {
        id: "50025",
        nm: "Almunia de Doña Godina, La",
      },
      {
        id: "50026",
        nm: "Alpartir",
      },
      {
        id: "50027",
        nm: "Ambel",
      },
      {
        id: "50028",
        nm: "Anento",
      },
      {
        id: "50029",
        nm: "Aniñón",
      },
      {
        id: "50030",
        nm: "Añón de Moncayo",
      },
      {
        id: "50031",
        nm: "Aranda de Moncayo",
      },
      {
        id: "50032",
        nm: "Arándiga",
      },
      {
        id: "50033",
        nm: "Ardisa",
      },
      {
        id: "50034",
        nm: "Ariza",
      },
      {
        id: "50035",
        nm: "Artieda",
      },
      {
        id: "50036",
        nm: "Asín",
      },
      {
        id: "50037",
        nm: "Atea",
      },
      {
        id: "50038",
        nm: "Ateca",
      },
      {
        id: "50039",
        nm: "Azuara",
      },
      {
        id: "50040",
        nm: "Badules",
      },
      {
        id: "50041",
        nm: "Bagüés",
      },
      {
        id: "50042",
        nm: "Balconchán",
      },
      {
        id: "50043",
        nm: "Bárboles",
      },
      {
        id: "50044",
        nm: "Bardallur",
      },
      {
        id: "50045",
        nm: "Belchite",
      },
      {
        id: "50046",
        nm: "Belmonte de Gracián",
      },
      {
        id: "50047",
        nm: "Berdejo",
      },
      {
        id: "50048",
        nm: "Berrueco",
      },
      {
        id: "50901",
        nm: "Biel",
      },
      {
        id: "50050",
        nm: "Bijuesca",
      },
      {
        id: "50051",
        nm: "Biota",
      },
      {
        id: "50052",
        nm: "Bisimbre",
      },
      {
        id: "50053",
        nm: "Boquiñeni",
      },
      {
        id: "50054",
        nm: "Bordalba",
      },
      {
        id: "50055",
        nm: "Borja",
      },
      {
        id: "50056",
        nm: "Botorrita",
      },
      {
        id: "50057",
        nm: "Brea de Aragón",
      },
      {
        id: "50058",
        nm: "Bubierca",
      },
      {
        id: "50059",
        nm: "Bujaraloz",
      },
      {
        id: "50060",
        nm: "Bulbuente",
      },
      {
        id: "50061",
        nm: "Bureta",
      },
      {
        id: "50062",
        nm: "Burgo de Ebro, El",
      },
      {
        id: "50063",
        nm: "Buste, El",
      },
      {
        id: "50064",
        nm: "Cabañas de Ebro",
      },
      {
        id: "50065",
        nm: "Cabolafuente",
      },
      {
        id: "50066",
        nm: "Cadrete",
      },
      {
        id: "50067",
        nm: "Calatayud",
      },
      {
        id: "50068",
        nm: "Calatorao",
      },
      {
        id: "50069",
        nm: "Calcena",
      },
      {
        id: "50070",
        nm: "Calmarza",
      },
      {
        id: "50071",
        nm: "Campillo de Aragón",
      },
      {
        id: "50072",
        nm: "Carenas",
      },
      {
        id: "50073",
        nm: "Cariñena",
      },
      {
        id: "50074",
        nm: "Caspe",
      },
      {
        id: "50075",
        nm: "Castejón de Alarba",
      },
      {
        id: "50076",
        nm: "Castejón de las Armas",
      },
      {
        id: "50077",
        nm: "Castejón de Valdejasa",
      },
      {
        id: "50078",
        nm: "Castiliscar",
      },
      {
        id: "50079",
        nm: "Cervera de la Cañada",
      },
      {
        id: "50080",
        nm: "Cerveruela",
      },
      {
        id: "50081",
        nm: "Cetina",
      },
      {
        id: "50092",
        nm: "Chiprana",
      },
      {
        id: "50093",
        nm: "Chodes",
      },
      {
        id: "50082",
        nm: "Cimballa",
      },
      {
        id: "50083",
        nm: "Cinco Olivas",
      },
      {
        id: "50084",
        nm: "Clarés de Ribota",
      },
      {
        id: "50085",
        nm: "Codo",
      },
      {
        id: "50086",
        nm: "Codos",
      },
      {
        id: "50087",
        nm: "Contamina",
      },
      {
        id: "50088",
        nm: "Cosuenda",
      },
      {
        id: "50089",
        nm: "Cuarte de Huerva",
      },
      {
        id: "50090",
        nm: "Cubel",
      },
      {
        id: "50091",
        nm: "Cuerlas, Las",
      },
      {
        id: "50094",
        nm: "Daroca",
      },
      {
        id: "50095",
        nm: "Ejea de los Caballeros",
      },
      {
        id: "50096",
        nm: "Embid de Ariza",
      },
      {
        id: "50098",
        nm: "Encinacorba",
      },
      {
        id: "50099",
        nm: "Épila",
      },
      {
        id: "50100",
        nm: "Erla",
      },
      {
        id: "50101",
        nm: "Escatrón",
      },
      {
        id: "50102",
        nm: "Fabara",
      },
      {
        id: "50104",
        nm: "Farlete",
      },
      {
        id: "50105",
        nm: "Fayón",
      },
      {
        id: "50106",
        nm: "Fayos, Los",
      },
      {
        id: "50107",
        nm: "Figueruelas",
      },
      {
        id: "50108",
        nm: "Fombuena",
      },
      {
        id: "50109",
        nm: "Frago, El",
      },
      {
        id: "50110",
        nm: "Frasno, El",
      },
      {
        id: "50111",
        nm: "Fréscano",
      },
      {
        id: "50113",
        nm: "Fuendejalón",
      },
      {
        id: "50114",
        nm: "Fuendetodos",
      },
      {
        id: "50115",
        nm: "Fuentes de Ebro",
      },
      {
        id: "50116",
        nm: "Fuentes de Jiloca",
      },
      {
        id: "50117",
        nm: "Gallocanta",
      },
      {
        id: "50118",
        nm: "Gallur",
      },
      {
        id: "50119",
        nm: "Gelsa",
      },
      {
        id: "50120",
        nm: "Godojos",
      },
      {
        id: "50121",
        nm: "Gotor",
      },
      {
        id: "50122",
        nm: "Grisel",
      },
      {
        id: "50123",
        nm: "Grisén",
      },
      {
        id: "50124",
        nm: "Herrera de los Navarros",
      },
      {
        id: "50125",
        nm: "Ibdes",
      },
      {
        id: "50126",
        nm: "Illueca",
      },
      {
        id: "50128",
        nm: "Isuerre",
      },
      {
        id: "50129",
        nm: "Jaraba",
      },
      {
        id: "50130",
        nm: "Jarque",
      },
      {
        id: "50131",
        nm: "Jaulín",
      },
      {
        id: "50132",
        nm: "Joyosa, La",
      },
      {
        id: "50133",
        nm: "Lagata",
      },
      {
        id: "50134",
        nm: "Langa del Castillo",
      },
      {
        id: "50135",
        nm: "Layana",
      },
      {
        id: "50136",
        nm: "Lécera",
      },
      {
        id: "50138",
        nm: "Lechón",
      },
      {
        id: "50137",
        nm: "Leciñena",
      },
      {
        id: "50139",
        nm: "Letux",
      },
      {
        id: "50140",
        nm: "Litago",
      },
      {
        id: "50141",
        nm: "Lituénigo",
      },
      {
        id: "50142",
        nm: "Lobera de Onsella",
      },
      {
        id: "50143",
        nm: "Longares",
      },
      {
        id: "50144",
        nm: "Longás",
      },
      {
        id: "50146",
        nm: "Lucena de Jalón",
      },
      {
        id: "50147",
        nm: "Luceni",
      },
      {
        id: "50148",
        nm: "Luesia",
      },
      {
        id: "50149",
        nm: "Luesma",
      },
      {
        id: "50150",
        nm: "Lumpiaque",
      },
      {
        id: "50151",
        nm: "Luna",
      },
      {
        id: "50152",
        nm: "Maella",
      },
      {
        id: "50153",
        nm: "Magallón",
      },
      {
        id: "50154",
        nm: "Mainar",
      },
      {
        id: "50155",
        nm: "Malanquilla",
      },
      {
        id: "50156",
        nm: "Maleján",
      },
      {
        id: "50160",
        nm: "Mallén",
      },
      {
        id: "50157",
        nm: "Malón",
      },
      {
        id: "50159",
        nm: "Maluenda",
      },
      {
        id: "50161",
        nm: "Manchones",
      },
      {
        id: "50162",
        nm: "Mara",
      },
      {
        id: "50163",
        nm: "María de Huerva",
      },
      {
        id: "50902",
        nm: "Marracos",
      },
      {
        id: "50164",
        nm: "Mediana de Aragón",
      },
      {
        id: "50165",
        nm: "Mequinenza",
      },
      {
        id: "50166",
        nm: "Mesones de Isuela",
      },
      {
        id: "50167",
        nm: "Mezalocha",
      },
      {
        id: "50168",
        nm: "Mianos",
      },
      {
        id: "50169",
        nm: "Miedes de Aragón",
      },
      {
        id: "50170",
        nm: "Monegrillo",
      },
      {
        id: "50171",
        nm: "Moneva",
      },
      {
        id: "50172",
        nm: "Monreal de Ariza",
      },
      {
        id: "50173",
        nm: "Monterde",
      },
      {
        id: "50174",
        nm: "Montón",
      },
      {
        id: "50175",
        nm: "Morata de Jalón",
      },
      {
        id: "50176",
        nm: "Morata de Jiloca",
      },
      {
        id: "50177",
        nm: "Morés",
      },
      {
        id: "50178",
        nm: "Moros",
      },
      {
        id: "50179",
        nm: "Moyuela",
      },
      {
        id: "50180",
        nm: "Mozota",
      },
      {
        id: "50181",
        nm: "Muel",
      },
      {
        id: "50182",
        nm: "Muela, La",
      },
      {
        id: "50183",
        nm: "Munébrega",
      },
      {
        id: "50184",
        nm: "Murero",
      },
      {
        id: "50185",
        nm: "Murillo de Gállego",
      },
      {
        id: "50186",
        nm: "Navardún",
      },
      {
        id: "50187",
        nm: "Nigüella",
      },
      {
        id: "50188",
        nm: "Nombrevilla",
      },
      {
        id: "50189",
        nm: "Nonaspe",
      },
      {
        id: "50190",
        nm: "Novallas",
      },
      {
        id: "50191",
        nm: "Novillas",
      },
      {
        id: "50192",
        nm: "Nuévalos",
      },
      {
        id: "50193",
        nm: "Nuez de Ebro",
      },
      {
        id: "50194",
        nm: "Olvés",
      },
      {
        id: "50195",
        nm: "Orcajo",
      },
      {
        id: "50196",
        nm: "Orera",
      },
      {
        id: "50197",
        nm: "Orés",
      },
      {
        id: "50198",
        nm: "Oseja",
      },
      {
        id: "50199",
        nm: "Osera de Ebro",
      },
      {
        id: "50200",
        nm: "Paniza",
      },
      {
        id: "50201",
        nm: "Paracuellos de Jiloca",
      },
      {
        id: "50202",
        nm: "Paracuellos de la Ribera",
      },
      {
        id: "50203",
        nm: "Pastriz",
      },
      {
        id: "50204",
        nm: "Pedrola",
      },
      {
        id: "50205",
        nm: "Pedrosas, Las",
      },
      {
        id: "50206",
        nm: "Perdiguera",
      },
      {
        id: "50207",
        nm: "Piedratajada",
      },
      {
        id: "50208",
        nm: "Pina de Ebro",
      },
      {
        id: "50209",
        nm: "Pinseque",
      },
      {
        id: "50210",
        nm: "Pintanos, Los",
      },
      {
        id: "50211",
        nm: "Plasencia de Jalón",
      },
      {
        id: "50212",
        nm: "Pleitas",
      },
      {
        id: "50213",
        nm: "Plenas",
      },
      {
        id: "50214",
        nm: "Pomer",
      },
      {
        id: "50215",
        nm: "Pozuel de Ariza",
      },
      {
        id: "50216",
        nm: "Pozuelo de Aragón",
      },
      {
        id: "50217",
        nm: "Pradilla de Ebro",
      },
      {
        id: "50218",
        nm: "Puebla de Albortón",
      },
      {
        id: "50219",
        nm: "Puebla de Alfindén, La",
      },
      {
        id: "50220",
        nm: "Puendeluna",
      },
      {
        id: "50221",
        nm: "Purujosa",
      },
      {
        id: "50222",
        nm: "Quinto",
      },
      {
        id: "50223",
        nm: "Remolinos",
      },
      {
        id: "50224",
        nm: "Retascón",
      },
      {
        id: "50225",
        nm: "Ricla",
      },
      {
        id: "50227",
        nm: "Romanos",
      },
      {
        id: "50228",
        nm: "Rueda de Jalón",
      },
      {
        id: "50229",
        nm: "Ruesca",
      },
      {
        id: "50241",
        nm: "Sabiñán",
      },
      {
        id: "50230",
        nm: "Sádaba",
      },
      {
        id: "50231",
        nm: "Salillas de Jalón",
      },
      {
        id: "50232",
        nm: "Salvatierra de Esca",
      },
      {
        id: "50233",
        nm: "Samper del Salz",
      },
      {
        id: "50234",
        nm: "San Martín de la Virgen de Moncayo",
      },
      {
        id: "50235",
        nm: "San Mateo de Gállego",
      },
      {
        id: "50236",
        nm: "Santa Cruz de Grío",
      },
      {
        id: "50237",
        nm: "Santa Cruz de Moncayo",
      },
      {
        id: "50238",
        nm: "Santa Eulalia de Gállego",
      },
      {
        id: "50239",
        nm: "Santed",
      },
      {
        id: "50240",
        nm: "Sástago",
      },
      {
        id: "50242",
        nm: "Sediles",
      },
      {
        id: "50243",
        nm: "Sestrica",
      },
      {
        id: "50244",
        nm: "Sierra de Luna",
      },
      {
        id: "50245",
        nm: "Sigüés",
      },
      {
        id: "50246",
        nm: "Sisamón",
      },
      {
        id: "50247",
        nm: "Sobradiel",
      },
      {
        id: "50248",
        nm: "Sos del Rey Católico",
      },
      {
        id: "50249",
        nm: "Tabuenca",
      },
      {
        id: "50250",
        nm: "Talamantes",
      },
      {
        id: "50251",
        nm: "Tarazona",
      },
      {
        id: "50252",
        nm: "Tauste",
      },
      {
        id: "50253",
        nm: "Terrer",
      },
      {
        id: "50254",
        nm: "Tierga",
      },
      {
        id: "50255",
        nm: "Tobed",
      },
      {
        id: "50256",
        nm: "Torralba de los Frailes",
      },
      {
        id: "50257",
        nm: "Torralba de Ribota",
      },
      {
        id: "50258",
        nm: "Torralbilla",
      },
      {
        id: "50259",
        nm: "Torrehermosa",
      },
      {
        id: "50260",
        nm: "Torrelapaja",
      },
      {
        id: "50261",
        nm: "Torrellas",
      },
      {
        id: "50262",
        nm: "Torres de Berrellén",
      },
      {
        id: "50263",
        nm: "Torrijo de la Cañada",
      },
      {
        id: "50264",
        nm: "Tosos",
      },
      {
        id: "50265",
        nm: "Trasmoz",
      },
      {
        id: "50266",
        nm: "Trasobares",
      },
      {
        id: "50267",
        nm: "Uncastillo",
      },
      {
        id: "50268",
        nm: "Undués de Lerda",
      },
      {
        id: "50269",
        nm: "Urrea de Jalón",
      },
      {
        id: "50270",
        nm: "Urriés",
      },
      {
        id: "50271",
        nm: "Used",
      },
      {
        id: "50272",
        nm: "Utebo",
      },
      {
        id: "50274",
        nm: "Val de San Martín",
      },
      {
        id: "50273",
        nm: "Valdehorna",
      },
      {
        id: "50275",
        nm: "Valmadrid",
      },
      {
        id: "50276",
        nm: "Valpalmas",
      },
      {
        id: "50277",
        nm: "Valtorres",
      },
      {
        id: "50278",
        nm: "Velilla de Ebro",
      },
      {
        id: "50279",
        nm: "Velilla de Jiloca",
      },
      {
        id: "50280",
        nm: "Vera de Moncayo",
      },
      {
        id: "50281",
        nm: "Vierlas",
      },
      {
        id: "50283",
        nm: "Villadoz",
      },
      {
        id: "50284",
        nm: "Villafeliche",
      },
      {
        id: "50285",
        nm: "Villafranca de Ebro",
      },
      {
        id: "50286",
        nm: "Villalba de Perejil",
      },
      {
        id: "50287",
        nm: "Villalengua",
      },
      {
        id: "50903",
        nm: "Villamayor de Gállego",
      },
      {
        id: "50288",
        nm: "Villanueva de Gállego",
      },
      {
        id: "50290",
        nm: "Villanueva de Huerva",
      },
      {
        id: "50289",
        nm: "Villanueva de Jiloca",
      },
      {
        id: "50291",
        nm: "Villar de los Navarros",
      },
      {
        id: "50292",
        nm: "Villarreal de Huerva",
      },
      {
        id: "50293",
        nm: "Villarroya de la Sierra",
      },
      {
        id: "50294",
        nm: "Villarroya del Campo",
      },
      {
        id: "50282",
        nm: "Vilueña, La",
      },
      {
        id: "50295",
        nm: "Vistabella",
      },
      {
        id: "50296",
        nm: "Zaida, La",
      },
      {
        id: "50297",
        nm: "Zaragoza",
      },
      {
        id: "50298",
        nm: "Zuera",
      },
      {
        id: "51001",
        nm: "Ceuta",
      },
      {
        id: "52001",
        nm: "Melilla",
      },
    ],
  },
  plugins: [
    createPersistedState({ storage: window.sessionStorage, paths: ["auth", "user"] }),
    createMultiTabState(),
  ],
  mutations: {
    modifyAuth(state, newAuth) {
      state.auth = newAuth;
    },
    modifyNameUser(state, newName) {
      state.user = newName;
    },
  },
  actions: {
    async loadData() {
      if (this.state.auth != "") {
        await this.dispatch("getProductor");
        await this.dispatch("getProductos");
        await this.dispatch("getPrecios");
        await this.dispatch("getUser");
      }
    },
    async getProductor() {
      const response = await genericReload(this.state.auth, `productor`);
      if (response.status === 200) {
        const json = await response.json();
        this.state.productor = json.body;
      }
    },
    async getProductos() {
      const response = await genericReload(this.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.state.productos = json.body;
      }
    },
    async getPrecios() {
      const response = await genericReload(
        this.state.auth,
        `fijacionPrecio`
      );
      if (response.status === 200) {
        const json = await response.json();
        this.state.precios = json.body;
      }
    },
    async getUser() {
      const response = await genericReload(this.state.auth, `almazara`);
      if (response.status === 200) {
        const json = await response.json();
        this.state.user = json.body.nombre;
      }
    },
    async getFincas() {
      const response = await genericReload(this.state.auth, `finca`);
      if (response.status === 200) {
        const json = await response.json();
        this.state.fincas = json.body;
      }
    },
  },
  getters: {
    productorById: (state) => (id) => {
      return state.productor.find((p) => p.id === id);
    },
    productsById: (state) => (id) => {
      return state.productos.find((product) => product.id === id);
    },
    productsByType: (state) => (type) => {
      return state.productos.filter((product) => product.producto === type);
    },
    municipioByProvincia: (state) => (id) => {
      return state.municipios.filter((mun) => mun.id.substr(0,2) === id);
    },
    provinciaById: (state) => (id) => {
      return state.provincias.find((pro) => pro.id == id);
    },
    provinciaByName: (state) => (nm) => {
      return state.provincias.find((pro) => pro.nm == nm);
    },
    provincia: (state) => {
      return state.provincias;
    }
  },
});
