<template>
  <v-app>
    <v-app-bar
      app
      color="primary_dark"
      dark
      class="elevation-12"
      v-if="$route.name != 'Login' && $route.name != 'RecoverPass'"
    >
      <div class="d-flex align-center">
        <v-img
          alt="OliCloud Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="20"
        />
        oli<span style="color:#91dc5a">Cloud</span>®
      </div>

      <v-spacer></v-spacer>

      <v-btn
        @click="exit"
        text
      >
        <span class="mr-2">Salir</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <NavBar v-if="$route.name != 'Login' && $route.name != 'RecoverPass'" />
      <v-container class="cMain" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import {BASE_URL} from '@/components/constants.js'
export default {
  name: 'App',
  components: {
    NavBar
  },
  mounted(){
    if(this.$store.state.auth){
      this.checkJWT();
    }else{
      this.$store.commit('modifyAuth','');
      location.href = "https://olicloud.es/acceso";
    }
  },
  methods: {
    async checkJWT(){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };

      const response = await fetch(
        `${BASE_URL}/auth/checkJWT`,
        {
          method: 'POST',
          headers: headers,
        }
      );
      if(response.status == 200){
        await this.$store.dispatch('loadData');
      }else{
        this.$store.commit('modifyAuth','');
        location.href = "https://olicloud.es/acceso";
      }
    },
    exit(){
      this.$store.commit('modifyAuth','');
      this.$router.push({ name: "Login" });
    }
  },
};
</script>
<style>
.cMain{
  padding: 0px !important;
}
h1{
  font-weight: 600;
  color: var(--v-primary-base);
  text-transform: uppercase;
  border-bottom: 3px solid var(--v-secondary-base);
}
.btnCustom{
  background-color: var(--v-primary-base) !important;
  color: var(--v-white-base) !important;
}
.save{
  background-color: var(--v-secondary_dark-base) !important;
  color: var(--v-white-base) !important;
}
.delete{
  background-color: var(--v-red-base) !important;
  color: var(--v-white-base) !important;
}
.btnMini{
  color: var(--v-primary-base) !important;
  width: 100% !important;
  height: 60% !important;
}
.btnIcon{
  margin-right: 8px;
}
.iconTitle{
  color: var(--v-secondary-base) !important;
}
</style>